<template>
  <div v-if="firstBatchOfThumbnailsAvailable">
    <pictureBox
      v-for="item in options.data"
      :key="item.FileName"
      :options="options"
      :template="item"
      keep-alive />
  </div>
</template>

<script>
import config from '../../configurations/config.js'
import pictureBox from '@/components/box-controls/pictureBox.vue'

export default {
  name: 'pictureBoxes',
  components: {
    pictureBox
  },
  props: ['template', 'options'],
  data() {
    return {
      firstBatchOfThumbnailsAvailable: false,
      mediaClassification: config.constants.MediaClassification.ProjectPhoto
    }
  },
  async mounted () {
    await this.ensureThumbnailsAreCached()
  },
  methods: {
    async ensureThumbnailsAreCached() {
      const fileNames = this.options.data.map(item => item.FileName)

      await this.$store
        .dispatch('getMediaThumbnailBatch', {
          PrGuid: this.options.prGuid,
          FileNames: fileNames,
          mediaClassification: this.mediaClassification,
          firstBatchOfThumbnailsAvailableCallback: this.onFirstBatchOfThumbnailsAvailable
        })
    },
    onFirstBatchOfThumbnailsAvailable() {
      this.firstBatchOfThumbnailsAvailable = true
    }
  }
}
</script>
