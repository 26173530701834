<template>
  <a :ref="'fiBox-' + template.FiGuid" :class="classes" href="javascript:" :style="{ cursor: (this.options || {}).enableBoxLink ? 'pointer' : 'default' }" @click="gotoDetails()">
    <div class="arrow"></div>
    <div class="item">
      <div class="text">
        <p :title="FiTypeCodeText">
          <strong>{{ FiTypeCodeText }}</strong>
        </p>
        <p v-if="template.FiFrom" :title="template.FiFrom + '-' + template.FiTo">{{ template.FiFrom }} - {{ template.FiTo }} cm</p>
      </div>
    </div>
  </a>
</template>
<script>
export default {
  name: 'finishingsBox',
  props: ['template', 'functions', 'options'],
  data() {
    return {
      classes: 'contents',
      isActive: false
    }
  },
  computed: {
    FiTypeCodeText() {
      let codeList = this.$store.getters.getTemplateField('finishtypeList')
      if (codeList instanceof Object && this.template.FiTypeCode) {
        let item = codeList.find((v) => v.value === this.template.FiTypeCode)
        if (item) {
          return item.desc
        }
      }
      return this.template.FiTypeCode
    },
    getTemplateField: {
      get() {
        return this.$store.state.getTemplateField
      }
    }
  },
  watch: {
    '$route.params.FiID': {
      handler: function (val) {
        if (!val) {
          this.classes = 'contents'
          return
        }
        this.isActive = val === this.template.FiID
        this.classes = this.isActive ? 'contents active' : 'contents'
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    if (this.isActive) {
      this.$nextTick(() => this.$refs['fiBox-' + this.template.FiGuid].scrollIntoView({ behavior: 'smooth', block: 'center' }))
    }
  },

  updated() {
    if (this.isActive) {
      this.$nextTick(() => this.$refs['fiBox-' + this.template.FiGuid].scrollIntoView({ behavior: 'smooth', block: 'center' }))
    }
  },

  methods: {
    gotoDetails() {
      let prid = this.$route.params.projectId
      let mpguid = this.options.MpGuid || this.$route.params.measurementPointId
      let fiID = this.template.FiID
      this.detailUrl = `/project/${prid}/measurementPoint/${mpguid}/finishings/${fiID}`
      if ((this.options || {}).enableBoxLink) {
        this.$router.push(this.detailUrl)
      }
    }
  }
}
</script>
