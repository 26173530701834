<template>
  <a :id="template.FileName + '_' + mediaClassification" :href="externalLink" target="new" :class="styleClasses" :title="template.FileName" @contextmenu="contextMenu(template, $event)">
    <div class="item">
      <p><strong>{{ fileIconText(template.FileName) }}</strong></p>
      <p style="word-wrap: break-word; text-overflow: ellipsis; overflow: hidden; white-space: nowrap">{{ template.FileName }}</p>
    </div>
  </a>
</template>
<script>
import popupUtils from '@/utils/popupUtils'
import config from '../../configurations/config.js'
import stringUtils from '@/utils/stringUtils'

export default {
  name: 'klicFileBox',
  props: ['template', 'options'],
  data() {
    return {
      styleClasses: '',
      externalLink: '',
      mediaClassification: config.constants.MediaClassification.KLICFiles
    }
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.optionsChanged()
      }
    }
  },
  computed: {
    selectedElement: {
      set(value) {
        this.$store.commit('setSelectedElement', value)
      },
      get() {
        return this.$store.state.selectedElement
      }
    }
  },
  methods: {
    optionsChanged() {
      this.styleClasses = ''
      this.externalLink = ''
      let optionsObject = this.options || {}
      if (!optionsObject.externalLink || (optionsObject.externalLink && optionsObject.externalLink !== '')) {
        this.externalLink = `${optionsObject.externalLink}&preview=${encodeURIComponent(this.template.FileName)}`
      }

      if (optionsObject.enableBoxLink) {
        this.styleClasses += 'buttonClickEnabled'
      }
    },
    contextMenu: function (projectDocument, e) {
      this.selectedElement = {
        type: 'ATTACHMENT',
        id: 'attachement-' + projectDocument.FileName,
        fileName: projectDocument.FileName,
        mediaClassification: this.mediaClassification
      }
      popupUtils.popup(e, '#projectContextMenuPopup', { elementAnchor: 'rb', popupAnchor: 'cc' })
      e.preventDefault()
    },
    fileIconText: function (filename) {
      let fileExtension = stringUtils.getFileExtension(filename)
      switch (fileExtension) {
        case 'pdf':
          return 'PDF'
        case 'xlsx':
        case 'xlsm':
        case 'xls':
          return 'EXCEL'
        case 'doc':
        case 'docm':
        case 'docx':
        case 'dotm':
          return 'WORD'
        case 'txt':
          return 'T'
        case 'tif':
        case 'tiff':
          return 'G'
        case 'msg':
          return 'M'
        case 'dxf':
        case 'dwg':
          return 'D'
        case 'shp':
          return 'S'
        default:
          return ''
      }
    }
  }
}
</script>

<style scoped>
</style>
