var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body layersDetail"},[_c('app-header'),_c('div',{staticClass:"sync-scroll gray"},[(_vm.workingObject && _vm.currentMeasurementPoint && _vm.currentMeasurementPoint.MpGuid && _vm.currentMeasurementPoint.PrID)?_c('boxes',{attrs:{"options":{
    bg: 'gray',
    title: 'label.layers',
    type:'layerBox',
    businesskey:'layer',
    fieldname: 'LaSoilType',
    isfieldRequest: true,
    isMultiple: true,
    modal:'laModal',
    data: _vm.layers,
    PrID: _vm.currentMeasurementPoint.PrID,
    MpGuid: _vm.currentMeasurementPoint.MpGuid,
      buttons: ['addButton'],
    enableBoxLink: true}}}):_vm._e(),(_vm.workingObject && _vm.currentMeasurementPoint && _vm.currentMeasurementPoint.MpGuid && _vm.currentMeasurementPoint.PrID)?_c('boxes',{attrs:{"options":{
    bg: 'gray',
    title: 'label.soilSamples',
    type:'soilSampleBox',
    businesskey:'soilSample',
    modal:'saModal',
    data: _vm.soilSamples,
    PrID: _vm.currentMeasurementPoint.PrID,
    MpGuid: _vm.currentMeasurementPoint.MpGuid,
    buttons: ['addButton'],
    enableBoxLink: true}}}):_vm._e()],1),(_vm.workingObject && _vm.workingObjectType === 'layer')?_c('editors',{attrs:{"screen":"frmLayerDetails"}}):_vm._e(),(_vm.workingObject && _vm.workingObjectType === 'soilSample')?_c('editors',{attrs:{"screen":"frmJarDetails"}}):_vm._e(),_c('nv-loading',{attrs:{"show":_vm.loading}}),_c('app-footer',{attrs:{"saveComponentDataFunction":_vm.save}}),_c('ExportProjectPopup',{attrs:{"exportPopupOpen":_vm.exportPopupOpen},on:{"closeButtonClicked":_vm.toggleExportPopup}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }