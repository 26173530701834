<template>
  <div>
    <!-- Modal add summary and conclusion-->
    <div id="modalAddSummaryAndConclusion" class="modal fade"
         tabindex="-1"
         @keypress="checkSubmit">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button"
                    class="close"
                    data-dismiss="modal">&times;</button>
            <h4 id="modalAddSummaryAndConclusionTitle"
                class="modal-title">{{$t("project.AddSummaryAndConclusionTitle")}}</h4>
          </div>
          <div class="modal-body">
            <p>{{$t("project.FinishTypeCode")}}</p>
            <select v-model='selection'
                    class="form-control" style="width:100%;">
              <option value=''>{{$t("project.SelectOne")}}</option>
              <option v-for="selection in summaryAndConclusionsOptions" :key="selection.key"
                      :value="selection.key">{{selection.value}}</option>
            </select>
            <p v-if="!valid" class="error">{{$t(errorMessage)}}</p>
          </div>
          <div class="modal-footer">
            <button id="btnAddSummaryAndConclusion"
                    type="button"
                    class="btn"
                    v-bind:class="{ disabled: !enableAddButton }"
                    @click="add">{{$t("project.CreateButton")}}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal delete summary and conclusion-->
    <div id="modalDeleteSummaryAndConclusion"
         class="modal fade"
         tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button"
                    class="close"
                    data-dismiss="modal">&times;</button>
            <h4 id="modalDeleteSummaryAndConclusionTitle"
                class="modal-title">{{$t("project.DeleteSummaryAndConlcusion")}}</h4>
          </div>
          <div class="modal-body">
            <p>{{$t("project.DeleteSummaryAndConclusionHelp")}}</p>
          </div>
          <div class="modal-footer">
            <button id="btnDeleteSummaryAndConclusion"
                    class="btn"
                    @click="deleteFinishing">{{$t("project.DeleteButton")}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
'use strict'
import { mapGetters } from 'vuex'
import sortUtils from '@/utils/sortUtils'

export default {
  name: 'sncModal',
  props: ['functions', 'option'],

  data () {
    return {
      selection: '',
      errorMessage: 'message.valueRequired',
      valid: false
    }
  },

  computed: {
    ...mapGetters(['getSummaryAndConclusionsTypes', 'getSummaryAndConclusions']),
    enableAddButton () {
      return this.valid && !this.$parent.loading
    },
    summaryAndConclusionsOptions: {
      get () {
        // Filter already selected options to avoid creating dublicates (only one SmType allowed per project)
        let filtered = [...this.getSummaryAndConclusionsTypes]
        this.getSummaryAndConclusions.forEach(element => {
          let index = filtered.findIndex(item => item.key === element.SmType)
          if (index >= 0) {
            filtered.splice(index, 1)
          }
        })
        return filtered.sort((a, b) => sortUtils.naturalSort(a.value, b.value))
      },
      set (value) {
        this.$store.dispatch('setTemplateField', { key: 'SmType', value: value })
      }
    }
  },
  watch: {
    'selection': {
      handler: function () {
        this.validate()
      }
    }
  },
  mounted () {
  },

  methods: {
    checkSubmit (evt) {
      if (evt.key === 'Enter' && this.valid) {
        this.add()
      }
    },
    validate () {
      let FiTypeField = {
        key: 'FiTypeCode',
        requiredBySystem: true,
        requiredByUser: true,
        maxLength: 10
      }
      this.$validateEditor(FiTypeField, this.selection)
        .then(() => {
          this.valid = true
        }).catch(reason => {
        // extract reason and set valid to false
          if (typeof reason.message === 'undefined') {
            console.error(reason)
          }
          this.valid = false
          this.errorMessage = reason.message
        })
    },
    add () {
      return this.functions.add({ selection: this.selection })
    },
    resetDefault () {
      this.selection = ''
      this.errorMessage = 'message.valueRequired'
    },
    deleteFinishing () {
      return this.functions.delete()
    }
  }
}
</script>
