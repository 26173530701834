<template>
  <div :id="'editor' + id" class="texture-code colour">
    <div class="input-group input-left">
      <input type="text" class="form-control" :class="{errorBorder: !valid}" readonly :value="validatedValueAsString" @click="$parent.expandPanel">
    </div>
    <i v-if="field.required" class="required">*</i>

    <div class="editor-panel hidden animated">
      <div class="close" @click="$parent.hidePanel(valid)">
        <span class="glyphicon glyphicon-chevron-right"></span>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label class="field-name">{{title}}</label>
          <div class="has-feedback">
            <div class="row">
              <div class="col-md-4">
                <div class="discription-box discription-box-color" style="height: 34px">
                  {{description}}
                  <span class="form-control-feedback glyphicon glyphicon-remove" @click="emptyValue"></span>
                </div>
              </div>
              <div class="col-md-8"></div>
            </div>
            <div v-if="!valid" class="row" >
              <div class="col-md-12">
                <label class="error">{{errorMessage}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <!--first list-->
        <div class="col-md-4 ">
          <label class="list-group-item field-name list-title">{{$t('label.gradation')}}</label>
          <div class="cardScroll">
            <ul v-for="(item, i) in listValue('gradation')" :key="i">
              <li
                :class="(item.value === currentValues.gradation) ? 'selected-item': ''"
                href="javascript:"
                class="item"
                @click="click(item,'gradation')"
              >{{item.description}}</li>
            </ul>
          </div>
        </div>

        <div class="col-md-4 ">
          <label class="list-group-item field-name list-title">{{$t('label.mainColour')}}</label>
          <div class="cardScroll">
            <ul v-for="(item, i) in listValue('mainColour')" :key="i">
              <li
                :class="(item.value === currentValues.mainColour) ? 'selected-item': ''"
                href="javascript:"
                class="item"
                @click="click(item,'mainColour')"
              >{{item.description}}</li>
            </ul>
          </div>
        </div>

        <div class="col-md-4 " :class="(currentValues.mainColour === '') ? 'disabled' : ''">
          <label class="list-group-item field-name list-title">{{$t('label.secondaryColour')}}</label>
          <div class="cardScroll">
            <ul v-for="(item, i) in getAllowedSubColours" :key="i">
              <li
                :class="(item.value === currentValues.subColour) ? 'selected-item': ''"
                href="javascript:"
                class="item"
                @click="click(item,'subColour')"
              >{{item.description}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Vue from 'vue'
import stringUtils from '@/utils/stringUtils'
import fieldCodeUtils from '@/utils/fieldCodeUtils'
import editorUtils from '@/utils/editorUtils'
import _ from 'lodash'

/**
   * Editor with which users can set the main colour, sub colour and gradation of an object (e.g. a layer).
   */
export default {
  name: 'colourEditor',
  props: ['field', 'functions', 'options'],

  data () {
    return {
      id: this._uid,
      errorMessage: null,
      title: String,
      valid: true,
      originalListData: {},
      buttonsData: {},
      activeCodeName: '',
      codeNameList: [],
      currentValues: {
        gradation: '',
        mainColour: '',
        subColour: ''
      },
      fieldnames: ['gradation', 'mainColour', 'subColour']
    }
  },
  computed: {
    description () {
      let desc = []
      for (let key in this.currentValues) {
        let items = this.buttonsData[key] || []
        let value = this.currentValues[key]

        if (!value) continue
        let item = items.find(v => v.value === value)
        if (item) { desc.push(stringUtils.capitalizeFirstLetter(item.description)) }
      }
      return desc.join('; ')
    },

    validatedValues: {
      get () {
        editorUtils.getEditorValue(this.field)

        let val = this.field.rawData

        // overwrite current value with 'valid' value from store and set valid to true. get() is called in the following cases:
        // - when the value of this.$store.state.workingObject[this.field.key] changed (e.g. because side effect or switching workingObject)
        // - when initializing the editor
        this.setCurrentValue(val)

        return val
      },
      set (value) {
        let result = editorUtils.setEditorValue(this.field, value)
        if (result.hasChanges) {
          this.$store.commit('updateWorkingObject', result.values)
        }
      }
    },
    /**
       * Computed property used to get the validated value as it should be shown to the user of the property
       * corresponding to this instance of the ListEditor.
       */
    validatedValueAsString: {
      get () {
        let text = []
        for (let key in this.validatedValues) {
          let items = this.buttonsData[key] || []
          let value = this.validatedValues[key]

          if (!value) continue
          let item = items.find(v => v.value === value)
          if (item) { text.push(stringUtils.capitalizeFirstLetter(item.description)) }
        }
        return text.join('; ')
      }
    },
    /**
       * Computed property used to get and set the current value as it should be shown to the user of the property
       * corresponding to this instance of the ListEditor.
       */
    currentValueAsString: {
      get () {
        let text = []

        for (let key in this.currentValues) {
          let items = this.buttonsData[key] || []
          let value = this.currentValues[key]

          if (!value) continue
          let item = items.find(v => v.value === value)
          if (item) { text.push(item.text) }
        }
        return text.join('')
      },
      set (v) {
        return v
      }
    },
    activeEditorValidationPending: {
      get () {
        return this.$store.state.activeEditorValidationPending
      },
      set (val) {
        this.$store.commit('setActiveEditorValidationPending', val)
      }
    },
    getAllowedSubColours () {
      if (this.buttonsData && this.buttonsData.subColour && this.buttonsData.subColour.length > 0) {
        return this.listValue('subColour').filter((sc) => {
          return sc.value !== this.currentValues.mainColour
        })
      } else {
        return []
      }
    }
  },
  watch: {
    'currentValues': {
      handler: function (val) {
        this.activeEditorValidationPending = true
        this.validateCurrentValue(this.currentValues)
      },
      deep: true
    }
  },
  mounted () {
    this.field.raws.forEach((raw) => {
      raw.value = ''
      let key = raw.key
      let dbField = this.field.rawDBFields.find((f) => { return f.name === key })
      if (dbField) {
        raw.value = this.field.rawData[dbField.mapName]
      }
    })
    this.buttonsData = this.constructOptions()
    if (!this.field) return

    this.title = this.field.title

    this.codeNameList = []
    for (let key in this.buttonsData) {
      this.codeNameList.push(key)
    }
  },
  methods: {
    listValue (listKey) {
      let currentValue = this.currentValues[listKey]
      let filtered = this.buttonsData[listKey]
      let originaList = this.originalListData[listKey]

      if (
        (filtered && originaList && currentValue && currentValue !== '' && filtered.filter((option) => { return option.value === currentValue }).length <= 0)) {
        let currentOption = originaList.find((option) => { return currentValue === option.value })
        filtered.push(currentOption)
      }

      return filtered
    },
    setCurrentValue (value) {
      this.currentValues = value
      this.valid = true
    },
    emptyValue () {
      Vue.set(this.currentValues, 'gradation', '')
      this.currentValues.mainColour = ''
      this.currentValues.subColour = ''
    },
    validateCurrentValue: _.debounce(function (val) {
      // validate field and value using TerraIndexValidator
      let context = {}
      this.$validateEditor(this.field, val, context).then(() => {
        // set valid to true and set val to validatedValue as validation has succeeded
        this.valid = true
        this.validatedValues = val
      }).catch(reason => {
        // extract reason and set valid to false
        this.valid = false
        this.errorMessage = reason.message
      }).finally(() => {
        // validation has finished
        this.activeEditorValidationPending = false
      })
    }, 500),

    deleteByBackspace () {
      if (!this.currentValueAsString) return

      let length = this.fieldnames.length
      for (let i = length - 1; i >= 0; i--) {
        let item = this.fieldnames[i]
        if (this.currentValues[item]) {
          this.currentValues[item] = ''
          break
        }
      }
    },

    constructOptions () {
      if (!this.field.raws) { return {} }
      // let options
      let buttonsData = {}

      for (var key in this.field.rawOptions) {
        let value = this.field.rawOptions[key]
        this.originalListData[key] = this.field.raws.find(v => v.key === key)
        let codes = fieldCodeUtils.transformCodes(this, value) || []

        let data = []
        if (codes.length > 0) {
          data = codes.map(v => {
            return { text: v.text, value: v.value, disable: '', description: v.desc }
          })
        }
        buttonsData[key] = data
      }
      return buttonsData
    },
    clear (rawData) {
      if (rawData) {
        rawData.gradation = rawData.mainColour = rawData.subColour = ''
      }
    },
    init (value) {
      if (value) {
        this.currentValues = value
      } else {
        this.currentValues.gradation = this.currentValues.mainColour = this.currentValues.subColour = ''
      }
    },
    click (item, codeName) {
      this.updateValues(item, codeName)
    },
    // update the value or reset the value to empty
    updateValues (item, codeName) {
      if (this.currentValues[codeName] === item.value) {
        this.currentValues[codeName] = ''
        if (this.currentValues.mainColour === '') {
          this.currentValues.subColour = ''
        }
      } else {
        this.currentValues[codeName] = item.value
        // Set the value of the subcolur to empty if the same main colour is choosen
        if (this.currentValues.mainColour === this.currentValues.subColour) {
          this.currentValues.subColour = ''
        }
      }
    },

    search (evt) {
      evt = (evt) || window.event
      if (evt.keyCode === 13 || (evt.keyCode >= 37 && evt.keyCode <= 40)) {
        // enter key , arrow keys
        evt.stopPropagation()
        return
      }

      // get value in input
      let val = evt.currentTarget.value.toUpperCase()

      // first see if item is selected, if so substract it from the search value (if it is not in there, searhc in first again)
      let gradationFound = false
      let mainColourFound = false
      let subColourFound = false
      let gradation, mainColour, subColour
      if (val !== '') {
        gradation = this.buttonsData.gradation.find(i => ((i.disable === '') && (val.indexOf(i.text) === 0)))
        if ((gradation) && (val.indexOf(gradation.text) === 0)) { // first result is matched, look in second list
          gradationFound = true
          this.currentValues['gradation'] = gradation.value
          val = val.substring(gradation.text.length)
        }
      }

      // always look in main colour
      if (val !== '') {
        mainColour = this.buttonsData.mainColour.find(i => ((i.disable === '') && (val.indexOf(i.text) === 0)))
        if ((mainColour) && (val.indexOf(mainColour.text) === 0)) {
          mainColourFound = true
          this.currentValues['mainColour'] = mainColour.value
          val = val.substring(mainColour.text.length)
        }
      }

      // only look in subColour if maincolour is okay
      if (val !== '') {
        subColour = this.buttonsData.subColour.find(i => ((i.disable === '') && (val.indexOf(i.text) === 0)))
        if ((subColour) && (val.indexOf(subColour.text) === 0)) {
          subColourFound = true
          this.currentValues['subColour'] = subColour.value
          val = val.substring(subColour.text.length)
        }
      }

      if (!mainColourFound) { // look in first list
        this.currentValues['mainColour'] = ''
        this.currentValues['subColour'] = ''
      }
      if (!subColourFound) {
        this.currentValues['subColour'] = ''
      }
      if (!gradationFound) {
        this.currentValues['gradation'] = ''
      }

      evt.stopPropagation()
    }
  }
}
</script>
<style lang="less" scoped>
.discription-box {
  background: #67ac45;
  min-height: 24px;
  line-height: 24px;
  color: #fff;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-direction: rtl;
  flex: 1;
  max-width: 100%;
}
.discription-box-color {
  display:flex;
}
.has-feedback {
  max-width: 100% !important;
}
.right-padding {
  right:15px
}

  .cardScroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: 44vh;
    width: 100%;
  }

  .list-title {
    border: 0;
    border-radius: 0;
    color: black !important;
    background-color:#d8d8d8;
    text-transform: capitalize;
    text-align: left;
    margin-top: 15px;
  }

  /* This class will be added to an item dynamically after the item has been selected */
  .selected-item {
    border: 0;
    border-radius: 0;
    color: white;
    background-color: #67ac45;
  }

  .group {
    border: 0;
    font-size: 0.85em;
    border-radius: 0;
    color: #000;
    font-weight: bold;
    text-align: center;
    background-color: rgba(168, 212, 163, 0.5);
  }

  .item {
    text-align: left;
    text-transform: capitalize;
  }
  .item:hover {
    cursor: pointer;
    color: #67ac45;
    background: rgba(168, 212, 163, 0.5);
  }

  ul:nth-child(odd) {
    border: 0;
    border-radius: 0;
    background-color: #ffff;
  }

  ul:nth-child(even) {
    border: 0;
    border-radius: 0;
    background-color: #eeeeee;
  }

  .editor-panel {
  padding: 50px 40px 10px 40px !important;
}
</style>
