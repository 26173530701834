<template>
  <a :ref="'slBox-'+template.SlGuid" href="javascript:" v-bind:class="{ 'active': isActive, 'contents': true}"  @click="gotoDetails()">
    <div class="arrow"></div>
    <div class="item">
      <div class="text">
        <p :title="template.SlName">
          <strong>{{ template.SlName }}</strong>
        </p>
      </div>
    </div>
  </a>
</template>
<script>

export default {
  name: 'subLocationBox',
  props: ['template', 'functions', 'options'],

  data () {
    return {
      classes: 'contents',
      selected: false,
      detailUrl: String,
      isActive: false
    }
  },
  mounted () {
    if (this.isActive) {
      this.$nextTick(() => this.$refs['slBox-' + this.template.SlGuid].scrollIntoView({ behavior: 'smooth', block: 'center' }))
    }
  },

  updated () {
    if (this.isActive) {
      this.$nextTick(() => this.$refs['slBox-' + this.template.SlGuid].scrollIntoView({ behavior: 'smooth', block: 'center' }))
    }
  },
  computed: {
    selectedElement: {
      set (value) {
        this.$store.commit('setSelectedElement', value)
      },
      get () {
        return this.$store.state.selectedElement
      }
    },
    getTemplateField: {
      get () {
        return this.$store.state.getTemplateField
      }
    }
  },
  watch: {
    '$route.params.SlGUID': {
      handler: function (val) {
        this.isActive = (val === this.template.SlGuid)
        if (this.isActive) {
          this.classes = 'contents active'
        } else {
          this.classes = 'contents'
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    gotoDetails () {
      // project of boxes in project detail or project id of boxes in subLocations
      let prid = this.$route.params.projectId
      let SlGUID = this.template.SlGuid
      this.detailUrl = `/project/${prid}/subLocation/${SlGUID}`
      if ((this.options || {}).enableBoxLink) { this.$router.push(this.detailUrl) }
    }
  }
}
</script>
