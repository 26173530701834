<template>
  <a class="contents">
    <div class="item empty">
    </div>
  </a>
</template>

<script>
export default {
  name: 'emptyBox',
  props: ['template', 'functions', 'options'],
  data () {
    return {
    }
  }
}
</script>
