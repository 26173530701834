<template>
  <a :ref="layerGuid" :class="classes" href="javascript:" :style="linkStyle" @click="gotoDetails()">
    <div class="arrow"></div>
    <div class="item">
      <div class="text">
        <p :title="layerFromAndTo">{{ template.LaFrom }} - {{ template.LaTo }} cm</p>
      </div>
      <div :class="soilTypeCode">
        <div :class="laAd3TypeCodeOrGradationCode"></div>
        <div :class="laAd2TypeCodeOrGradationCode"></div>
        <div :class="laAd1TypeCodeOrGradationCode"></div>
      </div>
    </div>
  </a>
</template>
<script>
export default {
  name: 'layerBox',
  props: ['template', 'functions', 'options'],
  data() {
    return {
      classes: 'contents',
      isActive: false
    }
  },
  watch: {
    '$route.params.layerID': {
      handler: function (val) {
        if (!val) {
          this.classes = 'contents'
          return
        }

        this.isActive = val === this.template.LaID
        this.classes = this.isActive ? 'contents active' : 'contents'
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    getTemplateField: {
      get() {
        return this.$store.state.getTemplateField
      }
    },
    linkStyle() {
      return {
        cursor: (this.options || {}).enableBoxLink ? 'pointer' : 'default'
      }
    },
    layerGuid() {
      return `laBox-${this.template.LaGuid}`
    },
    layerFromAndTo() {
      return `${this.template.LaFrom}-${this.template.LaTo}`
    },
    soilTypeCode() {
      return `bg tex${this.template.LaSoilTypeCode}`
    },
    laAd1TypeCodeOrGradationCode() {
      return `tex${this.template.LaAd1TypeCode || '0'} med${this.template.LaAd1GradationCode || '0'}`
    },
    laAd2TypeCodeOrGradationCode() {
      return `tex${this.template.LaAd2TypeCode || '0'} med${this.template.LaAd2GradationCode || '0'}`
    },
    laAd3TypeCodeOrGradationCode() {
      return `tex${this.template.LaAd3TypeCode || '0'} med${this.template.LaAd3GradationCode || '0'}`
    }
  },
  mounted() {
    if (this.isActive) {
      this.$nextTick(() => this.$refs['laBox-' + this.template.LaGuid].scrollIntoView({ behavior: 'smooth', block: 'center' }))
    }
  },

  updated() {
    if (this.isActive) {
      this.$nextTick(() => this.$refs['laBox-' + this.template.LaGuid].scrollIntoView({ behavior: 'smooth', block: 'center' }))
    }
  },

  methods: {
    translate(laSoilTypeList, key, value) {
      if (!value) {
        return value
      }
      let list = laSoilTypeList.find((v) => v.key === key)
      if (!list) {
        return value
      }
      let item = list.options.find((v) => v.value === value)
      if (item) {
        return item.text
      }
      return value
    },
    gotoDetails() {
      let prid = this.$route.params.projectId
      let mpguid = this.options.MpGuid
      let laid = this.template.LaID
      this.detailUrl = `/project/${prid}/measurementPoint/${mpguid}/layer/${laid}`
      if ((this.options || {}).enableBoxLink) {
        this.$router.push(this.detailUrl)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.item {
  text-align: center !important;
  padding-left: 0px !important;
  .text {
    p {
      color: white;
    }
  }
}
</style>
