<template>
  <div v-bind:class="{'labstatus':true}">
    <div
      :id="uuidStage(1)"
      @mouseenter="toggleConceptTooltip"
      @mouseleave="toggleConceptTooltip"
      v-html="icon('-1',true)"
    />
    <div class="line" />
    <div
      :id="uuidStage(2)"
      @mouseenter="toggleSendingTooltip"
      @mouseleave="toggleSendingTooltip"
      v-html="icon('-2',template.LbIsSent === 'true')"
    />
    <div class="line" />
    <div
      :id="uuidStage(3)"
      @mouseenter="toggleResultTooltip"
      @mouseleave="toggleResultTooltip"
      v-html="icon(template.LbLabStatusCode,template.LbLabStatusCode  !== undefined)"
    />
    <DxTooltip
      :target="uuidStage(1,true)"
      :position="'top'"
      :close-on-outside-click="false"
      :visible.sync="conceptTooltip"
      minWidth=200
    >
      <div class="tip">
        <div class="row">
          <span><b>{{translations.t('project.LabAssignmentSaved')}}</b></span>
        </div>
        <div class="row">
          <span>{{translations.t('project.SamplesMatrix')}}</span>
          <span>&nbsp;:&nbsp;</span>
          <span><b>{{getLabMatrixCode(template.LbFieldMatrixCode)}}</b></span>
        </div>
      </div>
    </DxTooltip>
    <DxTooltip
      :target="uuidStage(2,true)"
      :position="'top'"
      :close-on-outside-click="false"
      :visible.sync="sendingTooltip"
    >
      <div class="tip">
        <div class="row">
          <span><b>{{getStatusTextStage2()}}</b></span>
        </div>
      </div>

    </DxTooltip>
    <DxTooltip
      :target="uuidStage(3,true)"
      :position="'top'"
      :close-on-outside-click="false"
      :visible.sync="resultTooltip"
    >
      <div class="tip">
        <div v-if="template.LbLabStatusCode === undefined" class="row">
          <span><b>{{getStatusTextStage3()}}</b></span>
        </div>
        <div v-if="template.LbLabStatusCode !== undefined" class="row">
          <span>{{translations.t('project.LabStatus')}}</span>
          <span>&nbsp;:&nbsp;</span>
          <span><b>{{getStatusTextStage3()}}</b></span>
        </div>
        <div v-if="template.LbLabStatusCode !== undefined && template.LbLabCertificateNumber" class="row">
          <span>{{translations.t('project.CertificateNr')}}</span>
          <span>&nbsp;:&nbsp;</span>
          <span><b>{{template.LbLabCertificateNumber}}</b></span>
        </div>
      </div>
    </DxTooltip>
  </div>
</template>
<script>
import { DxTooltip } from 'devextreme-vue/tooltip'
import translations from '@/configurations/app/config-locales'

export default {
  name: 'labAssignmentStatus',
  props: ['functions', 'options', 'template'],
  components: {
    DxTooltip
  },

  data () {
    return {
      conceptTooltip: false,
      sendingTooltip: false,
      resultTooltip: false
    }
  },

  computed: {
    uuid: {
      get () {
        return `lb-${this.template.PrID}-${this.template.LbID}`
      }
    },
    translations: {
      get () {
        return translations
      }
    }
  },

  methods: {
    getStatusTextStage3 () {
      return this.template.LbLabStatusCode === undefined
        ? this.translations.t('project.NoLabStatusAvailableYet')
        : this.getStatusDescription(this.template.LbLabStatusCode)
    },
    getStatusTextStage2 () {
      return this.template.LbIsSent === 'true'
        ? this.translations.t('project.LabAssignmentSent')
        : this.translations.t('project.LabAssignmentNotSent')
    },
    uuidStage (stage, selector) {
      return `${selector ? '#' : ''}${this.uuid}-${stage}`
    },
    toggleConceptTooltip () {
      this.conceptTooltip = !this.conceptTooltip
    },
    toggleSendingTooltip () {
      this.sendingTooltip = !this.sendingTooltip
    },
    toggleResultTooltip () {
      this.resultTooltip = !this.resultTooltip
    },

    getLabMatrixCode (code) {
      return (
        (
          this.$store.state.fieldMatrixCodes.filter(r => {
            return code === r.GcCode
          })[0] || {}
        ).GcDescription || code
      )
    },

    getSIKBLab (code) {
      return (
        (
          this.$store.state.sikbLabStatusCodes.filter(r => {
            return code === r.GcCode
          })[0] || {}
        ).GcDescription || code
      )
    },

    getStatusDescription (code) {
      return (
        this.$store.state.labAssignmentStatusCodes.filter(r => {
          return code === r.GcCode
        })[0] || {}
      ).GcDescription ||
        code
    },
    icon (status, checked) {
      let color, icon
      switch (parseInt(status)) {
        case -1:
          icon = 'checked'
          color = '#67ac45'
          break
        case -2:
          icon = checked ? 'checked' : 'unchecked'
          color = checked ? '#67ac45' : 'grey'
          break
        case 7:
        case 11:
        case 12:
          icon = 'excl'
          color = 'red'
          break
        case 5:
        case 6:
          icon = 'checked'
          color = '#67ac45'
          break
        case 1:
        case 2:
        case 3:
        case 4:
        case 8:
        case 9:
        case 10:
        case 13:
        case 14:
          color = 'orange'
          icon = 'checked'
          break
        default:
          color = 'grey'
          icon = 'unckecked'
      }
      switch (icon) {
        case 'excl':
          icon = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffffff" d="M12.984 9v-2.016h-1.969v2.016h1.969zM12.984 17.016v-6h-1.969v6h1.969zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93z"></path></svg>`
          break
        case 'checked':
          icon = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffffff" d="M9.984 17.016l9-9-1.406-1.453-7.594 7.594-3.563-3.563-1.406 1.406zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93z"></path></svg>`
          break
        case 'unchecked':
        default:
          icon = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffffff" d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93z"></path></svg>`
      }
      return icon.replace('#ffffff', color)
    }
  }
}
</script>

<style scoped type="text/css">
.labstatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  width: 100%;
  position: relative;
}

.labstatus .status-checked {
  background-image: url("/assets/img/svg/checked.svg");
  background-size: contain;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.labstatus .status-unchecked {
  background-image: url("/assets/img/svg/unchecked.svg");
  background-size: contain;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.labstatus .line {
  background-color: #D6CAC1;
  opacity: 0.9;
  height: 1px;
  width: 10px;
  margin-bottom: 1px;
}

.labstatus .tip {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.labstatus .tip .row {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.labstatus .tip .row span {
  flex: 1;
  display: inline-block;
}

</style>
