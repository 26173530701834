<template>
  <div>
    <!-- Modal add concerned company -->
    <div id="modalAddConcernedContact" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">{{$t('project.AddConcernedContactTitle')}}</h4>
          </div>
          <div class="modal-body">
            <p>{{$t('project.AddConcernedContactHelp')}}</p>

            <div class="row">
              <div class="col-md-6">
                <span>{{$t('concernedCompany.CompanyType')}}</span>
                <select id="new-CcTypeConcerned" v-model='currentConcernedContact.CcTypeConcerned'
                        class="form-control">
                  <option v-for="obj in concernedCompanyTypes" :key="obj.GcCode"
                          :value="obj.GcCode">{{obj.GcDescription}}
                  </option>
                  <option :value="null" ></option>
                </select>
                <p v-if="!validCcTypeConcerned" class="error">{{errorMessageCcTypeConcerned}}</p>
              </div>

              <div class="col-md-6">
                <span>{{$t('concernedCompany.PersonType')}}</span>
                <select id="new-CcTypeFunctionContactPerson" v-model='currentConcernedContact.CcTypeFunctionContactPerson'
                        class="form-control">
                  <option v-for="obj in contactPersonFunctions" :key="obj.GcCode"
                          :value="obj.GcCode">{{obj.GcDescription}}
                  </option>
                  <option :value="null" ></option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <span>{{$t('concernedCompany.CompanyName')}}</span>
                <input id="new-CcCompanyName" v-model="currentConcernedContact.CcCompanyName" type="text" class="form-control">
                <p v-if="!validCcCompanyName" class="error">{{errorMessageCcCompanyName}}</p>
              </div>
              <div class="col-md-6">
                <span>{{$t('concernedCompany.PersonName')}}</span>
                <input id="new-CcContactPerson"  v-model="currentConcernedContact.CcContactPerson" type="text" class="form-control">
              </div>
            </div>

            <hr/>

            <div class="row" >
              <div class="col-xs-9 concerned-contact-pagecount">
                <span>{{$t('concernedCompany.SelectFromExistingContacts')}}</span>
              </div>
              <div class="col-xs-3 concerned-contact-pagecount">
                <span>{{$t("label.resultCount")}}</span>
                <select id="new-CcContactResultCount" v-model="resultCount" class="form-control mb-1" >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="250">250</option>
                </select>
              </div>
            </div>

            <div class="clearfix"></div>

            <div class="concerned-contact-table" v-bind:class="{ 'displayNone': !query}">
              <v-client-table :data="concernedContactsDisplay"
                              :columns="columns"
                              :options="options"
                              class="selectableTable"
                              @row-click="select"
              >
                <span slot="CcTypeConcerned" slot-scope="props"><small class="dimmed typeDisplay">{{findTextInListFromValue(props.row.CcTypeConcerned, concernedCompanyTypes)}}</small></span>
                <span slot="CcTypeFunctionContactPerson" slot-scope="props"><small class="dimmed typeDisplay">{{findTextInListFromValue(props.row.CcTypeFunctionContactPerson, contactPersonFunctions)}}</small></span>
              </v-client-table>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" v-bind:class="{ disabled: !(validCcTypeConcerned && validCcCompanyName) }" @click="add">
              {{$t('label.add')}}
            </button>
          </div>
        </div>
      </div>
      <nv-loading :show="loading"></nv-loading>
    </div>

    <!-- Modal finishings-->
    <div id="modalDeleteConcernedContact"
         class="modal fade"
         tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button"
                    class="close"
                    data-dismiss="modal">&times;
            </button>
            <h4 id="modalDeleteConcernedContactTitle"
                class="modal-title">{{$t('project.DeleteConcernedContactTitle')}}</h4>
          </div>
          <div class="modal-body">
            <p>{{$t('project.DeleteConcernedContactHelp')}}</p>
          </div>
          <div class="modal-footer">
            <button id="btnDeleteConcernedContact"
                    class="btn"
                    @click="deleteConcernedContact">{{$t('project.DeleteButton')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import nvLoading from '@/components/_shared/loading.vue'
import _ from 'underscore'
import { CodeListService } from '@/services/DataWS/codeListService'
import { ConcernedContactsService } from '@/services/DataWS/concernedContactsService'
const codeListService = new CodeListService()
const concernedContactsService = new ConcernedContactsService()
var self

export default {
  name: 'concernedContactModal',
  props: ['functions', 'option'],
  components: {
    nvLoading
  },
  data () {
    const options = {
      headings: {
        'CcTypeConcerned': this.$t('concernedCompany.CompanyType'),
        'CcCompanyName': this.$t('concernedCompany.CompanyName'),
        'CcTypeFunctionContactPerson': this.$t('concernedCompany.PersonType'),
        'CcContactPerson': this.$t('concernedCompany.PersonName')
      },
      columnsClasses: {
        'CcTypeConcerned': 'ccCol_CcTypeConcerned',
        'CcCompanyName': 'ccCol_CcCompanyName',
        'CcTypeFunctionContactPerson': 'ccCol_CcTypeFunctionContactPerson',
        'CcContactPerson': 'ccCol_CcContactPerson'
      },
      filterable: false,
      perPageValues: [1000], // hack to disable
      perPage: 1000,
      customFilters: [],
      pagination: {
        dropdown: false
      }
    }
    return {
      resultCount: 25,
      initialized: false,
      loading: false,
      errorMessageCcTypeConcerned: null,
      validCcTypeConcerned: false,
      errorMessageCcCompanyName: null,
      validCcCompanyName: false,
      columns: ['CcTypeConcerned', 'CcCompanyName', 'CcTypeFunctionContactPerson', 'CcContactPerson'],
      options: options,
      query: null,
      currentConcernedContact: {
        CcTypeFunctionContactPerson: null,
        CcContactPerson: null,
        CcTypeConcerned: null,
        CcCompanyName: null
      },
      concernedContacts: [],
      concernedCompanyTypes: [],
      contactPersonFunctions: []
    }
  },

  watch: {
    'resultCount': {
      handler () {
        if (this.initialized) {
          this.updateConcernedContactsWithFilters()
          this.validate()
        }
      },
      immediate: true,
      deep: false
    },
    'currentConcernedContact': {
      handler () {
        if (this.initialized) {
          this.updateConcernedContactsWithFilters()
          this.validate()
        }
      },
      immediate: true,
      deep: true
    }
  },

  mounted () {
    self = this

    $('#modalAddConcernedContact')
      .on('popupTrigger', function () {
        self.init()
      })
  },

  computed: {
    concernedContactsDisplay: {
      get () {
        let arr = []
        var l = this.concernedContacts.length
        for (let i = 0; i < l; i++) {
          let concernedContact = this.concernedContacts[i]
          let concernedContactDisplay = Object.assign(concernedContact, {
            // calculate CcContact
            CcContact: '',
            // calculate CcCompany
            CcCompany: ''
          })
          arr.push(concernedContactDisplay)
        }
        return arr
      }
    }
  },

  methods: {
    init () {
      this.loading = true

      this.currentConcernedContact = {
        CcTypeFunctionContactPerson: null,
        CcContactPerson: null,
        CcTypeConcerned: null,
        CcCompanyName: null
      }

      let tasks = []
      tasks.push(concernedContactsService.searchConcernedContacts(null, null, null, null, 0, this.resultCount))
      tasks.push(codeListService.getCodeListByCategoryIDOrCode(85)) // ConcernedCompanyTypes
      tasks.push(codeListService.getCodeListByCategoryIDOrCode(86)) // ContactPersonFunctions

      return window.Promise.all(tasks)
        .then(([concernedContacts, concernedCompanyTypes, contactPersonFunctions]) => {
          this.concernedCompanyTypes = concernedCompanyTypes
          this.concernedCompanyTypes.sort((a, b) => a['GcDescription'].localeCompare(b['GcDescription']))

          this.contactPersonFunctions = contactPersonFunctions
          this.contactPersonFunctions.sort((a, b) => a['GcDescription'].localeCompare(b['GcDescription']))

          // add description fields of CcTypeConcerned and CcTypeFunctionContactPerson
          this.concernedContacts = concernedContacts.map(cc => this.parseConcernedContact(cc))

          this.initialized = true

          this.validate()

          this.loading = false
        })
    },

    findTextInListFromValue (v, codeList) {
      var item = codeList.find((codeItem) => { return codeItem.GcCode.toString() === v.toString() })
      if (item) {
        return item.GcDescription
      } else {
        return '-'
      }
    },

    validate () {
      // CcTypeConcerned
      return this.$validateEditor({
        key: 'CcContactPerson',
        maxLength: -1,
        requiredBySystem: true,
        requiredByUser: true
      }, this.currentConcernedContact.CcTypeConcerned, null)
        .then(() => {
          this.validCcTypeConcerned = true
        })
        .catch(reason => {
          // extract reason and set valid to false
          if (typeof reason.message === 'undefined') {
            console.error(reason)
          }
          this.validCcTypeConcerned = false
          this.errorMessageCcTypeConcerned = reason.message
        })
        // CcCompanyName
        .then(() => {
          return this.$validateEditor({
            key: 'CcContactPerson',
            maxLength: 60,
            requiredBySystem: true,
            requiredByUser: true
          }, this.currentConcernedContact.CcCompanyName, null)
        })
        .then(() => {
          this.validCcCompanyName = true
        })
        .catch(reason => {
          // extract reason and set valid to false
          if (typeof reason.message === 'undefined') {
            console.error(reason)
          }
          this.validCcCompanyName = false
          this.errorMessageCcCompanyName = reason.message
        })
    },
    select ({ event, row }) {
      this.currentConcernedContact = row
    },
    add () {
      this.loading = true
      this.validate().then(() => {
        if (this.validCcCompanyName && this.validCcTypeConcerned) {
          return this.functions.add(this.currentConcernedContact)
        }
      })
    },
    deleteConcernedContact () {
      return this.functions.delete()
    },
    updateConcernedContactsWithFilters: _.debounce(() => {
      concernedContactsService.searchConcernedContacts(
        self.currentConcernedContact.CcTypeConcerned,
        self.currentConcernedContact.CcCompanyName,
        self.currentConcernedContact.CcContactPerson,
        self.currentConcernedContact.CcTypeFunctionContactPerson,
        0,
        self.resultCount
      )
        .then(concernedContacts => {
          // add description fields of CcTypeConcerned and CcTypeFunctionContactPerson
          self.concernedContacts = concernedContacts.map(cc => self.parseConcernedContact(cc))
        })
    }, 500),
    getConcernedCompanyTypeByID (id) {
      // find code with GcCode 'id'
      return this.concernedCompanyTypes.find(func => func.GcCode === id.toString())
    },
    getContactPersonFunctionByID (id) {
      // find code with GcCode 'id'
      return this.contactPersonFunctions.find(func => func.GcCode === id.toString())
    },
    parseConcernedContact (concernedContact) {
      // if type concerned is set
      if (concernedContact.CcTypeConcerned) {
        // try to find code of type concerned
        let concernedCompany = this.getConcernedCompanyTypeByID(concernedContact.CcTypeConcerned)
        // if found, then set to description field of concerned contact
        concernedContact.CcTypeConcernedDisplay = concernedCompany ? concernedCompany.GcDescription : null
      }

      // if function is set
      if (concernedContact.CcTypeFunctionContactPerson) {
        // try to find code of function
        let contactPersonFunction = this.getContactPersonFunctionByID(concernedContact.CcTypeFunctionContactPerson)
        // if found, then set to description field of concerned contact
        concernedContact.CcTypeFunctionContactPersonDisplay = contactPersonFunction ? contactPersonFunction.GcDescription : null
      }

      return concernedContact
    }
  }
}
</script>

<style lang="less" scoped>
  .concerned-contact-table {
    height: 400px;
    overflow-y: scroll;

    .dimmed {
      color: #444;
    }

    .typeDisplay {
      display: inline-block;
      text-align: left;
    }

    .checkBoxWrapper {
      width: 100%;
      height: 100%;
      margin: -8px;
      padding: 8px;
    }

    input[type="checkbox"] {
      display: none;

      & + label {
        width: 20px;
        height: 20px;
        display: block;
        position: relative;

        span {
          display: inline-block;
          width: 20px;
          height: 20px;
          top: 3px;
          left: 5px;
          border: 1px solid #9d9d9d;
          position: absolute;
          background-color: #ffffff;
          cursor: pointer;
        }
      }
    }

    label.checked span {
      background-color: #67ac45 !important;
    }

    .VueTables__table > tbody > tr > td {
      &:first-child,
      &:last-child {
        cursor: default;
      }
    }

  }

  #modalAddConcernedContact .modal-dialog {
    width: 800px;

    .form-control {
      width: 100%;
    }
  }
</style>
<style lang="less">
  .concerned-contact-table {
    .VueTables .table-bordered {
      border-top: none;
    }

    .selectableTable.VueTables{
      height: 100%;
      overflow-y: hidden;
    }

    .table-responsive{
      height: 100%;
      overflow-y: scroll;
    }

    th {
      position: -webkit-sticky;
      position: sticky;
      top: -1px;
      z-index: 100;
      border: none !important;
      background-color: #ffffff;
    }

    th.ccCol_CcTypeConcerned {
      width: 170px;
    }

    th.ccCol_CcCompanyName {
      width: auto;
    }

    th.ccCol_CcTypeFunctionContactPerson {
      width: 130px;
    }

    th.ccCol_CcContactPerson {
      width: 250px;
    }
  }
</style>
