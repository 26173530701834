import Vue from 'vue'
import $ from 'jquery'
import arrayUtils from '@/utils/arrayUtils'
import store from '@/vuex/store'
import { SublocationService } from '@/services/DataWS/sublocationService'

const sublocationService = new SublocationService()

export default {
  open (self) {
    Vue.set(self.suggestedNames, self.options.type, arrayUtils.getSuggestedName(self.options.data, 'Sl'))
    $('#modalAddSubLocation').modal('show')
      .on('shown.bs.modal', function (e) {
        $('#txtname').focus()
      })
  },
  delete (self) {
    self.loading = true
    $('#modalDeleteSubLocation').modal('hide')
    sublocationService.deleteSubLocation(store.state.workingObject.PrID, store.state.workingObject.SlGuid).then(res => {
      store.commit('clearWorkingObject')
      // Get a new list of sublocations becase the delete doesn't return them
      self.$store.dispatch('fetchTblSubLocations', { PrID: self.$route.params.projectId })
        .then(
          () => {
            // redirect to the first sublocation if exists else redirect to the project
            if (store.state.subLocations.length > 0) {
              self.loading = false
              self.$router.push({
                // Same route except different sl GUID
                name: self.$router.history.current.name,

                params: {
                  'projectId': self.$route.params.projectId,
                  'SlGUID': self.$store.state.subLocations[0].SlGuid
                }
              })
            } else {
              self.loading = false
              self.$router.push({
                name: 'project',
                params: {
                  'id': self.$route.params.projectId
                }
              })
            }
          }
        )
    }, err => {
      $('#modalDeleteSubLocation').modal('toggle')
      self.loading = false
      console.log(err)
    })
  },
  add (self, data) {
    if (self.loading) return
    self.loading = true
    data = {
      SlName: data.name,
      SlID: '1',
      PrID: self.$route.params.projectId ? self.$route.params.projectId.toString() : self.$route.params.projectId.toString()
    }
    sublocationService.createSublocation(data)
      .then(subLocations => {
        store.commit('setSubLocations', subLocations)
        self.loading = false
        $('#modalAddSubLocation').modal('hide')
        let maxID = Math.max(...subLocations.map(sl => sl.SlID))
        return subLocations.find(mp => mp.SlID === maxID.toString())
      })
      .then(subLocation => {
        self.loading = false
        let path = `/project/${data.PrID}/subLocation/${subLocation.SlGuid}?new=true`
        self.$router.push(path)
      })
      .catch(err => {
        console.log('Catch: ' + err)
        $('#modalAddSubLocation').modal('hide')
        let create = self.$children.find(v => v.$options.name === 'slModal') || {}
        create.resetDefault()
        create.validationError = err
        self.loading = false
      })
  }
}
