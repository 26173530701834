import moment from 'moment'
import Vue from 'vue'
import $ from 'jquery'
import arrayUtils from '@/utils/arrayUtils'
import store from '@/vuex/store'
import { MeasurementPointService } from '@/services/DataWS/measurementPointService'
const measurementPointService = new MeasurementPointService()

export default {
  gotolist(self) {
    let path = `/project/${self.options.projectId}/measurementpoints`
    self.$router.push(path)
  },
  open(self) {
    Vue.set(self.suggestedNames, self.options.type, arrayUtils.getSuggestedName(self.options.data, 'Mp'))
    $('#modalAddMeasurementPoint').modal('show')
      .on('shown.bs.modal', function (e) {
        $('#txtname').focus()
      })
  },
  openMap(self) {
    if (self.$route.params.measurementPointId) {
      let path = `/project/${self.options.projectId}/measurementPoint/${self.$route.params.measurementPointId}/map`
      self.$router.push(path)
    } else if (self.options.data.length > 0) {
      let path = `/project/${self.options.projectId}/measurementPoint/${self.options.data[0].MpGuid}/map`
      self.$router.push(path)
    } else {
      let path = `/project/${self.options.projectId}/measurementPoint/0/map`
      self.$router.push(path)
    }
  },
  delete(self) {
    self.loading = true
    $('#modalDeleteMeasurementPoint').modal('hide')
    measurementPointService.deleteMeasurementPoint(store.state.workingObject.PrID, store.state.workingObject.MpGuid).then(res => {
      store.commit('clearWorkingObject')
      if (store.state.measurementPoints.length > 1) {
        self.$router.push({
          // Same route except different mp ID
          name: self.$router.history.current.name,
          params: { ...self.$router.history.current.params, 'measurementPointId': '1' }
        }, () => {
          window.location.reload(true)
        }, () => {
          window.location.reload(true)
        })
      } else {
        self.$router.push({
          name: 'project',
          params: {
            'id': self.$route.params.projectId
          }
        })
      }
    }, err => {
      $('#modalDeleteMeasurementPoint').modal('toggle')
      self.loading = false
      console.log(err)
    })
  },
  add(self, data) {
    if (self.loading) return
    self.loading = true

    let now = moment().format('YYYY-MM-DDTHH:mm:ss')
    let measurementPoint = {
      'MpID': '-1',
      'MpName': data.name,
      'PrID': self.options.projectId,
      'MpDate': now
    }

    measurementPointService.createMeasurementPoint(measurementPoint)
      .then(measurementPoints => {
        store.commit('setMeasurementPoints', measurementPoints)
        self.loading = false
        $('#modalAddMeasurementPoint').modal('hide')
        let maxID = Math.max(...measurementPoints.map(mp => mp.MpID))
        return measurementPoints.find(mp => mp.MpID === maxID.toString())
      })
      .then(measurementPoint => {
        self.loading = false
        let path = `/project/${self.options.projectId}/measurementPoint/${measurementPoint.MpGuid}?new=true`
        if (self.$route.params.openMap === 'map') {
          path = `/project/${self.options.projectId}/measurementPoint/${measurementPoint.MpGuid}/map?new=true`
        }
        self.$router.push(path)
      })
      .catch(err => {
        console.log('Catch: ' + err)
        $('#modalAddMeasurementPoint').modal('hide')
        let create = self.$children.find(v => v.$options.name === 'mpModal') || {}
        create.resetDefault()
        create.validationError = err
        self.loading = false
      })
  }
}
