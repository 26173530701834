import $store from '@/vuex/store'
import {
  MEASUREMENT_POINT_TYPE,
  PROJECT_TYPE,
  LAYER_TYPE,
  SOIL_SAMPLE_TYPE,
  GAUGING_TUBE_TYPE,
  WATER_SAMPLE_TYPE,
  BOTTLE_TYPE,
  FINISHING_TYPE,
  CONCERNED_CONTACT_TYPE,
  SUBLOCATION_TYPE,
  SUMMARYANDCONCLUSION_TYPE,
  AIR_SAMPLE_TYPE
} from '@/vuex/constants'

import { SublocationService } from '@/services/DataWS/sublocationService'
import { SummaryAndConclusionService } from '@/services/DataWS/summaryAndConclusionService'
import { WaterSampleService } from '@/services/DataWS/waterSampleService'
import { FinishingsService } from '@/services/DataWS/finishingsService'
import { BottleService } from '@/services/DataWS/bottleService'
import { SoilSampleService } from '@/services/DataWS/soilSampleService'
import { ConcernedContactsService } from '@/services/DataWS/concernedContactsService'
import { GaugingTubeService } from '@/services/DataWS/gaugingTubeService'
import { LayerService } from '@/services/DataWS/layerService'
import { ProjectService } from '@/services/DataWS/projectService'
import { MeasurementPointService } from '@/services/DataWS/measurementPointService'

const sublocationService = new SublocationService()
const summaryAndConclusionService = new SummaryAndConclusionService()
const waterSampleService = new WaterSampleService()
const soilSampleService = new SoilSampleService()
const concernedContactsService = new ConcernedContactsService()
const gaugingTubeService = new GaugingTubeService()
const layerService = new LayerService()
const projectService = new ProjectService()
const measurementPointService = new MeasurementPointService()
const finishingsService = new FinishingsService()
const bottleService = new BottleService()

export default {
  pushWorkingObjectToDataService (data) {
    function getSaveFunction (data) {
      let type = $store.state.workingObjectType
      // console.log('type: ', type)
      switch (type) {
        case LAYER_TYPE:
          let layers = $store.state.layers.filter(v => v.type !== 'emptyBox')
          let prevTo
          layers.forEach(layer => {
            layer.LaFrom = prevTo || layer.LaFrom
            prevTo = layer.LaTo
          })
          return layerService.updateLayers(data.PrID, data.MpGuid, layers)
        case SOIL_SAMPLE_TYPE:
          let soilSamples = $store.state.soilSamples.filter(v => v.type !== 'emptyBox')
          return soilSampleService.updateSoilSamples(data.PrID, data.MpGuid, soilSamples)
        case GAUGING_TUBE_TYPE:
          let gaugingTubes = $store.state.gaugingTubes.filter(v => v.type !== 'emptyBox')
          return gaugingTubeService.setGaugingTubes(data.PrID, data.MpGuid, gaugingTubes)
        case FINISHING_TYPE:
          let finishings = $store.state.finishings.filter(v => v.type !== 'emptyBox')
          return finishingsService.updateFinishings(data.PrID, data.MpGuid, finishings)
        case PROJECT_TYPE:
          return projectService.setProject($store.state.workingObject)
        case MEASUREMENT_POINT_TYPE:
          return measurementPointService.setMeasurementPoint($store.state.workingObject)
        case CONCERNED_CONTACT_TYPE:
          return concernedContactsService.updateConcernedContact($store.state.workingObject)
        case WATER_SAMPLE_TYPE:
        case AIR_SAMPLE_TYPE:
          let observations = data.Observations.filter((ob) => (ob.ObID) || (!ob.ObID && ob.ObMeasurementValue && ob.ObMeasurementValue !== '') || (!ob.ObID && ob.ObPropertyCode && ob.ObPropertyCode !== '') || (!ob.ObID && ob.ObObservationDate && ob.ObObservationDate !== ''))
          return waterSampleService.updateWaterSample(data.PrID, data.MpGuid, data.FtGuid, data.WaterSamples, observations)
        case BOTTLE_TYPE:
          return bottleService.updateWaterSampleBottles(data.PrID, data.MpGuid, data.FtGuid, data.WsGuid, data.Bottles)
        case SUBLOCATION_TYPE:
          return sublocationService.updateSublocation(data)
        case SUMMARYANDCONCLUSION_TYPE:
          return summaryAndConclusionService.updateSummaryAndConclusion(data)
        default:
          // console.error('Saving is not implemented for workingObjectType ' + $store.state.workingObjectType + '.')
          return Promise.reject(new Error('Saving is not implemented for workingObjectType ' + $store.state.workingObjectType + '.'))
      }
    }

    // get save promise for current type of working object
    return getSaveFunction(data)
      .then(result => {
        // set working object changed to false as it has successfully been pushed to the server
        $store.commit('setWorkingObjectChanged', false)

        return result
      })
  }
}
