<template>
  <div>
    <!-- Modal add soil sample-->
    <div id="modalAddSoilSample" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 id="modalAddSoilSampleTitle" class="modal-title">{{$t("project.AddSoilSampleTitle")}}</h4>
          </div>
          <div class="modal-body">
            <p>{{$t("project.SoilSampleName")}}</p>
            <input id="testSoilSampleName"
                   v-model="name"
                   type="text"
                   class="form-control"
                   @keypress="checkSubmit">
            <p v-if="!validName" class="error">{{errorMessageName}}</p>
            <p>{{$t("project.SoilSampleFrom")}}</p>
            <input id="txtSoilSampleFrom" v-model="trajactoryValue.first" type="number" class="form-control" required>
            <p>{{$t("project.SoilSampleTo")}}</p>
            <input id="txtSoilSampleTo"
                   v-model="trajactoryValue.second"
                   type="number" class="form-control"
                   required
                   @keypress="checkSubmit">
            <p v-if="!validTraj" class="error">{{errorMessageTraj}}</p>
          </div>
          <div class="modal-footer">
            <button id="btnAddSoilSample" type="button" class="btn" v-bind:class="{ disabled: !enableAddButton }"
                    @click="add">{{$t("project.CreateButton")}}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal soil sample-->
    <div id="modalDeleteSoilSample" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 id="modalDeleteSoilSampleTitle" class="modal-title">{{$t("project.DeleteSoilSampleTitle")}}</h4>
          </div>
          <div class="modal-body">
            <p>{{$t("project.DeleteSoilSampleHelp")}}</p>
          </div>
          <div class="modal-footer">
            <button id="btnDeleteSoilSample" class="btn" @click="deleteSoilSample">{{$t("project.DeleteButton")}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'saModal',
  props: ['functions', 'option'],

  data () {
    return {
      name: '',
      trajactoryValue: { first: '', second: '' },
      errorMessageName: '',
      validName: false,
      errorMessageTraj: '',
      validTraj: false
    }
  },

  computed: {
    enableAddButton () {
      return this.validName && this.validTraj && !this.$parent.loading
    }
  },

  watch: {
    'option.suggestedName': {
      handler: function () {
        this.name = this.option.suggestedName
      }
    },
    'name': {
      handler: function () {
        this.validate()
      }
    },
    'trajactoryValue': {
      handler: function () {
        this.validateTrajectory()
      },
      deep: true
    }
  },

  methods: {
    checkSubmit (evt) {
      if (evt.key === 'Enter' && this.valid) {
        this.add()
      }
    },
    validate () {
      let JrNameField = {
        key: 'JrName',
        maxLength: 50,
        requiredBySystem: true,
        requiredByUser: true
      }
      this.$validateEditor(JrNameField, this.name, {
        JrGuid: '' /* empty since it cant ever match with itself */
      })
        .then(() => {
          this.validName = true
        })
        .catch(reason => {
          // extract reason and set valid to false
          if (typeof reason.message === 'undefined') {
            console.error(reason)
          }
          this.validName = false
          this.errorMessageName = reason.message
        })
    },
    validateTrajectory () {
      let JrTrajectoryField = {
        key: 'JrFrom',
        type: '19',
        requiredBySystem: true,
        rawOptions: {
          rule: 'next'
        }
      }

      this.$validateEditor(JrTrajectoryField, this.trajactoryValue, {})
        .then(() => {
          this.validTraj = true
        }).catch(reason => {
          // extract reason and set valid to false
          if (typeof reason.message === 'undefined') {
            console.error(reason)
          }
          this.validTraj = false
          this.errorMessageTraj = reason.message
        })
    },
    add () {
      return this.functions.add({ name: this.name, from: this.trajactoryValue.first, to: this.trajactoryValue.second })
    },
    resetDefault () {
      this.trajactoryValue = { first: '', second: '' }
      this.errorMessageName = ''
      this.errorMessageTraj = 'Required!'
      this.validate()
    },
    deleteSoilSample () {
      return this.functions.delete()
    }
  }
}
</script>
