import codeListUtils from '@/utils/codeListUtils'
import stringUtils from '@/utils/stringUtils'
import _ from 'lodash'
import Vue from 'vue'

export default class Transform {
  constructor () {
    this.transformCodes = this.transformCodes.bind(this)
  }
  static transformCodes (self, fieldKey) {
    if (!self.field.raws) { return }
    let rawOptions = self.field.raws.find(v => v.key === fieldKey)

    if (typeof rawOptions === 'undefined') {
      return []
    }
    let that = this
    let codes = codeListUtils.getVisibleCodesForField(rawOptions).map((item) => {
      return that.transformItem(self, item)
    })
    codes = _.sortBy(codes, function (item) {
      return item.text
    })

    return codes
  }
  static transformItem (self, item) {
    if (item && item._transformed) { return item }

    self = Object.assign({}, self, {
      lang: Vue.config.lang,
      relationLang: {
        nl: 'nld',
        en: 'eng',
        fr: 'fra',
        es: 'spa',
        de: 'deu',
        it: 'ita'
      }
    })

    let rlang = 'eng'
    if (self.relationLang) {
      rlang = self.relationLang[self.lang]
    }

    if (typeof item !== 'object' || !item) {
      return {
        value: '',
        key: '',
        text: '',
        desc: '',
        _transformed: true
      }
    }

    var texts = {}; var codes = {}
    item.text.forEach(t => Object.assign(texts, t))
    item.codeInterface.forEach(t => Object.assign(codes, t))

    item.desc = stringUtils.capitalizeFirstLetter(texts[rlang])
    if (!item.desc) {
      item.desc = stringUtils.capitalizeFirstLetter(texts['nld'])
    }

    item.text = codes[rlang]
    if (!item.text) {
      item.text = codes['nld']
    }

    // item.text = item.value
    item._transformed = true

    return item
  }
}
