<template>
  <div v-if="firstBatchOfThumbnailsAvailable">
    <component
      :is="fileIconText(item.FileName)"
      v-for="item in options.data"
      :key="item.FileName"
      :template="item"
      :mediaClassification="mediaClassification"
      :options="options" />
  </div>
</template>

<script>
import config from '../../configurations/config.js'
import pictureBox from '@/components/box-controls/pictureBox.vue'
import projectDocumentBox from '@/components/box-controls/projectDocumentBox.vue'
import stringUtils from '@/utils/stringUtils'

export default {
  name: 'mapImagesBox',
  props: {
    options: {
      type: Object,
      required: true
    },
    template: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },
  components: {
    pictureBox,
    projectDocumentBox
  },
  async mounted () {
    await this.ensureThumbnailsAreCached()
  },
  data () {
    return {
      firstBatchOfThumbnailsAvailable: false,
      mediaClassification: config.constants.MediaClassification.MapImages
    }
  },
  methods: {
    fileIconText (fileName) {
      let fileExtension = stringUtils.getFileExtension(fileName)
      switch (fileExtension) {
        case 'pdf':
          return 'projectDocumentBox'
        default:
          return 'pictureBox'
      }
    },
    async ensureThumbnailsAreCached() {
      if (this.options.data.every(item => stringUtils.getFileExtension(item.FileName) === 'pdf')) {
        this.onFirstBatchOfThumbnailsAvailable()
        return
      }
      const imageFiles = this.options.data.filter(item => stringUtils.getFileExtension(item.FileName) !== 'pdf')
      const fileNames = imageFiles.map(item => item.FileName)

      await this.$store
        .dispatch('getMediaThumbnailBatch', {
          PrGuid: this.options.prGuid,
          FileNames: fileNames,
          mediaClassification: this.mediaClassification,
          firstBatchOfThumbnailsAvailableCallback: this.onFirstBatchOfThumbnailsAvailable
        })
    },
    onFirstBatchOfThumbnailsAvailable() {
      this.firstBatchOfThumbnailsAvailable = true
    }
  }
}
</script>
