<template>
  <div>
    <div class="edit-panel" data-simplebar>
      <ul @keyup="keyboardUpAndDown">
        <li v-for="control in filledControls" :key="control.controlId">
          <div v-if="isControlActive(control)">
            <span :translationForm="screen" :fieldKey="getFieldKey(control)" :class="'label ' + control.controlId">{{getControlName(control)}}</span>
            <ul class="container-editor">
              <li v-for="(field, key) in control.fields" :key="key">
                <editor :id="'editor-component-'+field.key" :field="field" :functions="useFunctions"></editor>
                <span class="hidden">{{field.addtionalText}}</span>
              </li>
            </ul>
          </div>
        </li>
      </ul>

      <div class="row hide">
        <div class="col-md-8 col-offset-4">
          <button class="btn btn-default">{{$t("label.save")}}</button>
        </div>
      </div>

      <div class="edit-operation animated">
        <font-awesome-icon icon="fa-solid fa-arrow-up" @click="gotoPrev"/>
        <font-awesome-icon icon="fa-solid fa-arrow-down" @click="gotoNext"/>
      </div>
    </div>

    <nv-loading :show="loading"></nv-loading>
  </div>

</template>

<script>
'use strict'

import Editor from '@/components/input-controls/editor.vue'
import config from '@/configurations/config.js'
import _ from 'underscore'
import panelUtil from '@/utils/panelUtil'
import editorUtils from '@/utils/editorUtils'
import typefields from '@/configurations/app/config-typefield'
import nvLoading from '@/components/_shared/loading.vue'
import VueCookie from 'vue-cookie'
import { TemplateService } from '@/services/DataWS/templateService'

const templateService = new TemplateService()
let getEditor = config.configEditors

export default {
  name: 'Editors',

  components: {
    Editor,
    nvLoading
  },

  props: ['screen', 'functions'],

  data () {
    return {
      controls: [],
      loading: true,
      relationLang: {
        nl: 'nld',
        en: 'eng',
        fr: 'fra',
        es: 'spa',
        de: 'deu',
        it: 'ita'
      },
      template: {},
      activeEditor: null,
      hasChanges: false,
      useFunctions: {}
    }
  },

  computed: {
    activeEditorValidationPending: {
      get () {
        return this.$store.state.activeEditorValidationPending
      }
    },
    prTypeCode: {
      get () {
        return this.$store.state.prTypeCode
      }
    },
    prTemplateID: {
      get () {
        return this.$store.state.prTemplateID
      }
    },
    filledControls () {
      return this.controls.filter((control) => { return control })
        .sort((a, b) => (parseInt(a.order) > parseInt(b.order)) ? 1 : -1)
    },
    lang () {
      return VueCookie.get('language')
    }
  },
  watch: {
    'hasChanges': {
      handler: function (val, oldVal) {
        window.hasChanges = val
      }
    },

    'screen': {
      handler: function (val, oldVal) {
        this.loadScreenTemplate()
      }
    }
  },

  mounted () {
    if (this.functions) {
      this.useFunctions = this.functions
    }
    this.useFunctions.changeTemplates = this.getTemplates
    this.loadScreenTemplate()
  },

  methods: {
    isControlActive(control) {
      // if the new setting is not defined on the control we want to show it, like before
      if (!control.fields[0].editorOptions) return true
      if (!control.fields[0].editorOptions.disabledIfFieldIsNotCorrect) return true

      let relatedInput = control.fields[0].editorOptions.disabledIfFieldIsNotCorrect

      // it takes a while for the workingObject to get set properly; better show an extra field for a second than crash
      if (!this.$store.state.workingObject[relatedInput.fieldName]) return true

      return this.$store.state.workingObject[relatedInput.fieldName].toString() === relatedInput.code.toString()
    },
    loadScreenTemplate () {
      this.activeEditor = null
      this.loading = true
      let projectID = this.$route.params.projectId

      templateService.getFieldTemplate(this.screen, projectID, null).then(result => {
        this.loading = false
        this.template = result
        this.initTemplate()
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    },
    setFieldDefaultValue (field) {
      var hassetDefaultValue = false
      // set default value
      var fieldValue = editorUtils.getEditorValue(field)

      if (typeof fieldValue === 'undefined' && this.$route.query.new === 'true') {
        if (field.newRowValueSetting === '1') {
          // store the original value
          field.originalValue = fieldValue
          fieldValue = field.defaultValue
          hassetDefaultValue = true
        }
      }

      field.value = fieldValue
      return hassetDefaultValue
    },
    initTemplate () {
      let template = this.template
      this.controls = template.controls.map(control => {
        // leave one field
        if (control.fields.length > 1) {
          let tempfield = control.fields[0]
          let typeFields = typefields[tempfield.type]

          if (typeFields) {
            let generatedTypeField = typeFields.find(v => {
              let check = tempfield.key.indexOf(v.name) !== -1
              if (tempfield.title) { check = check && tempfield.title.indexOf(v.title) !== -1 }
              return check
            })
            if (generatedTypeField) {
              let raws = []
              control.fields.forEach(field => {
                let raw = {}
                raw.key = field.key
                raw.maxLength = field.maxLength
                raw.requiredBySystem = field.requiredBySystem
                raw.requiredByUser = field.requiredByUser
                raw.options = field.options
                raw.visibleCodes = field.visibleCodes
                raw.value = editorUtils.getDbFieldValue(field.key)
                raws.push(raw)
              })
              tempfield.raws = raws
              tempfield.templateSettings = control.templateSettings
              control.fields = [tempfield]
            }
          }
        }

        control.fields.forEach(field => {
          delete field.component
          let editor = getEditor(field.type || '*')
          field.component = editor.name
          field.editorOptions = editor.options
          field.inputControlOptions = control.inputControlOptions || {}
          // set default value
          this.setFieldDefaultValue(field)
        })
        return control
      }).sort(this.orderControls)
    },
    getControlName (control) {
      let rlang = this.relationLang[this.lang]
      let title = (control.labels.find(v => v[rlang]) || {})[rlang] || 'N/A'

      control.fields.forEach(v => {
        v.title = title
      })
      return title
    },

    getFieldKey(control) {
      if (!control) return ''
      return `${control.fields[0].key}`
    },

    keyboardUpAndDown (evt) {
      evt = (evt) || window.event
      let keyCode = evt.keyCode || evt.which
      let shift = evt.shiftKey

      if (keyCode === 9) { // tab
        evt.preventDefault()

        if (shift) { this.gotoPrev() } else { this.gotoNext() }
      }
    },

    setActiveEditor (editor, autoFocus = true) {
      if (this.activeEditorValidationPending) {
        setTimeout(this.setActiveEditor.bind(null, editor), 100)
      } else {
        let res = this.unfocusEditors(editor)

        if (res) {
          editor.isActive = true
          this.activeEditor = editor
          panelUtil.expandPanel(editor.$children[0].id, autoFocus)
        }
      }
    },

    unfocusEditors (editor, routePath) {
      let editors = this.$children.filter(c => c.$options.name === 'basicEditor')
      for (var i = 0; i < editors.length; i++) {
        let ed = editors[i]
        var res = ed.unfocus(editor, routePath)
        if (!res) {
          return false
        }
        ed.isActive = false
      }
      return true
    },

    getActiveIndex () {
      if (!this.activeEditor) { return 0 }

      let activeId = this.activeEditor._uid
      let index = _.findIndex(this.$children, { _uid: activeId })
      return index || 0
    },

    gotoNext () {
      let currentIndex = this.getActiveIndex()
      if (currentIndex + 1 >= this.$children.length) { currentIndex = 0 }

      let editor = this.$children[currentIndex + 1]
      if (editor) { this.setActiveEditor(editor) }
    },

    gotoPrev () {
      let currentIndex = this.getActiveIndex()
      if (currentIndex === 1) { currentIndex = this.$children.length }

      let editor = this.$children[currentIndex - 1]
      if (editor) { this.setActiveEditor(editor) }
    },

    orderControls: function (a, b) {
      return parseInt(a.order) - parseInt(b.order)
    }
  }
}

</script>

<style lang="less">
  .editor {
    div {
      .input-left {
        input {
          cursor: pointer;
        }
      }
    }
  }

  .edit-panel {
    ul {
      list-style: none;
      padding: 0;
    }
    &>ul>li {
      width: 100%;
      display: inline-block;
    }
    .label {
      color: #000;
      line-height: 25px;
      display: block;
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      padding: 5px;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .container-editor {
      li {
        padding: 5px;
        min-height: 30px;
        &.active {
          background: #515151;
          transition: background .5s .8s;
        }
        &.activeIn {
          background: #515151;
          transition: background .5s;
        }
      }
    }
    .edit-operation {
      position: fixed;
      top: 50%;
      right: 15px;
      margin-top: -50px;
      font-size: 20px;
      color: #fff;
      z-index: 11;
      opacity: 0;
      i {
        display: block;
        margin: 20px 0;
        &:hover {
          cursor: pointer;
        }
      }
    }
    @media (min-width: 640px){
      .label {width: 180px}
    }

    .form-control[readonly] {
      background-color: #ffffff;
      opacity: 1;
    }
  }

  @media (max-width: 640px) {
      .inDetails .edit-panel .input-group.input-left {
          width: 100%;
      }
  }

  @media (min-width: 768px) {
      .inDetails .editor-panel {
          width: calc(100vw - 614px);
      }
  }
</style>
<style scoped>
  @media (min-width: 640px) {
    .edit-panel {
      width: 220px;
      height: calc(100vh - 120px);
      overflow-y: scroll;
      overflow-x: hidden;
      float: left;
      padding: 5px 0 0 10px;
    }
  }
</style>
