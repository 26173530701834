<template>
  <a :ref="'boBox-' + template.BoGuid" :class="classes"  href="javascript:" :style="{ cursor: (this.options.base || {}).enableBoxLink ? 'pointer' : 'default' }" @click="gotoDetails()">
    <div class="arrow"></div>
    <div class="item" :class="{ filtered: isFiltered }">
      <div>
        <p :title="barcode">
          <strong>{{ barcode }}</strong>
        </p>
      </div>
      <div class="side-box" :class="{ filtered: isFiltered }"></div>
    </div>
  </a>
</template>
<script>
export default {
  name: 'bottleBox',
  props: ['template', 'functions', 'options', 'bottleType'],

  data() {
    return {
      classes: 'contents',
      barcode: '-',
      isActive: false
    }
  },

  watch: {
    '$route.params.BoID': {
      handler: function (val) {
        if (!val) {
          this.classes = 'contents'
          return
        }
        this.isActive = val === this.template.BoID && this.$route.params.WsID === this.template.WsID
        this.classes = this.isActive ? 'contents active' : 'contents'
      },
      immediate: true,
      deep: true
    },
    '$store.state.workingObject'(to, from) {
      if (to && to.BoBarcode && to.BoGuid && to.BoGuid === this.template.BoGuid) {
        if (to.barcode === '') {
          this.barcode = '-'
        } else {
          this.barcode = to.BoBarcode
        }
      }
    }
  },

  computed: {
    isFiltered() {
      let val = this.template.BoFiltered === 'true' || this.template.BoFiltered === true ? 1 : 0
      return val
    }
  },

  mounted() {
    if (this.template.BoBarcode) {
      this.barcode = this.template.BoBarcode
    }
    if (this.isActive) {
      this.$nextTick(() => this.$refs['boBox-' + this.template.BoGuid].scrollIntoView({ behavior: 'smooth', block: 'center' }))
    }
  },

  updated() {
    if (this.isActive) {
      this.$nextTick(() => this.$refs['boBox-' + this.template.BoGuid].scrollIntoView({ behavior: 'smooth', block: 'center' }))
    }
  },

  methods: {
    gotoDetails() {
      let prid = this.$route.params.projectId
      let mpguid = this.options.base.MpGuid || this.$route.params.measurementPointId
      let ftID = this.options.base.FtGuid || this.options.base.FtID
      let WsID = this.options.ws.WsID
      let BoID = this.template.BoID
      let type = typeof ftID === 'undefined' || ftID === null ? 'SURFACE' : 'GROUND'
      if (type === 'SURFACE') {
        if (this.bottleType === 'AIR') {
          this.detailUrl = `/project/${prid}/measurementPoint/${mpguid}/airSample/${WsID}/packaging/${BoID}`
        } else {
          this.detailUrl = `/project/${prid}/measurementPoint/${mpguid}/watersample/${WsID}/bottle/${BoID}`
        }
      } else if (type === 'GROUND') {
        if (this.bottleType === 'AIR') {
          this.detailUrl = `/project/${prid}/measurementPoint/${mpguid}/gaugingTubes/${ftID}/airSample/${WsID}/packaging/${BoID}`
        } else {
          this.detailUrl = `/project/${prid}/measurementPoint/${mpguid}/gaugingTubes/${ftID}/watersample/${WsID}/bottle/${BoID}`
        }
      } else {
        console.error('can not go to details for unknown bottle type: [' + type + ']')
      }
      if ((this.options.base || {}).enableBoxLink) {
        this.$router.push(this.detailUrl)
      }
    }
  }
}
</script>

<style scoped>
.side-box {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  bottom: 6px;
  border-radius: 4px;
  background: #83b5de;
  &.filtered {
    background: repeating-linear-gradient(to top left, #83b5de, #83b5de, #83b5de, #333 4px);
  }
}
</style>
