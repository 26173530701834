<template>
  <a :id="template.FileName + '_' + mediaClassification" :href="externalLink" target="new" :class="styleClasses" @contextmenu="contextMenu(template, $event)">
    <div class="item">
      <span :if="getGlyphIcon(template.FileName)" :class="'glyphicons ' + getGlyphIcon(template.FileName) + ' fronticon'"></span>
      <p :title="template.FileName" style="word-wrap: break-word;">{{ template.FileName }}</p>
    </div>
  </a>
</template>
<script>
import popupUtils from '@/utils/popupUtils'
import config from '../../configurations/config.js'
import stringUtils from '@/utils/stringUtils'

let mapLayerIcons = { kml: 'glyphicons-cadastral-map' }

export default {
  name: 'mapLayerBox',
  props: ['template', 'options'],
  data () {
    return {
      styleClasses: '',
      externalLink: ''
    }
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler () {
        this.optionsChanged()
      }
    }
  },
  computed: {
    mediaClassification () {
      return config.constants.MediaClassification.MapLayers
    },
    selectedElement: {
      set (value) {
        this.$store.commit('setSelectedElement', value)
      },
      get () {
        return this.$store.state.selectedElement
      }
    }
  },
  methods: {
    optionsChanged () {
      this.styleClasses = ''
      this.externalLink = ''
      let optionsObject = this.options || {}
      if (!optionsObject.externalLink || (optionsObject.externalLink && optionsObject.externalLink !== '')) {
        this.externalLink = `${optionsObject.externalLink}&preview=${this.template.FileName}`
      }

      if (optionsObject.enableBoxLink) {
        this.styleClasses += 'buttonClickEnabled'
      }
    },
    contextMenu: function (mapLayer, e) {
      this.selectedElement = {
        type: 'ATTACHMENT',
        id: 'attachement-' + mapLayer.FileName,
        fileName: mapLayer.FileName,
        mediaClassification: this.mediaClassification
      }
      popupUtils.popup(e, '#projectContextMenuPopup', { elementAnchor: 'rb', popupAnchor: 'cc' })
      e.preventDefault()
    },
    getGlyphIcon: function (filename) {
      return mapLayerIcons[stringUtils.getFileExtension(filename)]
    }
  }
}
</script>
