<template>
    <div :id="'editor' + id">
    <div class="input-group input-left">
      <textarea
        class="form-control texture-area"
        :class="{errorBorder: !valid}"
        readonly
        rows="3"
        :value="validatedValueAsString"
        :title="validatedValueAsString"
        @click="$parent.expandPanel"
      ></textarea>
    </div>

    <div class="editor-panel hidden animated">
      <div class="close" @click="$parent.hidePanel">
        <span class="glyphicon glyphicon-chevron-right"></span>
      </div>
      <div class="row">
        <div class="col-md-7">
          <label class="field-name">{{title}}</label>
          <div class="has-feedback">
            <textarea v-model="currentValue" class="form-control" :class="{errorBorder: !valid}" rows="5"></textarea>
            <span class="form-control-feedback glyphicon glyphicon-remove" @click="emptyValue"></span>
            <label v-if="!valid" class="error">{{errorMessage}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
'use strict'

import editorUtils from '@/utils/editorUtils'
import _ from 'lodash'

/**
   * Editor with which users can set a textual value (incl. new lines) to a property.
   */
export default {
  name: 'textareaEditor',
  props: ['field', 'functions', 'options'],

  data () {
    return {
      id: this._uid,
      currentValue: String,
      errorMessage: null,
      title: String,
      valid: true
    }
  },

  computed: {
    validatedValue: {
      get () {
        let val = editorUtils.getEditorValue(this.field)

        // overwrite current value with 'valid' value from store and set valid to true. get() is called in the following cases:
        // - when the value of this.$store.state.workingObject[this.field.key] changed (e.g. because side effect or switching workingObject)
        // - when initializing the editor
        this.setCurrentValue(val)

        return val
      },
      set (value) {
        let result = editorUtils.setEditorValue(this.field, value)
        if (result.hasChanges) {
          this.$store.commit('updateWorkingObject', result.values)
        }
      }
    },
    validatedValueAsString: {
      get () {
        return this.validatedValue == null ? '' : this.validatedValue + ''
      }
    },
    activeEditorValidationPending: {
      get () {
        return this.$store.state.activeEditorValidationPending
      },
      set (val) {
        this.$store.commit('setActiveEditorValidationPending', val)
      }
    }
  },

  watch: {
    'currentValue': function (value) {
      // set activeEditorValidationPending to true, so that navigation is blocked until validation is done
      this.activeEditorValidationPending = true

      this.validateCurrentValue(this.currentValue)
    }
  },

  mounted () {
    this.title = this.field.title
  },

  methods: {
    setCurrentValue (value) {
      this.currentValue = value
      this.valid = true
    },
    emptyValue () {
      this.currentValue = ''
    },

    validateCurrentValue: _.debounce(function (val) {
      // validate field and value using TerraIndexValidator
      this.$validateEditor(this.field, val).then(() => {
        // set valid to true and set val to validatedValue as validation has succeeded
        this.valid = true
        this.validatedValue = val
      }).catch(reason => {
        // extract reason and set valid to false
        this.valid = false
        this.errorMessage = reason.message
      }).finally(() => {
        // validation has finished
        this.activeEditorValidationPending = false
      })
    }, 500)
  }
}
</script>
