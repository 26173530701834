import $ from 'jquery'

export default {
  expandPanel (id, focus) {
    // console.log($(`#editor${id}`).parent().parent().offset().top)
    // $(".edit-panel").animate({
    //   scrollTop: $(`#editor${id}`).offset().top
    // }, 500);
    // $(".editor-panel").css("left", $(".edit-panel").offset().left + "px");
    $('.container-editor li').removeClass('active activeIn')

    if (focus) {
      setTimeout(() => {
        $(`#editor${id} .editor-panel input, #editor${id} .editor-panel textarea`).first().focus()
      }, 200)
    }

    if ($(`.editor-panel`).hasClass('in')) {
      $(`.editor-panel`).removeClass('fadeInRight').addClass('hidden')
      $(`#editor${id} .editor-panel`).removeClass('hidden')
      $(`#editor${id}`).parent().parent().addClass('activeIn')
    } else {
      $(`.editor-panel`).removeClass('bounceOutRight').addClass('hidden')
      $(`#editor${id} .editor-panel`).removeClass('hidden').addClass('fadeInRight in')
      $(`#editor${id}`).parent().parent().addClass('active')
    }
    $('.edit-operation').removeClass('fadeOut').addClass('fadeIn')
  },
  hidePanel (id) {
    $(`.editor-panel`).removeClass('fadeInRight in').addClass('hidden')
    $(`#editor${id} .editor-panel`).removeClass('hidden').addClass('bounceOutRight')
    $('.container-editor li').removeClass('active activeIn')
    $('.edit-operation').removeClass('fadeIn').addClass('fadeOut')
  }
}
