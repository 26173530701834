<template>
  <div class="body layersDetail">
    <app-header/>
    <div class="sync-scroll gray">
      <boxes v-if="workingObject && currentMeasurementPoint && currentMeasurementPoint.MpGuid && currentMeasurementPoint.PrID" :options="{
      bg: 'gray',
      title: 'label.layers',
      type:'layerBox',
      businesskey:'layer',
      fieldname: 'LaSoilType',
      isfieldRequest: true,
      isMultiple: true,
      modal:'laModal',
      data: layers,
      PrID: currentMeasurementPoint.PrID,
      MpGuid: currentMeasurementPoint.MpGuid,
        buttons: ['addButton'],
      enableBoxLink: true}">
        </boxes>
        <boxes v-if="workingObject && currentMeasurementPoint && currentMeasurementPoint.MpGuid && currentMeasurementPoint.PrID" :options="{
      bg: 'gray',
      title: 'label.soilSamples',
      type:'soilSampleBox',
      businesskey:'soilSample',
      modal:'saModal',
      data: soilSamples,
      PrID: currentMeasurementPoint.PrID,
      MpGuid: currentMeasurementPoint.MpGuid,
      buttons: ['addButton'],
      enableBoxLink: true}">
      </boxes>
    </div>

    <editors v-if="workingObject && workingObjectType === 'layer'" screen="frmLayerDetails"></editors>
    <editors v-if="workingObject && workingObjectType === 'soilSample'" screen="frmJarDetails"></editors>
    <nv-loading :show="loading"></nv-loading>
    <app-footer :saveComponentDataFunction="save"/>

    <ExportProjectPopup :exportPopupOpen="exportPopupOpen" @closeButtonClicked="toggleExportPopup"/>
  </div>
</template>
<script>
import $ from 'jquery'
import _ from 'lodash'
import AppHeader from '@/components/_shared/header'
import AppFooter from '@/components/_shared/footer'
import nvLoading from '@/components/_shared/loading.vue'
import Editors from '@/components/editors.vue'
import boxes from '@/components/boxes.vue'
import arrayFormater from '@/utils/arrayUtils'
import gapsRule from '@/utils/gapsRule'
import workingObjectUtils from '@/utils/workingObjectUtils'
import { LAYER_TYPE, SOIL_SAMPLE_TYPE } from '@/vuex/constants'
import { MeasurementPointService } from '@/services/DataWS/measurementPointService'
import { SoilSampleService } from '@/services/DataWS/soilSampleService'
import { LayerService } from '@/services/DataWS/layerService'
import ExportProjectPopup from '@/components/export/exportProjectPopup.vue'

const measurementPointService = new MeasurementPointService()
const soilSampleService = new SoilSampleService()
const layerService = new LayerService()

var self
// editors is used
// eslint-disable-next-line no-unused-vars
var editors

export default {
  name: '', // calculated on init
  components: {
    AppHeader,
    AppFooter,
    nvLoading,
    Editors,
    boxes,
    ExportProjectPopup
  },
  data () {
    return {
      loading: false,
      screen: 'frmLayerDetails',
      PrID: null,
      MpGuid: null,
      exportPopupOpen: false
    }
  },
  computed: {
    layers: {
      get () {
        return this.$store.state.layers
      },
      set (value) {
        this.$store.commit('setLayers', value)
      }
    },
    soilSamples: {
      get () {
        return this.$store.state.soilSamples
      },
      set (value) {
        this.$store.commit('setSoilSamples', value)
      }
    },
    measurementPoints: {
      get () {
        return this.$store.state.measurementPoints
      },
      set (value) {
        this.$store.commit('setMeasurementPoints', value)
      }
    },
    workingObject: {
      get () {
        return this.$store.state.workingObject
      },
      set (value) {
        this.$store.commit('setWorkingObject', value)
      }
    },
    workingObjectType: {
      get () {
        return this.$store.state.workingObjectType
      },
      set (value) {
        this.$store.commit('setWorkingObjectType', value)
      }
    },
    workingObjectIndex: {
      get () {
        return this.$store.state.workingObjectIndex
      },
      set (value) {
        this.$store.commit('setWorkingObjectIndex', value)
      }
    },
    currentMeasurementPoint: { // uses mpGuid and measurementpoints to get the current measurementpoint
      get () {
        if (this.measurementPoints) {
          if (this.MpGuid) {
            return this.measurementPoints.find(m => m.MpGuid === this.MpGuid)
          }
        }
        return null
      }
    }
  },
  watch: {
    '$route' (to, from) {
      this.setSelectedLayer()
    }
  },
  async created () {
    if (!this.$store.state.currentProject) {
      await this.$store.dispatch('fetchProject', {
        PrID: this.$route.params.projectId
      })
    }
    this.setSelectedLayer()
  },
  mounted () {
    self = this
    editors = self.$children.find(v => v.$options.name === 'Editors')
    this.setSelectedLayer()
  },
  methods: {
    format () {
      let layers = this.layers.filter(v => v.type !== 'emptyBox')
      let soilSamples = this.soilSamples.filter(v => v.type !== 'emptyBox')

      // new logic
      let result = gapsRule.gapTilesByLayers(layers, soilSamples, [])

      this.layers = result.layers || []
      this.soilSamples = result.soilSamples || []
    },
    resetActiveTile () {
      this.soilSamples && this.soilSamples.forEach(ed => (ed.isActive = false))
      this.layers && this.layers.forEach(ed => (ed.isActive = false))
    },
    setActiveLayer () {
      this.workingObjectIndex = _.findIndex(this.layers, { LaID: this.$route.params.layerID })

      this.resetActiveTile()

      this.workingObject = this.layers[this.workingObjectIndex]
      this.workingObject.isActive = true
      this.workingObjectType = LAYER_TYPE

      return this.setState('LaFrom', 'LaTo')
    },
    setActiveSoilSample () {
      this.workingObjectIndex = _.findIndex(this.soilSamples, { JrID: this.$route.params.jarID })

      this.resetActiveTile()

      this.soilSamples[this.workingObjectIndex].isActive = true
      this.workingObject = this.soilSamples[this.workingObjectIndex]
      this.workingObjectType = SOIL_SAMPLE_TYPE

      return this.setState('JrFrom', 'JrTo')
    },
    setState (from, to) {
      return this.$store.dispatch('setActiveTitle', this.workingObject[from] + '-' + this.workingObject[to])
        .then(r => { return this.$store.dispatch('setPrevTitle', (this.currentMeasurementPoint || {}).MpName) })
    },
    init (screenname) {
      this.screen = screenname
      if (this.$options.name !== this.$route.name) {
        this.$options.name = this.$route.name
      }
    },
    load () {
      switch (this.$route.name) {
        case 'layer':
          this.init('frmLayerDetails')
          if (_.isArray(this.layers) && this.layers.length) {
            return this.setActiveLayer().then(function () { return true })
          }
          break
        case 'soilSample':
          this.init('frmJarDetails')
          if (_.isArray(this.soilSamples) && this.soilSamples.length) {
            return this.setActiveSoilSample().then(function () { return true })
          }
          break
      }
      return Promise.resolve(false)
    },
    getNextLayerIndex (index) {
      // Get the first (active) layer after this index.
      while (index < this.layers.length - 1) {
        index++
        var obj = this.layers[index]
        if (obj.hasOwnProperty('to')) {
          // Current index is a valid/active layer.
          return index
        }
      }
      return -1
    },
    parseTblLayers (tblLayers) {
      // create fields that are missed in service when its value is empty, then the texture value can be watched
      for (let i of tblLayers) {
        i.LaSoilTypeCode = i.LaSoilTypeCode || ''
        i.LaMedianCode = i.LaMedianCode || ''
        i.LaAd1TypeCode = i.LaAd1TypeCode || ''
        i.LaAd1GradationCode = i.LaAd1GradationCode || ''
        i.LaAd2TypeCode = i.LaAd2TypeCode || ''
        i.LaAd2GradationCode = i.LaAd2GradationCode || ''
        i.LaAd3TypeCode = i.LaAd3TypeCode || ''
        i.LaAd3GradationCode = i.LaAd3GradationCode || ''
        i.LaAd4TypeCode = i.LaAd4TypeCode || ''
        i.LaAd4GradationCode = i.LaAd4GradationCode || ''
      }
      this.layers = tblLayers
    },
    parseTblJars (tblJars) {
      tblJars.forEach(v => {
        v.JrStirred = v.JrStirred || ''
        v.JrSieved = v.JrSieved || ''
        v.JrBarcode = v.JrBarcode || ''
        v.MpGuid = this.MpGuid
      })
      this.soilSamples = tblJars
    },
    setSelectedLayer () {
      this.MpGuid = this.$route.params.measurementPointId
      this.PrID = this.$route.params.projectId

      return this.load().then((dataAlreadyExists) => {
        if (!dataAlreadyExists) {
          let tasks = []
          tasks.push(layerService.getLayers(this.PrID, this.MpGuid))
          tasks.push(soilSampleService.getSoilSamples(this.PrID, this.MpGuid))
          tasks.push(measurementPointService.getMeasurementPoints(this.PrID))
          window.Promise.all(tasks)
            .then(([layersRes, soilSamplesRes, measurementPointRes]) => {
              this.parseTblLayers(layersRes.tblLayers)
              this.measurementPoints = measurementPointRes.tblMeasurementPoints

              if (soilSamplesRes.data && soilSamplesRes.data.ResultCode === 'Export_Succes') {
                this.parseTblJars(arrayFormater.formatArray(JSON.parse(soilSamplesRes.data.Files[0].FileContent).VeldData.tblJars))
              }
              this.format()
              return this.load(r => {
                this.loading = false
              })
            })
            .catch(err => {
              this.loading = false
              console.error(err)
              // this.handlerError(err)
            })
        } else {
          this.loading = false
        }
      })
    },
    save () {
      return workingObjectUtils.pushWorkingObjectToDataService({
        PrID: this.PrID,
        MpGuid: this.MpGuid
      })
        .then(response => {
          if (self.workingObjectType === LAYER_TYPE) { this.parseTblLayers(response) } else if (self.workingObjectType === SOIL_SAMPLE_TYPE) {
            this.parseTblJars(response)
          }
          this.format()
          return this.load()
        })
    },
    deleteLayer () {
      let modalDelete
      switch (self.$route.name) {
        case 'layer':
          modalDelete = $('#modalDeleteLayer')
          break
        case 'soilSample':
          modalDelete = $('#modalDeleteSoilSample')
          break
        default:
          return
      }

      modalDelete.modal('toggle')
    },
    async toggleExportPopup() {
      if (!this.exportPopupOpen && this.$store.state.workingObjectChanged) {
        await this.save()
      }
      this.exportPopupOpen = !this.exportPopupOpen
    }
  }
}
</script>
<style lang="less">
  @media (max-width: 640px) {
    .layersDetail .edit-panel .input-group.input-left{
      width: 100%;
    }
  }
  @media (min-width: 768px) {
    .layersDetail .editor-panel {
      width: calc(100vw - 614px);
    }
    .sync-scroll{
      height: calc(100vh - 120px);
    }
  }
  .sync-scroll{
    overflow-y: auto;
    float: left;
    .mpboxs{
      padding-top: 90px;
      .title-box{
        position: fixed;
        z-index: 5;
        top: 60px;
        width: 180px;
        padding-top: 10px;
        background: #eee;
      }
      .contentbox {
        overflow: initial;
        height: initial;
      }
      &.gray{
        .title-box{background: #515151}
      }
    }
    &.gray{background: #515151}
  }
</style>
