import arrayUtils from '@/utils/arrayUtils'
import moment from 'moment'
import Vue from 'vue'
import $store from '../../vuex/store'
import $ from 'jquery'
import { SoilSampleService } from '@/services/DataWS/soilSampleService'
const soilSampleService = new SoilSampleService()

export default {
  gotolist (self) {
    // todo: change path for SoilSample
    let path = `/project/${self.options.projectId}/measurementPoint`
    self.$router.push(path)
  },
  open (self) {
    $('#modalAddSoilSample').modal('toggle')
      .on('shown.bs.modal', function (e) {
        $('#txtSoilSampleName').focus()
      })
    let soilSamples = $store.state.soilSamples
    Vue.set(self.suggestedNames, self.options.type, arrayUtils.getDefaultName([...soilSamples], 'JrName', 'JrID'))

    let last = soilSamples[soilSamples.length - 1]
    let create = self.$children.find(v => v.$options.name === 'saModal') || {}
    create.resetDefault()
    create.from = (last && last.JrTo) || 0
    create.to = ''
    create.validationError_name = ''
    create.validationError_to = ''
  },
  delete (self) {
    self.loading = true
    $('#modalDeleteSoilSample').modal('hide')

    let jrGuid = $store.state.workingObject.JrGuid
    let jrID = $store.state.workingObject.JrID
    let soilSamples = $store.state.soilSamples
    let newSoilSamples = soilSamples.filter(jr => jr.JrGuid !== jrGuid)
    $store.commit('setSoilSamples', newSoilSamples)

    newSoilSamples.forEach((jr, index) => {
      jr.JrID = `${index + 1}`
    })

    const maxJrID = Math.max(...newSoilSamples.map(jr => +jr.JrID))
    jrID = (jrID > maxJrID) ? maxJrID : jrID

    let soilsamples = $store.state.soilSamples.filter(v => v.type !== 'emptyBox')
    soilSampleService.deleteSoilSample(self.$route.params.projectId, self.$route.params.measurementPointId, $store.state.workingObject.JrGuid).then(res => {
      const workingObject = soilsamples.length > 0 ? newSoilSamples[jrID - 1] : newSoilSamples[0]
      if (workingObject ?? false) {
        $store.commit('setWorkingObject', workingObject)
      }
      self.loading = false
      if (soilsamples.length >= 1) {
        self.$router.push({
          name: 'soilSample',
          params: {
            'projectId': self.$route.params.projectId,
            'measurementPointId': self.$route.params.measurementPointId,
            'jarID': `${jrID}`
          }
        })
        self.$parent.format()
      } else {
        self.$router.push({
          name: 'measurementPoint',
          params: {
            'projectId': self.$route.params.projectId,
            'measurementPointId': self.$route.params.measurementPointId
          }
        })
      }
    }, err => {
      $('#modalDeleteSoilSample').modal('toggle')
      self.loading = false
      console.log(err)
    })
  },
  add (self, data) {
    if (self.loading) return
    self.loading = true

    let now = moment().format('YYYY-MM-DDTHH:mm:ss')
    let newitem = {
      'MpID': '1',
      'JrID': '1',
      'JrName': data.name,
      'JrFrom': data.from,
      'JrTo': data.to,
      'JrDateTime': now,
      'JrDateLastChanged': now
    }

    let soilSamples = $store.state.soilSamples.filter(v => v.type !== 'emptyBox')
    soilSamples.push(newitem)

    let prid = self.$route.params.projectId
    let mpguid

    if (isNaN(self.$route.params.measurementPointId)) {
      mpguid = self.$route.params.measurementPointId
    } else {
      mpguid = $store.state.measurementPoints.find(measurementpoint => measurementpoint.MpID === self.$route.params.measurementPointId).MpGuid
    }

    soilSampleService.createSoilSamples(prid, mpguid, soilSamples)
      .then(soilSamples => {
        $store.commit('setSoilSamples', soilSamples)
        self.loading = false
        $('#modalAddSoilSample').modal('hide')

        soilSamples.forEach(v => {
          v.JrStirred = v.JrStirred || ''
          v.JrSieved = v.JrSieved || ''
          v.JrBarcode = v.JrBarcode || ''
        })

        let realParent = self.$parent
        if (realParent.$options.name === 'syncScroll') {
          realParent = realParent.$parent
        }
        if (realParent.$options.name === 'measurementPoint' || realParent.$options.name === 'layer' || realParent.$options.name === 'soilSample') {
          realParent.format && realParent.format()
        } else {
          console.warn('Can not format [' + realParent.$options.name + ']')
        }

        let maxID = Math.max(...soilSamples.map(jr => jr.JrID))
        return soilSamples.find(jr => jr.JrID === maxID.toString())
      })
      .then((soilSample) => {
        self.loading = false
        let path = '/project/' + prid + '/measurementPoint/' + mpguid + '/soilSample/' + soilSample.JrID + '?new=true'
        self.$router.push(path)
      })
      .catch(err => {
        console.error('Catch: ', err)
        $('#modalAddSoilSample').modal('hide')
        self.loading = false
      })
  }
}
