import $ from 'jquery'
export default {
  open (self) {
    $('#modalSelectUser').modal('show')
      .on('shown.bs.modal', function (e) {
        $('#modalSelectUserSearch').focus()
        $('#modalSelectUser').trigger('popupTrigger')
      })
  }
}
