import arrayUtils from '@/utils/arrayUtils'
import moment from 'moment'
import $store from '../../vuex/store'
import Vue from 'vue'
import $ from 'jquery'

import { WaterSampleService } from '@/services/DataWS/waterSampleService'
const waterSampleService = new WaterSampleService()

export default {
  modalName: 'waModal',
  gotolist (self) {
    let path = `/project/${self.options.projectId}/measurementPoint`
    self.$router.push(path)
  },
  open (self) {
    let mpName = self.options.MpName
    let ftGuid = self.options.FtGuid
    let mpComplexWaterSamples = self.options.data
    Vue.set(self.suggestedNames, 'waBox', this.getDefaultName(mpComplexWaterSamples, ftGuid, mpName))
    let modalName = 'waModal'
    let type = (['SURFACEAIR', 'AIR'].includes(self.options.type)) ? 'AIR' : 'WATER'
    if (type === 'AIR') {
      modalName = 'asModal'
    }
    let create = self.$children.find(v => v.$options.name === modalName) || {}
    create.resetDefault()
    if (type === 'AIR') {
      create.matrixcode = 'BL'
      $('#modalAddAirSample' + ftGuid).modal('toggle')
        .on('shown.bs.modal', function (e) {
          $('#txtAirSampleName' + ftGuid + type).focus()
        })
    } else {
      create.matrixcode = 'GW'
      $('#modalAddWaterSample' + ftGuid).modal('toggle')
        .on('shown.bs.modal', function (e) {
          $('#txtWaterSampleName' + ftGuid + type).focus()
        })
    }
  },
  delete (self, type) {
    self.loading = true
    let ftComplexWaterSample = self.template.data
    let prid = self.$route.params.projectId
    let mpguid = self.options.MpGuid || self.$route.params.measurementPointId
    let ftguid = self.options.FtGuid || self.$route.params.FtGuid
    let wsguid = $store.state.workingObject.WsGuid
    let waterSamples = ftComplexWaterSample.waterSamples
    let WsID = '1'
    if (type === 'AIR') {
      $('#modalDeleteAirSample' + ftComplexWaterSample.guid)
        .modal('hide')
    } else {
      $('#modalDeleteWaterSample' + ftComplexWaterSample.guid)
        .modal('hide')
    }
    waterSampleService.deleteWaterSample(prid, mpguid, ftguid, wsguid).then(res => {
      $store.commit('clearWorkingObject')
      let el = document.getElementsByClassName('modal-backdrop ')
      if (el[0]) {
        el[0].remove()
      }
      if (waterSamples.length > 1) {
        if (type === 'WATER') {
          if (ftguid) {
            self.$router.push({
              name: 'watersample',
              params: {
                'projectId': self.$route.params.projectId,
                'measurementPointId': self.$route.params.measurementPointId,
                'FtGuid': ftguid,
                'WsID': WsID
              }
            })
            self.$router.go()
          } else {
            self.$router.push({
              name: 'watersampleSurface',
              params: {
                'projectId': self.$route.params.projectId,
                'measurementPointId': self.$route.params.measurementPointId,
                'WsID': WsID
              }
            })
            self.$router.go()
          }
        } else {
          if (ftguid) {
            self.$router.push({
              name: 'airSample',
              params: {
                'projectId': self.$route.params.projectId,
                'measurementPointId': self.$route.params.measurementPointId,
                'FtGuid': ftguid,
                'WsID': WsID
              }
            })
            self.$router.go()
          } else {
            self.$router.push({
              name: 'airSampleSurface',
              params: {
                'projectId': self.$route.params.projectId,
                'measurementPointId': self.$route.params.measurementPointId,
                'WsID': WsID
              }
            })
            self.$router.go()
          }
        }
      } else {
        self.$router.push({
          name: 'measurementPoint',
          params: {
            'projectId': self.$route.params.projectId,
            'measurementPointId': self.$route.params.measurementPointId
          }
        })
      }
    }, err => {
      if (type === 'AIR') {
        $('#modalDeleteAirSample' + ftComplexWaterSample.guid)
          .modal('hide')
      } else {
        $('#modalDeleteWaterSample' + ftComplexWaterSample.guid)
          .modal('hide')
      }
      self.loading = false
      console.log(err)
    })
  },
  add (self, data) {
    if (self.loading) return
    self.loading = true

    let ftComplexWaterSample = self.template.data

    let now = moment().format('YYYY-MM-DDTHH:mm:ss')
    let newitem = {
      'WsID': '1',
      'WsName': data.name,
      'WsMatrixCode': data.matrixcode,
      'WsDate': now,
      'WsDateLastChanged': now
    }

    let watersamples = self.$store.state.WaterSamples || []

    // let index = 1
    watersamples.forEach((v) => {
      if ((typeof v.FtID !== 'undefined') && (v.FtID !== null)) {
        v.FtID = '1'
      }
    })
    watersamples.unshift(newitem)

    let prid = self.$route.params.projectId
    let mpguid = self.options.MpGuid || self.$route.params.measurementPointId
    let ftguid = self.options.FtGuid || self.$route.params.FtGuid

    waterSampleService.createWaterSample(prid, mpguid, ftguid, [newitem])
      .then(res => {
        if (data.type === 'AIR') {
          $('#modalAddAirSample' + ftComplexWaterSample.guid)
            .modal('hide')
        } else {
          $('#modalAddWaterSample' + ftComplexWaterSample.guid)
            .modal('hide')
        }
        return $store.dispatch('fetchWaterSurfaceWaterAirSurfaceAirSamplesBottlesAndObservations', { projectId: prid, MpGuid: mpguid })
      })
      .then(() => {
        self.loading = false
        let wsID = '1'
        if (ftguid) {
          let path = ''
          let ft = $store.state.gaugingTubes.find((gt) => { return gt.FtGuid === ftguid })
          if (data.type === 'AIR') {
            let asSample = $store.state.airSamples.filter((as) => { return as.FtID === ft.FtID }).last()
            if (asSample) {
              wsID = asSample.WsID
            }
            path = '/project/' + prid + '/measurementPoint/' + mpguid + '/gaugingTubes/' + ftguid + '/airSample/' + wsID.toString() + '?new=true'
          } else {
            let wsSample = $store.state.waterSamples.filter((as) => { return as.FtID === ft.FtID }).last()
            if (wsSample) {
              wsID = wsSample.WsID
            }
            path = '/project/' + prid + '/measurementPoint/' + mpguid + '/gaugingTubes/' + ftguid + '/watersample/' + wsID.toString() + '?new=true'
          }
          self.$router.push(path)
        } else {
          let path = ''
          if (data.type === 'AIR') {
            wsID = $store.state.surfaceAirSamples.last().WsID
            path = '/project/' + prid + '/measurementPoint/' + mpguid + '/airSample/' + wsID.toString() + '?new=true'
          } else {
            wsID = $store.state.surfaceWaterSamples.last().WsID
            path = '/project/' + prid + '/measurementPoint/' + mpguid + '/watersample/' + wsID.toString() + '?new=true'
          }
          self.$router.push(path)
        }
      })
      .catch(err => {
        console.error(err)
        if (data.type === 'AIR') {
          $('#modalAddAirSample' + ftComplexWaterSample.guid)
            .modal('hide')
        } else {
          $('#modalAddWaterSample' + ftComplexWaterSample.guid)
            .modal('hide')
        }
        self.loading = false
      })
  },
  getDefaultName (mpComplexWaterSamples, currentFtGuid, mpName) {
    let ftComplexWaterSample = mpComplexWaterSamples.find(v => v.guid === currentFtGuid)
    let ftName = null
    if (ftComplexWaterSample && ftComplexWaterSample.name) {
      ftName = ftComplexWaterSample.name
    }

    let wsName = ''

    // get the newest water sample under the SAME gauging tube.
    if (ftComplexWaterSample.waterSamples) {
      wsName = arrayUtils.getDefaultName(ftComplexWaterSample.waterSamples, 'WsName', 'WsID')
    }
    if (!wsName) {
      if (ftName !== null) {
        wsName = mpName + '-' + ftName + '-1'
      } else {
        wsName = mpName + '-1'
      }
    }
    // check wsName is unique in watersamples under all gauging tubes.
    let wsNameIsUnqiue = true

    do {
      if (!wsNameIsUnqiue) {
        wsName = arrayUtils.getNewNameByIncremented(wsName)
      }

      wsNameIsUnqiue = this.checkNameIsUnique(wsName, mpComplexWaterSamples)
    } while (!wsNameIsUnqiue)

    return wsName
  },

  checkNameIsUnique (wsName, mpComplexWaterSamples) {
    if (!wsName) return false

    let wsNameIsUnqiue = true
    for (let cws of mpComplexWaterSamples) {
      wsNameIsUnqiue = wsNameIsUnqiue && (cws.waterSamples || []).find(v => v.WsName === wsName) == null
    }
    return wsNameIsUnqiue
  }
}
