<template>
  <a href="javascript:" :class="classes" @click="gotoDetails()">
    <div class="arrow"></div>
    <div class="item">
      <p :title="parsedTemplate.CcTypeFunctionContactPersonDisplay"><strong>{{parsedTemplate.CcTypeFunctionContactPersonDisplay}}</strong></p>
      <p :title="parsedTemplate.CcContactPerson">{{parsedTemplate.CcContactPerson}}</p>
      <p :title="parsedTemplate.CcTypeConcernedDisplay">{{parsedTemplate.CcTypeConcernedDisplay}}</p>
      <p :title="parsedTemplate.CcCompanyName">{{parsedTemplate.CcCompanyName}}</p>
    </div>
  </a>
</template>
<script>
import { CONCERNED_COMPANY_TYPES_CODE_LIST, CONTACT_PERSON_FUNCTIONS_CODE_LIST } from '@/vuex/constants'

export default {
  name: 'concernedContactBox',
  props: ['template', 'functions', 'options'],

  data () {
    return {
      classes: 'contents',
      selected: false,
      detailUrl: String,
      isActive: false
    }
  },
  computed: {
    [CONTACT_PERSON_FUNCTIONS_CODE_LIST]: {
      get () {
        return this.$store.state.codeLists[CONTACT_PERSON_FUNCTIONS_CODE_LIST]
      }
    },
    [CONCERNED_COMPANY_TYPES_CODE_LIST]: {
      get () {
        return this.$store.state.codeLists[CONCERNED_COMPANY_TYPES_CODE_LIST]
      }
    },
    parsedTemplate: {
      get () {
        let parsedTemplate = this.template

        // if type concerned is set
        if (parsedTemplate.CcTypeConcerned) {
          // try to find code of type concerned
          let concernedCompany = this.getCodeFromListByCode(CONCERNED_COMPANY_TYPES_CODE_LIST, parsedTemplate.CcTypeConcerned)
          // if found, then set to description field of concerned contact
          parsedTemplate.CcTypeConcernedDisplay = concernedCompany ? concernedCompany.GcDescription : '-'
        } else {
          parsedTemplate.CcTypeConcernedDisplay = '-'
        }

        // if function is set
        if (parsedTemplate.CcTypeFunctionContactPerson) {
          // try to find code of function
          let contactPersonFunction = this.getCodeFromListByCode(CONTACT_PERSON_FUNCTIONS_CODE_LIST, parsedTemplate.CcTypeFunctionContactPerson)
          // if found, then set to description field of concerned contact
          parsedTemplate.CcTypeFunctionContactPersonDisplay = contactPersonFunction ? contactPersonFunction.GcDescription : '-'
        } else {
          parsedTemplate.CcTypeFunctionContactPersonDisplay = '-'
        }

        return parsedTemplate
      }
    }
  },
  watch: {
    '$route.params.CcGUID': {
      handler: function (val) {
        if (!val) {
          this.classes = 'contents'
          return
        }
        this.isActive = val === this.template.CcGUID
        this.classes = this.isActive ? 'contents active' : 'contents'
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getCodeFromListByCode (list, code) {
      if (this[list]) {
        // find code with GcCode 'id'
        return this[list].find(func => func.GcCode === code.toString())
      } else {
        return null
      }
    },
    gotoDetails () {
      // project of boxes in project detail or project id of boxes in concerned contact detail
      let prid = this.$route.params.projectId
      let CcGUID = this.template.CcGUID
      this.detailUrl = `/project/${prid}/concernedContact/${CcGUID}`
      if ((this.options || {}).enableBoxLink) { this.$router.push(this.detailUrl) }
    }
  }
}
</script>
