export default {
  type: 'ISO',
  defaultGroup: 8,
  categoryGroups: [
    { GcCode: 1, SortOrder: 1 }, // Organisch, ISO 14688
    { GcCode: 2, SortOrder: 2 }, // Zeer grove grond, ISO 14688
    { GcCode: 3, SortOrder: 3 }, // Grove grond, ISO 14688
    { GcCode: 4, SortOrder: 4 }, // Fijne grond, ISO 14688
    { GcCode: 5, SortOrder: 5 }, // Sedimentaire gesteenten, ISO 14689
    { GcCode: 6, SortOrder: 6 }, // Stollingsgesteenten, ISO 14689
    { GcCode: 7, SortOrder: 7 }, // Metamorfe gesteenten, ISO 14689
    { GcCode: 8, SortOrder: 8 } // Overige
  ],
  codes: [
    // { primaryGcCode: 'V', primaryRemark: 'Veen', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['R','E', 'H', 'T', 'O', 'C', , 'A', 'D'], qualifier: [], organic: null, tertiary: [], plasticity: [], decomposition: null, grainshape: [], form: [], surface: [], particlestrength: null, rockshape: [], rockweathering: [], consistency: null } },

    // Organisch, ISO 14688
    { categoryGroup: 1, sortOrderInGroup: 1, primaryGcCode: 'V', primaryRemark: 'Veen', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier: ['4', '0', '2'], secondary2: ['R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier2: ['4', '0', '2'], organic: null, tertiary: null, plasticity: null, decomposition: ['AV1', 'AV2', 'AV3', 'VE1', 'VE2', 'VE3', '8', '9', '11', '12'], grainshape: null, form: null, surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: null } },
    { categoryGroup: 1, sortOrderInGroup: 2, primaryGcCode: 'H', primaryRemark: 'Teelaarde of Humus', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier: ['4', '0', '2'], secondary2: ['R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier2: ['4', '0', '2'], organic: null, tertiary: [], plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: null } },
    { categoryGroup: 1, sortOrderInGroup: 3, primaryGcCode: 'Y', primaryRemark: 'Gyttja', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier: ['4', '0', '2'], secondary2: ['R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier2: ['4', '0', '2'], organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: null } },
    { categoryGroup: 1, sortOrderInGroup: 4, primaryGcCode: 'D', primaryRemark: 'Dy', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier: ['4', '0', '2'], secondary2: ['R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier2: ['4', '0', '2'], organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: null } },
    { categoryGroup: 1, sortOrderInGroup: 5, primaryGcCode: 'F', primaryRemark: 'Detritus', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier: ['4', '0', '2'], secondary2: ['R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier2: ['4', '0', '2'], organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: null } },
    { categoryGroup: 1, sortOrderInGroup: 6, primaryGcCode: 'I', primaryRemark: 'Bruinkool organisch', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier: ['4', '0', '2'], secondary2: ['R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier2: ['4', '0', '2'], organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: null } },

    // Zeer grove grond, ISO 14688
    { categoryGroup: 2, sortOrderInGroup: 1, primaryGcCode: 'B', primaryRemark: 'Blokken', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier: ['4', '0', '2'], secondary2: ['R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier2: ['4', '0', '2'], organic: ['4', '0'], tertiary: null, plasticity: null, decomposition: null, grainshape: [], form: [], surface: [], particlestrength: null, rockshape: null, rockweathering: null, consistency: null } },
    { categoryGroup: 2, sortOrderInGroup: 2, primaryGcCode: 'E', primaryRemark: 'Keien', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['R', 'V', 'M', 'E', 'H', 'T', 'O', 'C', 'L', 'A', 'D', '16'], qualifier: ['4', '0', '2'], secondary2: ['R', 'V', 'M', 'E', 'H', 'T', 'O', 'C', 'L', 'A', 'D', '16'], qualifier2: ['4', '0', '2'], organic: ['4', '0'], tertiary: null, plasticity: null, decomposition: null, grainshape: [], form: [], surface: [], particlestrength: null, rockshape: null, rockweathering: null, consistency: null } },
    { categoryGroup: 2, sortOrderInGroup: 3, primaryGcCode: 'P', primaryRemark: 'Keitjes', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['R', 'V', 'M', 'E', 'H', 'T', 'O', 'C', 'L', 'A', 'D', '16'], qualifier: ['4', '0', '2'], secondary2: ['R', 'V', 'M', 'E', 'H', 'T', 'O', 'C', 'L', 'A', 'D', '16'], qualifier2: ['4', '0', '2'], organic: ['4', '0'], tertiary: null, plasticity: null, decomposition: null, grainshape: [], form: [], surface: [], particlestrength: null, rockshape: null, rockweathering: null, consistency: null } },

    // Grove grond, ISO 14688
    { categoryGroup: 3, sortOrderInGroup: 1, primaryGcCode: 'G', primaryRemark: 'Grind', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: ['1', '2', '3'], secondary: ['G', 'R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier: ['4', '0', '2'], secondary2: ['G', 'R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier2: ['4', '0', '2'], organic: ['4', '0'], tertiary: null, plasticity: null, decomposition: null, grainshape: [], form: [], surface: [], particlestrength: null, rockshape: null, rockweathering: null, consistency: null } },
    { categoryGroup: 3, sortOrderInGroup: 2, primaryGcCode: 'Z', primaryRemark: 'Zand', disableColumnOption: { primary: [], sandmedian: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'], gravelmedian: null, secondary: ['Z', 'R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier: ['4', '0', '2'], secondary2: ['Z', 'R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier2: ['4', '0', '2'], organic: ['4', '0'], tertiary: null, plasticity: null, decomposition: null, grainshape: [], form: [], surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: null } },

    // Fijne grond, ISO 14688
    { categoryGroup: 4, sortOrderInGroup: 1, primaryGcCode: 'J', primaryRemark: 'Silt', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['S', 'R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier: ['4', '0', '2'], secondary2: ['S', 'R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier2: ['4', '0', '2'], organic: ['4', '0'], tertiary: null, plasticity: [], decomposition: null, grainshape: null, form: null, surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: ['2', '3', '5', '6'] } },
    { categoryGroup: 4, sortOrderInGroup: 2, primaryGcCode: 'K', primaryRemark: 'Klei', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['K', 'R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier: ['4', '0', '2'], secondary2: ['K', 'R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier2: ['4', '0', '2'], organic: ['4', '0'], tertiary: null, plasticity: [], decomposition: null, grainshape: null, form: null, surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: ['2', '3', '5', '6'] } },

    // Sedimentaire gesteenten, ISO 14689
    { categoryGroup: 5, sortOrderInGroup: 1, primaryGcCode: '22', primaryRemark: 'Conglomerate', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 2, primaryGcCode: '62', primaryRemark: 'Breccia', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 3, primaryGcCode: '23', primaryRemark: 'Zandsteen', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 4, primaryGcCode: '52', primaryRemark: 'Greywacke', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 5, primaryGcCode: '24', primaryRemark: 'Siltstone', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 6, primaryGcCode: '25', primaryRemark: 'Glaystone', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 7, primaryGcCode: '53', primaryRemark: 'Mudstone', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 8, primaryGcCode: '26', primaryRemark: 'Vuursteen', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 9, primaryGcCode: '54', primaryRemark: 'Chert', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 10, primaryGcCode: '27', primaryRemark: 'Kalksteen', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 11, primaryGcCode: '55', primaryRemark: 'Dolomiet', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 12, primaryGcCode: '28', primaryRemark: 'Calcirudiet', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 13, primaryGcCode: '29', primaryRemark: 'Calcarenite', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 14, primaryGcCode: '56', primaryRemark: 'Krijt', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 15, primaryGcCode: '57', primaryRemark: 'Calcisiltiet', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 16, primaryGcCode: '30', primaryRemark: 'Calcilutiet', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 17, primaryGcCode: '31', primaryRemark: 'Agglomarate', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 18, primaryGcCode: '58', primaryRemark: 'Vulkanische breccia', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 19, primaryGcCode: '32', primaryRemark: 'Tufsteen', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 20, primaryGcCode: '33', primaryRemark: 'Kolen', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 21, primaryGcCode: '63', primaryRemark: 'Bruinkool', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 22, primaryGcCode: '34', primaryRemark: 'Halite', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 23, primaryGcCode: '59', primaryRemark: 'Anhydrite', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 24, primaryGcCode: '60', primaryRemark: 'Gypsum', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 5, sortOrderInGroup: 25, primaryGcCode: '61', primaryRemark: 'Travertine', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },

    // Stollingsgesteenten, ISO 14689
    { categoryGroup: 6, sortOrderInGroup: 1, primaryGcCode: '35', primaryRemark: 'Graniet', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 6, sortOrderInGroup: 2, primaryGcCode: '36', primaryRemark: 'Micro-graniet', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 6, sortOrderInGroup: 3, primaryGcCode: '37', primaryRemark: 'Ryoliet', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 6, sortOrderInGroup: 4, primaryGcCode: '38', primaryRemark: 'Dioriet', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 6, sortOrderInGroup: 5, primaryGcCode: '39', primaryRemark: 'Micro-diorite', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 6, sortOrderInGroup: 6, primaryGcCode: '40', primaryRemark: 'Andesiet', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 6, sortOrderInGroup: 7, primaryGcCode: '41', primaryRemark: 'Vulkanisch glas / obsidiaan', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 6, sortOrderInGroup: 8, primaryGcCode: '42', primaryRemark: 'Gabbro', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 6, sortOrderInGroup: 9, primaryGcCode: '43', primaryRemark: 'Dolerite', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 6, sortOrderInGroup: 10, primaryGcCode: '44', primaryRemark: 'Bazalt', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },

    // Metamorfe gesteenten, ISO 14689
    { categoryGroup: 7, sortOrderInGroup: 1, primaryGcCode: '45', primaryRemark: 'Gneis', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 7, sortOrderInGroup: 2, primaryGcCode: '46', primaryRemark: 'Schilfersteen', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 7, sortOrderInGroup: 3, primaryGcCode: '47', primaryRemark: 'Leisteen', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 7, sortOrderInGroup: 4, primaryGcCode: '48', primaryRemark: 'Marmeren', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 7, sortOrderInGroup: 5, primaryGcCode: '49', primaryRemark: 'Kwartsiet', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 7, sortOrderInGroup: 6, primaryGcCode: '50', primaryRemark: 'Hornfels', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 7, sortOrderInGroup: 7, primaryGcCode: '51', primaryRemark: 'Mergel', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },
    { categoryGroup: 7, sortOrderInGroup: 8, primaryGcCode: 'A', primaryRemark: 'Kalk', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, secondary2: null, qualifier2: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: [], rockshape: [], rockweathering: [], consistency: null } },

    // Overige
    { categoryGroup: 8, sortOrderInGroup: 1, primaryGcCode: 'W', primaryRemark: 'Water', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: null } },
    { categoryGroup: 8, sortOrderInGroup: 2, primaryGcCode: 'O', primaryRemark: 'Antropogene stort- of vullaag', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier: ['4', '0', '2'], secondary2: ['R', 'L', 'V', 'M', 'H', 'T', 'O', 'C', 'A', 'D', '16'], qualifier2: ['4', '0', '2'], organic: ['4', '0'], tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: null } },
    { categoryGroup: 8, sortOrderInGroup: 3, primaryGcCode: 'S', primaryRemark: 'Slib', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: null } }
  ],
  /**
     * A mapping between the dsFieldsProject->tblLayer to a key of the CODES->disableColumnOption->KEY
     */
  mappings: {
    primary: {
      dbField: 'LaSoilTypeCode',
      codeList: { key: 'MainTypes', sort: 'GcShortCode' }
    },
    median: {
      dbField: 'LaMedianCode',
      codeList: { key: 'Median', sort: 'GcCode' }
    },
    consistency: {
      dbField: 'LaConsistencyCode',
      codeList: { key: 'Consistency', sort: 'GcCode' }
    },
    secondary: {
      dbField: 'LaAd1TypeCode',
      codeList: { key: 'Additions', sort: 'GcShortCode' }
    },

    qualifier: {
      dbField: 'LaAd1GradationCode',
      codeList: { key: 'Gradients', sort: 'GcCode' },
      required: ['LaAd1TypeCode']
    },

    secondary2: {
      dbField: 'LaAd3TypeCode',
      codeList: { key: 'Additions', sort: 'GcShortCode' },
      required: ['LaAd1TypeCode']
    },

    qualifier2: {
      dbField: 'LaAd3GradationCode',
      codeList: { key: 'Gradients', sort: 'GcCode' },
      required: ['LaAd1TypeCode', 'LaAd3TypeCode']
    },

    organic: {
      dbField: 'LaAd2GradationCode',
      codeList: { key: 'Gradients', sort: 'GcCode' }
    },

    decomposition: {
      dbField: 'LaPeatAmorphousCode',
      codeList: { key: 'Decomposition', sort: 'GcCode' }
    },

    plasticity: {
      dbField: 'LaTexturePlasticityCode',
      codeList: { key: 'Plasticity', sort: 'GcCode' }
    },

    grainshape: {
      dbField: 'LaTextureGrainShapeCode',
      codeList: { key: 'GrainShape', sort: 'GcCode' }
    },

    form: {
      dbField: 'LaTextureFormCode',
      codeList: { key: 'Form', sort: 'GcCode' }
    },

    surface: {
      dbField: 'LaTextureSurfaceCode',
      codeList: { key: 'Surface', sort: 'GcCode' }
    },

    particlestrength: {
      dbField: 'LaTextureParticleStrengthCode',
      codeList: { key: 'ParticleStrength', sort: 'GcCode' }
    },

    rockshape: {
      dbField: 'LaRockShapeCode',
      codeList: { key: 'RockShape', sort: 'GcCode' }
    },
    rockweathering: {
      dbField: 'LaRockWeatheringCode',
      codeList: { key: 'RockWeathering', sort: 'GcCode' }
    }
  }
}
