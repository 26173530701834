<template>
  <div :id="'editor' + id" class="trajectory">
    <div class="input-group input-left">
      <input
        type="text"
        class="form-control"
        :class="{ errorBorder: !valid, warnBorder: warning }"
        :value="validatedValueAsString.x"
        :title="validatedValueAsString.x"
        readonly
        @click="$parent.expandPanel"
      />
      <input
        type="text"
        class="form-control"
        :class="{ errorBorder: !valid, warnBorder: warning }"
        :value="validatedValueAsString.y"
        :title="validatedValueAsString.y"
        readonly
        @click="$parent.expandPanel"
      />
    </div>
    <i v-if="field.required" class="required">*</i>
    <div class="editor-panel hidden animated">
      <div class="close" @click="$parent.hidePanel(valid)">
        <span class="glyphicon glyphicon-chevron-right"></span>
      </div>
      <div class="row">
        <div class="col-md-10">
          <p class="field-sub-name">
            {{ $t('label.system') + ': ' }}
            <strong>{{ selectedCoordinateSystemAsString }}</strong>
            <br />
            {{ $t('project.whereChangeCoordinateSystem') }}.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5">
          <label class="field-name">{{ $t('label.oldX') }}</label>
          <div class="has-feedback">
            <input readonly class="form-control unselectable" :class="{ warnBorder: warning }" :value="validatedValueAsString.x" />
          </div>
        </div>
        <div class="col-md-5">
          <label class="field-name">{{ $t('label.oldY') }}</label>
          <div class="has-feedback">
            <input readonly class="form-control unselectable" :class="{ warnBorder: warning }" :value="validatedValueAsString.y" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10">
          <div class="has-feedback">
            <label v-if="warning" class="warning">{{ warningMessage }}</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5">
          <label class="field-name">{{ $t('label.newX') }}</label>
          <div class="has-feedback">
            <input :id="'x_' + id" v-model="currentValue.x" type="text" class="form-control" @click="setFocus('x')" />
            <span class="form-control-feedback glyphicon glyphicon-remove" @click="emptyValue('x')"></span>
          </div>
        </div>
        <div class="col-md-5">
          <label class="field-name">{{ $t('label.newY') }}</label>
          <div class="has-feedback">
            <input :id="'y_' + id" v-model="currentValue.y" type="text" class="form-control" @click="setFocus('y')" />
            <span class="form-control-feedback glyphicon glyphicon-remove" @click="emptyValue('y')"></span>
          </div>
        </div>
        <div class="col-md-1">
          <button class="btn" @click="save()">{{ $t('label.save') }}</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10">
          <div class="has-feedback">
            <label v-if="!valid" class="error">{{ errorMessage }}</label>
          </div>
        </div>
      </div>
      <div class="num-box">
        <div class="row">
          <div class="item" @click="boxClicked('1')">1</div>
          <div class="item" @click="boxClicked('2')">2</div>
          <div class="item" @click="boxClicked('3')">3</div>
          <div class="item" @click="boxClicked('Backspace')"><span class="glyphicons glyphicons-delete"></span></div>
        </div>
        <div class="row">
          <div class="item" @click="boxClicked('4')">4</div>
          <div class="item" @click="boxClicked('5')">5</div>
          <div class="item" @click="boxClicked('6')">6</div>
        </div>
        <div class="row">
          <div class="item" @click="boxClicked('7')">7</div>
          <div class="item" @click="boxClicked('8')">8</div>
          <div class="item" @click="boxClicked('9')">9</div>
        </div>
        <div class="row">
          <div v-if="options.allowDigits" class="item" @click="boxClicked('.')">.</div>
          <div v-else class="item disabled">.</div>
          <div class="item" @click="boxClicked('0')">0</div>
          <div v-if="options.allowMinus" class="item" @click="boxClicked('-')">-</div>
          <div v-else class="item disabled">-</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// openlayers like lowercase constructors, so we need to tell eslint it is ok
/* eslint-disable new-cap */

'use strict'
import olGeomPoint from 'ol/geom/Point'
import $ from 'jquery'
import editorUtils from '@/utils/editorUtils'
import projectionUtils from '@/utils/projectionUtil'
import geometryUtil from '@/utils/geometryUtil'

/**
 * Editor with which users can set the coordinates of an object (e.g. of a measurement point).
 */
export default {
  name: 'coordinatesEditor',
  props: ['field', 'functions', 'options'], // options.allowDigits, options.allowMinus

  data() {
    return {
      id: this._uid,
      currentValue: {
        x: null,
        y: null,
        z: null
      },
      selectedCoordinateSystem: 'EPSG:4326', // WSG84
      errorMessage: null,
      warningMessage: null,
      valid: true,
      warning: false,
      title: String,
      focusKey: String
    }
  },

  computed: {
    validatedValue: {
      get() {
        let val
        let wktString = editorUtils.getEditorValue(this.field)

        // parse wkt string to structured object if it is set.
        if (wktString != null) {
          val = geometryUtil.parseWKTString(wktString)
        } else {
          val = null
        }

        // reset current Value, because a new validated value is retrieved. this is for instance the case when
        // 1) a new working object has been set (e.g. switch of measurement point), 2) coordinate has been updated in map
        this.setCurrentValue({ x: null, y: null, z: null })
        return val
      },
      set(value) {
        if (!value.x && !value.y) {
          let result = editorUtils.setEditorValue(this.field, null)
          result.values.MpXCoord = null
          result.values.MpYCoord = null
          this.$store.commit('updateWorkingObject', result.values)
        } else {
          let x = parseFloat(value.x).toFixed(7)
          let y = parseFloat(value.y).toFixed(7)

          // convert to wkt string
          let wktString = geometryUtil.getWKTString({ x: x, y: y, z: this.currentValue.z })
          // get object with property that must be updated in working object corresponding to this field.
          let result = editorUtils.setEditorValue(this.field, wktString)

          if (result.hasChanges) {
            this.$store.commit('updateWorkingObject', result.values)
          }
        }
      }
    },
    validatedValueAsString: {
      get() {
        // initialize to empty strings
        var x = ''
        var y = ''
        // if validated Value is set, then parse and set x and y
        if (this.validatedValue && this.validatedValue.x && this.validatedValue.y) {
          let validation = this.$validatorFunctions.coordinatesInWGS84Bounds(this.validatedValue.x, this.validatedValue.y, this.selectedCoordinateSystem)
          if (validation.valid) {
            let point = this.createPoint(this.validatedValue.x, this.validatedValue.y, 'EPSG:4326', this.selectedCoordinateSystem)
            let fractionDigits = projectionUtils.getFractionDigitsOfProjection(this.selectedCoordinateSystem)
            x = parseFloat(point.flatCoordinates[0]).toFixed(fractionDigits)
            y = parseFloat(point.flatCoordinates[1]).toFixed(fractionDigits)
            this.activateWarning(false)
          } else {
            x = '???'
            y = '???'
            this.activateWarning(true, validation.message)
          }
        }
        return { x: x, y: y }
      }
    },
    selectedCoordinateSystemAsString: {
      get() {
        return projectionUtils.getNameFromEPSG(this.selectedCoordinateSystem)
      }
    }
  },
  created() {
    this.selectedCoordinateSystem = this.getCoordinateSystem()
  },
  mounted() {
    this.title = this.field.title
  },

  methods: {
    setCurrentValue(value) {
      this.currentValue = value
      this.valid = true
    },
    activateWarning(activated, message) {
      if (activated && message) {
        this.warningMessage = message
      }
      this.warning = activated
    },
    save: function () {
      // validate field and value using TerraIndexValidator
      if (!this.currentValue.x && !this.currentValue.y) {
        this.saveValue()
      } else {
        this.$validatorFunctions.coordinatesInProjectedBounds(this.currentValue.x, this.currentValue.y, this.selectedCoordinateSystem).then(this.saveValue, this.showErrorMessage)
      }
    },
    showErrorMessage(reason) {
      this.valid = false
      this.errorMessage = reason.message
    },
    saveValue() {
      this.valid = true
      if (!this.currentValue.x && !this.currentValue.y) {
        this.validatedValue = { x: null, y: null }
      } else {
        let transformedPoint = this.createPoint(this.currentValue.x, this.currentValue.y, this.selectedCoordinateSystem, 'EPSG:4326')
        this.validatedValue = { x: transformedPoint.flatCoordinates[0], y: transformedPoint.flatCoordinates[1] }
      }
    },
    /**
     * Create ol.geom.Point object in projection 'toProjection' for x 'x' and y 'y' in projection 'fromProjection'.
     */
    createPoint(x, y, fromProjection, toProjection) {
      let point = new olGeomPoint([parseFloat(x), parseFloat(y)])
      point = projectionUtils.transformPointToProjection(point, fromProjection, toProjection)
      return point
    },
    /**
     * Handle click on box with character 'inputChar'.
     */
    boxClicked(inputChar) {
      // key of currently selected input in this.currentValue
      let key = this.focusKey

      // if input char is a character, then add, else remove last character from currentValue
      if (inputChar !== 'Backspace') {
        this.currentValue[key] = this.currentValue[key] ? this.currentValue[key] + inputChar : inputChar
      } else {
        this.currentValue[key] = this.currentValue[key].slice(0, -1)
      }
    },
    /**
     * Set key of field on which is focused to 'key' (but do not focus itself).
     */
    setFocus(key) {
      this.focusKey = key
    },
    /**
     * Focus on the first field that is not read only.
     */
    focus() {
      this.focusKey = 'x'
      $('#x_' + this.id).focus()
    },
    /**
     * Set value of key 'key' in this.currentValue to null.
     * @param key Key in this.currentValue that must be emptied.
     */
    emptyValue(key) {
      // reset value in input
      $('#' + key + '_' + this.id).val('')
      // reset value in this.currentValue
      this.currentValue[key] = null
    },
    getGps() {
      let self = this
      navigator.geolocation.getCurrentPosition(function (position) {
        self.currentValue.x = position.coords.latitude
        self.currentValue.y = position.coords.longitude
      })
    },
    getCoordinateSystem() {
      let system = ''
      if (this.$store.state.projects.length > 0) {
        system = this.$store.state.projects[0].PrCoordinateSystem
      } else if (this.$store.state.currentProject) {
        system = this.$store.state.currentProject.PrCoordinateSystem
      }

      // Map the saved coordinate system (2-letter code) to the EPSG code.
      let systemObject = projectionUtils.coordinateSystems.find((s) => s.GcCode === system)
      if (systemObject) {
        return systemObject.epsg
      } else {
        console.error('Stored coordinate system not found/supported! [' + system + ']')
        return 'EPSG:4326' // Return WGS84 as default.
      }
    }
  }
}
</script>

<style lang="less" scoped >
.trajectory {
  .input-left input {
    width: 45%;
    border-radius: 0;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
    & + input {
      float: right;
    }
  }
  .row {
    max-width: 600px;
  }
}

.num-box {
  padding-right: 15px;
  padding-left: 13px;
  margin-top: 30px;
  .row {
    height: 74px;
  }
  .item {
    width: 70px;
    height: 70px;
    background: #67ac45;
    margin: 2px;
    color: #fff;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      cursor: pointer;
      transform: scale(0.9);
    }
    &.disabled {
      opacity: 0.7;
      cursor: not-allowed;
      display: flex !important;
    }
  }
}

.disabled-field {
  opacity: 0.8 !important;
  pointer-events: none;
}

.unselectable {
  cursor: default;
  border: 0;
  box-shadow: none;
}

.btn {
  border-radius: 0;
  background-color: #67ac45;
  color: #fff;
  margin-top: 32px;
}
.btn:hover,
.btn:focus {
  color: #fff;
  transition: 0.5s;
  transform: scale(0.9);
}
</style>
