<template>
  <div>
    <!-- Modal add Bottle-->
    <div :id="'modalAddBottle' + option.guid" class="modal fade"
         tabindex="-1"
         @keypress="checkSubmit">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button"
                    class="close"
                    data-dismiss="modal">&times;</button>
            <h4 :id="'modalAddBottleTitle' + option.guid"
                class="modal-title">{{$t("project.AddBottleTitle")}}</h4>
          </div>
          <div class="modal-body">
            <p>{{$t("project.WaterSample")}}</p>
            <select v-model='wsGuid'
                    class="form-control">
              <option v-for="(ws, key) in watersamples" :key="key"
                      :value="ws.WsGuid">{{ws.WsName}}</option>
            </select>
            <p v-if="!valid" class="error">{{errorMessage}}</p>
          </div>
          <div class="modal-footer">
            <button :id="'btnAddBottle' + option.guid"
                    type="button"
                    class="btn"
                    @click="add">{{$t("project.CreateButton")}}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Bottle-->
    <div :id="'modalDeleteBottle' + option.guid"
         class="modal fade"
         tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button"
                    class="close"
                    data-dismiss="modal">&times;</button>
            <h4 :id="'modalDeleteBottleTitle' + option.guid"
                class="modal-title">{{$t("project.DeleteBottleTitle")}}</h4>
          </div>
          <div class="modal-body">
            <p>{{$t("project.DeleteBottleHelp")}}</p>
          </div>
          <div class="modal-footer">
            <button :id="'btnDeleteBottle' + option.guid"
                    class="btn"
                    @click="deleteBottle">{{$t("project.DeleteButton")}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'boModal',
  props: ['functions', 'option'],

  data () {
    return {
      wsGuid: '',
      valid: false,
      watersamples: [],
      errorMessage: 'This field is required.'
    }
  },

  computed: {
    enableAddButton () {
      return this.valid && !this.$parent.loading
    }
  },

  watch: {
    'wsGuid': {
      handler: function () {
        this.validate()
      }
    }
  },

  methods: {
    checkSubmit (evt) {
      if (evt.key === 'Enter' && this.valid) {
        this.add()
      }
    },
    validate () {
      if (this.wsGuid) {
        this.valid = true
      } else {
        this.valid = false
        this.errorMessage = this.$t('message.invalidWS')
      }
    },
    add () {
      return this.functions.add(this.option.key, { wsGuid: this.wsGuid, type: 'WATER' })
    },
    resetDefault () {
      this.wsGuid = ''
      this.errorMessage = 'This field is required.'
    },
    deleteBottle () {
      return this.functions.delete(this.option.key, 'WATER')
    }
  }
}
</script>
<style>
  .water-sample-list {
    width: 250px
  }
</style>
