<template>
  <div :key="options.data.length" :class="['mpboxs', options.type, options.bg || '']">
    <div class="title-box" :class="{ inline: inlineButtons }">
      <h5>{{ $t(options.title) }} {{ itemCount }}</h5>
      <div class="title">
        <a
          v-if="contains(options.buttons, 'addButton')"
          :id="options.businesskey + '-add-button-boxes'"
          href="javascript:"
          class="flatbutton"
          :class="[buttonPositionClass(options.buttons, 'addButton')]"
          @click="open"
        >
          <span class="glyphicons glyphicons-plus"></span>
        </a>
        <a
          v-if="contains(options.buttons, 'sortButton')"
          :id="options.businesskey + '-sort-button-boxes'"
          href="javascript:"
          class="flatbutton"
          :class="[buttonPositionClass(options.buttons, 'sortButton'), { sortOpen: sortOpen }]"
          @click="openSortList(true)"
          @mouseleave="openSortList(false)"
        >
          <span class="glyphicons glyphicons-sorting"></span>
          <h5>{{ $t('Buttons["Sort"]') }}</h5>
          <ul>
            <li v-for="(sortableColumn, key) in sortableColumns" :key="key" :class="[{ 'active-sort': sortableColumn.column === currentSorting }]" @click="sortList(sortableColumn.column)">
              {{ sortableColumn.label }}
              <span
                v-if="sortableColumn.column === currentSorting"
                class="sortOrderIcon glyphicons"
                :class="[{ 'glyphicons-arrow-down': currentSortOrder === 1 }, { 'glyphicons-arrow-up': currentSortOrder === -1 }]"
              ></span>
            </li>
          </ul>
        </a>
        <a
          v-if="contains(options.buttons, 'analysisResultStatusButton')"
          :id="options.businesskey + '-ar-status-button-boxes'"
          href="javascript:"
          class="flatbutton"
          :class="[buttonPositionClass(options.buttons, 'analysisResultStatusButton'), { sortOpen: arStatusOpen }]"
          @click="openArStatus(true)"
          @mouseleave="openArStatus(false)"
        >
          <span class="glyphicons glyphicons-history"></span>
          <h5>{{ $t('Buttons["Lab results"]') }}</h5>
          <div class="analysisResult">
            <span
              >{{ $t('Buttons["The lab result importer was last run"]') }}<strong> {{ arRetrieveLastRun }} {{ $t('Buttons["min"]') }}</strong>
              {{ $t('Buttons["ago"]') }}
              {{ $t('Buttons["and is currently"]') }}
              <strong> {{ $t(arStatus) }} </strong></span
            >
            <span v-if="arStatus === 'Idle'" class="button" v-on:click.stop="forceArImport()"> <i class="glyphicon glyphicon-tasks"></i> {{ $t('boxes.button.labResults') }} </span>
            <span disabled class="button" :title="labDeliveryTitleMessage" :class="{ 'disabled-button': labDeliveryButtonDisabled }" v-on:click.stop="forceDeliveryImport()">
              <i class="glyphicon glyphicon-briefcase button"></i> {{ $t('boxes.button.labDelivery') }}
            </span>
          </div>
        </a>
        <a
          v-if="contains(options.buttons, 'mapButton')"
          :id="options.businesskey + '-map-button-boxes'"
          href="javascript:"
          class="flatbutton"
          :class="[buttonPositionClass(options.buttons, 'mapButton')]"
          @click="openMap"
        >
          <span class="glyphicons glyphicons-map"></span>
        </a>
        <a
          v-if="contains(options.buttons, 'listButton')"
          :id="options.businesskey + '-list-button-boxes'"
          href="javascript:"
          class="flatbutton"
          :class="[buttonPositionClass(options.buttons, 'listButton')]"
          @click="gotolist"
        >
          <span class="glyphicons glyphicons-list-alt"></span>
        </a>
        <a
          v-if="contains(options.buttons, 'linkButton')"
          :id="options.businesskey + '-link-button-boxes'"
          :href="(options || {}).externalLink"
          target="new"
          class="flatbutton"
          :disabled="!(options || {}).externalLink"
          :class="[{ disabled: !(options || {}).externalLink }, buttonPositionClass(options.buttons, 'linkButton')]"
        >
          <span class="glyphicons glyphicons-link"></span>
        </a>
      </div>
    </div>
    <slot>
      <div :key="options.data.length" :class="'contentbox ' + (options.border || '')" data-simplebar>
        <div
          v-if="
            options.type === 'mpBox' ||
            options.type === 'userBox' ||
            options.type === 'labAssignmentBox' ||
            options.type === 'finishingsBox' ||
            options.type === 'concernedContactBox' ||
            options.type === 'subLocationBox' ||
            options.type === 'mapLayerBox' ||
            options.type === 'projectDocumentBox' ||
            options.type === 'klicFileBox' ||
            options.type === 'labCertificateBox'
          "
        >
          <component
            :is="options.type"
            v-for="item in sortedDataOptions"
            :key="item[boxTypeIdentifiers[options.type]]"
            :options="options"
            :template="item"
            :functions="functions"
            keep-alive
          ></component>
        </div>
        <div v-else-if="options.type === 'pictureBoxes'">
          <component :is="options.type" :options="options" keep-alive></component>
        </div>
        <div v-else-if="options.type === 'mapImagesBox'">
          <component :is="options.type" :options="options" keep-alive></component>
        </div>
        <div v-else>
          <component
            :is="item.type"
            v-for="item in dataOptionsWithType"
            :key="item[boxTypeIdentifiers[options.type]]"
            :options="options"
            :template="item"
            :functions="functions"
            keep-alive
          ></component>
          <empty-box v-if="!options.data || options.data.length === 0"></empty-box>
        </div>
      </div>
    </slot>
    <component
      :is="options.modal"
      v-if="options.modal"
      :option="{
        projectId: options.projectId,
        suggestedName: suggestedNames[options.type],
        uploadType: options.uploadType
      }"
      :projectGuid="projectGuid"
      :functions="{
        delete: deleteObject,
        add: add,
        validate: validate,
        checkSubmit: checkSubmit
      }"
      @uploaded="onMediaUploaded"
    >
    </component>
  </div>
</template>
<script>
import Vue from 'vue'
import config from '@/configurations/config.js'
import business from '@/business'
import fieldCodeUtils from '@/utils/fieldCodeUtils'
import sortUtils from '@/utils/sortUtils'

import concernedContactBox from '@/components/box-controls/concernedContactBox.vue'
import layerBox from '@/components/box-controls/layerBox.vue'
import soilSampleBox from '@/components/box-controls/soilSampleBox.vue'
import gaugingTubesBox from '@/components/box-controls/gaugingTubesBox.vue'
import finishingsBox from '@/components/box-controls/finishingsBox.vue'
import waterSampleBox from '@/components/box-controls/waterSampleBox.vue'
import bottlesBox from '@/components/box-controls/bottleBox.vue'
import userBox from '@/components/box-controls/userBox.vue'
import labAssignmentBox from '@/components/box-controls/labAssignmentBox.vue'
import pictureBox from '@/components/box-controls/pictureBox.vue'
import pictureBoxes from '@/components/box-controls/pictureBoxes.vue'
import mpBox from '@/components/box-controls/mpBox.vue'
import emptyBox from '@/components/box-controls/emptyBox.vue'
import subLocationBox from '@/components/box-controls/subLocationBox.vue'
import mapLayerBox from '@/components/box-controls/mapLayerBox.vue'
import projectDocumentBox from '@/components/box-controls/projectDocumentBox.vue'
import klicFileBox from '@/components/box-controls/klicFileBox.vue'
import labCertificateBox from '@/components/box-controls/labCertificateBox.vue'
import mapImagesBox from '@/components//box-controls/mapImagesBox.vue'

import laModal from '@/components/modal/laModal.vue'
import saModal from '@/components/modal/saModal.vue'
import mpModal from '@/components/modal/mpModal.vue'
import gaModal from '@/components/modal/gaModal.vue'
import fiModal from '@/components/modal/fiModal.vue'
import waModal from '@/components/modal/waModal.vue'
import paModal from '@/components/modal/paModal.vue'
import sncModal from '@/components/modal/sncModal.vue'
import boModal from '@/components/modal/boModal.vue'
import userModal from '@/components/modal/userModal.vue'
import concernedContactModal from '@/components/modal/concernedContactModal.vue'
import { LabImportService } from '@/services/DeliveryService/labImportService'

import { boxTypeIdentifiers } from '@/vuex/constants'

import actions from '@/vuex/actions'
import getters from '@/vuex/getters'

import moment from 'moment'
import { TemplateService } from '@/services/DataWS/templateService'
const labImportService = new LabImportService()

const templateService = new TemplateService()
const slModal = () => import(/* webpackChunkName: "slModal" */ '@/components/modal/slModal.vue')
const uploadModal = () => import(/* webpackChunkName: "uploadModal" */ '@/components/modal/uploadModal.vue')

var self

const labDeliveryStartedKey = 'timeLabDeliveryStarted'

export default {
  name: 'boxes',
  components: {
    concernedContactBox,
    layerBox,
    soilSampleBox,
    gaugingTubesBox,
    finishingsBox,
    waterSampleBox,
    bottlesBox,
    mpBox,
    userBox,
    emptyBox,
    labAssignmentBox,
    pictureBox,
    pictureBoxes,
    mapLayerBox,
    laModal,
    saModal,
    mpModal,
    gaModal,
    fiModal,
    waModal,
    boModal,
    paModal,
    userModal,
    concernedContactModal,
    subLocationBox,
    slModal,
    uploadModal,
    sncModal,
    projectDocumentBox,
    klicFileBox,
    mapImagesBox,
    labCertificateBox
  },
  props: {
    options: {
      type: Object,
      required: true
    },
    functions: {
      type: Object,
      required: false,
      default() {
        return undefined
      }
    },
    inlineButtons: {
      type: Boolean,
      required: false
    },
    projectGuid: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    showItemCount: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      From: '',
      To: '',
      Name: '',
      loading: false,
      root: config.root,
      business: {},
      lang: Vue.config.lang,
      relationLang: {
        nl: 'nld',
        en: 'eng',
        fr: 'fra',
        es: 'spa',
        de: 'deu',
        it: 'ita'
      },
      currentSorting: '',
      currentSortOrder: -1,
      sortOpen: false,
      sortableColumns: [],
      arStatusOpen: false,
      arStatus: 'checking...',
      arRetrieveLastRun: 'checking...',
      suggestedNames: {},
      boxTypeIdentifiers: boxTypeIdentifiers,
      /**
       * This flag is used in the mpBox component to bypass the scrolling when the context
       * menu is active.
       * @See mpBox:updated()
       */
      canScroll: true,
      labDeliveryButtonDisabled: false,
      isApplicationManager: false,
      labDeliveryTitleMessage: ''
    }
  },
  created() {
    this.sortList(localStorage.getItem(this.options.type + 'Sorting'))
  },
  mounted() {
    // get template field
    this.handleFields()
    let businesskey = this.options.businesskey
    this.business = business[businesskey]
    switch (this.options.type) {
      case 'mpBox':
        // Set which columns are sortable
        this.sortableColumns = [
          {
            column: 'MpName',
            label: this.$t('SortableColumns.Name')
          },
          {
            column: 'MpNameNumeric',
            label: this.$t('SortableColumns.NumericName')
          },
          {
            column: 'MpDate',
            label: this.$t('SortableColumns.Date')
          }
        ]
        break
    }
  },
  computed: {
    sortedDataOptions() {
      let sortedData = this.options.data.filter((data) => {
        return data
      })

      // this.currentSorting is the key to sort on. multiply with this.currentSortOrder (takes value 1 or -1) to switch order.
      sortedData.sort((mpA, mpB) => sortUtils.naturalSort(mpA[this.currentSorting], mpB[this.currentSorting]) * this.currentSortOrder)

      return sortedData
    },
    dataOptionsWithType() {
      return this.options.data.filter((data) => {
        return data.type
      })
    },
    itemCount() {
      return this.showItemCount ? `(${this.options.data.length})` : ''
    }
  },
  vuex: {
    actions: {
      setTemplateField: actions.setTemplateField
    },
    getters: {
      getTemplateField: getters.getTemplateField
    }
  },
  watch: {
    'options.data': {
      handler: function () {
        self = this
        if (self.options.businesskey === 'measurementPoint') {
          self.options.data.forEach(function (measurementPoint) {
            measurementPoint.MpNameNumeric = parseInt(measurementPoint.MpNameNumeric)
          })
        }
      }
    }
  },
  methods: {
    disableGetDeliveryFilesButton() {
      this.labDeliveryButtonDisabled = false
      this.isApplicationManager = false
      // First check if the action has been called within 10 minutes
      const deliveryStorageItem = window.localStorage.getItem(labDeliveryStartedKey)
      const TENMINUTES = 10 * 60 * 1000
      if (deliveryStorageItem && deliveryStorageItem !== '') {
        try {
          const lastRunDateTime = new Date(deliveryStorageItem)
          const currentDateTime = new Date()
          if (currentDateTime - lastRunDateTime <= TENMINUTES) {
            this.labDeliveryButtonDisabled = true
          }
        } catch (e) {
          console.error('Storage item could not be parsed ' + e)
        }
      }

      // Check if the user is an license manager
      const rightsStorageItem = window.localStorage.getItem('UserRights')
      if (rightsStorageItem && rightsStorageItem !== '') {
        const userRightsArray = rightsStorageItem.split(',')
        if (!userRightsArray.includes('IS_ADMIN')) {
          this.labDeliveryButtonDisabled = true
        } else {
          this.isApplicationManager = true
        }
      }
      this.labDeliveryTitleText()
    },
    labDeliveryTitleText() {
      this.labDeliveryTitleMessage = this.$t('boxes.labDelivery.notBusy')
      if (this.labDeliveryButtonDisabled) {
        this.labDeliveryTitleMessage = this.$t('boxes.labDelivery.busy')
      }
      if (!this.isApplicationManager) {
        this.labDeliveryTitleMessage = this.$t('boxes.labDelivery.notAllowed')
      }
    },
    handleFields() {
      if (!this.options.isfieldRequest) {
        return
      }
      let fields = this.$store.getters.getTemplateField(this.options.fieldname)
      if (fields) {
        return
      }
      // lock
      // this.$store.commit('setTemplateField', this.options.fieldname, 'tempData')
      let projectID = this.$route.params.projectId
      templateService['get' + this.options.fieldname](projectID)
        .then((res) => {
          if (typeof res === 'undefined') {
            console.error('get' + this.options.fieldname + ' returned undefined.')
          }
          let list = []
          let fields = []
          if (res.controls) {
            for (let i = 0; i < res.controls.length; i++) {
              let control = res.controls[i]
              if (!this.options.isMultiple) {
                // if isMultiple is true it will put multiple fields in the lists variable
                let field = control.fields.find((v) => {
                  return v.key === this.options.fieldname
                })
                if (typeof field !== 'undefined') {
                  fields = control.fields
                  list = field.options.map((v) => {
                    return fieldCodeUtils.transformItem(this, v)
                  })
                  break
                }
              } else {
                // if ismultiple is false list will hold a list of codes instead of fields
                let field = control.fields.find((v) => {
                  return v.key === this.options.fieldname
                })
                if (field) {
                  fields = control.fields
                  list = fields.map((v) => {
                    return {
                      key: v.key,
                      options: v.options.map((v) => {
                        return fieldCodeUtils.transformItem(this, v)
                      })
                    }
                  })
                  break
                }
              }
            }
          }

          // promise chain
          new Promise((resolve) => {
            resolve()
          })
            // set template field
            .then(() => {
              return this.$store.dispatch('setTemplateField', {
                key: this.options.fieldname,
                value: fields
              })
            })
            // set template field list
            .then(() => {
              return this.$store.dispatch('setTemplateField', {
                key: this.options.fieldname + 'List',
                value: list
              })
            })
        })
        .catch((err) => {
          console.error(err)
        })
    },
    buttonPositionClass(arr, value) {
      if (this.contains(arr, value)) {
        return 'pos-' + (arr.indexOf(value) + 1)
      } else {
        return 'disabled'
      }
    },

    contains(arr, value) {
      if (arr == null) return false
      return arr.includes(value)
    },
    gotolist() {
      return this.business.gotolist(this)
    },
    open() {
      return this.business.open(this)
    },
    openMap() {
      return this.business.openMap(this)
    },
    deleteObject() {
      return this.business.delete(this)
    },
    add(data) {
      return this.business.add(this, data)
    },
    validate(data) {
      return this.business.validate && this.business.validate(this, data)
    },
    checkSubmit(evt, data) {
      return this.business.checkSubmit(evt, this, data)
    },

    sortList(column) {
      if (this.options.disableSort) {
        return
      }
      if (column == null) {
        if (typeof this.sortableColumns[0] !== 'undefined') {
          column = this.sortableColumns[0].column
        } else {
          column = 'undefined'
        }
        this.currentSortOrder = 1
        localStorage.setItem(this.options.type + 'SortOrder', 1)
      }
      if (this.currentSorting === column) {
        if (this.currentSortOrder === 1) {
          this.currentSortOrder = -1
          localStorage.setItem(this.options.type + 'SortOrder', -1)
        } else {
          this.currentSortOrder = 1
          localStorage.setItem(this.options.type + 'SortOrder', 1)
        }
      } else {
        switch (this.options.type) {
          case 'labAssignmentBox':
            this.currentSorting = 'LbID'
            this.currentSortOrder = -1
            break
          default:
            this.currentSorting = column
            this.currentSortOrder = 1
            break
        }
        localStorage.setItem(this.options.type + 'SortOrder', this.currentSortOrder)
        localStorage.setItem(this.options.type + 'Sorting', this.currentSorting)
      }
    },
    openSortList(open) {
      this.sortOpen = open
    },
    openArStatus(open) {
      if (open) this.retrieveLabImportStatus()
      this.disableGetDeliveryFilesButton()
      this.arStatusOpen = open
    },
    // Get the download and import status of analysis results to display in the button.
    retrieveLabImportStatus() {
      labImportService.getLabImportStatus().then(
        (res) => {
          // If either the Import or Download are running the status is running.
          if (res.ArImportStatus === 'Running' || res.ArRetrieveStatus === 'Running') {
            this.arStatus = 'Running'
          } else {
            this.arStatus = 'Idle'
          }
          this.arRetrieveLastRun = moment.utc().diff(moment.utc(res.ArRetrieveLastRun), 'minutes')
        },
        (err) => {
          console.error(err)
        }
      )
    },
    forceArImport() {
      labImportService.importLabResults()
      // close box
      this.openArStatus(false)
    },

    forceDeliveryImport() {
      localStorage.setItem(labDeliveryStartedKey, new Date().toISOString())
      this.labDeliveryButtonDisabled = true
      labImportService.importLabDeliveries()
    },

    onMediaUploaded(event) {
      this.$emit('uploaded', event)
    }
  }
}
</script>
<style lang="less">
.disabled-button {
  opacity: 0.5;
}
@media (min-width: 640px) {
  .mpboxs .contentbox {
    height: calc(100vh - 210px);
    overflow-y: auto;
  }
}
.disabled {
  pointer-events: none;
}

.title-box.inline {
  display: flex;
  justify-content: space-between;

  & > h5 {
    width: 180px;
    margin-right: 10px;
  }

  & > .title {
    width: 180px;
  }
}

.pos-1 {
  left: 0px;
  z-index: 4;
}
.pos-2 {
  left: 45px;
  z-index: 3;
}
.pos-3 {
  left: 90px;
  z-index: 2;
}
.pos-4 {
  left: 135px;
  z-index: 1;
}

.mpboxs {
  width: 200px;
  float: left;
  padding: 10px 10px 0;
  h5 {
    margin: 0 0 20px 0;
  }
  p {
    margin: 0;
  }
  .title {
    position: relative;
    height: 50px;
    .flatbutton {
      cursor: pointer;
      width: 40px;
      height: 40px;
      display: block;
      position: absolute;
      overflow: hidden;
      transition: 0.5s;
      text-decoration: none;
      &:hover {
        transform: scale(0.9);
      }
      &:last-of-type {
        margin-right: 0px;
      }
      span {
        display: inline-block;
        width: 35px;
        height: 35px;
        color: #fff;
        font-size: 21px;
        margin: 3px 1px;
      }
      h5 {
        display: inline-block;
        opacity: 0;
        color: #fff;
        font-size: 14px;
        padding: 5px;
        margin-bottom: 0px;
        padding-bottom: 0px;
        line-height: 30px;
      }
      ul {
        list-style: none;
        color: #333;
        margin: 0px;
        padding: 0px;
        background-color: #fff;
      }
      li {
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 13px;
      }
      li:last-of-type {
        margin-bottom: 5px;
      }
      li:nth-child(2n) {
        background: #eee;
      }
      li:hover {
        background: rgba(168, 212, 163, 0.5);
        color: #333;
      }
      .active-sort {
        background: #67ac45 !important;
        color: #fff !important;
      }
    }
    .empty-button-left {
      margin-left: 45px;
    }
  }
  .contentbox a {
    display: block;
    margin-bottom: 3px;
    transition: 0.5s;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover,
    &:focus {
      text-decoration: none;
    }
    &.contents {
      border: solid 1px transparent;
      & > .arrow {
        display: none;
        width: 40px;
        height: 40px;
        position: absolute;
        background: #fff;
        z-index: 2;
        right: -20px;
        top: -20px;
        transform: rotate(45deg);
      }
      &.active {
        background: #fff;
        border: solid 1px #ddd;
        position: relative;
        overflow: hidden;
        & > .arrow {
          display: block;
        }
        &:before {
          content: '';
          position: absolute;
          z-index: 3;
          top: 4px;
          right: 0;
          width: 13px;
          height: 8px;
          background: #515151;
          transform: rotate(-45deg);
        }
        &:after {
          content: '';
          position: absolute;
          z-index: 3;
          top: -3px;
          right: -1px;
          display: block;
          width: 15px;
          height: 10px;
          background: #fff;
          transform: rotate(-45deg);
        }
      }
    }
  }
  .item {
    position: relative;
    height: 71px;
    color: black;
    display: flex;
    justify-content: center;
    flex-direction: column;
    transition: 0.5s;
    background-position: center center;
    background-size: cover;
    padding-left: 16px;
    &:hover,
    &:focus {
      transform: scale(0.95);
    }
    &.empty {
      background-color: transparent !important;
    }
    .text {
      z-index: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      overflow: hidden;
      white-space: normal;
      word-wrap: break-word;
    }
    .bg {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      & > div {
        float: right;
        height: 100%;
      }
    }
  }
  &.gray {
    background: #515151;
    h5 {
      color: #fff;
    }
    .contentbox a.contents.active {
      border: solid 1px #fff;
    }
    ::-webkit-scrollbar-thumb {
      border: 1px solid #515151;
      background-color: #fff;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    &.gaugingTubesBox {
      .contentbox {
        background: transparent;
        border: none;
      }
    }
  }
  &.layerBox {
    .title .flatbutton {
      background: #793f11;
    }
    .item {
      background: linear-gradient(to right, #793f11 0 10px, #f3f4f6 0);
      border: 1px solid #d2d6db;
      &.empty {
        background: none;
        border: 0px;
      }
      .text {
        width: 100px;
        height: 25px;
        background: #000;
        margin: 0 auto;
      }
      .med0 {
        display: none;
      }
      .med1 {
        width: 6.66%;
      }
      .med2 {
        width: 13.33%;
      }
      .med3 {
        width: 20%;
      }
      .med4 {
        width: 26.66%;
      }
    }
  }
  &.gaugingTubesBox {
    .title .flatbutton {
      background-color: #07828a;
    }

    .item {
      background: linear-gradient(to right, #07828a 0 10px, #f3f4f6 0);
      border: 1px solid #d2d6db;
      &.empty {
        background: transparent;
        border: 1px solid transparent;
      }
    }

    .contentbox.border {
      background: #dedede;
      border: 2px #999 dashed;
    }
  }
  &.soilSampleBox {
    .title .flatbutton {
      background: #c7b39a;
    }

    .item {
      &.disturbed {
        background: linear-gradient(to right, #876e50 0 10px, #f3f4f6 0);
      }
      background: linear-gradient(to right, #c7b39a 0 10px, #f3f4f6 0);
      border: 1px solid #d2d6db;
      &.empty {
        background: transparent;
        border: 1px solid transparent;
      }
    }
  }
  &.mpBox {
    .title .flatbutton,
    .item {
      background-color: #67ac45;
    }
  }
  &.subLocationBox {
    .title .flatbutton {
      background-color: #e56a17;
    }

    .item {
      background: linear-gradient(to right, #e56a17 0 10px, #f3f4f6 0);
      border: 1px solid #d2d6db;
    }
  }

  &.mapLayerBox {
    .title .flatbutton {
      background-color: #737982;
    }

    .item {
      background: linear-gradient(to right, #737982 0 10px, #f3f4f6 0);
      border: 1px solid #d2d6db;
    }
  }
  &.userBox,
  &.concernedContactBox {
    .title .flatbutton {
      background-color: #3653bf;
    }

    .item {
      background: linear-gradient(to right, #3653bf 0 10px, #f3f4f6 0);
      border: 1px solid #d2d6db;
    }
  }
  &.finishingsBox {
    .title .flatbutton {
      background-color: #808080;
    }

    .item {
      background: linear-gradient(to right, #808080 0 10px, #f3f4f6 0);
      border: 1px solid #d2d6db;
    }
  }

  &.labAssignmentBox {
    .title .flatbutton {
      background: #793f10;
    }

    .item {
      background: linear-gradient(to right, #793f10 0 10px, #f3f4f6 0);
      border: 1px solid #d2d6db;
    }
  }

  &.waterSampleBox {
    .title .flatbutton {
      background-color: #5e88d2;
    }

    .item {
      background: linear-gradient(to right, #5e88d2 0 10px, #f3f4f6 0);
      border: 1px solid #d2d6db;
    }
  }

  &.bottlesBox {
    .title .flatbutton {
      background: #83b5de;
    }
    .contentbox a {
      margin-bottom: 6px;
    }
    .item {
      background: #83b5de;
      height: 31px;
    }
  }
  &.pictureBoxes,
  &.pictureBox,
  &.mapImagesBox {
    .title .flatbutton {
      background-color: #bf3636;
    }

    .item {
      background: linear-gradient(to right, #bf3636 0 10px, #f3f4f6 0);
      border: 1px solid #d2d6db;
    }
  }
  &.klicFileBox,
  &.labCertificateBox,
  &.projectDocumentBox {
    .title .flatbutton {
      background-color: #36bfbf;
    }

    .item {
      background: linear-gradient(to right, #36bfbf 0 10px, #f3f4f6 0);
      border: 1px solid #d2d6db;
    }
  }

  &.concernedContactBox .simplebar-content p {
    line-height: 16px;
  }
  .sortOpen {
    width: auto !important;
    height: auto !important;
    min-width: 220px;
    z-index: 50;
    transition: 0.5s;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    transform: scale(1.1) !important;
    &:hover {
      transform: scale(1.1) !important;
    }
    h5 {
      opacity: 1 !important;
      transition: 0.5s;
    }
  }

  .sortOrderIcon {
    font-size: 13px !important;
    width: auto !important;
    height: auto !important;
    margin: -4px !important;
  }

  .analysisResult {
    display: none;
  }

  .sortOpen {
    .analysisResult {
      display: block;
      background-color: #fff;
      border-bottom: 5px solid #793f11;

      strong {
        font-size: 13px;
        color: #333;
      }
      span {
        color: #333;
        font-size: 13px;
        display: block;
        height: auto;
        width: 100%;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        margin: 0px;
        cursor: default;
      }

      .button {
        &:hover {
          background-color: rgba(121, 63, 17, 0.5);
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}

.texA {
  background: url('../assets/textures/peat.gif');
}

.texG {
  background: url('../assets/textures/gravel.gif');
}

.texH,
.texV {
  background: url('../assets/textures/wood.gif');
}

.texK {
  background: url('../assets/textures/clay.gif');
}

.texL {
  background: url('../assets/textures/l.gif');
}

.texS {
  background: url('../assets/textures/silt.gif');
}

.texJ {
  background: url('../assets/textures/silt.gif');
}
.texW {
  background: url('../assets/textures/water.gif');
}

.texZ {
  background: url('../assets/textures/sand.gif');
}
</style>
