<template>
  <div>
    <!-- Modal add soil sample-->
    <div id="modalAddGaugingTube" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 id="modalAddGaugingTubeTitle" class="modal-title">{{$t("project.AddGaugingTubeTitle")}}</h4>
          </div>
          <div class="modal-body">
            <p>{{$t("project.GaugingTubeName")}}</p>
            <input id="txtGaugingTubeName" v-model="name" type="text"
                   class="form-control" required>
            <p v-if="!validName" class="error">{{validationErrorName}}</p>
            <p>{{$t("project.GaugingTubeFrom")}}</p>
            <input id="txtGaugingTubeFrom" v-model="trajectoryValue.first" type="number" class="form-control" required>
            <p>{{$t("project.GaugingTubeTo")}}</p>
            <input id="txtGaugingTubeTo" v-model="trajectoryValue.second" type="number" class="form-control" required
                   @keypress="checkSubmit">
            <p v-if="!validTrajectory" class="error">{{validationErrorTrajectory}}</p>
          </div>
          <div class="modal-footer">
            <button id="btnAddGaugingTube" type="button" class="btn" v-bind:class="{ disabled: !enableAddButton }"
                    @click="add">{{$t("project.CreateButton")}}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal soil sample-->
    <div id="modalDeleteGaugingTube" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 id="modalDeleteGaugingTubeTitle" class="modal-title">{{$t("project.DeleteGaugingTubeTitle")}}</h4>
          </div>
          <div class="modal-body">
            <p>{{$t("project.DeleteGaugingTubeHelp")}}</p>
          </div>
          <div class="modal-footer">
            <button id="btnDeleteGaugingTube" class="btn" @click="deleteGaugingTube">{{$t("project.DeleteButton")}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'gaModal',
  props: ['functions', 'option'],

  data () {
    return {
      name: '',
      from: '',
      to: '',
      trajectoryValue: { first: '', second: '' },
      validationErrorName: '',
      validationErrorTrajectory: 'Required!',
      validName: false,
      validTrajectory: false
    }
  },

  computed: {
    enableAddButton () {
      return this.validName && this.validTrajectory && !this.$parent.loading
    }
  },

  watch: {
    'option.suggestedName': {
      handler: function () {
        this.name = this.option.suggestedName
      }
    },
    'name': {
      handler: function () {
        this.validate()
      }
    },
    'trajectoryValue': {
      handler: function () {
        this.validateTrajectory()
      },
      deep: true
    }
  },

  methods: {
    checkSubmit (evt) {
      if (evt.key === 'Enter' && this.valid) {
        this.add()
      }
    },
    validate () {
      let FtNameField = {
        key: 'FtName',
        maxLength: 50,
        requiredBySystem: true,
        requiredByUser: true
      }
      this.$validateEditor(FtNameField, this.name, {
        FtGuid: '' /* empty since it cant ever match with itself */
      })
        .then(() => {
          this.validName = true
        }).catch(reason => {
          // extract reason and set valid to false
          if (typeof reason.message === 'undefined') {
            console.error(reason)
          }
          this.validName = false
          this.validationErrorName = reason.message
        })
    },
    validateTrajectory () {
      let JrTrajectoryField = {
        key: 'FtFrom',
        type: '19',
        requiredBySystem: true,
        rawOptions: {
          rule: 'lower'
        }
      }
      this.$validateEditor(JrTrajectoryField, this.trajectoryValue, {})
        .then(() => {
          this.validTrajectory = true
        }).catch(reason => {
          // extract reason and set valid to false
          if (typeof reason.message === 'undefined') {
            console.error(reason)
          }
          this.validTrajectory = false
          this.validationErrorTrajectory = reason.message
        })
    },
    add () {
      return this.functions.add({ name: this.name, from: this.trajectoryValue.first, to: this.trajectoryValue.second })
    },
    resetDefault () {
      this.trajectoryValue = { first: '', second: '' }
      this.validationErrorName = ''
      this.validationErrorTrajectory = 'Required!'
    },
    deleteGaugingTube () {
      return this.functions.delete()
    }
  }
}
</script>
