<template>
  <div :id="'editor' + id" >
    <div class="input-group input-left">
      <input type="text" class="form-control" :class="{errorBorder: !valid}" readonly :value="validatedValueAsString" @click="$parent.expandPanel">
    </div>
    <i v-if="field.required" class="required">*</i>

    <div class="editor-panel hidden animated">
      <div class="close" @click="$parent.hidePanel">
        <span class="glyphicon glyphicon-chevron-right"></span>
      </div>
      <div class="row">
        <div class="col-md-7">
          <label class="field-name">{{title}}</label>
          <div class="has-feedback">
            <toggle v-model="currentValue" />
            <label class="error">{{errorMessage}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
'use strict'

import editorUtils from '@/utils/editorUtils'
import _ from 'lodash'
import Toggle from '@/components/_shared/toggle'

/**
   * Editor with which users can toggle the boolean value of a property.
   */
export default {
  name: 'booleanEditor',
  components: { Toggle },
  props: ['field', 'options'],

  data () {
    return {
      id: this._uid,
      currentValue: String,
      errorMessage: null,
      title: String,
      valid: true
    }
  },

  computed: {
    validatedValue: {
      get () {
        let val = editorUtils.getEditorValue(this.field)
        // overwrite current value with 'valid' value from store and set valid to true. get() is called in the following cases:
        // - when the value of this.$store.state.workingObject[this.field.key] changed (e.g. because side effect or switching workingObject)
        // - when initializing the editor
        this.setCurrentValue(val)
        return val
      },
      set (value) {
        let result = editorUtils.setEditorValue(this.field, value)
        if (result.hasChanges) {
          this.$store.commit('updateWorkingObject', result.values)
        }
      }
    },
    validatedValueAsString: {
      get () {
        return this.validatedValue === 'true' ? this.$t('toggle.yes') : this.$t('toggle.no')
      }
    },
    activeEditorValidationPending: {
      get () {
        return this.$store.state.activeEditorValidationPending
      },
      set (val) {
        this.$store.commit('setActiveEditorValidationPending', val)
      }
    }
  },

  watch: {
    'currentValue': function (value) {
      // set activeEditorValidationPending to true, so that navigation is blocked until validation is done
      this.activeEditorValidationPending = true
      if (value === '') { this.currentValue = 'false' }
      this.validateCurrentValue(value)
    }
  },

  mounted () {
    this.title = this.field.title
  },

  methods: {
    setCurrentValue (value) {
      this.currentValue = value
      this.valid = true
    },
    validateCurrentValue: _.debounce(function (val) {
      // validate field and value using TerraIndexValidator
      this.$validateEditor(this.field, val).then(() => {
        // set valid to true and set val to validatedValue as validation has succeeded
        this.valid = true
        this.validatedValue = val
        this.errorMessage = null
      }).catch(reason => {
        // extract reason and set valid to false
        this.valid = false
        this.errorMessage = reason.message
      }).finally(() => {
        // validation has finished
        this.activeEditorValidationPending = false
      })
    }, 100)
  }
}
</script>
