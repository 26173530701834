import Vue from 'vue'
import config from '@/configurations/config-environment'
import { BaseService } from '../baseService'

const deliveryservice = config.apis.deliveryservice

export class LabImportService extends BaseService {
  getLabImportStatus() {
    const data = {
      Authorisation: this.getAuthorizationObject()
    }

    return Vue.http
      .post(`${deliveryservice}LabImportService_V1_0/GetLabImportStatus`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Success') throw new Error('Unable to get labimport status')

        return resp.data.labImportStatus
      })
  }

  importLabResults() {
    const data = {
      Authorisation: this.getAuthorizationObject()
    }

    return Vue.http
      .post(`${deliveryservice}LabImportService_V1_0/ImportLabResults`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Success') throw new Error('Unable to import analysis results')

        return resp.data.ResultMessage
      })
  }

  importLabDeliveries() {
    const data = {
      Authorisation: this.getAuthorizationObject()
    }

    return Vue.http
      .post(`${deliveryservice}LabImportService_V1_0/ImportLabDelivery`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Success') throw new Error('Unable to import lab deliveries')

        return resp.data.ResultMessage
      })
  }
}
