import arrayUtils from '@/utils/arrayUtils'
import $store from '@/vuex/store'
import moment from 'moment'
import $ from 'jquery'

import { BottleService } from '@/services/DataWS/bottleService'

const bottleService = new BottleService()
export default {
  modalName: 'boModal',
  gotolist (self) {
    // todo: change path for Finishings
    let path = `/project/${self.options.projectId}/measurementPoint`
    self.$router.push(path)
  },
  open (self, type) {
    let ftGuid = self.options.FtGuid
    let mpComplexWaterSamples = self.options.data
    let ftComplexWaterSample = mpComplexWaterSamples.find(v => v.guid === ftGuid)
    if (ftComplexWaterSample.waterSamples.length === 0) return
    let modalName = this.modalName
    if (type === 'AIR') {
      modalName = 'paModal'
    }
    let create = self.$children.find(v => v.$options.name === modalName) || {}
    create.resetDefault()
    create.watersamples = ftComplexWaterSample.waterSamples || []
    // create.$forceUpdate()
    let newestWS = this.getNewestWatersample(ftComplexWaterSample.waterSamples, 'WsID')
    create.wsGuid = newestWS.WsGuid || ''

    if (type === 'WATER') {
      $('#modalAddBottle' + ftComplexWaterSample.guid)
        .modal('toggle')
        .on('shown.bs.modal', function (e) {
          $('#optWaterSampleList' + ftComplexWaterSample.guid)
            .focus()
        })
    } else {
      $('#modalAddPackaging' + ftComplexWaterSample.guid)
        .modal('toggle')
        .on('shown.bs.modal', function (e) {
          $('#optWaterSampleList' + ftComplexWaterSample.guid)
            .focus()
        })
    }
  },
  delete (self, sampleType) {
    self.loading = true
    let bottle = $store.state.workingObject
    let waterSample = {}
    if (sampleType === 'WATER') {
      if (self.$route.params.FtGuid) {
        waterSample = $store.state.waterSamples.find(ws => ws.WsID === bottle.WsID)
      } else {
        waterSample = $store.state.surfaceWaterSamples.find(ws => ws.WsID === bottle.WsID)
      }
    } else {
      if (self.$route.params.FtGuid) {
        waterSample = $store.state.airSamples.find(ws => ws.WsID === bottle.WsID)
      } else {
        waterSample = $store.state.surfaceAirSamples.find(ws => ws.WsID === bottle.WsID)
      }
    }
    let type = ((typeof waterSample.FtID === 'undefined') || (waterSample.FtID === null)) ? 'SURFACEWATER' : 'WATER'
    let gaugingTube = {}
    let measurementPoint = null
    if (type === 'WATER') {
      gaugingTube = $store.state.gaugingTubes.find(gt => gt.FtGuid === self.$route.params.FtGuid)
      measurementPoint = $store.state.measurementPoints.find(mp => mp.MpGuid === self.$route.params.measurementPointId)
    } else if (type === 'SURFACEWATER') {
      measurementPoint = $store.state.measurementPoints.find(mp => mp.MpGuid === self.$route.params.measurementPointId)
    } else {
      console.error('Unknown [' + type + '] when trying to delete bottle.')
    }
    if (sampleType === 'WATER') {
      $('#modalDeleteBottle' + gaugingTube.FtGuid)
        .modal('hide')
    } else {
      $('#modalDeletePackaging' + gaugingTube.FtGuid)
        .modal('hide')
    }
    bottleService.deleteWaterSampleBottle(measurementPoint.PrID, measurementPoint.MpGuid, gaugingTube.FtGuid, waterSample.WsGuid, bottle.BoGuid).then(res => {
      self.loading = false
      $store.commit('clearWorkingObject')
      if (sampleType === 'WATER') {
        if (type === 'WATER') {
          if (waterSample.bottles.length > 1) {
            self.$router.push({
              name: 'bottle',
              params: {
                'projectId': self.$route.params.projectId,
                'measurementPointId': self.$route.params.measurementPointId,
                'FtGuid': self.$route.params.FtGuid,
                'WsID': self.$route.params.WsID,
                'BoID': '1'
              }
            })
            self.$router.go()
          } else {
            self.$router.push({
              name: 'watersample',
              params: {
                'projectId': self.$route.params.projectId,
                'measurementPointId': self.$route.params.measurementPointId,
                'FtGuid': self.$route.params.FtGuid,
                'WsID': self.$route.params.WsID
              }
            })
          }
        } else if (type === 'SURFACEWATER') {
          if (waterSample.bottles.length > 1) {
            self.$router.push({
              name: 'bottleSurface',
              params: {
                'projectId': self.$route.params.projectId,
                'measurementPointId': self.$route.params.measurementPointId,
                'WsID': self.$route.params.WsID,
                'BoID': '1'
              }
            })
            self.$router.go()
          } else {
            self.$router.push({
              name: 'watersampleSurface',
              params: {
                'projectId': self.$route.params.projectId,
                'measurementPointId': self.$route.params.measurementPointId,
                'WsID': self.$route.params.WsID
              }
            })
          }
        }
      } else {
        if (type === 'WATER') {
          if (waterSample.bottles.length > 1) {
            self.$router.push({
              name: 'bottleAir',
              params: {
                'projectId': self.$route.params.projectId,
                'measurementPointId': self.$route.params.measurementPointId,
                'FtGuid': self.$route.params.FtGuid,
                'WsID': self.$route.params.WsID,
                'BoID': '1'
              }
            })
            self.$router.go()
          } else {
            self.$router.push({
              name: 'airSample',
              params: {
                'projectId': self.$route.params.projectId,
                'measurementPointId': self.$route.params.measurementPointId,
                'FtGuid': self.$route.params.FtGuid,
                'WsID': self.$route.params.WsID
              }
            })
          }
        } else if (type === 'SURFACEWATER') {
          if (waterSample.bottles.length > 1) {
            self.$router.push({
              name: 'bottleSurfaceAir',
              params: {
                'projectId': self.$route.params.projectId,
                'measurementPointId': self.$route.params.measurementPointId,
                'WsID': self.$route.params.WsID,
                'BoID': '1'
              }
            })
            self.$router.go()
          } else {
            self.$router.push({
              name: 'airSampleSurface',
              params: {
                'projectId': self.$route.params.projectId,
                'measurementPointId': self.$route.params.measurementPointId,
                'WsID': self.$route.params.WsID
              }
            })
          }
        }
      }
    }, err => {
      $('#modalDeleteBottle' + gaugingTube.FtGuid).modal('toggle')
      self.loading = false
      console.error(err)
    })
  },
  add (self, data) {
    if (self.loading) return
    self.loading = true
    let ftGuid = self.options.FtGuid
    let mpComplexWaterSamples = self.options.data
    let ftComplexWaterSample = mpComplexWaterSamples.find(v => v.guid === ftGuid)

    let waterSample = ftComplexWaterSample.waterSamples.find(v => v.WsGuid === data.wsGuid)
    let bottles = waterSample.bottles || []

    bottles.forEach(v => (v.WsID = '1'))

    let now = moment().format('YYYY-MM-DDTHH:mm:ss')
    let boName = arrayUtils.getDefaultName(bottles, 'BoName', 'BoID')

    let newitem = {
      'BoID': '1',
      'BoName': boName || '1',
      'BoBarcode': '',
      'BoDateLastChanged': now
    }

    bottles.push(newitem)
    bottles = JSON.parse(JSON.stringify(bottles))
    bottles.forEach(v => (v.WsID = '1'))

    let prid = self.$route.params.projectId
    let mpguid = self.options.MpGuid || self.$route.params.measurementPointId
    bottleService.createWaterSampleBottles(prid, mpguid, ftGuid, waterSample.WsGuid, bottles)
      .then(res => {
        // TODO: revert wsID to original one, fucking annoying stuff (fix needed in DataWS)
        res.tblBottles.forEach(v => {
          v.WsID = waterSample.WsID
          v.BoBarcode = v.BoBarcode || ''
        })

        // remove outdated bottles of watersample and add new (res only contains the bottles of THIS watersample)
        let bottles = $store.state.bottles.filter(bo => bo.WsID !== waterSample.WsID)
        bottles = bottles.concat(res.tblBottles)

        $store.commit('setBottles', bottles)
        if (data.type === 'WATER') {
          $('#modalAddBottle' + ftComplexWaterSample.guid)
            .modal('hide')
        } else {
          $('#modalAddPackaging' + ftComplexWaterSample.guid)
            .modal('hide')
        }
        // update parent as well.
        waterSample.bottles = res.tblBottles
        self.loading = false
      })
      .then(() => {
        self.loading = false
        let newData = waterSample.bottles[waterSample.bottles.length - 1]
        if (newData) {
          let path = ''
          if (data.type === 'WATER') {
            if (ftGuid) {
              path = '/project/' + prid + '/measurementPoint/' + mpguid + '/gaugingTubes/' + ftGuid + '/watersample/' + waterSample.WsID + '/bottle/' + newData.BoID + '?new=true'
            } else {
              path = '/project/' + prid + '/measurementPoint/' + mpguid + '/watersample/' + waterSample.WsID + '/bottle/' + newData.BoID + '?new=true'
            }
          } else {
            if (ftGuid) {
              path = '/project/' + prid + '/measurementPoint/' + mpguid + '/gaugingTubes/' + ftGuid + '/airSample/' + waterSample.WsID + '/packaging/' + newData.BoID + '?new=true'
            } else {
              path = '/project/' + prid + '/measurementPoint/' + mpguid + '/airSample/' + waterSample.WsID + '/packaging/' + newData.BoID + '?new=true'
            }
          }
          self.$router.push(path)
        }
      })
      .catch(err => {
        console.error('Catch: ' + err)
        $('#modalAddBottle' + ftComplexWaterSample.guid).modal('hide')
        self.loading = false
      })
  },
  getNewestWatersample (list, sortby) {
    if (list && list.length > 0) {
      let last = list[0]
      for (let item of list) {
        let itemValue = (!item[sortby]) ? 0 : parseInt(item[sortby])
        let lastValue = (!last[sortby]) ? 0 : parseInt(last[sortby])

        if (itemValue > lastValue) {
          last = item
        }
      }
      return last
    }
    return {}
  }
}
