export default {
  gapTilesByLayers (layers, soilSamples, gaugingTubes) {
    this.getSortData(layers, 'LaFrom', 'LaTo')
    this.getSortData(soilSamples, 'JrFrom', 'JrTo')
    this.getSortData(gaugingTubes, 'FtFilterFrom', 'FtFilterTo')
    this.prepareForGaps(layers, 'LaFrom', 'LaTo')
    this.prepareForGaps(soilSamples, 'JrFrom', 'JrTo')
    this.prepareForGaps(gaugingTubes, 'FtFilterFrom', 'FtFilterTo')

    this.calculateGaps(layers, [soilSamples, gaugingTubes])
    let result = {}

    result.layers = this.formatBoxes(layers, 'layerBox')
    result.soilSamples = this.formatBoxes(soilSamples, 'soilSampleBox')
    result.gaugingTubes = this.formatBoxes(gaugingTubes, 'gaugingTubesBox')

    return result
  },

  calculateGaps (masterList, slaveLists = []) {
    let masterLength = masterList ? masterList.length : 0
    if (masterLength === 0 || slaveLists.length === 0) return

    while (true) {
      let masterItem = this.getWaitingMasterItem(masterList)
      let slaveItems = this.getWaitingSlaveItems(slaveLists)
      if (slaveItems.length === 0) break

      let isMasterLowest = true
      for (let slaveItem of slaveItems) {
        let gapRelation = this.calculateItemGap(masterItem, slaveItem) // -1 :master lower , 0 :master include , 1 : master higher
        if (isMasterLowest) {
          switch (gapRelation) {
            case -1:
              masterItem.gapDone = true
              slaveItem.preGaps++
              break
            case 0:
              masterItem.gapDone = true
              slaveItem.gapDone = true
              break
            case 1:
              masterItem.preGaps++
              slaveItem.gapDone = true

              isMasterLowest = false
              break
            default:
              slaveItem.gapDone = true
              break
          }
        } else {
          switch (gapRelation) {
            case -1:
            case 0:
              slaveItem.preGaps++
              break
            case 1:
              slaveItem.gapDone = true
              break
            default:
              slaveItem.gapDone = true
              break
          }
        }
      }
    }
  },

  calculateItemGap (masterItem, slaveItem) {
    if (!masterItem || !slaveItem) {
      return -2
    }

    let fromM = parseInt(masterItem.from)
    let toM = parseInt(masterItem.to)
    let fromS = parseInt(slaveItem.from)
    let toS = parseInt(slaveItem.to)

    if (fromM >= toS || fromM > fromS) return 1
    if (toM <= fromS) return -1

    return 0
  },

  formatBoxes (array, boxtype) {
    let boxes = []
    if (!array) return boxes

    for (let item of array) {
      while (item.preGaps > 0) {
        let emptyBox = {}
        emptyBox.type = 'emptyBox'
        boxes.push(emptyBox)

        item.preGaps--
      }

      item.type = boxtype
      boxes.push(item)
    }

    return boxes
  },

  getWaitingMasterItem (masterList) {
    if (!masterList) return null

    return masterList.find(v => v.gapDone === false)
  },

  getWaitingSlaveItems (slaveLists) {
    let slaveItems = []
    for (let list of slaveLists) {
      if (!list) continue

      let item = list.find(v => v.gapDone === false)
      if (!item) continue

      slaveItems.push(item)
    }

    if (slaveItems.length > 0) {
      this.getSortData(slaveItems, 'from', 'to')
    }

    return slaveItems
  },

  prepareForGaps (array, fromName, toName) {
    if (array && array.length > 0) {
      array.forEach(v => {
        v.gapDone = false
        v.preGaps = 0
        v.from = parseInt(v[fromName])
        v.to = parseInt(v[toName])
      })
    }
  },

  getSortDataKeys (data, keys = []) {
    if (!data) return

    data.sort(function (a, b) {
      let sort = 0
      for (let key of keys) {
        if (!key) continue

        let aValue = (!a[key]) ? 0 : parseInt(a[key])
        let bValue = (!b[key]) ? 0 : parseInt(b[key])

        sort = aValue - bValue
        if (sort === 0) {
          continue
        } else {
          break
        }
      }
      return sort
    })
  },

  // TODO an variable should be return. An Solution is to make an copy of the data, sort the copy and return the copy variabel
  getSortData (data, key1, key2) {
    let keys = []
    if (key1) keys.push(key1)
    if (key2) keys.push(key2)
    return this.getSortDataKeys(data, keys)
  }
}
