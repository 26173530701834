<template>
  <div>
    <!--Modal add measurementpoint-->
    <div id="modalAddMeasurementPoint" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button class="close" data-dismiss="modal">&times;</button>
            <h4 id="modalAddMeasurementPointTitle" class="modal-title">{{$t("project.AddMeasurementPointTitle")}}</h4>
          </div>
          <div class="modal-body">
            <p>{{$t("project.AddMeasurementPointHelp")}}</p>
            <input id="txtname" v-model="name" type="text" class="form-control" @keypress="checkSubmit">
            <p v-if="!valid" class="error">{{errorMessage}}</p>
          </div>
          <div class="modal-footer">
            <button id="btnAddMeasurementPoint" class="btn" v-bind:class="{ disabled: !enableAddButton }" @click="add">{{$t("project.CreateButton")}}</button>
          </div>
        </div>
      </div>
    </div>

    <!--Modal delete measurementpoint-->
    <div id="modalDeleteMeasurementPoint" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 id="modalDeleteMeasurementPointTitle" class="modal-title">{{$t("project.DeleteMeasurementPointTitle")}}</h4>
          </div>
          <div class="modal-body">
            <p>{{$t("project.DeleteMeasurementPointHelp")}}</p>
          </div>
          <div class="modal-footer">
            <button id="btnDeleteMeasurementPoint" class="btn" @click="deleteMeasurementPoint">{{$t("project.DeleteButton")}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: 'mpModal',
  props: ['functions', 'option'],

  data () {
    return {
      errorMessage: '',
      name: '',
      valid: false
    }
  },

  computed: {
    enableAddButton () {
      return this.valid && !this.$parent.loading
    }
  },

  watch: {
    'option.suggestedName': {
      handler: function () {
        this.name = this.option.suggestedName
      }
    },
    'name': {
      handler: function () {
        this.validate()
      }
    }
  },

  mounted () {
    this.validate()
  },

  methods: {
    checkSubmit (evt) {
      if (evt.key === 'Enter' && this.valid) {
        this.add()
      }
    },
    validate () {
      let MpNameField = {
        key: 'MpName',
        maxLength: 50,
        requiredBySystem: true,
        requiredByUser: true
      }
      this.$validateEditor(MpNameField, this.name, {
        MpGuid: '' /* empty since it cant ever match with itself */
      })
        .then(() => {
          this.valid = true
        }).catch(reason => {
          // extract reason and set valid to false
          if (typeof reason.message === 'undefined') { console.error(reason) }
          this.valid = false
          this.errorMessage = reason.message
        })
    },
    add () {
      return this.functions.add({ name: this.name })
    },
    resetDefault () {
      this.errorMessage = ''
      this.name = ''
    },
    deleteMeasurementPoint () {
      return this.functions.delete()
    }
  }
}
</script>
