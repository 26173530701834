<style>
  .listgroup-actived {
    border: 4px solid green;
  }
</style>

<template>
  <div :id="'editor' + id" @keyup="keyboardArrow">
    <div class="input-group input-left">
      <input type="text" class="form-control" style="text-transform: capitalize" :value="validatedValuesAsString" :title="validatedValuesAsString" readonly="readonly"
             @click="$parent.expandPanel">
    </div>

    <div class="editor-panel hidden animated" @keyup.enter="acceptCode">
      <div class="close" @click="$parent.hidePanel">
        <span class="glyphicon glyphicon-chevron-right"></span>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label class="field-name">{{title}}</label>
          <div class="has-feedback">
            <div class="row">
              <div class="col-md-6">
                <div class="discription-box discription-box-color" style="height: 34px">
                  {{validatedValuesAsString}}
                  <span class="form-control-feedback glyphicon glyphicon-remove" @click="clear"></span>
                </div>
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-2">
                  <input :id="'input' + id" v-model="currentValuesAsString" type="text" class="form-control" @keyup="search">
                  <span class="form-control-feedback glyphicon glyphicon-remove" @click="clear"></span>
              </div>
            </div>
            <div v-if="!valid" class="row" >
              <div class="col-md-12">
                <label class="error">{{errorMessage}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!--first list-->
        <div class="col-md-6">
          <div :class="['panel panel-default panel-list', activatedList.name === 'first'? cssListGroupActived:'']">
            <div class="panel-heading">{{twolistTitles.first}}</div>
            <div :id="'firstlist' + id" class="list-group">
              <a v-for="(item, key) in sourceFirstWithLocalizedText"
                 :key="item.value + '-' + key + '-' + 'one'"
                 href="javascript:" v-bind:class="[cssNormal, item.value === currentValues.first ? cssSelected : '', (activatedList.name === 'first' && item.value === activatedList.value) ? cssActived : '']" @click="setSelectedItem(item.value, 'first')" >{{getText(item)}}
                <span v-if='item.value === currentValues.first' style='float:right;'>selected</span>
              </a>
            </div>
          </div>
        </div>
        <!--second list-->
        <div class="col-md-6">
          <div :class="['panel panel-default panel-list', activatedList.name === 'second'? cssListGroupActived:'']">
            <div class="panel-heading">{{twolistTitles.second}}</div>
            <div :id="'secondlist' + id" class="list-group">
              <a v-for="(item, key) in sourceSecondWithLocalizedText"
                 :key="item.value + '-' + key + '-' + 'two'"
                 href="javascript:" v-bind:class="[cssNormal, item.value == currentValues.second ? cssSelected : '', (activatedList.name === 'second' && item.value === activatedList.value) ? cssActived : '']" @click="setSelectedItem(item.value, 'second')" >{{getText(item)}}
                <span v-if='item.value === currentValues.second' style='float:right;'>selected</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
'use strict'
import Vue from 'vue'
import _ from 'underscore'
import $ from 'jquery'
import stringUtils from '@/utils/stringUtils'
import codeListUtils from '@/utils/codeListUtils'
import sortUtils from '@/utils/sortUtils'
import editorUtils from '@/utils/editorUtils'

/**
   * Editor with which users can set the value of two properties based on the selected item in two lists (e.g. odour types and odour intensities).
   */
export default {
  name: 'twoListEditor',
  props: ['field', 'functions', 'options'],

  data () {
    return {
      id: this._uid,
      currentValues: {
        first: '',
        second: ''
      },
      validateMsg: '',
      title: String,
      lang: Vue.config.lang,
      cssNormal: 'list-group-item',
      cssSelected: 'selected',
      cssActived: 'actived',
      cssListGroup: 'listgroup',
      cssListGroupActived: 'listgroup-actived',
      source: {}, // This is the source that will be used to display the lists
      originalSource: { // This is the original source of the field options before all visible codes are filterd
        first: [],
        second: []
      },
      codeNameList: [],
      activatedList: {
        name: 'first',
        value: ''
      },

      value: String
    }
  },

  computed: {
    /**
       * Computed property used to get the validated value as it should be shown to the user of the property
       * corresponding to this instance of the TwoListEditor.
       */
    validatedValuesAsString () {
      let value = ''
      if (this.validatedValues && this.validatedValues.first) {
        let firstItem = this.originalSource.first.find(v => v.value === this.validatedValues.first)
        value += stringUtils.capitalizeFirstLetter(firstItem.text[0][stringUtils.convert2To3LetterLanguageCode(this.lang)]) + '; '
      }
      if (this.validatedValues && this.validatedValues.second) {
        let secondItem = this.originalSource.second.find(v => v.value === this.validatedValues.second)
        value += stringUtils.capitalizeFirstLetter(secondItem.text[0][stringUtils.convert2To3LetterLanguageCode(this.lang)])
      }
      return value
    },
    /**
       * Computed property used to get the title that is shown above each of the two lists.
       */
    twolistTitles () {
      let firstTitle = ''
      let secondTitle = ''

      if (this.currentValues && this.source.first) {
        let firstItem = this.source.first.find(v => v.value === this.currentValues.first)

        if (firstItem) {
          firstTitle = this.getText(firstItem)
        }

        let secondItem = this.source.second.find(v => v.value === this.currentValues.second)

        if (secondItem) {
          secondTitle = this.getText(secondItem)
        }
      }

      return { first: firstTitle, second: secondTitle }
    },

    sourceSecondWithLocalizedText () {
      let langCode = stringUtils.convert2To3LetterLanguageCode(this.lang)
      let sourcesWithLocalizedText = this.source.second.filter(function (item) {
        return item.localizedText
      })
      // add current selected option here if it is not in the visible code list
      if (
        (this.currentValues.second && sourcesWithLocalizedText.filter((option) => { return option.value === this.currentValues.second }).length <= 0)) {
        let currentOption = this.originalSource.second.find((option) => { return this.currentValues.second === option.value })
        sourcesWithLocalizedText.push(currentOption)
      }
      return sourcesWithLocalizedText.sort((a, b) => sortUtils.naturalSort(a.text[0][langCode], b.text[0][langCode]))
    },

    sourceFirstWithLocalizedText () {
      let langCode = stringUtils.convert2To3LetterLanguageCode(this.lang)
      let sourcesWithLocalizedText = this.source.first.filter(function (item) {
        return item.localizedText
      })
      // add current selected option here if it is not in the visible code list
      if (
        (this.currentValues.first && sourcesWithLocalizedText.filter((option) => { return option.value === this.currentValues.first }).length <= 0)) {
        let currentOption = this.originalSource.first.find((option) => { return this.currentValues.first === option.value })
        sourcesWithLocalizedText.push(currentOption)
      }
      return sourcesWithLocalizedText.sort((a, b) => sortUtils.naturalSort(a.text[0][langCode], b.text[0][langCode]))
    },
    /**
       * Computed property used to get and set the current value as it should be shown to the user of the property
       * corresponding to this instance of the ListEditor.
       */
    currentValuesAsString: {
      get () {
        let value = ''
        if (this.currentValues && this.currentValues.first) {
          let firstItem = this.source.first.find(v => v.value === this.currentValues.first)
          value += this.getCode(firstItem)
        }
        if (this.currentValues && this.currentValues.second) {
          let secondItem = this.source.second.find(v => v.value === this.currentValues.second)
          value += this.getCode(secondItem)
        }
        return value
      },
      set (value) {

      }
    },
    /**
       * Computed property used to get and set the validated value of the property corresponding to this instance of the TwoListEditor.
       */
    validatedValues: {
      get () {
        // getEditorValue return the concatenated value and sets the underlying object in
        // this.field.rawData
        editorUtils.getEditorValue(this.field)

        let val
        if (typeof this.field.rawData !== 'undefined') {
          val = this.field.rawData
        } else {
          val = { first: '', second: '' }
          console.warn('twoListEditor for [' + this.field.key + '] has no editorvalue.')
        }

        // overwrite current value with 'valid' value from store and set valid to true. get() is called in the following cases:
        // - when the value of this.$store.state.workingObject[this.field.key] changed (e.g. because side effect or switching workingObject)
        // - when initializing the editor
        this.setCurrentValue({ first: val.first, second: val.second })

        return val
      },
      set (value) {
        let result = editorUtils.setEditorValue(this.field, value)

        if (result.hasChanges) {
          this.$store.commit('updateWorkingObject', result.values)
        }
      }
    },
    /**
       * Computed property used to get and set whether the validation of the current value has finished.
       */
    activeEditorValidationPending: {
      get () {
        return this.$store.state.activeEditorValidationPending
      },
      set (val) {
        this.$store.commit('setActiveEditorValidationPending', val)
      }
    }
  },

  watch: {
    'currentValues': {
      handler: function (value) {
        // set activeEditorValidationPending to true, so that navigation is blocked until validation is done
        this.activeEditorValidationPending = true
        this.validateCurrentValue(this.currentValues)
      },
      immediate: true,
      deep: true
    }
  },

  created () {
    this.field.raws.forEach((raw) => {
      raw.value = ''
      let key = raw.key
      let dbField = this.field.rawDBFields.find((f) => { return f.name === key })
      if (dbField) {
        raw.value = this.field.rawData[dbField.mapName]
      }
    })
    this.initLists()
  },

  mounted () {
    this.title = this.field.title
  },

  methods: {
    setCurrentValue (value) {
      this.currentValues = value
      this.valid = true
    },
    validateCurrentValue: _.debounce(function (val) {
      // validate field and value using TerraIndexValidator
      this.$validateEditor(this.field, val).then(() => {
        // set valid to true and set val to validatedValue as validation has succeeded
        this.valid = true
        this.validatedValues = val
      }).catch(reason => {
        // extract reason and set valid to false
        this.valid = false
        this.errorMessage = reason.message
      }).finally(() => {
        // validation has finished
        this.activeEditorValidationPending = false
      })
    }, 500),

    initLists () {
      let source = { first: [], second: [] }
      if (this.field.raws) {
        // map to desired structure and sort alphabetically
        let firstIndex = this.field.raws.findIndex(f => f.key === this.field.rawDBFields.find(dbf => dbf.mapName === 'first').name)
        this.originalSource.first = this.field.raws[firstIndex].options
        source.first = codeListUtils.getVisibleCodesForField(this.field.raws[firstIndex]).map(this.transformItem)
        // map to desired structure and sort alphabetically
        let secondIndex = this.field.raws.findIndex(f => f.key === this.field.rawDBFields.find(dbf => dbf.mapName === 'second').name)
        this.originalSource.second = this.field.raws[secondIndex].options
        source.second = codeListUtils.getVisibleCodesForField(this.field.raws[secondIndex]).map(this.transformItem)
      }

      this.source = source
      this.rawSource = source

      this.codeNameList = []

      for (let key in this.source) {
        this.codeNameList.push(key)
      }

      if (Array.isArray(this.source.first)) {
        this.source.first = sortUtils.sortByFormatted(this.options.textFormatKeys, this.source.first, ['localizedCode', 'localizedText'])
      }

      if (Array.isArray(this.source.second)) {
        this.source.second = sortUtils.sortByFormatted(this.options.textFormatKeys, this.source.second, ['localizedCode', 'localizedText'])
      }
    },

    setSelectedItem (value, list) {
      // if the same item is selected in the list, then de-select
      if (this.currentValues[list] === value) {
        this.activatedList['name'] = list
        this.currentValues[list] = ''

        return
      }

      if (list === 'first') {
        // set activated list to second
        this.activatedList['name'] = 'second'
      }

      if (list === 'second') {
        this.activatedList['name'] = 'first'
      }

      this.currentValues[list] = value

      this.focus()
    },

    search (evt) {
      evt = (evt) || window.event
      if (evt.keyCode === 13 || (evt.keyCode >= 37 && evt.keyCode <= 40)) {
        // enter key , arrow keys
        evt.stopPropagation()
        return
      }

      // get value in input
      let val = evt.currentTarget.value

      // first see if item is selected, if so substract it from the search value (if it is not in there, searhc in first again)
      let firstFound = false
      if (this.currentValues['first']) {
        let firstCode = this.source.first.find(i => i.value === this.currentValues['first'])
        if (firstCode) firstCode = firstCode.localizedCode
        if ((firstCode) && val.indexOf(firstCode) === 0) { // first result is matched, look in second list
          firstFound = true
          val = val.substring(firstCode.length)
          if (val === '') {
            this.currentValues['second'] = ''
          } else {
            let secondItem = this.source['second'].find(item => this.getCode(item).toUpperCase() === val.toUpperCase())
            if (secondItem) {
              this.currentValues['second'] = secondItem.value
            }
          }
        }
      }

      if (!firstFound) { // look in first list
        if (val === '') {
          this.currentValues['first'] = ''
          this.currentValues['second'] = ''
        } else {
          let firstItem = this.source['first'].find(item => this.getCode(item).toUpperCase() === val.toUpperCase())
          if (firstItem) {
            this.currentValues['first'] = firstItem.value
            this.currentValues['second'] = ''
          }
        }
      }

      evt.stopPropagation()

      this.focus()
    },

    clear () {
      this.currentValues.first = this.currentValues.second = ''

      this.activatedList.name = this.codeNameList[0]
      this.activatedList.value = ''
    },

    acceptCode (evt) {
      this.setSelectedItem(this.activatedList.value, this.activatedList.name)
    },

    transformItem (item) {
      if (item && item._transformed) { return item }

      let rlang = stringUtils.convert2To3LetterLanguageCode(this.lang)

      if (typeof item !== 'object' || !item) {
        return {
          value: item,
          localizedText: item,
          localizedCode: '',
          _transformed: true
        }
      }

      let texts = {}; let codes = {}
      item.text.forEach(t => Object.assign(texts, t))
      item.codeInterface.forEach(t => Object.assign(codes, t))

      item.localizedText = texts[rlang]
      item.localizedCode = codes[rlang]
      item._transformed = true

      return item
    },

    getText (item) {
      this.transformItem(item)

      if (!item) { return '' }
      if (item.localizedText && item.localizedText !== '') {
        item.localizedText = stringUtils.capitalizeFirstLetter(item.localizedText)
      }
      if (this.options.textFormat) { return this.options.textFormat(item) }

      return stringUtils.capitalizeFirstLetter(item.localizedText)
    },

    getCode (item) {
      this.transformItem(item)
      if (!item) { return '' }
      return item.localizedCode
    },

    focus () {
      $('#input' + this.id).focus()// .setCursorPosition(1)
    },

    getActivatedIndexAndListname () {
      let result = { index: -1, listname: '' }

      if (!this.options.isObject && !this.activatedList) {
        return result
      }

      let toselectedvalue = this.activatedList.value || this.currentValues[this.activatedList.name]

      if (toselectedvalue) {
        result.index = this.source[this.activatedList.name].findIndex(v => v.value.indexOf(toselectedvalue) !== -1)
      } else {
        result.index = 0
      }
      result.listname = this.activatedList.name

      return result
    },

    setActivatedListItem (listname, item, orientation) {
      if (!item) return

      this.activatedList.name = listname
      this.activatedList.value = item.value

      let listid = '#' + listname + 'list' + this.id

      let activeIndex = $(listid + ' .list-group-item.actived').index()
      let scrollNumber = orientation ? activeIndex * 40 : (activeIndex - 2) * 40
      $(listid).scrollTop(scrollNumber)
    },

    gotoNextListItem () {
      let IndexAndListname = this.getActivatedIndexAndListname()
      let index = IndexAndListname.index
      let listname = IndexAndListname.listname
      if (index + 1 >= this.source[listname].length) { return } else { index++ }

      let item = this.source[listname][index]
      this.setActivatedListItem(listname, item, 1)
    },

    gotoPrevListItem () {
      let IndexAndListname = this.getActivatedIndexAndListname()
      let index = IndexAndListname.index
      let listname = IndexAndListname.listname
      if (index === 0) { return } else { index-- }
      let item = this.source[listname][index]
      this.setActivatedListItem(listname, item, 0)
    },

    gotoLeftListItem () {
      let listname = this.codeNameList[0]
      let item = this.source[listname].find(v => v.value === this.currentValues[listname])
      if (!item) {
        item = this.source[listname][0]
      }
      this.setActivatedListItem(listname, item, 0)
    },

    gotoRightListItem () {
      let listname = this.codeNameList[1]
      let item = this.source[listname].find(v => v.value === this.currentValues[listname])
      if (!item) {
        item = this.source[listname][0]
      }
      this.setActivatedListItem(listname, item, 0)
    },

    keyboardArrow (evt) {
      evt = (evt) || window.event
      if (evt.keyCode) {
        if (evt.keyCode === 38) {
          // up arrow
          this.gotoPrevListItem()
          evt.stopPropagation()
        } else if (evt.keyCode === 40) {
          // down arrow
          this.gotoNextListItem()
          evt.stopPropagation()
        } else if (evt.keyCode === 39) {
          // right arrow
          this.gotoRightListItem()
          evt.stopPropagation()
        } else if (evt.keyCode === 37) {
          // left arrow
          this.gotoLeftListItem()
          evt.stopPropagation()
        }
      }
    }

  }
}
</script>

<style scoped>
.discription-box {
  background: #67ac45;
  min-height: 24px;
  line-height: 24px;
  color: #fff;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-direction: rtl;
  flex: 1;
  max-width: 100%;
}
.discription-box-color {
  display:flex;
}
.has-feedback {
  max-width: 100% !important;
}
.form-control-feedback {
  right:15px
}
</style>
