<template>
    <div :id="'editor' + id" @keyup="keyboardUpAndDown">
        <div class="input-group input-left">
            <input type="text" class="form-control" :class="{errorBorder: !valid}"
                   :value="validatedValueAsString" :title="validatedValueAsString" readonly
                   @click="$parent.expandPanel">
        </div>

        <div class="editor-panel hidden animated" @keyup="setKeySelected">
            <div class="close" @click="$parent.hidePanel(valid)">
                <span class="glyphicon glyphicon-chevron-right"></span>
            </div>
            <div class="row">
                <div class="col-md-7">
                    <label class="field-name">{{title}}</label>
                    <div class="has-feedback">
                        <input :id="'input' + id" v-model="currentValueAsString" type="text"
                               class="form-control" :class="{errorBorder: !valid}" autocomplete="nope" @keyup="search" >
                        <span class="form-control-feedback glyphicon glyphicon-remove" @click="emptyValue"></span>
                        <label v-if="!valid" class="error">{{errorMessage}}</label>
                    </div>
                </div>
            </div>
            <div v-if="source.length" class="row">
                <div class="col-md-7">
                    <div class="panel panel-default panel-list">
                        <div class="panel-heading">{{currentValueAsString}}
                            <span v-if="!options.isObject" class="glyphicon glyphicon-plus pull-right"
                                  @click="add()"></span>
                        </div>
                        <div class="list-group">
                            <a v-for="(item, key) in sourceWithLocalizedText" :key="key"
                               href="javascript:"
                               class="list-group-item"
                               :class="{ 'selected': item === selected, 'actived': item === activated}" @click="setClickSelected(item)">{{getText(item)}}
                                <span v-if='item === selected'>selected</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
'use strict'
import Vue from 'vue'
import $ from 'jquery'
import _ from 'underscore'
import codeListUtils from '@/utils/codeListUtils'
import stringUtils from '@/utils/stringUtils'
import sortUtils from '@/utils/sortUtils'
import editorUtils from '@/utils/editorUtils'
import projectionUtils from '@/utils/projectionUtil'

/**
 * Editor with which users can set a value to a property based on the selected item in a list.
 */
export default {
  name: 'listEditor',
  props: ['field', 'options'],

  data () {
    return {
      id: this._uid,
      currentValue: String,
      errorMessage: null,
      valid: true,
      selected: null,
      activated: null,
      title: String,
      lang: Vue.config.lang,
      relationLang: {
        nl: 'nld',
        en: 'eng',
        fr: 'fra',
        es: 'spa',
        de: 'deu',
        it: 'ita'
      },
      source: [],
      originalSource: [],
      value: String,
      currentValueAsString: String
    }
  },

  computed: {
    sourceWithLocalizedText: {
      get () {
        let sourcesWithLocalizedText = this.source.filter(function (item) {
          return item.localizedText
        })
        // Get the value of the current selected item and add it to the option list if it is not visible
        if (
          (this.selected && sourcesWithLocalizedText.filter((option) => { return option.value === this.selected.value }).length <= 0)) {
          let currentOption = this.field.options.find((option) => { return this.selected.value === option.value })
          sourcesWithLocalizedText.push(currentOption)
        }
        return sourcesWithLocalizedText.sort((a, b) => sortUtils.naturalSort(a.localizedText, b.localizedText))
      }
    },
    /**
     * Computed property used to get the boolean that determines whether the 'input value' should be saved
     * as is (= true) or whether the ID of the matching option in this.field.options should be saved (= false).
     */
    isSavedAsString: {
      get () {
        let saveAsString = this.options.saveFullTextInsteadOfID !== undefined
        return saveAsString && this.options.saveFullTextInsteadOfID.indexOf(this.field.key) >= 0
      }
    },
    /**
     * Computed property used to get the validated value as it should be shown to the user of the property
     * corresponding to this instance of the ListEditor.
     */
    validatedValueAsString: {
      get () {
        let item
        if (this.isSavedAsString) {
          item = this.getOptionFromString(this.validatedValue)
        } else {
          item = this.getOptionFromValue(this.validatedValue)
        }
        if (item) {
          this.setSelected(item)
        }
        let output = (item && item.text[0] && item.text[0][this.relationLang[this.lang]]) || this.validatedValue
        output = output.charAt(0).toUpperCase() + output.slice(1)
        return output === undefined ? '' : output + ''
      }
    },
    /**
     * Computed property used to get and set the validated value of the property corresponding to this instance of the ListEditor.
     */
    validatedValue: {
      get () {
        let val = editorUtils.getEditorValue(this.field)
        // overwrite current value with 'valid' value from store and set valid to true. get() is called in the following cases:
        // - when the value of this.$store.state.workingObject[this.field.key] changed (e.g. because side effect or switching workingObject)
        // - when initializing the editor
        this.setCurrentValue(val)

        return val
      },
      set (value) {
        let result = editorUtils.setEditorValue(this.field, value)
        if (result.hasChanges) {
          this.$store.commit('updateWorkingObject', result.values)
        }
      }
    },
    /**
     * Computed property used to get and set whether the validation of the current value has finished.
     */
    activeEditorValidationPending: {
      get () {
        return this.$store.state.activeEditorValidationPending
      },
      set (val) {
        this.$store.commit('setActiveEditorValidationPending', val)
      }
    }
  },

  watch: {
    'currentValue': function (value) {
      // set activeEditorValidationPending to true, so that navigation is blocked until validation is done
      this.activeEditorValidationPending = true
      this.validateCurrentValue(this.currentValue)
      // compute string version
      if (this.isSavedAsString) {
        let item = this.getOptionFromString(this.currentValue)

        this.selected = item || null
        this.activated = item || null

        this.currentValueAsString = this.currentValue
      } else {
        let item = this.getOptionFromValue(this.currentValue)

        this.selected = item || null
        this.activated = item || null

        this.currentValueAsString = (item && (item.localizedText || item.value)) || this.currentValue
      }
    },
    'validatedValueAsString': function (value) {
      if (value === this.currentValueAsString) {
        this.source = this.originalSource
      }
    },
    'currentValueAsString': function (inputValue) {
      // only set currentValue is we save currentValue as string. in case we want to save id, we only want to set
      // filter this.source using value currently in input and set activated if case insensitive exact match has
      // been found

      // if the currentValueAsString is the same as the validatedValue as string show the entire list
      this.source = this.originalSource.filter(v => {
        let value = this.getText(v)

        if (typeof (value) === 'string' && typeof (inputValue) === 'string') {
          // if case insensitive exact match has been found, then set it as activated (highlight it with green)
          if (value.toLowerCase() === inputValue.toLowerCase()) {
            this.setActivated(v, 1)
          }

          return value && value.toLowerCase()
            .indexOf(inputValue.toLowerCase()) !== -1
        } else {
          return false
        }
      })
      // validated value on enter or click on item
      if (this.isSavedAsString) {
        this.currentValue = inputValue
      }
    }
  },

  created () {
    // First filter the list on visible columns then to someting only for coordinate systems
    // we need to do this on created() (NOT ready()), because then getDisplayValueForValue() has already failed because localizedText is not set
    this.source = codeListUtils.getVisibleCodesForField(this.field).filter(this.includeItem)
    this.source = this.source.map(this.transformItem)

    this.source = sortUtils.sortByFormatted(this.options.textFormatKeys, this.source, ['localizedCode', 'localizedText'])

    this.originalSource = this.source
    this.content = ''

    if (!this.options.isObject) {
      let storage = window.localStorage
      let tempdata = this.getTemp(storage)
      tempdata.forEach(v => {
        this.originalSource.push(this.transformItem(v))
      })
    }
    // initiate this.value to the current value
    this.value = this.field.value
  },

  mounted () {
    this.title = this.field.title
  },

  methods: {
    setCurrentValue (value) {
      this.currentValue = value
      this.valid = true
    },
    allowNewEntries () {
      // Allows entries not yet given as choices in the drop-down list shown
      return this.isSavedAsString // These properties are equal for now, but might change in the future
    },
    getChoiceItemMatchValue (source, value) {
      return source.find(v => {
        return v.value === value
      })
    },
    setSelected (item) {
      // if current value should be saved as string, then set localized text, else set value
      if (this.isSavedAsString) {
        this.currentValue = item.localizedText || item.value
      } else {
        this.currentValue = item.value
      }
    },
    setActivated (item, orientation) {
      this.activated || (this.activated = item)
      this.activated = item
    },
    getTemp (storage) {
      let tempdata = storage.getItem(this.field.key)
      if (!tempdata) {
        tempdata = []
      } else {
        tempdata = tempdata.split(',')
      }
      return tempdata
    },
    validateCurrentValue: _.debounce(function (val) {
      // validate field and value using TerraIndexValidator
      this.$validateEditor(this.field, val)
        .then(() => {
          // set valid to true and set val to validatedValue as validation has succeeded
          this.valid = true
          this.validatedValue = val
        })
        .catch(reason => {
          // extract reason and set valid to false
          this.valid = false
          this.errorMessage = reason.message
        })
        .finally(() => {
          // validation has finished
          this.activeEditorValidationPending = false
        })
    }, 500),
    add () {
      if (this.options.isObject) {
        return
      }
      let data = this.getChoiceItem(this.originalSource, this.content)
      if (data === '') {
        return
      }
      if (data) {
        return
      }
      let item = this.transformItem(this.content)
      this.originalSource.push(item)
      var storage = window.localStorage
      let tempdata = this.getTemp(storage)
      tempdata.push(this.content)
      storage.setItem(this.field.key, tempdata)
      if (this.originalSource !== this.source) {
        this.source.push(item)
      }
    },
    setClickSelected (item) {
      this.setActivated(item)
      this.setSelected(item)
    },
    emptyValue () {
      this.source = this.originalSource
      this.currentValue = ''
      this.selected = undefined
    },
    setKeySelected (evt) {
      evt = (evt) || window.event
      // 13 is enter key
      if (evt.keyCode && evt.keyCode === 13) {
        this.setSelected(this.activated)
        evt.preventDefault()
      }
    },
    search (evt) {
      evt = (evt) || window.event
      let keyCode = evt.keyCode || evt.which
      const DISABLED_KEYCODES = [37, 38, 39, 40, 13]
      // if current key code is in DISABLED_KEYCODES, then return/exit
      if (DISABLED_KEYCODES.indexOf(keyCode) > -1) {
        evt.preventDefault()
      }
    },
    /**
     * Whether or not to include the item in the list of options.
     * True = include
     */
    includeItem (item) {
      if (this.field.key === 'PrCoordinateSystem') {
        return projectionUtils.isCodeSupported(item.value)
      }
      return true
    },
    transformItem (item) {
      if (item && item._transformed) {
        return item
      }

      let rlang = this.relationLang[this.lang]

      if (typeof item !== 'object' || !item) {
        return {
          value: item,
          localizedText: item,
          localizedCode: '',
          _transformed: true
        }
      }

      let texts = {}
      let codes = {}
      item.text.forEach(t => Object.assign(texts, t))
      item.codeInterface.forEach(t => Object.assign(codes, t))

      item.localizedText = texts[rlang]
      item.localizedCode = codes[rlang]
      item._transformed = true

      return item
    },
    getText (item) {
      this.transformItem(item)
      if (!item) {
        return ''
      }
      if (item.localizedText && item.localizedText !== '') {
        item.localizedText = stringUtils.capitalizeFirstLetter(item.localizedText)
      }

      if (this.options.textFormat) {
        return this.options.textFormat(item)
      }
      return item.localizedText
    },
    focus () {
      $('#input' + this.id)
        .focus()
      this.source = this.originalSource
    },
    unfocus () {
      let item = this.getChoiceItemMatchValue(this.originalSource, this.value)
      if (this.isSavedAsString && this.allowNewEntries()) {
        this.content = this.value
      } else if (item) {
        this.content = item.localizedText
      } else {
        this.content = undefined // undefined;
      }
    },
    getActivatedIndex () {
      if (!this.options.isObject && !this.activated && this.activated !== '') {
        return -1
      }

      if (this.options.isObject && !this.activated) {
        return -1
      }

      let key = this.activated.value || this.activated
      let index = 0

      if (this.options.isObject) {
        index = _.findIndex(this.source, { value: key })
        return index || 0
      }

      index = _.indexOf(this.source, key)

      return index || 0
    },
    gotoNext () {
      let currentIndex = this.getActivatedIndex()
      if (currentIndex + 1 >= this.source.length) {
        return
      } else {
        currentIndex++
      }

      let item = this.source[currentIndex]
      if (item === '' || item) {
        this.setActivated(item, 1)
      }
    },
    gotoPrev () {
      let currentIndex = this.getActivatedIndex()

      if (currentIndex === 0) {
        return
      } else {
        currentIndex--
      }

      let item = this.source[currentIndex]

      if (item === '' || item) {
        this.setActivated(item, 0)
      }
    },
    keyboardUpAndDown (evt) {
      evt = (evt) || window.event
      if (evt.keyCode) {
        if (evt.keyCode === 38) {
          this.gotoPrev()
          evt.preventDefault()
        }
        if (evt.keyCode === 40) {
          this.gotoNext()
          evt.preventDefault()
        }
      }
    },
    /***
     * Find object in this.field.options with value of property localizedText that matches parameter 'value'.
     * @param value The string to be matched with 'option.localizedText'
     */
    getOptionFromString (value) {
      return this.field.options.find(function (option) {
        return option.localizedText && option.localizedText.toString() === value.toString()
      })
    },
    /***
     * Find object in this.field.options with value of property value that matches parameter 'value'.
     * @param value The string to be matched with 'option.value'
     */
    getOptionFromValue (value) {
      if (value === null) return ''
      return this.field.options.find(function (option) {
        return option.value && option.value.toString() === value.toString()
      })
    }
  }
}
</script>
