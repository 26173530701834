import $ from 'jquery'

// TODO refactor into the popup map

class ContextMenu { // example: popupUtils.popup(e,'#projectContextMenuPopup',{elementAnchor: 'rb', popupAnchor: 'cc'});
  constructor () {
    this.menuOpen = false
  }
  /**
     * Shows the context menu element.
     *
     * @param {*} e
     * @param {*} popupSelector
     * @param {*} align
     * @param {*} onPopupClose a callback handler for when the context menu is closed.
     * @memberof ContextMenu
     */
  popup (e, popupSelector, align, onPopupClose) {
    this.handleClose = false

    if (this.menuOpen) { // old menu was left open
      this.closeMenu()
    }
    if (typeof align === 'undefined') { // default
      align = { elementAnchor: 'rb', popupAnchor: 'cc' } // xy
    }
    this.$ele = $(e.currentTarget)
    if (this.$ele.length < 1) console.error('Element not found for event [' + e.currentTarget + ']')
    this.$popup = $(popupSelector)
    if (this.$popup.length < 1) console.error('Popup not found for selector [' + popupSelector + ']')
    this.align = align
    var self = this

    this.refreshPosition = function () {
      let targetPosition = { x: 0, y: 0 }
      let popupPosition = { x: 0, y: 0 }
      let a = this.align.elementAnchor
      let w = this.$ele.outerWidth()
      let h = this.$ele.outerHeight()
      let p = this.$ele.offset()
      if (a.substr(0, 1) === 'c') {
        targetPosition.x = p.left + (w / 2)
      } else if (a.substr(0, 1) === 'r') {
        targetPosition.x = p.left + w
      } else {
        targetPosition.x = p.left
      } // left is default
      if (a.substr(1, 1) === 'c') {
        targetPosition.y = p.top + (h / 2)
      } else if (a.substr(1, 1) === 'b' && !window.location.href.includes('overview/11')) {
        targetPosition.y = p.top + h
      } else {
        targetPosition.y = p.top
      } // left is default
      // decide where the popup needs to go
      w = this.$popup.outerWidth()
      h = this.$popup.outerHeight()
      a = this.align.popupAnchor
      if (a.substr(0, 1) === 'c') {
        popupPosition.x = targetPosition.x - (w / 2)
      } else if (a.substr(0, 1) === 'r') {
        popupPosition.x = targetPosition.x
      } else {
        popupPosition.x = targetPosition.x - w
      } // left is default
      if (a.substr(1, 1) === 'c') {
        popupPosition.y = targetPosition.y - (h / 2)
      } else if (a.substr(1, 1) === 'b') {
        popupPosition.y = targetPosition.y
      } else {
        popupPosition.y = targetPosition.y - h
      } // left is default
      this.$popup.css({ left: popupPosition.x, top: popupPosition.y })
    }

    this.openMenu = function (e) {
      this.menuOpen = true
      this.refreshPosition()
      this.handleClose = true
      this.$popup.show()
    }

    this.closeMenu = function () {
      this.menuOpen = false
      this.$popup.hide()
      $(document).off('.contextmenu') // kill contextmenu handlers
    }

    this.closeMenuClick = function (e) {
      if (onPopupClose && this.handleClose) {
        this.handleClose = false
        onPopupClose()
      }
      this.closeMenu()
    }

    // document escape or click should close it if it exists
    $(document).on('click.contextmenu', function (e) {
      // console.log('contextMenu', 'Document click');
      if (self.closeMenuClick !== null) self.closeMenuClick(e)
    })
    $(document).on('keydown.contextmenu', function (e) {
      // console.log('contextMenu', e);
      if (self.closeMenuClick !== null) self.closeMenu()
    })

    this.openMenu() // finally open menu
    e.preventDefault()
  }
}

export let _contextMenu = new ContextMenu()

export default {
  popup: _contextMenu.popup
}
