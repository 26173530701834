<template>
  <a :ref="'wsBox-'+template.WsGuid" :class="classes"
     href="javascript:"
     :style="{cursor: (this.options || {}).enableBoxLink ? 'pointer' : 'default'}"
     @click="gotoDetails()">
    <div class="arrow"></div>
    <div class="item">
      <p :title="template.WsName"><strong>{{template.WsName}}</strong></p>
      <p :title="WsDateDisplay">{{WsDateDisplay}}</p>
      <p :title="WsMatrixCode">{{WsMatrixCode}}</p>
    </div>
  </a>
</template>
<script>
import dateFormatter from '@/utils/dateFormatter'

export default {
  name: 'waterSampleBox',
  props: ['template', 'functions', 'options'],
  data () {
    return {
      classes: 'contents',
      isActive: false
    }
  },
  computed: {
    WsDateDisplay () {
      return dateFormatter.formatDateOnly(this.template.WsDate).toString('dd-MM-yyy')
    },
    WsMatrixCode () {
      let matrixcodeList = this.$store.getters.getTemplateField('matrixcodeList')
      if (matrixcodeList instanceof Object && this.template.WsMatrixCode) {
        let item = matrixcodeList.find(v => v.value === this.template.WsMatrixCode)
        if (item) { return item.desc }
      }
      return this.template.WsMatrixCode
    }
  },
  watch: {
    '$route.params.WsID': {
      handler: function (val) {
        if (!val) {
          this.classes = 'contents'
          return
        }
        this.isActive = val === this.template.WsID && this.$route.params.FtID === this.template.FtGuid
        this.classes = this.isActive ? 'contents active' : 'contents'
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    if (this.isActive) {
      this.$nextTick(() => this.$refs['wsBox-' + this.template.WsGuid].scrollIntoView({ behavior: 'smooth', block: 'center' }))
    }
  },

  updated () {
    if (this.isActive) {
      this.$nextTick(() => this.$refs['wsBox-' + this.template.WsGuid].scrollIntoView({ behavior: 'smooth', block: 'center' }))
    }
  },

  methods: {
    gotoDetails () {
      let prid = this.$route.params.projectId
      let mpguid = this.options.MpGuid || this.$route.params.measurementPointId
      let ftID = this.options.FtGuid || this.options.FtID
      let WsID = this.template.WsID
      if ((typeof ftID !== 'undefined') && (ftID !== null)) {
        this.detailUrl = `/project/${prid}/measurementPoint/${mpguid}/gaugingTubes/${ftID}/watersample/${WsID}`
      } else {
        this.detailUrl = `/project/${prid}/measurementPoint/${mpguid}/watersample/${WsID}`
      }
      if ((this.options || {}).enableBoxLink) { this.$router.push(this.detailUrl) }
    }
  }
}

</script>
