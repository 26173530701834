<template>
  <div>
    <!-- Modal add layer-->
    <div id="modalAddLayer" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 id="modalAddLayerTitle" class="modal-title">{{$t("project.AddLayerTitle")}}</h4>
          </div>
          <div class="modal-body">
            <p>{{$t("project.LayerFrom")}}</p>
            <select v-model="from" class="form-control">
              <option v-for="(la, key) in Layers" :key="key"
                    :value="la.LaFrom">{{la.LaFrom}}</option>
              <option :value="laUpperBound">{{laUpperBound}}</option>
            </select>
            <p v-if="isIntermediateLayer" class="error">{{$t("layers.intermediateLayerWarn")}}</p>
            <p>{{$t("project.LayerTo")}}</p>
            <input id="txtLayerTo" v-model="to" type="number" class="form-control" required :min="from" @keypress="checkSubmit">
            <p v-if="!valid" class="error">{{errorMessage}}</p>

          </div>
          <div class="modal-footer">
            <button id="btnAddLayer" class="btn" v-bind:class="{ disabled: !enableAddButton }" @click="add" >{{$t("project.CreateButton")}}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal delete Layer-->
    <div id="modalDeleteLayer" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 id="modalDeleteLayerTitle" class="modal-title">{{$t("project.DeleteLayerTitle")}}</h4>
          </div>
          <div class="modal-body">
            <p>{{$t("project.DeleteLayerHelp")}}</p>
          </div>
          <div class="modal-footer">
            <button id="btnDeleteLayer" class="btn" @click="deleteLayer">{{$t("project.DeleteButton")}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'laModal',
  props: ['functions', 'option'],

  data () {
    return {
      errorMessage: '',
      valid: false,
      to: 0,

      from: 0,
      laUpperBound: Number.MAX_SAFE_INTEGER,
      isIntermediateLayer: false
    }
  },

  computed: {
    Layers: {
      get () {
        return this.$store.state.layers.filter((layer) => { return layer.type !== 'emptyBox' })
      },
      set (value) {
        let self = this
        value.forEach(function (entry) {
          entry.MpGUID = self.MpGuid
        })
        this.$store.commit('setLayers', value)
      }
    },
    enableAddButton () {
      return this.valid && !this.$parent.loading
    }
  },

  watch: {
    'option.suggestedName': {
      handler: function () {
        this.name = this.option.suggestedName
      }
    },
    'to': {
      handler: function (newVal, OldVal) {
        this.validate()
      }
    },
    from: function (newVal, oldVal) {
      Number(newVal) === this.laUpperBound ? this.isIntermediateLayer = false : this.isIntermediateLayer = true
    }
  },

  methods: {
    checkSubmit (evt) {
      if (evt.key === 'Enter' && this.valid) {
        this.add()
      }
    },
    validate () {
      let LaTrajectoryField = {
        editorOptions: {
          allowDigits: true,
          allowMinus: false
        },
        rawOptions: {
          rule: 'next'
        },
        key: 'LaTo',
        maxLength: -1,
        requiredBySystem: true,
        requiredByUser: true,
        type: '19'
      }
      this.$validateEditor(LaTrajectoryField, { first: this.from, second: this.to }, { newLayer: true })
        .then(() => {
          this.valid = true
        }).catch(reason => {
          // extract reason and set valid to false
          if (typeof reason.message === 'undefined') { console.error(reason) }
          this.valid = false
          this.errorMessage = reason.message
        })
    },
    add () {
      return this.functions.add({ from: this.from, to: this.to })
    },
    resetDefault () {
      if (this.Layers && this.Layers.length > 0) {
        this.from = Number(this.Layers.slice(-1)[0].LaTo)
        this.laUpperBound = this.from
      } else {
        this.from = 0
        this.laUpperBound = 0
      }
      this.isIntermediateLayer = false
      this.to = ''
      this.errorMessage = ''
    },
    deleteLayer () {
      return this.functions.delete()
    }
  }
}
</script>
