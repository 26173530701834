import $ from 'jquery'
import moment from 'moment'
import $store from '@/vuex/store'
import { FinishingsService } from '@/services/DataWS/finishingsService'

const finishingsService = new FinishingsService()
export default {
  gotolist (self) {
    // todo: change path for Finishings
    let path = `/project/${self.options.projectId}/measurementPoint`
    self.$router.push(path)
  },
  open (self) {
    $('#modalAddFinishings').modal('toggle')
      .on('shown.bs.modal', function (e) {
        $('#txtFinishingsName').focus()
      })

    let create = self.$children.find(v => v.$options.name === 'fiModal') || {}
    create.resetDefault()
    create.typecode = ''
  },
  delete (self) {
    self.loading = true
    $('#modalDeleteFinishings').modal('hide')

    let fiGuid = $store.state.workingObject.FiGuid
    let fiID = $store.state.workingObject.FiID
    let finishings = $store.state.finishings
    let newFinishings = finishings.filter(fi => fi.FiGuid !== fiGuid)
    $store.commit('setFinishings', newFinishings)

    // Sorting and assigning new FiIDs
    newFinishings.sort((a, b) => a.FiID - b.FiID)
    newFinishings.forEach((fi, index) => {
      fi.FiID = `${index + 1}`
    })

    const maxFiID = Math.max(...newFinishings.map(fi => +fi.FiID))
    fiID = (fiID > maxFiID) ? maxFiID : fiID

    finishingsService.deleteFinishings(self.$route.params.projectId, self.$route.params.measurementPointId, $store.state.workingObject.FiGuid).then(res => {
      const workingObject = newFinishings.length > 0 ? newFinishings[fiID - 1] : newFinishings[0]
      if (workingObject ?? false) {
        $store.commit('setWorkingObject', workingObject)
      }
      self.loading = false
      if ($store.state.finishings.length >= 1) {
        // window.location.href = config.root + 'project/' + self.$route.params.projectId + '/measurementPoint/' + self.$route.params.measurementPointId + '/finishings/1'
        self.$router.push({
          name: 'finishings',
          params: {
            'projectId': self.$route.params.projectId,
            'measurementPointId': self.$route.params.measurementPointId,
            'FiID': `${fiID}`
          }
        })
      } else {
        // window.location.href = config.root + 'project/' + self.$route.params.projectId + '/measurementPoint/' + self.$route.params.measurementPointId
        self.$router.push({
          name: 'measurementPoint',
          params: {
            'projectId': self.$route.params.projectId,
            'measurementPointId': self.$route.params.measurementPointId
          }
        })
      }
    }, err => {
      console.log(err)
      $('#modalDeleteFinishings').modal('toggle')
      self.loading = false
    })
  },
  add (self, data) {
    if (self.loading) return
    self.loading = true

    let now = moment().format('YYYY-MM-DDTHH:mm:ss')
    let newitem = {
      'MpID': '1',
      'FiID': '1',
      'FiName': data.name,
      'FiTypeCode': data.typecode,
      'FiFrom': data.from,
      'FiTo': data.to,
      'FiDateLastChanged': now
    }

    let finishings = $store.state.finishings
    finishings.push(newitem)

    let prid = self.$route.params.projectId
    let mpguid

    if (isNaN(self.$route.params.measurementPointId)) {
      mpguid = self.$route.params.measurementPointId
    } else {
      mpguid = $store.state.measurementPoints.find(measurementpoint => measurementpoint.MpID === self.$route.params.measurementPointId).MpGuid
    }

    finishingsService.createFinishing(prid, mpguid, finishings)
      .then(finishings => {
        $store.commit('setFinishings', finishings)
        self.loading = false
        const backdrop = document.getElementsByClassName('modal-backdrop fade in')
        if (backdrop && backdrop[0]) {
          backdrop[0].remove()
        }
        $('#modalAddFinishings').modal('hide')

        let realParent = self.$parent
        if (realParent.$options.name === 'syncScroll') {
          realParent = realParent.$parent
        }
        if (realParent.$options.name === 'measurementPoint' || realParent.$options.name === 'finishings') {
          realParent.format && realParent.format()
        } else {
          console.warn('Can not format [' + realParent.$options.name + ']')
        }

        let maxID = Math.max(...finishings.map(ga => ga.FiID))
        let finishing = finishings.find(ga => ga.FiID === maxID.toString())

        return finishing
      })
      .then((finishing) => {
        self.loading = false
        let path = '/project/' + prid + '/measurementPoint/' + mpguid + '/finishings/' + finishing.FiID + '?new=true'
        self.$router.push(path)
      })
      .catch(err => {
        console.log('Catch: ' + err)
        $('#modalAddFinishings').modal('hide')
        self.loading = false
      })
  }
}
