<template>
  <a :ref="'jrBox-' + template.JrGuid" :class="classes" href="javascript:" :style="{ cursor: (this.options || {}).enableBoxLink ? 'pointer' : 'default' }" @click="gotoDetails()">
    <div class="arrow"></div>
    <div class="item" v-bind:class="{ disturbed: isDisturbed }">
      <div>
        <p :title="template.JrName">
          <strong>{{ template.JrName }}</strong>
        </p>
        <p :title="template.JrFrom + '-' + template.JrTo">{{ template.JrFrom }} - {{ template.JrTo }} cm</p>
        <p v-if="template.JrBarcode" :title="template.JrBarcode">{{ template.JrBarcode }}</p>
      </div>
      <div class="side-box" v-bind:class="{ disturbed: isDisturbed }"></div>
    </div>
  </a>
</template>
<script>
export default {
  name: 'soilSampleBox',
  props: ['template', 'functions', 'options'],
  data() {
    return {
      classes: 'contents',
      isActive: false
    }
  },
  computed: {
    isDisturbed() {
      let val = this.template.JrStirred === 'true' || this.template.JrStirred === true ? 1 : 0
      return val
    }
  },
  watch: {
    '$route.params.jarID': {
      handler: function (val) {
        if (!val) {
          this.classes = 'contents'
          return
        }
        this.isActive = val === this.template.JrID
        this.classes = this.isActive ? 'contents active' : 'contents'
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    if (this.isActive) {
      this.$nextTick(() => this.$refs['jrBox-' + this.template.JrGuid].scrollIntoView({ behavior: 'smooth', block: 'center' }))
    }
  },

  updated() {
    if (this.isActive) {
      this.$nextTick(() => this.$refs['jrBox-' + this.template.JrGuid].scrollIntoView({ behavior: 'smooth', block: 'center' }))
    }
  },

  methods: {
    gotoDetails() {
      let prid = this.$route.params.projectId
      let mpguid = this.options.MpGuid || this.$route.params.measurementPointId
      let jrid = this.template.JrID
      this.detailUrl = `/project/${prid}/measurementPoint/${mpguid}/soilSample/${jrid}`
      if ((this.options || {}).enableBoxLink) {
        this.$router.push(this.detailUrl)
      }
    }
  }
}
</script>

<style scoped>
.side-box {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 12px;
  bottom: 15px;
  border-radius: 4px;
  background-color: #c7b39a;
}
.disturbed {
  background-color: #876e50;
}
</style>
