import concernedContact from './libs/concernedContact'
import measurementPoint from './libs/measurementPoint'
import layer from './libs/layer'
import soilSample from './libs/soilSample'
import gaugingTubes from './libs/gaugingTubes'
import finishings from './libs/finishings'
import watersample from './libs/watersample'
import bottle from './libs/bottle'
import selectUser from './libs/selectUser'
import labassignments from './libs/labassignments'
import subLocation from './libs/subLocation'
import mapLayers from './libs/mapLayers'
import pictures from './libs/pictures'
import mapImages from './libs/mapImages'
import labCertificates from './libs/labCertificates'
import summaryAndConclusion from './libs/summaryAndConclusion'
import projectDocuments from './libs/projectDocuments'

export default {
  concernedContact,
  measurementPoint,
  layer,
  soilSample,
  gaugingTubes,
  finishings,
  watersample,
  bottle,
  selectUser,
  labassignments,
  subLocation,
  mapLayers,
  summaryAndConclusion,
  pictures,
  mapImages,
  projectDocuments,
  labCertificates
}
