<template>
  <div :id="'editor' + id">
    <div class="input-group input-left">
      <textarea
        v-if="isMultiLine"
        class="form-control texture-area"
        :class="{ errorBorder: !valid }"
        readonly
        rows="3"
        :value="validatedValueAsString"
        :title="validatedValueAsString"
        @click="$parent.expandPanel"
      ></textarea>
      <input
        v-else
        type="text"
        class="form-control"
        :class="{ errorBorder: !valid }"
        readonly
        :value="validatedValueAsString"
        :title="validatedValueAsString"
        @click="$parent.expandPanel"
      />
    </div>
    <i v-if="field.required" class="required">*</i>

    <div class="editor-panel hidden animated">
      <div class="close" @click="$parent.hidePanel(valid)">
        <span class="glyphicon glyphicon-chevron-right"></span>
      </div>
      <div class="row">
        <div class="col-md-7">
          <label class="field-name">{{ title }}</label>
          <div class="has-feedback">
            <textarea
              v-if="isMultiLine"
              v-model="currentValue"
              type="text"
              class="form-control"
              :class="{ errorBorder: !valid }"
              rows="10"
            />
            <input
              v-else
              v-model="currentValue"
              type="text"
              class="form-control"
              :class="{ errorBorder: !valid }"
            />
            <span
              class="form-control-feedback glyphicon glyphicon-remove"
              @click="emptyValue"
            ></span>
            <label v-if="!valid" class="error">{{ errorMessage }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
'use strict'

import editorUtils from '@/utils/editorUtils'
import _ from 'lodash'

/**
 * Editor with which users can set a textual value (excl. new lines) to a property.
 */
export default {
  name: 'textEditor',
  props: ['field', 'functions', 'options'],

  data() {
    return {
      id: this._uid,
      currentValue: String,
      errorMessage: null,
      title: String,
      valid: true
    }
  },

  computed: {
    validatedValue: {
      get() {
        // texteditor
        let val = editorUtils.getEditorValue(this.field)

        // overwrite current value with 'valid' value from store and set valid to true. get() is called in the following cases:
        // - when the value of this.$store.state.workingObject[this.field.key] changed (e.g. because side effect or switching workingObject)
        // - when initializing the editor
        this.setCurrentValue(val)

        return val
      },
      set(value) {
        let result = editorUtils.setEditorValue(this.field, value)
        if (result.hasChanges) {
          this.$store.commit('updateWorkingObject', result.values)
        }
      }
    },
    validatedValueAsString: {
      get() {
        return this.validatedValue === undefined ? '' : this.validatedValue + ''
      }
    },
    activeEditorValidationPending: {
      get() {
        return this.$store.state.activeEditorValidationPending
      },
      set(val) {
        this.$store.commit('setActiveEditorValidationPending', val)
      }
    },
    isMultiLine() {
      return (this.field.inputControlOptions.MultiLineInput === 'true') || false
    }
  },

  watch: {
    currentValue: function (value) {
      // set activeEditorValidationPending to true, so that navigation is blocked until validation is done
      this.activeEditorValidationPending = true
      this.validateCurrentValue(this.currentValue)
    }
  },

  mounted() {
    this.title = this.field.title
  },

  methods: {
    setCurrentValue(value) {
      this.currentValue = value
      this.valid = true
    },
    emptyValue() {
      this.currentValue = ''
    },
    validateCurrentValue: _.debounce(function (val) {
      // validate field and value using TerraIndexValidator
      let context = {}

      switch (this.field.key) {
        case 'MpName':
          context.MpGuid = this.$store.state.workingObject.MpGuid
          break
        case 'JrName':
          context.JrGuid = this.$store.state.workingObject.JrGuid
          break
        case 'FtName':
          context.FtGuid = this.$store.state.workingObject.FtGuid
          break
        case 'WsName':
          context.WsGuid = this.$store.state.workingObject.WsGuid
          let projectID = this.$route.params.projectId
          context.PrID = projectID
          break
        case 'BoName':
          context.BoGuid = this.$store.state.workingObject.BoGuid
          break
        case 'SlName':
          context.SlGuid = this.$store.state.workingObject.SlGuid
          break
      }
      this.$validateEditor(this.field, val, context)
        .then(() => {
          // set valid to true and set val to validatedValue as validation has succeeded
          this.valid = true
          this.validatedValue = val
        })
        .catch((reason) => {
          // extract reason and set valid to false
          this.valid = false
          this.errorMessage = reason.message
        })
        .finally(() => {
          // validation has finished
          this.activeEditorValidationPending = false
        })
    }, 500)
  }
}
</script>
