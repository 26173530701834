import moment from 'moment'
import $store from '../../vuex/store'
import $ from 'jquery'
import { ConcernedContactsService } from '@/services/DataWS/concernedContactsService'
const concernedContactsService = new ConcernedContactsService()

export default {
  open (self) {
    $('#modalAddConcernedContact').modal('toggle')
      .on('shown.bs.modal', function (e) {
        $('#txtConcernedContactContactPerson').focus()
        $('#modalAddConcernedContact').trigger('popupTrigger')
      })
  },
  delete (self) {
    self.loading = true
    $('#modalDeleteConcernedContact').modal('hide')
    let concernedContacts = $store.state.concernedContacts.filter(v => v.type !== 'emptyBox')
    let guidToDelete = $store.state.workingObject.CcGUID

    concernedContactsService.deleteConcernedContact(self.$route.params.projectId, guidToDelete).then(res => {
      let ccToNavigateTo = concernedContacts.find(cc => cc['CcGUID'] !== guidToDelete)
      $store.commit('clearWorkingObject')
      if (concernedContacts.length > 1) {
        self.$router.push({
          name: 'concernedContact',
          params: {
            'projectId': self.$route.params.projectId,
            'CcGUID': ccToNavigateTo['CcGUID']
          }
        })
        $store.commit('setConcernedContacts', $store.state.concernedContacts.filter(v => v.CcGUID !== guidToDelete))
      } else {
        self.$router.push({
          name: 'project',
          params: {
            'id': self.$route.params.projectId
          }
        })
      }
    }, err => {
      $('#modalDeleteConcernedContact').modal('toggle')
      self.loading = false
      console.error(err)
    })
  },
  add (self, data) {
    if (self.loading) return
    self.loading = true
    var PrID = self.$route.params.projectId || self.$route.params.projectId // Parameter name depends on view

    let now = moment().format('YYYY-MM-DDTHH:mm:ss')
    let newConcernedContact = {
      // project of boxes in project detail or project id of boxes in concerned contact detail
      'PrID': PrID,
      'CcTypeFunctionContactPerson': parseInt(data.CcTypeFunctionContactPerson),
      'CcContactPerson': data.CcContactPerson,
      'CcTypeConcerned': parseInt(data.CcTypeConcerned),
      'CcCompanyName': data.CcCompanyName,
      'CcDateLastChanged': now
    }

    concernedContactsService.createConcernedContact(newConcernedContact)
      .then(concernedContacts => {
        $store.commit('setConcernedContacts', concernedContacts)

        self.loading = false
        $('#modalAddConcernedContact').modal('hide')

        let maxID = Math.max(...concernedContacts.map(cc => cc.CcID))
        return concernedContacts.find(cc => cc.CcID === maxID.toString())
      })
      .then((concernedContact) => {
        self.loading = false
        self.$router.push({
          name: 'concernedContact',
          params: {
            'projectId': PrID, // Parameter name depends on view
            'CcGUID': concernedContact['CcGUID']
          }
        })
      })
      .catch(err => {
        console.error('Catch: ', err)
        $('#modalAddConcernedContact').modal('hide')
        self.loading = false
      })
  }
}
