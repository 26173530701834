import $store from '@/vuex/store'
import typefields from '@/configurations/app/config-typefield'
import _ from 'lodash'

export default {
  // If the field has rawDataObject also set the value for those specific field
  setEditorValue (field, currentValue) {
    let workingObject = $store.state.workingObject

    try {
      // TODO: Parser is a hot fix should be solved in boxes.vue line 379 to get a date string there instead of a object
      if (field.component === 'dateEditor') {
        // Transform working object
        // It is possible that a date object is send instead of a date string below it is parsed to a string
        if (typeof workingObject[field.key] !== 'string' && workingObject[field.key]) {
          // Parse the string to work as 'YYYY-MM-DDTmm:ss:ms'
          let localDateString = workingObject[field.key].toLocaleDateString().split('-')
          if (localDateString[1].length === 1) {
            localDateString[1] = `0${localDateString[1]}`
          }
          if (localDateString[0].length === 1) {
            localDateString[0] = `0${localDateString[0]}`
          }
          let parsedDateString = `${localDateString[2]}-${localDateString[1]}-${localDateString[0]}`
          workingObject[field.key] = `${parsedDateString}T${workingObject[field.key].toLocaleTimeString()}`
        }

        // Remove the time zone check this is solved on the server
        if (workingObject[field.key] && workingObject[field.key].includes('+')) {
          workingObject[field.key] = workingObject[field.key].split('+')[0]
        }

        // Remove the miliseconds
        if (workingObject[field.key] && workingObject[field.key].includes('.')) {
          workingObject[field.key] = workingObject[field.key].split('.')[0]
        }

        // Transform current value
        if (typeof currentValue !== 'string' && currentValue) {
          // Parse the string to work as 'YYYY-MM-DDTmm:ss:ms'
          let localDateString = currentValue.toLocaleDateString().split('-')
          if (localDateString[1].length === 1) {
            localDateString[1] = `0${localDateString[1]}`
          }
          if (localDateString[0].length === 1) {
            localDateString[0] = `0${localDateString[0]}`
          }
          let parsedDateString = `${localDateString[2]}-${localDateString[1]}-${localDateString[0]}`
          currentValue = `${parsedDateString}T${currentValue.toLocaleTimeString()}`
        }
        // Remove the time zone check this is solved on the server
        if (currentValue && currentValue.includes('+')) {
          currentValue = currentValue.split('+')[0]
        }

        // Remove the miliseconds
        if (currentValue && currentValue.includes('.')) {
          currentValue = currentValue.split('.')[0]
        }
      }
    } catch (e) {
      console.error('Date parser')
      console.log({ e })
    }

    // Parse the data for a date editor to be a valid date string for TerraIndex
    let result = {
      hasChanges: false,
      values: {}
    }
    try {
      let typeFields = typefields[field.type]

      if (!typeFields || typeFields.length === 0) {
        if (workingObject[field.key] !== currentValue && !(workingObject[field.key] === undefined && currentValue === '')) {
          result.values[field.key] = currentValue
          result.hasChanges = true
        }

        return result
      }

      let typefield = typeFields[0]

      if (typeFields.length > 1) {
        typefield = typeFields.find(v => v.name === field.rawKey) || typefield
      }

      // skip handle multiple  field
      if (!typefield || typefield.skip) {
        if (workingObject[field.key] !== currentValue && !(workingObject[field.key] === undefined && currentValue === '')) {
          result.values[field.key] = currentValue
          result.hasChanges = true

          return result
        }
      }

      let dbfields = typefield.db_fields

      if (!dbfields) { return result }

      // TODO: remove '|| editor.field.rawData' after the migration to Vuex is done
      let currentValues = currentValue || field.rawData
      _.each(currentValues, (value, key) => {
        let field = dbfields.find(v => v.mapName === key)
        if (!field) return result

        if (workingObject[field.name] !== value && !(workingObject[field.name] === undefined && value === '')) {
          result.values[field.name] = value
          result.hasChanges = true
        }
      })
    } catch (e) {
      console.error('Checker')
      console.log({ ...field, ...currentValue, ...e })
    }
    return result
  },

  getDbFieldValue (fieldKey) {
    return $store.state.workingObject[fieldKey] || ''
  },

  getEditorValue (field) {
    let typeFields = typefields[field.type]

    if ($store.state.workingObject == null) {
      throw new Error('Tried to getEditorValue when $store.state.workingObject is not set.')
    }
    if (!typeFields || typeFields.length === 0) {
      if ($store.state.workingObject[field.key]) {
        return $store.state.workingObject[field.key]
      } else {
        return '' // return empty string and not undefined so we can see if this value is set
      }
    }

    let typefield = typeFields[0]

    if (typeFields.length > 1) {
      typefield = typeFields.find(v => field.key.indexOf(v.name) === 0) ||
          typeFields.find(v => v.name === 'default')
    }

    if (typefield.skip) {
      if ($store.state.workingObject[field.key]) {
        return $store.state.workingObject[field.key]
      } else {
        return '' // return empty string and not undefined so we can see if this value is set
      }
    }

    let dbfields = typefield.db_fields
    field.rawOptions = typefield.options || {}
    field.rawKey = typefield.name
    field.skipParentValid = typefield.skipParentValid
    field.rawDBFields = (dbfields || null)

    if (!dbfields) { return '' }
    let data = {}
    let value = ''
    let temp = ''

    dbfields.forEach(v => {
      temp = $store.state.workingObject[v.name] || ''
      if (typefield.spiltchar) { value = value + typefield.spiltchar + temp } else { value = value + temp }
      data[v.mapName] = temp
    })
    field.rawData = data
    // field.rawOptions = typefield.options || {}
    field.maxLength = field.maxLength || typefield.maxLength

    return value
  }
}
