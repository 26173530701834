import moment from 'moment'
import $store from '@/vuex/store'
import $ from 'jquery'
import { LayerService } from '@/services/DataWS/layerService'
import { MeasurementPointService } from '@/services/DataWS/measurementPointService'
import dateFormatter from '@/utils/dateFormatter'
const layerService = new LayerService()
const measurementPointService = new MeasurementPointService()

export default {
  gotolist (self) {
    // todo: change path for layer
    let path = `/project/${self.options.projectId}/measurementPoint`
    self.$route.push(path)
  },
  open (self) {
    $('#modalAddLayer').modal('show')
      .on('shown.bs.modal', function (e) {
        $('#txtLayerTo').focus()
      })

    let layers = $store.state.layers.filter(v => v.type !== 'emptyBox')
    let last = layers[layers.length - 1]

    let create = self.$children.find(v => v.$options.name === 'laModal') || {}
    create.resetDefault()
    create.from = (last && last.LaTo) || '0'
    create.to = ''
    create.validationError_to = ''
  },
  delete (self) {
    self.loading = true
    $('#modalDeleteLayer').modal('hide')

    let layers = $store.state.layers.filter(v => v.type !== 'emptyBox')
    let remainingLayers = layers.filter(layer => layer.LaGuid !== $store.state.workingObject.LaGuid)
    layerService.deleteLayer(self.$route.params.projectId, self.$route.params.measurementPointId, $store.state.workingObject.LaGuid, layers)
      .then(res => {
        $store.commit('clearWorkingObject')
        measurementPointService.setNewMeasurementPointDepth(remainingLayers)

        let path
        if (layers.length > 1) {
          path = `/project/${self.$route.params.projectId}/measurementPoint/${self.$route.params.measurementPointId}/layer/1`
          self.$router.push(path)
          // Layer sidebar won't update with just a router navigation. Reload page to refresh layers in sidebar.
          // For some reason, Vue.nextTick doesn't work for this. Need a timeout instead.
          setTimeout(() => self.$router.go(), 50)
        } else {
          path = `/project/${self.$route.params.projectId}/measurementPoint/${self.$route.params.measurementPointId}`
          self.$router.push(path)
        }
      }, err => {
        $('#modalDeleteLayer').modal('toggle')
        self.loading = false
        console.log(err)
      })
  },
  add (self, data) {
    if (self.loading) return
    self.loading = true

    let now = moment().format('YYYY-MM-DDTHH:mm:ss')
    let newLayer = {
      'MpID': '1',
      'LaID': '-1',
      'LaFrom': data.from,
      'LaTo': data.to,
      'LaDateLastChanged': now
    }

    let layers = $store.state.layers
    layers.push(newLayer)

    // Check if the new layer is an intermediate layer, if so the parents layer  'from' needs to be adjuste
    let newLaIndex = layers.findIndex((el) => el.LaID === '-1')
    if (newLaIndex >= 0) {
      let outDatedLaIndex = layers.findIndex((el) => (el.LaFrom === layers[newLaIndex].LaFrom && el.LaID !== '-1'))
      if (outDatedLaIndex >= 0) {
        layers[outDatedLaIndex].LaFrom = layers[newLaIndex].LaTo
        layers[outDatedLaIndex].LaDateLastChanged = dateFormatter.generateDateLastChangedValue()
      }
    }

    let prid = self.$route.params.projectId
    let mpguid

    if (isNaN(self.$route.params.measurementPointId)) {
      mpguid = self.$route.params.measurementPointId
    } else {
      mpguid = $store.state.measurementPoints.find(measurementpoint => measurementpoint.MpID === self.$route.params.measurementPointId).MpGuid
    }

    layerService.createLayers(prid, mpguid, layers)
      .then(layers => {
        $store.commit('setLayers', layers)
        $store.commit('newLayer', layers)
        self.loading = false

        let realParent = self.$parent
        if (realParent.$options.name === 'syncScroll') {
          realParent = realParent.$parent
        }
        if (realParent.$options.name === 'measurementPoint' || realParent.$options.name === 'layer' || realParent.$options.name === 'soilSample') {
          realParent.format && realParent.format()
        } else {
          console.warn('Can not format [' + realParent.$options.name + ']')
        }

        let maxID = Math.max(...layers.map(la => la.LaID))
        let layer = layers.find(la => la.LaID === maxID.toString())

        return layer
      })
      .then(layer => {
        self.loading = false
        $('#modalAddLayer').modal('hide')
        // For some reason JQuery modal hide doesn't remove the backdrop of the modal anymore so remove it by hand
        const backdrop = document.getElementsByClassName('modal-backdrop fade in')
        if (backdrop && backdrop[0]) {
          backdrop[0].remove()
        }
        let path = '/project/' + prid + '/measurementPoint/' + mpguid + '/layer/' + layer.LaID + '?new=true'
        self.$router.push(path)
      })
      .catch(err => {
        console.error('Catch: ', err)
        $('#modalAddLayer').modal('hide')
        // For some reason JQuery modal hide doesn't remove the backdrop of the modal anymore so remove it by hand
        const backdrop = document.getElementsByClassName('modal-backdrop fade in')
        if (backdrop && backdrop[0]) {
          backdrop[0].remove()
        }
        self.loading = false
      })
  }
}
