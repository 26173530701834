export default {
  getVisibleCodesForField (field, isTextureInput) {
    let visibleCodes = field.visibleCodes

    if (!visibleCodes || !visibleCodes.length > 0) {
      visibleCodes = []
      // if watersample matrix add AIR and AMBientAir title = "lblWatersampleMatrix"
      if (field.inputControlOptions && field.inputControlOptions.DefaultFitler && field.inputControlOptions.DefaultFitler === 'WATER') {
        visibleCodes.push('SL')
        visibleCodes.push('OV')
        visibleCodes.push('OW')
        visibleCodes.push('AW')
        visibleCodes.push('GW')
        visibleCodes.push('SW')
      }
      // If airsample at water sample matrices title = "lblAirsampleMatrix"
      if (field.inputControlOptions && field.inputControlOptions.DefaultFitler && field.inputControlOptions.DefaultFitler === 'AIR') {
        visibleCodes.push('OL')
        visibleCodes.push('BL')
      }
    }
    if (
      ((Array.isArray(visibleCodes) && visibleCodes.length > 0)) ||
      isTextureInput) {
      return field.options.filter((option) => { return visibleCodes.includes(option.value) })
    } else {
      return field.options
    }
  }
}
