<template>
  <div>
    <!-- Modal add finishings-->
    <div id="modalAddFinishings" class="modal fade"
         tabindex="-1"
         @keypress="checkSubmit">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button"
                    class="close"
                    data-dismiss="modal">&times;</button>
            <h4 id="modalAddFinishingsTitle"
                class="modal-title">{{$t("project.AddFinishingsTitle")}}</h4>
          </div>
          <div class="modal-body">
            <p>{{$t("project.FinishTypeCode")}}</p>
            <select v-model='typecode'
                    class="form-control">
              <option value=''>- select -</option>
              <option v-for="typecode in finishTypeList" :key="typecode.value"
                      :value="typecode.value">{{typecode.desc}}</option>
            </select>
            <p v-if="!valid" class="error">{{errorMessage}}</p>
          </div>
          <div class="modal-footer">
            <button id="btnAddFinishings"
                    type="button"
                    class="btn"
                    v-bind:class="{ disabled: !enableAddButton }"
                    @click="add">{{$t("project.CreateButton")}}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal finishings-->
    <div id="modalDeleteFinishings"
         class="modal fade"
         tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button"
                    class="close"
                    data-dismiss="modal">&times;</button>
            <h4 id="modalDeleteFinishingsTitle"
                class="modal-title">{{$t("project.DeleteFinishingsTitle")}}</h4>
          </div>
          <div class="modal-body">
            <p>{{$t("project.DeleteFinishingsHelp")}}</p>
          </div>
          <div class="modal-footer">
            <button id="btnDeleteFinishings"
                    class="btn"
                    @click="deleteFinishing">{{$t("project.DeleteButton")}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
'use strict'
import fieldCodeUtils from '@/utils/fieldCodeUtils'
import { TemplateService } from '@/services/DataWS/templateService'
import sortUtils from '@/utils/sortUtils'

const templateService = new TemplateService()
export default {
  name: 'fiModal',
  props: ['functions', 'option'],

  data () {
    return {
      typecode: '',
      errorMessage: 'This field is required.',
      valid: false
    }
  },

  computed: {

    enableAddButton () {
      return this.valid && !this.$parent.loading
    },
    finishTypeList: {
      get () {
        let data = this.$store.getters.getTemplateField('finishtypeList')
        return data.sort((a, b) => sortUtils.anotherNaturalSort(a.desc, b.desc))
      },
      set (value) {
        this.$store.dispatch('setTemplateField', { key: 'finishtypeList', value: value })
      }
    }
  },
  watch: {
    'typecode': {
      handler: function () {
        this.validate()
      }
    }
  },

  mounted () {
    let options = this.$store.getters.getTemplateField('FiTypeCode')
    if (!options) {
      // lock
      this.$store.dispatch('setTemplateField', { key: 'FiTypeCode', value: 'tempData' })

      let projectID = this.$route.params.projectId
      templateService.getFiTypeCode(projectID).then(v => {
        let field = v.controls[0].fields.find(f => { return f.key === 'FiTypeCode' })
        this.$store.dispatch('setTemplateField', { key: 'FiTypeCode', value: field })
        this.handleList(field)
      }).catch(err => {
        console.log(err)
      })
    }
  },

  methods: {
    handleList (field) {
      let matrixcodeList = field.options.map(v => { return fieldCodeUtils.transformItem(this, v) })
      this.finishTypeList = matrixcodeList
    },
    checkSubmit (evt) {
      if (evt.key === 'Enter' && this.valid) {
        this.add()
      }
    },
    validate () {
      let FiTypeField = {
        key: 'FiTypeCode',
        requiredBySystem: true,
        requiredByUser: true,
        maxLength: 10
      }
      this.$validateEditor(FiTypeField, this.typecode)
        .then(() => {
          this.valid = true
        }).catch(reason => {
        // extract reason and set valid to false
          if (typeof reason.message === 'undefined') {
            console.error(reason)
          }
          this.valid = false
          this.errorMessage = reason.message
        })
    },
    add () {
      return this.functions.add({ typecode: this.typecode })
    },
    resetDefault () {
      this.typecode = ''
      this.errorMessage = 'This field is required.'
    },
    deleteFinishing () {
      return this.functions.delete()
    }
  }
}
</script>
