import moment from 'moment'
import Vue from 'vue'
import $ from 'jquery'
import arrayUtils from '@/utils/arrayUtils'
import $store from '@/vuex/store'
import { GaugingTubeService } from '@/services/DataWS/gaugingTubeService'
const gaugingTubeService = new GaugingTubeService()

export default {
  gotolist (self) {
    // todo: change path for GaugingTube
    let path = `/project/${self.options.projectId}/measurementPoint`
    self.$router.push(path)
  },
  open (self) {
    $('#modalAddGaugingTube').modal('toggle')
      .on('shown.bs.modal', function (e) {
        $('#txtGaugingTubeName').focus()
      })

    let GaugingTubes = self.options.data
    Vue.set(self.suggestedNames, self.options.type, arrayUtils.getDefaultName([...GaugingTubes], 'FtName', 'FtID'))

    let last = GaugingTubes[GaugingTubes.length - 1]
    let create = self.$children.find(v => v.$options.name === 'gaModal') || {}
    create.from = (last && last.FtFilterTo) || 0
    create.to = ''
    create.validationError_name = ''
    create.validationError_to = ''
    create.resetDefault()
  },
  delete (self) {
    self.loading = true
    $('#modalDeleteGaugingTube').modal('hide')

    let ftGuid = $store.state.workingObject.FtGuid
    let ftID = $store.state.workingObject.FtID
    let gaugingTubes = $store.state.gaugingTubes
    let remainingGaugingTubes = gaugingTubes.filter(ft => ft.FtGuid !== ftGuid)
    $store.commit('setGaugingTubes', remainingGaugingTubes)

    // Sorting and assigning new FtIDs
    remainingGaugingTubes.sort((a, b) => a.FtID - b.FtID)
    remainingGaugingTubes.forEach((ft, index) => {
      ft.FtID = `${index + 1}`
    })

    const maxFtID = Math.max(...remainingGaugingTubes.map(ft => +ft.FtID))
    ftID = (ftID > maxFtID) ? maxFtID : ftID

    gaugingTubeService.deleteGaugingTube(self.$route.params.projectId, self.$route.params.measurementPointId, $store.state.workingObject.FtGuid).then(res => {
      const workingObject = remainingGaugingTubes.length > 0 ? remainingGaugingTubes[ftID - 1] : remainingGaugingTubes[0]
      if (workingObject ?? false) {
        $store.commit('setWorkingObject', workingObject)
      }
      self.loading = false
      if ($store.state.gaugingTubes.length >= 1) {
        self.$router.push({
          name: 'gaugingTubes',
          params: {
            'projectId': self.$route.params.projectId,
            'measurementPointId': self.$route.params.measurementPointId,
            'FtID': `${ftID}`
          }
        })
      } else {
        self.$router.push({
          name: 'measurementPoint',
          params: {
            'projectId': self.$route.params.projectId,
            'measurementPointId': self.$route.params.measurementPointId
          }
        })
      }
    }, err => {
      $('#modalDeleteGaugingTube').modal('toggle')
      self.loading = false
      console.log(err)
    })
  },
  add (self, data) {
    if (self.loading) return
    self.loading = true

    let now = moment().format('YYYY-MM-DDTHH:mm:ss')
    let newitem = {
      'MpID': '1',
      'FtID': '1',
      'FtName': data.name,
      'FtFilterFrom': data.from,
      'FtFilterTo': data.to,
      'FtDateLastChanged': now
    }

    let gaugingTubes = $store.state.gaugingTubes.filter(v => v.type !== 'emptyBox')
    gaugingTubes.push(newitem)

    let prid = self.$route.params.projectId
    let mpguid

    if (isNaN(self.$route.params.measurementPointId)) {
      mpguid = self.$route.params.measurementPointId
    } else {
      mpguid = $store.state.measurementPoints.find(measurementpoint => measurementpoint.MpID === self.$route.params.measurementPointId).MpGuid
    }

    gaugingTubeService.createGaugingTube(prid, mpguid, gaugingTubes)
      .then(gaugingTubes => {
        $store.commit('setGaugingTubes', gaugingTubes)
        self.loading = false
        $('#modalAddGaugingTube').modal('hide')
        let realParent = self.$parent
        if (realParent.$options.name === 'syncScroll') {
          realParent = realParent.$parent
        }
        if (realParent.$options.name === 'measurementPoint' || realParent.$options.name === 'gaugingTubes') {
          realParent.format && realParent.format(false)
        } else {
          console.warn('Can not format [' + realParent.$options.name + ']')
        }

        let maxID = Math.max(...gaugingTubes.map(ga => ga.FtID))
        let gaugingTube = gaugingTubes.find(ga => ga.FtID === maxID.toString())

        return gaugingTube
      })
      .then((gaugingTube) => {
        self.loading = false
        let path = '/project/' + prid + '/measurementPoint/' + mpguid + '/gaugingTubes/' + gaugingTube.FtID + '?new=true'
        self.$router.push(path)
      })
      .catch(err => {
        console.error('Catch: ', err)
        $('#modalAddGaugingTube').modal('hide')
        self.loading = false
      })
  }
}
