<template>
  <div :id="'editor' + id" class="trajectory" @keyup="keyboardArrow">
    <div class="input-group input-left">
      <input type="number" class="form-control" :class="{errorBorder: !valid}" :value="validatedValueAsString.first" :title="validatedValueAsString.first" readonly @click="$parent.expandPanel">
      <input type="number" class="form-control" :class="{errorBorder: !valid}" :value="validatedValueAsString.second" :title="validatedValueAsString.second" readonly @click="$parent.expandPanel">
    </div>
    <i v-if="field.required" class="required">*</i>
    <div class="editor-panel hidden animated">
      <div class="close" @click="$parent.hidePanel(valid)">
        <span class="glyphicon glyphicon-chevron-right"></span>
      </div>
      <div class="row">
        <div class="col-md-4" :class="{greyed: (inputmethod !== 'trajectory')}" @click="enableInputMethod('trajectory', 'first')">
          <label class="field-name">{{$t(translateOne)}}</label>
          <div class="has-feedback">
            <input v-bind:id="'first_' + id" v-model="currentValue.first" type="text" class="form-control" v-bind:readonly="field.rawOptions.first_isReadOnly" :class="{'disabled-field': field.rawOptions.first_isReadOnly}" @focus="inputmethod = 'trajectory'" @click="setFocus('first')">
            <span v-if="inputmethod === 'trajectory' && !field.rawOptions.first_isReadOnly" class="form-control-feedback glyphicon glyphicon-remove" @click="emptyValue('first')"></span>
          </div>
        </div>
        <div class="col-md-4" :class="{greyed: (inputmethod !== 'trajectory')}" @click="enableInputMethod('trajectory', 'second')">
          <label class="field-name">{{$t(translateTwo)}}</label>
          <div class="has-feedback">
            <input :id="'second_' + id" v-model="currentValue.second" type="text" class="form-control" v-bind:readonly="field.rawOptions.second_isReadOnly" :class="{'disabled-field': field.rawOptions.second_isReadOnly}" @focus="inputmethod = 'trajectory'" @click="setFocus('second')">
              <span v-if="inputmethod === 'trajectory' && !field.rawOptions.second_isReadOnly" class="form-control-feedback glyphicon glyphicon-remove" @click="emptyValue('second')"></span>
          </div>
        </div>
      </div>
      <div v-if="field.inputControlOptions.InputMethodLength" class="row">
        <div class="col-md-4" :class="{greyed: (inputmethod !== 'length')}" @click="enableInputMethod('length', 'height')">
          <label class="field-name">{{$t("label.Height")}}</label>
          <div class="has-feedback">
            <input v-if="inputmethod === 'trajectory'" v-model="computedHeight" type="text" class="form-control">
            <input v-if="inputmethod === 'length'" v-bind:id="'height_' + id" v-model="height" type="text" class="form-control" @focus="inputmethod = 'length'" @click="setFocus('height')">
            <span  v-if="inputmethod === 'length' && !field.rawOptions.first_isReadOnly" class="form-control-feedback glyphicon glyphicon-remove" @click="height = 0"></span>
          </div>
        </div>
        <div class="col-md-4" :class="{greyed: (inputmethod !== 'length')}" @click="enableInputMethod('length', 'length')">
          <label class="field-name">{{$t("label.Length")}}</label>
          <div class="has-feedback">
            <input v-if="inputmethod === 'trajectory'" v-model="computedLength" type="text" class="form-control">
            <input v-if="inputmethod === 'length'" v-bind:id="'length_' + id" v-model="length" type="text" class="form-control" @focus="inputmethod = 'length'" @click="setFocus('length')">
            <span v-if="inputmethod === 'length' && !field.rawOptions.second_isReadOnly" class="form-control-feedback glyphicon glyphicon-remove" @click="length = 0"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-9">
          <div class="has-feedback">
            <label v-if="!valid" class="error">{{errorMessage}}</label>
          </div>
        </div>
      </div>
      <div class="num-box">
        <div class="row">
          <div class="item" @click="boxClicked('1')">1</div>
          <div class="item" @click="boxClicked('2')">2</div>
          <div class="item" @click="boxClicked('3')">3</div>
          <div class="item" @click="boxClicked('Backspace')"><span class="glyphicons glyphicons-delete"></span></div>
        </div>
        <div class="row">
          <div class="item" @click="boxClicked('4')">4</div>
          <div class="item" @click="boxClicked('5')">5</div>
          <div class="item" @click="boxClicked('6')">6</div>
        </div>
        <div class="row">
          <div class="item" @click="boxClicked('7')">7</div>
          <div class="item" @click="boxClicked('8')">8</div>
          <div class="item" @click="boxClicked('9')">9</div>
        </div>
        <div class="row">
          <div v-if="options.allowDigits" class="item" @click="boxClicked('.')">.</div>
          <div v-else class="item disabled">.</div>
          <div class="item" @click="boxClicked('0')">0</div>
          <div v-if="options.allowMinus" class="item" @click="boxClicked('-')">-</div>
          <div v-else class="item disabled">-</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
'use strict'

import _ from 'lodash'
import $ from 'jquery'
import editorUtils from '@/utils/editorUtils'

/**
   * Editors with which users can set the value of two properties that are a trajectory (e.g. trajectory of a layer).
   */
export default {
  name: 'trajectoryEditor',
  props: ['field', 'functions', 'options'], // options.allowDigits, options.allowMinus

  data () {
    return {
      id: this._uid,
      currentValue: {
        first: '',
        second: ''
      },
      errorMessage: null,
      valid: true,

      title: String,
      focusKey: String,
      isSelfChange: false,
      translateOne: 'label.from',
      translateTwo: 'label.to',
      inputmethod: 'trajectory',
      height: 0,
      length: 0
    }
  },

  computed: {
    computedHeight: function () {
      // Recalculate the Height when the from changes using the formula: Height = -From
      return -parseFloat(this.currentValue.first)
    },
    computedLength: function () {
      // Recalculate the Length when the To or From changes using the formula:  Length = To - From
      return (parseFloat(this.currentValue.second) - parseFloat(this.currentValue.first))
    },
    /**
       * Computed property used to get and set the validated value of the property corresponding to this instance of the TrajectoryEditor.
       */
    validatedValue: {
      get () {
        // getEditorValue return the concatenated value (for example 'ZK1G2') and sets the underlying object in
        // this.field.rawData
        editorUtils.getEditorValue(this.field)
        let val = this.field.rawData
        this.initCurrentValue(val)
        return val
      },

      set (value) {
        let result = editorUtils.setEditorValue(this.field, value)

        if (result.hasChanges) {
          this.$store.commit('updateWorkingObject', result.values)
        }
      }
    },

    validatedValueAsString: {
      get () {
        let val = this.validatedValue
        return {
          first: val.first === undefined ? '' : val.first + '',
          second: val.second === undefined ? '' : val.second + ''
        }
      }
    },
    /**
       * Computed property used to get and set whether the validation of the current value has finished.
       */
    activeEditorValidationPending: {
      get () {
        return this.$store.state.activeEditorValidationPending
      },
      set (val) {
        this.$store.commit('setActiveEditorValidationPending', val)
      }
    }
  },

  watch: {
    'height': {
      handler: function (val) {
        // Recalculate the To and From when the height changes using the forumlas: From = -Height and To = (-Height) + Length
        this.currentValue.first = (-parseFloat(val)).toString()
        this.currentValue.second = (((-parseFloat(val)) + parseFloat(this.length))).toString()
      },
      immediate: true
    },
    'length': {
      handler: function (val) {
        // Recalculate the To and From when the length changes using the forumlas: From = -Height and To = (-Height) + Length
        this.currentValue.first = (-parseFloat(this.height)).toString()
        this.currentValue.second = (((-parseFloat(this.height)) + parseFloat(val))).toString()
      },
      immediate: true
    },
    'inputmethod': {
      // We need update the previously hidden input fields with the actual
      handler: function (val) {
        if (val === 'length') {
          this.height = this.computedHeight
          this.length = this.computedLength
        }
      },
      immediate: true
    },
    'currentValue': {
      handler: function (val) {
        // set activeEditorValidationPending to true, so that navigation is blocked until validation is done
        this.activeEditorValidationPending = true

        // clean/handle characters from 'first value' that are not allowed
        let cleanedFirstValue = this.handleDecimalValue(val.first)
        cleanedFirstValue = this.handleNegativeValue(cleanedFirstValue)

        // clean/handle characters from 'second value' that are not allowed
        let cleanedSecondValue = this.handleDecimalValue(val.second)
        cleanedSecondValue = this.handleNegativeValue(cleanedSecondValue)

        // if cleaning has changed the values, then set them to currentValue resulting them to be validated in the
        // next tick
        if (cleanedFirstValue !== val.first || cleanedSecondValue !== val.second) {
          this.currentValue = { first: cleanedFirstValue, second: cleanedSecondValue }
          // really ugly way to overwrite value in input control, but there is no other way to do this when the
          // current field is focused
          $('#first_' + this.id).val(cleanedFirstValue)
          $('#second_' + this.id).val(cleanedSecondValue)
        } else {
          this.validateCurrentValue(this.currentValue)
        }
      },
      immediate: true,
      deep: true
    }
  },

  mounted () {
    this.title = this.field.title
    this.isSelfChange = false

    this.translateOne = 'label.' + this.field.rawOptions.translateOne
    this.translateTwo = 'label.' + this.field.rawOptions.translateTwo
    if (this.field.key === 'FiFrom') {
      this.options.allowMinus = true
    }
  },

  methods: {
    initCurrentValue (val) {
      // overwrite current value with 'valid' value from store and set valid to true. get() is called in the following cases:
      // - when the value of this.$store.state.workingObject[this.field.key] changed (e.g. because side effect or switching workingObject)
      // - when initializing the editor
      this.currentValue = { first: val.first, second: val.second }
      this.valid = true
    },
    enableInputMethod (inputmethod, field) {
      this.inputmethod = inputmethod
      this.setFocus(field)
    },
    emptyValue (key) {
      // reset value in input
      $('#' + key + '_' + this.id).val('')
      // reset value in this.currentValue
      this.currentValue[key] = null
    },
    validateCurrentValue: _.debounce(function (val) {
      // validate field and value using TerraIndexValidator
      this.$validateEditor(this.field, val).then(() => {
        // set valid to true and set val to validatedValue as validation has succeeded
        this.valid = true
        this.validatedValue = { first: val.first, second: val.second }
      }).catch(reason => {
        // extract reason and set valid to false
        if (typeof reason.errorMessage === 'undefined') {
          console.error(reason)
        }
        // reset validated values
        this.valid = false
        this.errorMessage = reason.message
      }).finally(() => {
        // validation has finished
        this.activeEditorValidationPending = false
      })
    }, 500),

    handleDecimalValue (value) {
      // if value is not defined (value is string, so we can do it like this)
      if (!value) return value

      // if we allow digits, then use .'s else remove them from the string
      let replaceCharacter = this.options.allowDigits ? '.' : ''

      // first replace all ,'s to .'s and then replace all .'s by the replace character
      return value.replace(',', '.').replace('.', replaceCharacter)
    },
    handleNegativeValue (value) {
      // if value is not defined (value is string, so we can do it like this)
      if (!value) return value

      // if we dont allow negative values, then replace the minus from the string
      if (!this.options.allowMinus) {
        value = value.replace('-', '')
      } else {
        let count = [...value].filter(char => char === '-').length

        value = value.replace(new RegExp('-', 'g'), '')

        // if count is 1, then make sure the minus is at the front of the string
        // if it is not 1 (e.g. 2), then convert to positive integer
        if (count === 1) {
          value = '-' + value
        }
      }

      return value
    },
    setFocus (key) {
      this.focusKey = key
    },
    keyboardArrow (evt) {
      evt = (evt) || window.event
      let keyCode = evt.keyCode || evt.which

      if (keyCode) {
        if (keyCode === 38) {
          // up arrow
          if (this.focusKey === 'first') {
            evt.stopPropagation()
            return
          }
          this.focusKey = 'first'

          $('#first_' + this.id).focus()

          evt.stopPropagation()
        } else if (keyCode === 40) {
          // down arrow
          if (this.focusKey === 'second') {
            evt.stopPropagation()
            return
          }

          this.focusKey = 'second'

          $('#second_' + this.id).focus()

          evt.stopPropagation()
        }
      }
    },
    boxClicked (inputChar) {
      // key of currently selected input in this.currentValue
      let key = this.focusKey

      // if currently selected field is read only, then do not allow to edit
      if (this.field.rawOptions.first_isReadOnly && (key === 'first')) {
        return
      }
      if (this.field.rawOptions.second_isReadOnly && (key === 'second')) {
        return
      }

      // set activeEditorValidationPending to true, so that navigation is blocked until validation is done
      this.activeEditorValidationPending = true

      // if input char is a character, then add, else remove last character from currentValue
      if (inputChar !== 'Backspace') {
        if (this.inputmethod === 'trajectory') {
          this.currentValue[key] = this.currentValue[key] ? this.currentValue[key] + inputChar : inputChar
        } else if (this.inputmethod === 'length') {
          if (key === 'height') { this.height = this.height ? this.height + inputChar : inputChar }
          if (key === 'length') { this.length = this.length ? this.length + inputChar : inputChar }
        }
      } else {
        if (this.inputmethod === 'trajectory') {
          this.currentValue[key] = this.currentValue[key].slice(0, -1)
        } else if (this.inputmethod === 'length') {
          if (key === 'height') { this.height = this.height.toString().slice(0, -1) }
          if (key === 'length') { this.length = this.length.toString().slice(0, -1) }
        }
      }
    },
    /**
       * Focus on the first field that is not read only.
       */
    focus () {
      if (this.field.rawOptions.first_isReadOnly) {
        let second = $('#second_' + this.id)
        this.focusKey = 'second'
        second.focus()
      } else {
        this.focusKey = 'first'
        $('#first_' + this.id).focus()
      }
    }
  }
}

</script>

<style lang="less">
  .trajectory {
    .input-left input {
      width: 45%;
      border-radius: 0;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0;
      }
      &[type="number"] {
        -moz-appearance: textfield;
      }
      &+input {
        float: right;
      }
    }
    .row {
      max-width: 600px;
    }
  }

  .greyed{
    opacity: 0.7;
  }

  .num-box {
    padding-right: 15px;
    padding-left: 13px;
    margin-top: 30px;
    .row {
      height: 74px;
    }
    .item {
      width: 70px;
      height: 70px;
      background: #67ac45;
      margin: 2px;
      color: #fff;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      float: left;
      cursor: pointer;
      transition: 0.5s;
      &:hover {
        cursor: pointer;
        transform: scale(0.90);
      }
    ;
      &.disabled {
        opacity: 0.7;
        cursor: not-allowed;
        display: flex !important;
      }
    }
  }
  .disabled-field{
    opacity: 0.8 !important;
    pointer-events: none;

  }

</style>
