<template>
  <!--Modal add user-->
  <div id="modalSelectUser" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">{{$t("project.AddSelectedUserTitle")}}</h4>
        </div>
        <div class="modal-body">
          <p>{{$t("project.AddSelectedUserHelp")}}</p>
          <div class="search">
            <search ref="search-component" @search="search" />
          </div>
          <div class="project-table"
               v-bind:class="{ 'displayNone': !query}">
            <v-client-table :data="users"
                            :columns="columns"
                            :options="options"
                            class="selectableTable"
            >
                <div slot="selected" slot-scope="props" class="unselectable checkBoxWrapper" v-on:click="toggleSelected(props.row)">
                    <input type="checkbox" />
                    <label v-bind:class="{ checked:props.row.check }" >
                        <span></span>
                    </label>
                </div>
            </v-client-table>
          </div>
        </div>
        <div class="modal-footer">
          <button id="btnAddSelectedUser" class="btn" @click="add">{{$t("label.select")}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Vue from 'vue'
import dateFormatter from '@/utils/dateFormatter'
import search from '@/components/_shared/search'
import { ProjectUserService } from '@/services/DataWS/projectUserService'

const projectUserService = new ProjectUserService()

let tableOptions = {
  SearchFilter: '',
  PageSize: 30,
  LastRowNR: 0,
  OrderPreviewBy: 0
}

export default {
  name: 'userModal',

  components: {
    search
  },

  props: ['functions', 'option'],

  data () {
    const options = {
      formatValue: (row, column) => {
        dateFormatter.ready()
        return dateFormatter.formatIfDate(row[column], column)
      },
      nocheckbox: false,
      headings: {
        selected: '',
        Function: this.$t('columnTitles.Function'),
        FirstName: this.$t('columnTitles.FirstName'),
        UserName: this.$t('columnTitles.UserName'),
        PersonName: this.$t('columnTitles.PersonName')
      },
      columnsDisplay: {
        Function: 'not_mobile',
        FirstName: 'not_mobile'
      },
      filterable: false,
      perPage: 1000,
      perPageValues: [1000], // hacky way to not show dropdown
      customFilters: [],
      pagination: {
        dropdown: false
      }
    }
    return {
      validationError: '',
      name: '',
      step: 1,
      columns: ['selected', 'PersonName', 'UserName'],
      allUsersUnpinned: [], // get once when popping up
      users: [], // filter users to get showing users
      table_options: tableOptions,
      options: options,
      query: null
    }
  },

  mounted () {
    var self = this
    $('#modalSelectUser').on('popupTrigger', function () {
      self.init()
    })
  },

  methods: {
    init () {
      projectUserService.getProjectUsersNotPinned(this.option.projectId).then(res => {
        this.loading = false
        this.allUsersUnpinned = res
        for (let i = 0; i < this.allUsersUnpinned.length; i++) {
          this.allUsersUnpinned[i].PrID = this.allUsersUnpinned[i].UserName // fake a prid to make checkboxes work
        }
        this.getProjectUsersNotPinnedWithFilter(this.table_options) // once all loaded filter
      })
    },
    getProjectUsersNotPinnedWithFilter (tableOptions) {
      let usersToShow = []
      let searchKeys = ['UserName', 'PersonName']
      let searchKeyCount = searchKeys.length
      if (tableOptions.SearchFilter === '') {
        usersToShow = this.allUsersUnpinned
      } else {
        this.allUsersUnpinned.forEach(function (user) {
          let found = false
          for (let i = 0; i < searchKeyCount; i++) {
            if (user[searchKeys[i]].toUpperCase().indexOf(tableOptions.SearchFilter.toUpperCase()) > -1) {
              found = true
              break
            }
          }
          if (found) usersToShow.push(user)
        })
      }
      // add checked
      usersToShow.forEach(function (user) {
        Vue.set(user, 'check', false)
      })
      this.users = usersToShow.sort(function (a, b) { // sort by username
        if (a.PersonName < b.PersonName) { return -1 }
        if (a.PersonName > b.PersonName) { return 1 }
        return 0
      })
    },
    select () {

    },
    add () {
      const checkedUsers = this.users.filter((user) => user.check)
      const promises = []

      checkedUsers.forEach((checkedUser) => {
        promises.push(projectUserService.pinUnpinUser({ PrID: this.option.projectId, UserName: checkedUser.UserName, Enabled: true })
          .then(() => {
            if (this.$parent.$parent.addPinnedUser) {
              this.$parent.$parent.addPinnedUser(checkedUser)
            }
          }))
      })

      Promise.all(promises).then(() => {
        this.$emit('pinnedUsersUpdated')
      })

      // close modal
      $('#modalSelectUser').modal('hide')
    },
    delete () {

    },
    search (query) {
      if (typeof query === 'object') {
        query = query.PrCode
      }
      this.query = query
      this.step = 1
      tableOptions.SearchFilter = this.query
      tableOptions.LastRowNR = 0
      this.loading = true
      this.getProjectUsersNotPinnedWithFilter(tableOptions)
    },
    toggleSelected (row) {
      let index = this.users.findIndex(u => u.UserID === row.UserID)
      let user = this.users[index]
      let checked = user.check

      if (!checked) {
        checked = true
      } else {
        checked = false
      }

      user.check = checked
      this.users[index] = user
    }
  }
}
</script>

<style lang="less" scoped>
  .project-table {
    height: 500px;
    overflow-y: scroll;

    .checkBoxWrapper {
      width: 100%;
      height: 100%;
      margin: -8px;
      padding: 8px;
    }

    input[type="checkbox"] {
      display: none;

      & + label {
        width: 20px;
        height: 20px;
        display: block;
        position: relative;

        span {
          display: inline-block;
          width: 20px;
          height: 20px;
          top: 3px;
          left: 5px;
          border: 1px solid #9d9d9d;
          position: absolute;
          background-color: #ffffff;
          cursor: pointer;
        }
      }
    }

    label.checked span {
      background-color: #67ac45 !important;
    }

    .VueTables__table > tbody > tr > td {
      &:first-child,
      &:last-child {
        cursor: default;
      }
    }
  }
</style>
