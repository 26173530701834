<template>
  <div :class="listId" :style="visibleList.length <= 0 ? 'display: none !important' : ''">
    <label
      class="list-group-item field-name list-title"
    >{{title}}</label>
    <div class="cardScroll">
      <ul v-for="(item, i) in visibleList" :key="i">
        <li
          v-if="item && item.SoilTypeGroup && i !== items.length -1 && (items[i++] && !items[i++].hasOwnProperty('GcDescription'))"
          :key="i"
          class="group"
        >{{item.GcDescription }}</li>
        <li
          v-else-if="item && !item.hasOwnProperty('GcDescription')"
          href="javascript:"
          :class="(item.value === selected) ? 'selected-item': ''"
          class="item"
          @click="setSelected(item.value)"
        >{{item.text[0][lang]}}</li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'texture-options-list',
  /*
   * title: the title of the list
   * items: a sorted list of codeItem that will be shown in the list
   * selected: the database code of the selected item in the list
   * listId: the unique identifier for the list
   * lang: a three letter language code if the logged in language
  */
  props: ['title', 'items', 'selected', 'listId', 'lang', 'currentValue', 'originalSource'],
  data: () => ({
    currentItem: {},
    colColorCount: 0
  }),
  mounted () {
    this.bringSelectedIntoView()
  },

  computed: {
    visibleList () {
      let visibleItems = this.items
      if (
        (this.selected && this.selected !== -1 && visibleItems.filter((option) => { return option.value === this.selected }).length <= 0)) {
        let currentOption = this.originalSource.find((option) => { return this.selected === option.value })
        visibleItems.push(currentOption)
      }
      return visibleItems
    }
  },

  methods: {
    /**
     * When the list is loaded in the view and an Item is selected scroll the selected item in view
     */
    bringSelectedIntoView () {
      setTimeout(() => {
        document.querySelectorAll('.LaSoilTypeCode .selected-item').forEach(i => {
          i.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest'
          })
        })
      }, 150)
    },

    /** setSelected Values is called when an Item from the list has been selected
     * @params itemValue = the database value of the selected item
     * @ It emits the id of the list and de item value to the parent to update it's data
     */
    setSelected (itemValue) {
      // If the selected item is clicked this will be deselected
      if (this.selected === itemValue) {
        this.$emit('OnItemSelect', this.listId, '')
      } else {
        this.$emit('OnItemSelect', this.listId, itemValue)
      }
    }
  }
}
</script>

<style scoped>
.cardScroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: 44vh;
  width: 100%;
}

.list-title {
  border: 0;
  border-radius: 0;
  color: black !important;
  background-color:#d8d8d8;
  text-transform: capitalize;
  text-align: left;
}

/* This class will be added to an item dynamically after the item has been selected */
.selected-item {
  border: 0;
  border-radius: 0;
  color: white;
  background-color: #67ac45;
}

.group {
  border: 0;
  font-size: 0.85em;
  border-radius: 0;
  color: #000;
  font-weight: bold;
  text-align: center;
  background-color: rgba(168, 212, 163, 0.5);
}

.item {
  text-align: left;
  text-transform: capitalize;
}
.item:hover {
  cursor: pointer;
  color: #67ac45;
  background: rgba(168, 212, 163, 0.5);
}

ul:nth-child(odd) {
  border: 0;
  border-radius: 0;
  background-color: #ffff;
}

ul:nth-child(even) {
  border: 0;
  border-radius: 0;
  background-color: #eeeeee;
}
</style>
