<template>
  <div class="editor">
    <!-- Modal -->
    <div :id="'modalCloseEdit' + modalId" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <a class="close" data-dismiss="modal">&times;</a>
            <h4 class="modal-title">{{$t("message.validate_error_action_confirm_title")}}</h4>
          </div>
          <div class="modal-body">
            {{$t("message.validate_error_action_confirm_desc")}}
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">{{$t("label.cancel")}}</button>
            <button class="btn" @click="continueNavigation">{{$t("label.continue")}}</button>
          </div>
        </div>
      </div>
    </div>

    <component :is="field.component" v-if="field" :options="field.editorOptions" :field="field" :functions="functions" keep-alive></component>
  </div>
</template>

<script>
'use strict'

import $ from 'jquery'
import textEditor from '@/components/input-controls/textEditor.vue'
import textAreaEditor from '@/components/input-controls/textareaEditor.vue'
import numberEditor from '@/components/input-controls/numberEditor.vue'
import labelEditor from '@/components/input-controls/labelEditor.vue'
import projectCodeEditor from '@/components/input-controls/projectCodeEditor.vue'
import textureCodeEditor from '@/components/input-controls/textureCodeEditor.vue'
import colourEditor from '@/components/input-controls/colourEditor.vue'
import booleanEditor from '@/components/input-controls/booleanEditor.vue'
import trajectoryEditor from '@/components/input-controls/trajectoryEditor.vue'
import barcodeEditor from '@/components/input-controls/barcodeEditor.vue'
import twoListEditor from '@/components/input-controls/twoListEditor.vue'
import specCompEditor from '@/components/input-controls/specCompEditor.vue'
import dateEditor from '@/components/input-controls/dateEditor.vue'
import listEditor from '@/components/input-controls/listEditor.vue'
import coordinatesEditor from '@/components/input-controls/coordinatesEditor.vue'
import panelUtil from '@/utils/panelUtil'
import imageEditor from '@/components/input-controls/imageEditor.vue'

const subLocationListEditor = () => import(/* webpackChunkName: "subLocationListEditor" */ '@/components/input-controls/subLocationListEditor.vue')

export default {
  name: 'basicEditor',

  components: {
    textEditor,
    textAreaEditor,
    numberEditor,
    labelEditor,
    projectCodeEditor,
    dateEditor,
    listEditor,
    textureCodeEditor,
    colourEditor,
    booleanEditor,
    trajectoryEditor,
    barcodeEditor,
    twoListEditor,
    specCompEditor,
    coordinatesEditor,
    subLocationListEditor,
    imageEditor
  },

  props: ['field', 'functions'],

  data () {
    return {
      modalId: this._uid,
      isActive: Boolean,
      continueEditor: null,
      continueRoutePath: null
    }
  },

  methods: {
    expandPanel () {
      if (this.$children[0].focus) {
        // set active editor WITHOUT trying to automatically find field to focus on
        this.$parent.setActiveEditor(this, false)

        this.$children[0].focus()
      } else {
        // set active editor WITH trying to automatically find field to focus on
        this.$parent.setActiveEditor(this, true)
      }
    },

    hidePanel (valid) {
      if (valid) {
        panelUtil.hidePanel(this.$children[0].id)
      } else {
        this.confirmEditShow()
      }
    },

    unfocus (editor, routePath) {
      if (this.isActive && this.$children[0].valid === false) {
        this.confirmEditShow()
        this.continueEditor = editor
        this.continueRoutePath = routePath
        return false
      } else {
        if (typeof this.$children[0].unfocus === 'function') {
          this.$children[0].unfocus()
        }
        return true
      }
    },

    confirmEditShow () {
      $('#modalCloseEdit' + this.modalId).modal('show')
    },

    confirmEditHide () {
      $('#modalCloseEdit' + this.modalId).modal('hide')
    },

    continueNavigation () {
      if (this.$children[0].validatedValue === undefined) {
        console.error(this.$children[0], this.$children[0].$options.propsData.field.component, "Editor has no 'validatedValue' property, so can not reset.")
      }
      this.$children[0].currentValue = this.$children[0].validatedValue
      this.$children[0].errorMessage = ''
      this.$children[0].valid = true
      this.confirmEditHide()
      if (this.continueEditor) {
        this.$parent.setActiveEditor(this.continueEditor)
      } else if (this.continueRoutePath) {
        this.$router.push(this.continueRoutePath)
      }
    }

  }
}
</script>

<style>
  .editor-panel {
    height: calc(100vh - 120px);
    width: 100vw;
  }

  .editor-panel .panel-list .list-group {
    max-height: calc(100vh - 320px);
  }

  .errorBorder, .errorBorder:focus{
    border: 1px solid red;
    outline: none;
  }

  .warnBorder, .warnBorder:focus{
    border: 1px solid #ffc400;
    outline: none;
  }

  .editor-panel .texture-list {
    height: calc(100vh - 330px);
  }

  @media (min-width:768px) {
    .editor-panel {
      width: calc(100vw - 210px);
    }
  }
  /*@media (max-width:991px) {
        .m-t-sm{margin-top:10px}
    }*/
</style>
<style lang="less">
  html {
    overflow: hidden
  }

  i.required {
    color: red
  }

  .editor-panel {
    position: fixed;
    right: 0;
    background: #515151;
    top: 60px;
    padding: 50px 40px 10px 40px;
    z-index: 12;
    .close {
      position: absolute;
      left: 10px;
      top: 10px;
      font-size: 30px;
      color: #eee;
      opacity: 1;
    }
    .form-control {
      border-radius: initial;
    }
    .field-name {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .field-sub-name {
      color: #fff;
      font-size: 14px;
      font-weight: 300;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .field-sub-sub-name {
      color: #fff;
      font-size: 12px;
      font-weight: 300;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .has-feedback {
      max-width: 500px;
      input {
        margin-bottom: 5px
      }
      label {
        font-weight: 400;
        display: block;
        &.error {
          color: #fff;
          background-color: #bf3232;
          padding: 5px;
          padding-left: 10px;
          padding-right: 10px;
        }
        &.error:empty {
          background-color: transparent;
        }
        &.warning {
          color: #fff;
          background-color: #ffc400;
          padding: 5px;
          padding-left: 10px;
          padding-right: 10px;
        }
        &.warning:empty {
          background-color: transparent;
        }
      }
      .form-control-feedback {
        pointer-events: initial;
        cursor: pointer;
      }
      textarea.form-control {
        resize: vertical;
      }
    }
    .panel-list {
      margin-top: 15px;
      border-radius: initial;
      .panel-heading {
        background: #d8d8d8;
        font-weight: bold;
        font-size: 16px;
        height: 40px;
        border-radius: initial;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .glyphicon-plus:hover {
          cursor: pointer;
        }
      }
      .list-group {
        overflow-y: auto;
        a {
          color: #000
        }
        .list-group-item {
          height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:nth-child(2n) {
            background: #eee
          }
          &:hover {
            color: #67ac45;
            background: rgba(168, 212, 163, 0.5);
          }
          &.actived {
            color: #67ac45;
            background: rgba(168, 212, 163, 0.5);
          }
          &.selected {
            font-weight: 600;
            text-shadow: initial;
            background: #67ac45;
            border: #67ac45 1px solid;
            color: #fff;
            padding-right: 60px;
            >span {
              position: absolute;
              right: 3px;
            }
          }
        }
      }
    }
  }

  .texture-code .editor-panel {
    padding: 50px 0 10px 40px;
    .item-discription {
      background: #67ac45;
      margin: 25px 0;
      min-height: 24px;
      line-height: 24px;
      color: #fff;
      padding: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      z-direction: rtl;
      flex: 1
    }
    .discription-box {
      z-max-width: 500px;
      padding-right: 50px;
      position: relative;
      .glyphicons-delete {
        z-position: absolute;
        z-right: 0;
        top: -6px;
        font-size: 35px;
        color: #fff;
        margin: 20px 0;
      }
    }
    .texture-list {
      min-width: 480px;
      overflow: auto;
      .item {
        color: #fff;
        float: left;
        margin-right: 5px;
        ul {
          list-style: none;
          padding: 0;
          margin: 0 5px 0 0;
          float: left;
          li {
            width: 50px;
            height: 50px;
            background: #67ac45;
            margin: 5px 0;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            &.is-disabled {
              opacity: .6;
              pointer-events: none;
              user-select: none;
            }
            p {
              margin: 0
            }
          }
        }
      }
    }
  }

  .toggle.ios,
  .toggle-on.ios,
  .toggle-off.ios {
    border-radius: 20px;
  }

  .toggle.ios .toggle-handle {
    border-radius: 20px;
  }

  @media (min-width:640px) {
    .input-left {
      width: 170px
    }
  }

  .editor.warn {
    .editor-panel {
      .has-feedback {
        .error {
          color: #ffc400;
        }
      }
    }
  }
</style>
