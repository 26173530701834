import $ from 'jquery'
import config from '@/configurations/config.js'

export default {
  open () {
    $(`#modal-${config.constants.MediaClassification.LabCertificates}`).modal('show')
      .on('shown.bs.modal', function (e) {
        $('#txtname').focus()
      })
  }
}
