<template>
  <div :id="'editor' + id">
    <div class="input-group input-left">
      <input type="text" class="form-control" readonly :value="validatedValueAsString" :title="validatedValueAsString" @click="$parent.expandPanel">
    </div>
    <i v-if="field.required" class="required">*</i>

    <div class="editor-panel hidden animated">
      <div class="close" @click="$parent.hidePanel(true)">
        <span class="glyphicon glyphicon-chevron-right"></span>
      </div>
      <div class="row">
        <div class="col-md-7">
          <label class="field-name">{{title}}</label>
          <div class="has-feedback">
            <input type="text" class="form-control unselectable" readonly :value="validatedValueAsString" @click="$parent.expandPanel">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
'use strict'

import editorUtils from '@/utils/editorUtils'

/**
   * Editor that shows the value of a property. It does NOT allow editing of the value.
   */
export default {
  name: 'labelEditor',
  props: ['field', 'functions', 'options'],

  data () {
    return {
      id: this._uid,
      title: String
    }
  },

  computed: {
    validatedValue: {
      get () {
        return editorUtils.getEditorValue(this.field)
      }
    },
    validatedValueAsString: {
      get () {
        return this.validatedValue === undefined ? '' : this.validatedValue + ''
      }
    }
  },

  mounted () {
    this.title = this.field.title
  },

  methods: {}
}
</script>

<style scoped>
  span {
    box-sizing: none;
  }
  .unselectable{
    cursor: default;
    border: 0;
    box-shadow: none;
  }
</style>
