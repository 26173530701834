import config from '@/configurations/config.js'

export default {
  open (self) {
    window.location = config.apps.labassignment + '1?PrID=' + self.$route.params.projectId + '&create=true'
  },

  labCodeMap() {
    return [
      { id: 1, code: null, description: 'De Ruiter' },
      { id: 2, code: 'BI', description: 'Biochem Het MilieuLab' },
      { id: 3, code: null, description: 'Heinrici milieulaboratorium b.v.' },
      { id: 4, code: null, description: 'Pro Analyse' },
      { id: 5, code: 'OM', description: 'Omegam' },
      { id: 6, code: null, description: 'GCML' },
      { id: 7, code: 'AL', description: 'Alcontrol B.V.' },
      { id: 8, code: 'GR', description: 'Daniel C. Griffith (Holland) B.V.' },
      { id: 9, code: null, description: 'Oranjewoud' },
      { id: 10, code: null, description: 'Tjaden' },
      { id: 11, code: null, description: 'Witteveen + Bos' },
      { id: 12, code: 'TA', description: 'AL-West' },
      { id: 13, code: 'PR', description: 'Analytico' },
      { id: 14, code: null, description: 'BLGG' },
      { id: 15, code: 'BC', description: 'BCO' },
      { id: 16, code: null, description: 'CBB' },
      { id: 17, code: 'AC', description: 'Acmaa' },
      { id: 18, code: 'EN', description: 'Envirolab' },
      { id: 19, code: 'SG', description: 'SGS' },
      { id: 20, code: 'IW', description: 'Iwaco' },
      { id: 21, code: 'AS', description: 'Envirocontrol BVBA' },
      { id: 22, code: 'FC', description: 'Fibrecount' },
      { id: 23, code: 'RP', description: 'RPS Analyse' },
      { id: 24, code: 'SA', description: 'Sanitas Milieu Services' },
      { id: 25, code: 'SE', description: 'Search BV' },
      { id: 26, code: 'EC', description: 'Ecca NV' },
      { id: 27, code: 'SV', description: 'Servaco NV' },
      { id: 28, code: 'WP', description: 'Stichting Waterproef' }
    ]
  },

  getCodeObjectFromCode(codeMap, code) {
    const codeObject = codeMap.find((element) => element.code === code)
    return codeObject || { id: 0, code: '', description: code }
  }
}
