export default {
  type: 'ISO',
  defaultGroup: 8,
  categoryGroups: [
    { GcCode: 1, SortOrder: 1 }, // Organisch, ISO 14688
    { GcCode: 2, SortOrder: 2 }, // Zeer grove grond, ISO 14688
    { GcCode: 3, SortOrder: 3 }, // Grove grond, ISO 14688
    { GcCode: 4, SortOrder: 4 }, // Fijne grond, ISO 14688
    { GcCode: 5, SortOrder: 5 }, // Sedimentaire gesteenten, ISO 14689
    { GcCode: 6, SortOrder: 6 }, // Stollingsgesteenten, ISO 14689
    { GcCode: 7, SortOrder: 7 }, // Metamorfe gesteenten, ISO 14689
    { GcCode: 8, SortOrder: 8 } // Overige
  ],
  codes: [
    // { primaryGcCode: 'V', primaryRemark: 'Veen', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['E', 'H', 'T', 'O', 'C', , 'A', 'D'], qualifier: [], organic: null, tertiary: [], plasticity: [], decomposition: null, grainshape: [], form: [], surface: [], particlestrength: null, rockshape: [], rockweathering: [], consistency: null } },

    // Organisch, ISO 14688
    { categoryGroup: 1, sortOrderInGroup: 1, primaryGcCode: 'V', primaryRemark: 'Veen', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['L', 'V', 'M', 'E', 'H', 'T', 'O', 'C', 'A', 'D'], qualifier: ['2', '4'], organic: null, tertiary: null, plasticity: null, decomposition: ['AV1', 'AV2', 'AV3', 'VE1', 'VE2', 'VE3', '10'], grainshape: null, form: null, surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: ['5', '8', '9'] } },
    { categoryGroup: 1, sortOrderInGroup: 2, primaryGcCode: 'H', primaryRemark: 'Teelaarde of Humus', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['V', 'M', 'E', 'H', 'T', 'O', 'C', 'L', 'A', 'D'], qualifier: ['2', '4'], organic: null, tertiary: [], plasticity: null, decomposition: ['AV1', 'AV2', 'AV3', 'VE1', 'VE2', 'VE3', '10', '13'], grainshape: null, form: null, surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: ['5', '8', '9'] } },
    { categoryGroup: 1, sortOrderInGroup: 3, primaryGcCode: 'Y', primaryRemark: 'Gyttja', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['V', 'M', 'E', 'H', 'T', 'O', 'C', 'L', 'A', 'D'], qualifier: ['2', '4'], organic: null, tertiary: [], plasticity: null, decomposition: ['AV1', 'AV2', 'AV3', 'VE1', 'VE2', 'VE3', '10', '13'], grainshape: null, form: null, surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: ['5', '8', '9'] } },
    { categoryGroup: 1, sortOrderInGroup: 5, primaryGcCode: 'F', primaryRemark: 'Detritus', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['V', 'M', 'E', 'H', 'T', 'O', 'C', 'L', 'A', 'D'], qualifier: ['2', '4'], organic: null, tertiary: [], plasticity: null, decomposition: ['AV1', 'AV2', 'AV3', 'VE1', 'VE2', 'VE3', '10', '13'], grainshape: null, form: null, surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: ['5', '8', '9'] } },
    { categoryGroup: 1, sortOrderInGroup: 6, primaryGcCode: 'I', primaryRemark: 'Bruinkool organisch', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['V', 'M', 'E', 'H', 'T', 'O', 'C', 'L', 'A', 'D'], qualifier: ['2', '4'], organic: null, tertiary: [], plasticity: null, decomposition: ['AV1', 'AV2', 'AV3', 'VE1', 'VE2', 'VE3', '10', '13'], grainshape: null, form: null, surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: ['5', '8', '9'] } },

    // Zeer grove grond, ISO 14688
    { categoryGroup: 2, sortOrderInGroup: 2, primaryGcCode: 'E', primaryRemark: 'Keien', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['V', 'M', 'E', 'H', 'T', 'O', 'C', 'L', 'A', 'D'], qualifier: ['2', '4'], organic: ['2', '4'], tertiary: ['2'], plasticity: null, decomposition: null, grainshape: [], form: [], surface: [], particlestrength: null, rockshape: null, rockweathering: null, consistency: null } },
    { categoryGroup: 2, sortOrderInGroup: 3, primaryGcCode: 'P', primaryRemark: 'Keitjes', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['V', 'M', 'E', 'H', 'T', 'O', 'C', 'L', 'A', 'D'], qualifier: ['2', '4'], organic: ['2', '4'], tertiary: ['3'], plasticity: null, decomposition: null, grainshape: [], form: [], surface: [], particlestrength: null, rockshape: null, rockweathering: null, consistency: null } },

    // Grove grond, ISO 14688
    { categoryGroup: 3, sortOrderInGroup: 1, primaryGcCode: 'G', primaryRemark: 'Grind', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: ['1', '2', '3'], secondary: ['V', 'M', 'G', 'E', 'H', 'T', 'L', 'A', 'D', 'O', 'C'], qualifier: ['2', '4'], organic: ['2', '4'], tertiary: ['1'], plasticity: null, decomposition: null, grainshape: [], form: [], surface: [], particlestrength: null, rockshape: null, rockweathering: null, consistency: null } },
    { categoryGroup: 3, sortOrderInGroup: 2, primaryGcCode: 'Z', primaryRemark: 'Zand', disableColumnOption: { primary: [], sandmedian: ['1', '2', '3', '4', '5', '6', '13'], gravelmedian: null, secondary: ['V', 'M', 'C', 'Z', 'E', 'H', 'T', 'O', 'L', 'A', 'D'], qualifier: ['2', '4'], organic: ['2', '4'], tertiary: ['4'], plasticity: null, decomposition: null, grainshape: [], form: [], surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: null } },

    // Fijne grond, ISO 14688
    { categoryGroup: 4, sortOrderInGroup: 1, primaryGcCode: 'J', primaryRemark: 'Silt', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['V', 'M', 'S', 'E', 'H', 'C', 'T', 'O', 'L', 'A', 'D'], qualifier: ['2', '4'], organic: ['2', '4'], tertiary: ['5'], plasticity: [], decomposition: null, grainshape: null, form: null, surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: ['2', '3', '5', '6'] } },
    { categoryGroup: 4, sortOrderInGroup: 2, primaryGcCode: 'K', primaryRemark: 'Klei', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: ['V', 'M', 'K', 'E', 'H', 'T', 'O', 'C', 'L', 'A', 'D'], qualifier: ['2', '4'], organic: ['2', '4'], tertiary: ['6'], plasticity: [], decomposition: null, grainshape: null, form: null, surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: ['2', '3', '5', '6'] } },

    // Overige
    { categoryGroup: 8, sortOrderInGroup: 1, primaryGcCode: 'W', primaryRemark: 'Water', disableColumnOption: { primary: [], sandmedian: null, gravelmedian: null, secondary: null, qualifier: null, organic: null, tertiary: null, plasticity: null, decomposition: null, grainshape: null, form: null, surface: null, particlestrength: null, rockshape: null, rockweathering: null, consistency: null } }
  ],
  /**
     * A mapping between the dsFieldsProject->tblLayer to a key of the CODES->disableColumnOption->KEY
     */
  mappings: {
    primary: {
      dbField: 'LaSoilTypeCode',
      codeList: { key: 'MainTypes', sort: 'GcShortCode' }
    },
    median: {
      dbField: 'LaMedianCode',
      codeList: { key: 'Median', sort: 'GcCode' }
    },
    consistency: {
      dbField: 'LaConsistencyCode',
      codeList: { key: 'Consistency', sort: 'GcCode' }
    },
    secondary: {
      dbField: 'LaAd1TypeCode',
      codeList: { key: 'Additions', sort: 'GcShortCode' }
    },

    qualifier: {
      dbField: 'LaAd1GradationCode',
      codeList: { key: 'Gradients', sort: 'GcCode' }
    },

    organic: {
      dbField: 'LaAd2GradationCode',
      codeList: { key: 'Gradients', sort: 'GcCode' }
    },

    tertiary: {
      dbField: 'LaTextureTertiaryCode',
      codeList: { key: 'Tertiary', sort: 'GcCode' }
    },

    decomposition: {
      dbField: 'LaPeatAmorphousCode',
      codeList: { key: 'Decomposition', sort: 'GcCode' }
    },

    plasticity: {
      dbField: 'LaTexturePlasticityCode',
      codeList: { key: 'Plasticity', sort: 'GcCode' }
    },

    grainshape: {
      dbField: 'LaTextureGrainShapeCode',
      codeList: { key: 'GrainShape', sort: 'GcCode' }
    },

    form: {
      dbField: 'LaTextureFormCode',
      codeList: { key: 'Form', sort: 'GcCode' }
    },

    surface: {
      dbField: 'LaTextureSurfaceCode',
      codeList: { key: 'Surface', sort: 'GcCode' }
    },

    particlestrength: {
      dbField: 'LaTextureParticleStrengthCode',
      codeList: { key: 'ParticleStrength', sort: 'GcCode' }
    },

    rockshape: {
      dbField: 'LaRockShapeCode',
      codeList: { key: 'RockShape', sort: 'GcCode' }
    },
    rockweathering: {
      dbField: 'LaRockWeatheringCode',
      codeList: { key: 'RockWeathering', sort: 'GcCode' }
    }
  },

  rulesNL: {
    columns: ['primary', 'secondary', 'qualifier', 'tertiary'],
    rules: [
      { primary: '', secondary: '', qualifier: '', tertiary: '' }, // Empty
      { primary: 'E', secondary: '', qualifier: '', tertiary: '' }, // KEIEN
      { primary: 'E', secondary: '', qualifier: '', tertiary: '1' }, // KEIEN, met grind
      { primary: 'E', secondary: '', qualifier: '', tertiary: '4' }, // KEIEN, met zand
      { primary: 'E', secondary: '', qualifier: '', tertiary: '5' }, // KEIEN, met silt
      { primary: 'E', secondary: '', qualifier: '', tertiary: '6' }, // KEIEN, met klei
      { primary: 'P', secondary: '', qualifier: '', tertiary: '' }, // KEITJES
      { primary: 'P', secondary: '', qualifier: '', tertiary: '1' }, // KEITJES met grind
      { primary: 'P', secondary: '', qualifier: '', tertiary: '4' }, // KEITJES met zand
      { primary: 'P', secondary: '', qualifier: '', tertiary: '5' }, // KEITJES met silt
      { primary: 'P', secondary: '', qualifier: '', tertiary: '6' }, // KEITJES met klei
      { primary: 'G', secondary: '', qualifier: '', tertiary: '' }, // GRIND
      { primary: 'G', secondary: '', qualifier: '', tertiary: '2' }, // GRIND met keien
      { primary: 'G', secondary: '', qualifier: '', tertiary: '3' }, // GRIND met keitjes
      { primary: 'G', secondary: 'Z', qualifier: '1', tertiary: '' }, // GRIND, zwak zandig
      { primary: 'G', secondary: 'Z', qualifier: '3', tertiary: '' }, // GRIND, sterk zandig
      { primary: 'G', secondary: 'S', qualifier: '', tertiary: '' }, // GRIND, siltig
      { primary: 'G', secondary: 'K', qualifier: '', tertiary: '' }, // GRIND, kleiig
      { primary: 'Z', secondary: '', qualifier: '', tertiary: '' }, // ZAND
      { primary: 'Z', secondary: '', qualifier: '', tertiary: '2' }, // ZAND, met keien
      { primary: 'Z', secondary: '', qualifier: '', tertiary: '3' }, // ZAND, met keitjes
      { primary: 'Z', secondary: 'G', qualifier: '1', tertiary: '' }, // ZAND, zwak grindig
      { primary: 'Z', secondary: 'G', qualifier: '3', tertiary: '' }, // ZAND, sterk grindig
      { primary: 'Z', secondary: 'S', qualifier: '', tertiary: '' }, // ZAND, siltig
      { primary: 'Z', secondary: 'S', qualifier: '', tertiary: '1' }, // ZAND, siltig, met grind S
      { primary: 'Z', secondary: 'K', qualifier: '', tertiary: '' }, // ZAND, kleiig
      { primary: 'Z', secondary: 'K', qualifier: '', tertiary: '1' }, // ZAND, kleiiig, met grind
      { primary: 'J', secondary: '', qualifier: '', tertiary: '' }, // SILT
      { primary: 'J', secondary: '', qualifier: '', tertiary: '2' }, // SILT, met keien
      { primary: 'J', secondary: '', qualifier: '', tertiary: '3' }, // SILT, met keitjes
      { primary: 'J', secondary: 'G', qualifier: '1', tertiary: '' }, // SILT, zwak grindig
      { primary: 'J', secondary: 'G', qualifier: '3', tertiary: '' }, // SILT, sterk grindig
      { primary: 'J', secondary: 'Z', qualifier: '1', tertiary: '' }, // SILT, zwak zandig
      { primary: 'J', secondary: 'Z', qualifier: '1', tertiary: '1' }, // SILT, zwak zandig, met grind
      { primary: 'J', secondary: 'Z', qualifier: '3', tertiary: '' }, // SILT, sterk zandig
      { primary: 'J', secondary: 'Z', qualifier: '3', tertiary: '1' }, // SILT, sterk zandig, met grind
      { primary: 'K', secondary: '', qualifier: '', tertiary: '' }, // KLEI
      { primary: 'K', secondary: '', qualifier: '', tertiary: '2' }, // KLEI, met keien
      { primary: 'K', secondary: '', qualifier: '', tertiary: '3' }, // KLEI, met keitjes
      { primary: 'K', secondary: 'G', qualifier: '1', tertiary: '' }, // KLEI, zwak grindig
      { primary: 'K', secondary: 'G', qualifier: '3', tertiary: '' }, // KLEI, sterk grindig
      { primary: 'K', secondary: 'Z', qualifier: '1', tertiary: '' }, // KLEI, zwak zandig
      { primary: 'K', secondary: 'Z', qualifier: '1', tertiary: '1' }, // KLEI, zwak zandig, met grind
      { primary: 'K', secondary: 'Z', qualifier: '3', tertiary: '' }, // KLEI, sterk zandig
      { primary: 'K', secondary: 'Z', qualifier: '3', tertiary: '1' }, // KLEI, sterk zandig, met grind
      { primary: 'F', secondary: '', qualifier: '', tertiary: '' }, // DETRITUS
      { primary: 'F', secondary: 'Z', qualifier: '1', tertiary: '' }, // DETRITUS, zwak zandig
      { primary: 'F', secondary: 'Z', qualifier: '3', tertiary: '' }, // DETRITUS, sterk zandig
      { primary: 'F', secondary: 'S', qualifier: '', tertiary: '' }, // DETRITUS, siltig
      { primary: 'F', secondary: 'K', qualifier: '', tertiary: '' }, // DETRITUS, kleiig
      { primary: 'H', secondary: '', qualifier: '', tertiary: '' }, // HUMUS
      { primary: 'H', secondary: 'Z', qualifier: '1', tertiary: '' }, // HUMUS, zwak zandig
      { primary: 'H', secondary: 'Z', qualifier: '3', tertiary: '' }, // HUMUS, sterk zandig
      { primary: 'H', secondary: 'S', qualifier: '', tertiary: '' }, // HUMUS, siltig
      { primary: 'H', secondary: 'K', qualifier: '', tertiary: '' }, // HUMUS, kleiig
      { primary: 'V', secondary: '', qualifier: '', tertiary: '' }, // VEEN
      { primary: 'V', secondary: 'Z', qualifier: '1', tertiary: '' }, // VEEN, zwak zandig
      { primary: 'V', secondary: 'Z', qualifier: '3', tertiary: '' }, // VEEN, sterk zandig
      { primary: 'V', secondary: 'S', qualifier: '', tertiary: '' }, // VEEN, slitig
      { primary: 'V', secondary: 'K', qualifier: '', tertiary: '' }, // VEEN, kleiig
      { primary: 'I', secondary: '', qualifier: '', tertiary: '' }, // BRUINKOOL
      { primary: 'Y', secondary: '', qualifier: '', tertiary: '' }, // GYTTJA
      { primary: 'W', secondary: '', qualifier: '', tertiary: '' } // WATER
    ]
  }
}
