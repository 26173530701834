import $ from 'jquery'
import moment from 'moment'
import $store from '@/vuex/store'

import { SummaryAndConclusionService } from '@/services/DataWS/summaryAndConclusionService'
const summaryAndConclusionService = new SummaryAndConclusionService()

export default {
  open (self) {
    $('#modalAddSummaryAndConclusion').modal('toggle')
      .on('shown.bs.modal', function (e) {
        $('#txtSummaryAndConclusionName').focus()
      })

    let create = self.$children.find(v => v.$options.name === 'sncModal') || {}
    create.resetDefault()
  },
  delete (self) {
    self.loading = true
    $('#modalDeleteSummaryAndConclusion').modal('hide')
    summaryAndConclusionService.deleteSummaryAndConclusion($store.state.workingObject.PrID, $store.state.workingObject.SmGUID).then(res => {
      $store.commit('clearWorkingObject')
      // Get a new list of summary and conclusions becase the delete doesn't return them
      self.$store.dispatch('fetchTblSummaryConclusions', { PrID: self.$route.params.projectId })
        .then(
          () => {
            // redirect to the first sublocation if exists else redirect to the project
            if ($store.state.summaryAndConclusions.length > 0) {
              self.loading = false
              self.$router.push({
                // Same route except different SmGUID
                name: self.$router.history.current.name,

                params: {
                  'projectId': self.$route.params.projectId,
                  'SmGUID': self.$store.state.summaryAndConclusions[0].SmGUID
                }
              })
            } else {
              self.loading = false
              self.$router.push({
                name: 'project',
                params: {
                  'id': self.$route.params.projectId
                }
              })
            }
          }
        )
    }, err => {
      $('#modalDeleteSummaryAndConclusion').modal('toggle')
      self.loading = false
      console.log(err)
    })
  },
  add (self, params) {
    if (self.loading) return
    self.loading = true
    let data = {
      PrID: $store.state.workingObject.PrID,
      SmType: params.selection,
      SmDateLastChanged: moment().format('YYYY-MM-DDTHH:mm:ss')
    }
    summaryAndConclusionService.createSummaryAndConclusion(data)
      .then(result => {
        $store.commit('setSummaryAndConclusions', result)
        $('#modalAddSummaryAndConclusion').modal('hide')
        let newSnc = result.find((snc) => { return snc.SmType === data.SmType })
        self.loading = false
        let path = `/project/${data.PrID}/summaryAndConclusions/${newSnc.SmGUID}?new=true`
        self.$router.push(path)
      })
      .catch(err => {
        console.log('Catch: ' + err)
        $('#modalAddSummaryAndConclusion').modal('hide')
        let create = self.$children.find(v => v.$options.name === 'sncModal') || {}
        create.resetDefault()
        create.validationError = err
        self.loading = false
      })
  }
}
