<template>
  <div :id="'editor' + id" class="barcode">
    <div class="input-group input-left">
      <input :id="'ivBarcode' + id" type="text" class="form-control" :class="{errorBorder: !valid}" :value="validatedValueAsString" readonly="readonly" :title="validatedValueAsString"
       @click="$parent.expandPanel">
    </div>
    <i v-if="field.required" class="required">*</i>

    <div :id="'editorpanel' + id" class="editor-panel hidden animated">
      <div class="close" @click="$parent.hidePanel">
        <span class="glyphicon glyphicon-chevron-right"></span>
      </div>
      <div class="row">
        <div class="col-md-7">
          <label class="field-name">{{title}}</label>
          <div class="has-feedback">
            <input v-model="currentValueAsString" type="text" class="form-control" @blur="addBarcode()" v-on:keyup.enter="addBarcode">
            <span class="form-control-feedback glyphicon glyphicon-plus" @click="addBarcode"></span>
            <label v-if="!valid" class="error">{{errorMessage}}</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div v-if="validatedValue" class="col-md-4" >
          <div v-for="(item, key) in validatedValue.split(';')" :key="key" class="has-feedback" >
            <label class="form-control barcode-label">{{item}}</label>
            <span class="form-control-feedback glyphicon glyphicon-remove" @click="removeBarcode(item)"></span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
'use strict'
import stringUtils from '@/utils/stringUtils'
import editorUtils from '@/utils/editorUtils'

/**
 * Editor with which users can add/remove barcodes.
 */

export default {
  name: 'barcodeEditor',
  props: ['field', 'functions', 'options'],

  data () {
    return {
      id: this._uid,
      currentValue: '',
      errorMessage: null,
      valid: true,
      title: String
    }
  },
  computed: {
    /**
       * Computed property used to get and set the validated value of the
       */
    validatedValue: {
      get () {
        let val = editorUtils.getEditorValue(this.field)
        this.setCurrentValue(val)
        return val
      },
      set (value) {
        let result = editorUtils.setEditorValue(this.field, value)
        if (result.hasChanges) {
          this.$store.commit('updateWorkingObject', result.values)
        }
      }
    },
    validatedValueAsString: {
      get () {
        return this.validatedValue === undefined ? '' : this.validatedValue + ''
      }
    },

    /**
       * Computed property used to get and set whether the validation of the current value has finished.
       */
    activeEditorValidationPending: {
      get () {
        return this.$store.state.activeEditorValidationPending
      },
      set (val) {
        this.$store.commit('setActiveEditorValidationPending', val)
      }
    },

    currentValueAsString: {
      get () { // take the currentvalue, substract the validatedValue and strip the ; from the front
        if ((typeof this.validatedValue !== 'undefined') && (this.validatedValue !== '')) { // no validated value so just use currentvalue
          return this.currentValue.substring(this.validatedValue.length).replace(';', '')
        } else {
          return this.currentValue
        }
      },
      set (val) {
        let arr = []
        if ((typeof this.validatedValue !== 'undefined') && this.validatedValue !== '') arr.push(this.validatedValue)
        if ((typeof val !== 'undefined') && (val !== '')) arr.push(val)
        this.currentValue = arr.join(';')
      }
    }
  },

  watch: {
    'currentValueAsString': function (value) {
      if ((!this.valid) && (value === '')) { // just in case the field is invalid, the person can clear it and leave
        this.validateCurrentValue(this.currentValue)
      }
    }
  },

  mounted () {
    this.value = this.field.value
    this.title = this.field.title
  },

  methods: {
    setCurrentValue (value) {
      this.currentValue = value
      this.valid = true
    },
    addBarcode () {
      this.activeEditorValidationPending = true
      this.validateCurrentValue(this.currentValue)
    },
    removeBarcode (barcode) {
      if (typeof barcode !== 'undefined') {
        let regex = new RegExp('(^|;)' + stringUtils.escapeRegExp(barcode) + '($|;)') // finds barcode in validatedvalue
        let match = this.validatedValue.match(regex)
        let replaceWith = ''
        if (match[1] === match[2] && (match[1] === ';')) {
          replaceWith = ';'
        }
        this.validatedValue = this.validatedValue.replace(regex, replaceWith)
      }
    },
    validateCurrentValue (val) {
      // validate field and value using TerraIndexValidator
      this.$validateEditor(this.field, val).then(() => {
        // set valid to true and set val to validatedValues as validation has succeeded
        this.valid = true
        this.validatedValue = val
      }).catch(reason => {
        // extract reason and set valid to false
        if (typeof reason.message === 'undefined') {
          console.error(reason)
        }
        this.valid = false
        this.errorMessage = reason.message
      }).finally(() => {
        // validation has finished
        this.activeEditorValidationPending = false
      })
    }
  }
}
</script>

<style lang="less">
  .barcode{
    .editor-panel{
      padding-right: 40px;
      .row{margin-bottom: 10px}
    }
    .barcode-label{
      background: #67ac45;
      border: none;
      color: #fff;
      padding-right: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &~.form-control-feedback{
        top: 0 !important;
        color: #fff;
      }
    }
  }
</style>
