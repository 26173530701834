<template>
  <div :id="'editor' + id" class="spec-comp" @keyup="keyboardArrow">
    <div class="input-group input-left">
      <textarea
        class="form-control texture-area"
        :class="{ errorBorder: !valid }"
        readonly
        rows="3"
        :value="validatedValueAsString"
        :title="validatedValueAsString"
        @click="$parent.expandPanel"
      ></textarea>
    </div>
    <i v-if="field.systemRequired || field.userRequired" class="required">*</i>

    <div
      :id="'editorpanel' + id"
      class="editor-panel hidden animated"
      @keyup.enter="addCode"
    >
      <div class="close" @click="$parent.hidePanel">
        <span class="glyphicon glyphicon-chevron-right"></span>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label class="field-name">{{ title }}</label>
          <div class="row">
            <div class="col-md-3">
              <div class="has-feedback">
                <input
                  :id="'input' + id"
                  v-model="searchString"
                  type="text"
                  class="form-control"
                />
                <span
                  class="form-control-feedback glyphicon glyphicon-plus"
                  @click="addCode"
                ></span>
              </div>
            </div>
            <div v-if="!valid" class="col-md-9">
              <div class="has-feedback">
                <label class="error">{{ errorMessage }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          v-for="(item, key) in codeList"
          :key="key"
          class="col-md-3"
          :title="item.description"
        >
          <div class="has-feedback">
            <label class="form-control barcode-label">{{
              item.description
            }}</label>
            <span
              class="form-control-feedback glyphicon glyphicon-remove"
              @click="removeCode(key)"
            ></span>
          </div>
        </div>
      </div>

      <div class="row">
        <div v-for="list in listGroups" :key="list.index" class="col-md-4">
          <div class="list-title">
            <input
              v-if="list.userRequired !== null"
              :id="'boolean' + list.name + id"
              type="checkbox"
              data-toggle="toggle"
            />
            {{ list.title }}
          </div>
          <div
            class="panel panel-default panel-list"
            :class="[activeListIndex === list.index ? cssListGroupActived : '']"
          >
            <div class="panel-heading">
              {{
                list.selected
                  ? list.selected.text + "-" + list.selected.desc
                  : ""
              }}
            </div>
            <div :id="list.name + 'list' + id" class="list-group type-list">
              <a
                v-for="(item, key) in list.source"
                :key="key"
                href="javascript:"
                :class="[
                  cssNormal,
                  isItemSelected(list, item) ? cssSelected : '',
                  isItemActivated(list, item) ? cssActived : '',
                ]"
                class="list-group-item"
                @click="clickOption(item, list.index)"
                >{{ item.desc }}
                <span v-if="isItemSelected(list, item)" style="float: right"
                  >selected</span
                >
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
'use strict'
import Vue from 'vue'
import _ from 'lodash'
import $ from 'jquery'
import stringUtils from '@/utils/stringUtils'
import fieldCodeUtils from '@/utils/fieldCodeUtils'
import sortUtils from '@/utils/sortUtils'
import editorUtils from '@/utils/editorUtils'

/**
 * Editor with which users can set special compound(s) by selecting a type, gradation and/or source.
 */
export default {
  name: 'specCompEditor',
  props: ['field', 'functions', 'options'],

  data () {
    return {
      id: this._uid,
      value: String,
      errorMessage: '',
      valid: true,

      title: String,

      lang: Vue.config.lang,
      relationLang: {
        nl: 'nld',
        en: 'eng',
        fr: 'fra',
        es: 'spa',
        de: 'deu',
        it: 'ita'
      },

      cssNormal: 'list-group-item',
      cssDisabled: 'disabled',
      cssSelected: 'selected',
      cssActived: 'actived',
      cssListGroup: 'listgroup',
      cssListGroupActived: 'listgroup-actived',

      listGroups: [
        {
          index: 0,
          name: 'first',
          title: this.$t('label.bzb.type'),
          fieldKey: 'LaSpecialCompounds',
          systemRequired: true,
          userRequired: null,
          isUnique: true,
          source: []
        },
        {
          index: 1,
          name: 'second',
          title: this.$t('label.bzb.gradation'),
          fieldKey: 'LaSpecialCompoundsGradiation',
          systemRequired: true,
          userRequired: null,
          isUnique: false,
          source: []
        },
        {
          index: 2,
          name: 'third',
          title: this.$t('label.bzb.source'),
          fieldKey: 'LaSpecialCompoundsOrigin',
          systemRequired: false,
          userRequired: false,
          isUnique: false,
          source: []
        }
      ],

      originalListGroups: {
        LaSpecialCompounds: [],
        LaSpecialCompoundsGradiation: [],
        LaSpecialCompoundsOrigin: []
      },

      activeListIndex: 0,

      maxListLength: 7,

      // first_* = type of *th special compound,
      // second** = gradation of *th special compound,
      // third_* = source of *th special compound,
      currentValues: {
        first_1: '',
        second_1: '',
        third_1: '',
        first_2: '',
        second_2: '',
        third_2: '',
        first_3: '',
        second_3: '',
        third_3: '',
        first_4: '',
        second_4: '',
        third_4: ''
      },

      searchString: ''
    }
  },

  computed: {
    codeList: {
      get () {
        let list = []

        for (let i = 1; i <= this.maxListLength; i++) {
          // if one of the keys has a value
          if (
            this.validatedValues['first_' + i] ||
            this.validatedValues['second_' + i] ||
            this.validatedValues['third_' + i]
          ) {
            list.push(
              // get code object for current value
              this.getCodeObject(
                this.validatedValues['first_' + i],
                this.validatedValues['second_' + i],
                this.validatedValues['third_' + i]
              )
            )
          }
        }

        return list
      }
    },
    /**
     * Computed property used to get the validated value as it should be shown to the user of the property
     * corresponding to this instance of the SpecialCompoundEditor.
     */
    validatedValueAsString () {
      let result = ''

      for (let i = 1; i <= this.maxListLength; i++) {
        // if one of the keys has a value
        if (
          this.validatedValues['first_' + i] ||
          this.validatedValues['second_' + i] ||
          this.validatedValues['third_' + i]
        ) {
          // get code and append text to current string
          let code = this.getCodeObject(
            this.validatedValues['first_' + i],
            this.validatedValues['second_' + i],
            this.validatedValues['third_' + i]
          )

          result += '- ' + code.description + '; \n'
        }
      }

      return result
    },
    /**
     * Computed property used to get and set the validated value of the property corresponding to this instance of
     * the SpecialCompoundEditor.
     */
    validatedValues: {
      get () {
        // getEditorValue return the concatenated value (for example 'ZK1G2') and sets the underlying object in
        // this.field.rawData
        editorUtils.getEditorValue(this.field)

        let val = this.field.rawData

        // overwrite current value with 'valid' value from store and set valid to true. get() is called in the following cases:
        // - when the value of this.$store.state.workingObject[this.field.key] changed (e.g. because side effect or switching workingObject)
        // - when initializing the editor
        this.setCurrentValue(_.cloneDeep(val))

        return val
      },
      set (value) {
        let result = editorUtils.setEditorValue(this.field, value)

        if (result.hasChanges) {
          this.$store.commit('updateWorkingObject', result.values)
        }
      }
    },
    /**
     * Computed property used to get and set whether the validation of the current value has finished.
     */
    activeEditorValidationPending: {
      get () {
        return this.$store.state.activeEditorValidationPending
      },
      set (val) {
        this.$store.commit('setActiveEditorValidationPending', val)
      }
    }
  },

  watch: {
    searchString: {
      handler: function (val, oldVal) {
        // reset shown error message
        this.errorMessage = ''

        // if type could not be set from string, then return
        if (!this.setSelectedTypeFromString(val)) {
          this.setSelectedItem(2, null)
          this.setSelectedItem(1, null)
          this.setSelectedItem(0, null)

          return
        }

        // if gradation could not be set from string, then return
        if (!this.setSelectedGradationFromString(val)) {
          this.setSelectedItem(2, null)
          this.setSelectedItem(1, null)

          return
        }

        // try to set selected source from string
        if (!this.setSelectedSourceFromString(val)) {
          this.setSelectedItem(2, null)
        }
      }
    }
  },

  mounted () {
    if (!this.field) return
    this.setFieldRawsValues()
    this.initLists()
    this.initOptionListEvent()
    this.title = this.field.title
  },

  methods: {
    setFieldRawsValues () {
      let keyValue = {
        LaSpecialCompounds: [
          'first_1',
          'first_2',
          'first_3',
          'first_4',
          'first_5',
          'first_6',
          'first_7'
        ],
        LaSpecialCompoundsGradiation: [
          'second_1',
          'second_2',
          'second_3',
          'second_4',
          'second_5',
          'second_6',
          'second_7'
        ],
        LaSpecialCompoundsOrigin: [
          'third_1',
          'third_2',
          'third_3',
          'third_4',
          'third_5',
          'third_6',
          'third_7'
        ]
      }

      this.field.raws.forEach((raw) => {
        raw.values = []
        let key = raw.key
        keyValue[key].forEach((value) => {
          if (this.field.rawData[value] && this.field.rawData[value] !== '') {
            raw.values.push(this.field.rawData[value])
          }
        })
      })
    },
    setCurrentValue (value) {
      this.currentValues = value
      this.valid = true
    },
    validateCurrentValues: _.debounce(function (val) {
      // validate field and value using TerraIndexValidator
      this.$validateEditor(this.field, val, { listGroups: this.listGroups })
        .then(() => {
          // set valid to true and set val to validatedValues as validation has succeeded
          this.valid = true
          this.validatedValues = _.cloneDeep(this.currentValues)

          this.searchString = ''
          this.activeListIndex = 0

          // reset search string and active list index
          this.errorMessage = ''
        })
        .catch(this.showErrorMessage)
        .finally(() => {
          // validation has finished
          this.activeEditorValidationPending = false
        })
    }, 100),

    getCodeObject (typeValue, gradationValue, sourceValue) {
      let values = []
      let texts = []
      let descriptions = []
      let result = {
        first: '',
        second: '',
        third: '',
        value: '',
        text: '',
        description: ''
      }

      for (let list of this.listGroups) {
        let item

        if (list.fieldKey === 'LaSpecialCompounds') {
          item = this.originalListGroups.LaSpecialCompounds.find(
            (s) => s.value === typeValue
          )
        } else if (list.fieldKey === 'LaSpecialCompoundsGradiation') {
          item = this.originalListGroups.LaSpecialCompoundsGradiation.find(
            (s) => s.value === gradationValue
          )
        } else if (list.fieldKey === 'LaSpecialCompoundsOrigin') {
          item = this.originalListGroups.LaSpecialCompoundsOrigin.find(
            (s) => s.value === sourceValue
          )
        }

        if (item) {
          values.push(item.value)
          texts.push(item.text)
          if (item.desc) {
            descriptions.push(stringUtils.capitalizeFirstLetter(item.desc))
          } else {
            let textItem = item.text[0]
            if (textItem) {
              let languageCode = stringUtils.convert2To3LetterLanguageCode(
                this.lang
              )
              descriptions.push(
                stringUtils.capitalizeFirstLetter(textItem[languageCode])
              )
            }
          }

          result[list.name] = item.value
        }
      }

      result.value = values.join('')
      result.text = texts.join('')
      result.description = descriptions.join('; ')
      return result
    },

    initLists () {
      for (let list of this.listGroups) {
        this.originalListGroups[list.fieldKey] = this.field.raws.find(
          (v) => v.key === list.fieldKey
        ).options
        list.source = fieldCodeUtils
          .transformCodes(this, list.fieldKey)
          .sort((a, b) => sortUtils.naturalSort(a.desc, b.desc))
      }

      this.activeListIndex = 0
    },

    isItemSelected (list, item) {
      return (
        item.value ===
        this.currentValues[list.name + '_' + this.getCurrentIndex()]
      )
    },

    isItemActivated (list, item) {
      return (
        this.activeListIndex === list.index &&
        item.value ===
          this.currentValues[list.name + '_' + this.getCurrentIndex()]
      )
    },

    addCode () {
      if (this.codeList.length === this.maxListLength) {
        this.valid = false
        this.errorMessage = this.$t('message.allowed_maxListLength').replace(
          '<maxListLength>',
          this.maxListLength
        )
        return
      }
      // set activeEditorValidationPending to true, so that navigation is blocked until validation is done
      this.activeEditorValidationPending = true
      this.validateCurrentValues(this.currentValues)
    },

    checkCurrentEntriesUnique () {
      let context = {
        listGroups: this.listGroups
      }
      return this.$validatorFunctions.specialCompoundUniqueValidator(
        this.field,
        this.currentValues,
        context
      )
    },

    checkCurrentEntryUserRequiredParts () {
      return this.checkEntryUserRequiredParts(this.getCurrentIndex())
    },

    checkEntryUserRequiredParts (entryIndex) {
      let context = {
        listGroups: this.listGroups,
        requiredGroups: this.listGroups.filter(
          (group) => group.systemRequired || group.userRequired
        ),
        index: entryIndex
      }

      return this.$validatorFunctions.specialCompoundRequiredSet(
        this.field,
        this.currentValues,
        context
      )
    },

    checkEntrySystemRequiredParts (entryIndex) {
      let context = {
        listGroups: this.listGroups,
        requiredGroups: this.listGroups.filter((group) => group.systemRequired),
        index: entryIndex
      }

      return this.$validatorFunctions.specialCompoundRequiredSet(
        this.field,
        this.currentValues,
        context
      )
    },

    resetEntriesWithoutRequiredParts () {
      for (let i = 1; i < 5; i++) {
        this.checkEntrySystemRequiredParts(i).catch(() => {
          resetEntry(i)
        })
      }

      function resetEntry (index) {
        this.currentValues['first_' + index] = ''
        this.currentValues['second_' + index] = ''
        this.currentValues['third_' + index] = ''
      }
    },

    removeCode (index) {
      // set activeEditorValidationPending to true, so that navigation is blocked until validation is done
      this.activeEditorValidationPending = true

      // index in current values starts at one
      index = index + 1

      // shift all elements one forward
      for (let i = index; i < this.maxListLength; i++) {
        this.currentValues['first_' + i] = this.currentValues['first_' + (i + 1)]
        this.currentValues['second_' + i] = this.currentValues['second_' + (i + 1)]
        this.currentValues['third_' + i] = this.currentValues['third_' + (i + 1)]
      }

      // 'remove' last element
      this.currentValues['first_' + this.maxListLength] = ''
      this.currentValues['second_' + this.maxListLength] = ''
      this.currentValues['third_' + this.maxListLength] = ''

      // If there are currently incomplete parts validation will always fail, so reset those.
      this.resetEntriesWithoutRequiredParts()
      this.validateCurrentValues(this.currentValues)
    },

    setSelectedItem (listindex, item) {
      let list = this.listGroups[listindex]

      if (item) {
        this.activeListIndex = list.index + 1
        this.currentValues[list.name + '_' + this.getCurrentIndex()] =
          item.value
      } else {
        this.activeListIndex = list.index
        this.currentValues[list.name + '_' + this.getCurrentIndex()] = ''
      }
    },

    showErrorMessage (reason) {
      this.valid = false
      this.errorMessage = reason.message
    },

    hideErrorMessage (reason) {
      this.valid = true
    },

    clickOption (item, listindex) {
      this.setSelectedItem(listindex, item)
      this.checkCurrentEntriesUnique()
        .then(this.hideErrorMessage, this.showErrorMessage)
        .then(this.checkCurrentEntryUserRequiredParts)
        .then(saveCode.bind(this), () => {}) // Ignore any uncatched errors (from pre-checks)

      function saveCode () {
        this.addCode()
        this.focus()
      }
    },

    focus () {
      $('#input' + this.id).focus() // .setCursorPosition(1)
    },

    getActivatedListItemIndex () {
      let list = this.listGroups[this.activeListIndex]

      let index = list.source.findIndex(
        (v) =>
          v.value ===
          this.currentValues[list.name + '_' + this.getCurrentIndex()]
      )

      return index === -1 ? 0 : index
    },

    setActivatedListItem (list, item, orientation) {
      if (!item) return

      this.currentValues[list.name + '_' + this.getCurrentIndex()] = item.value

      let listid = '#' + list.name + 'list' + this.id
      let at = $(listid + ' .list-group-item.actived').get(0)
      if (!at) return

      let container = $(listid)
      // var boxHeight = container.height()
      let interval = orientation
        ? at.offsetTop - container.offset().top + container.scrollTop() - 20
        : at.offsetTop - container.offset().top + container.scrollTop() - 100
      container.scrollTop(interval)
    },

    gotoNextListItem () {
      let itemindex = this.getActivatedListItemIndex()
      let list = this.listGroups[this.activeListIndex]

      if (itemindex + 1 >= list.source.length) {
        return
      } else {
        itemindex++
      }

      let item = list.source[itemindex]
      this.setActivatedListItem(list.name, item, 1)
    },

    gotoPrevListItem () {
      let itemindex = this.getActivatedListItemIndex()
      let list = this.listGroups[this.activeListIndex]

      if (itemindex === 0) {
        return
      } else {
        itemindex--
      }

      let item = list.source[itemindex]
      this.setActivatedListItem(list.name, item, 0)
    },

    gotoLeftListItem () {
      let listindex = this.activeListIndex
      if (listindex === 0) return

      let prevlist = this.listGroups[listindex - 1]

      let item = this.currentValues[prevlist.name + '_' + this.getCurrentIndex()]
        ? prevlist.source.find(
          (v) =>
            v.value ===
              this.currentValues[prevlist.name + '_' + this.getCurrentIndex()]
        )
        : null

      if (!item) {
        item = prevlist.source[0]
      }

      this.activeListIndex = prevlist.index
      this.setActivatedListItem(prevlist.name, item, 0)
    },

    gotoRightListItem () {
      let nextlistindex = this.activeListIndex + 1
      if (nextlistindex === this.listGroups.length) return

      let nextlist = this.listGroups[nextlistindex]

      let item = this.currentValues[nextlist.name + '_' + this.getCurrentIndex()]
        ? nextlist.source.find((v) =>
          v.value === this.currentValues[nextlist.name + '_' + this.getCurrentIndex()]
        )
        : null

      if (!item) {
        item = nextlist.source[0]
      }

      this.activeListIndex = nextlist.index
      this.setActivatedListItem(nextlist.name, item, 0)
    },

    keyboardArrow (evt) {
      evt = evt || window.event
      if (evt.keyCode) {
        if (evt.keyCode === 38) {
          // up arrow
          this.gotoPrevListItem()
          evt.stopPropagation()
        } else if (evt.keyCode === 40) {
          // down arrow
          this.gotoNextListItem()
          evt.stopPropagation()
        } else if (evt.keyCode === 39) {
          // right arrow
          this.gotoRightListItem()
          evt.stopPropagation()
        } else if (evt.keyCode === 37) {
          // left arrow
          this.gotoLeftListItem()
          evt.stopPropagation()
        }
      }
    },

    initOptionListEvent () {
      let control = this

      for (let list of this.listGroups) {
        let optionobj = $('#boolean' + list.name + control.id)
        optionobj.bootstrapToggle({
          on: '',
          off: '',
          onstyle: 'success',
          style: 'ios'
        })

        optionobj.change(function () {
          list.userRequired = $(this).prop('checked')
          control.focus()
        })
      }
    },

    getCurrentIndex () {
      return this.codeList.length + 1
    },

    /**
     * Get selected type from search string
     * @param val String containing the search
     */
    setSelectedTypeFromString (val) {
      if (val.length < 2) {
        return false
      }

      let typeText = val.substring(0, 2)
      let typeItem = this.listGroups[0].source.find(
        (v) => v.text.toLowerCase() === typeText.toLowerCase()
      )

      this.setSelectedItem(0, typeItem)

      return typeItem || false
    },

    /**
     * Get selected gradation from search string
     * @param val String containing the search
     */
    setSelectedGradationFromString (val) {
      // try to match with type item
      if (val.length <= 2) {
        return false
      }

      let gradationText = val[2]

      // if there is a fourt character and it is a number, then add to search text
      if (val.length > 3 && !isNaN(val[3])) {
        gradationText += val[3]
      }

      let gradationItem = this.listGroups[1].source.find(
        (v) => v.text.toLowerCase() === gradationText.toLowerCase()
      )

      this.setSelectedItem(1, gradationItem)

      return gradationItem || false
    },

    /**
     * Get selected source from search string
     * @param val String containing the search
     */
    setSelectedSourceFromString (val) {
      // try to match with type item
      if (val.length < 2) {
        return false
      }

      // get last two characters of string
      let gradationText = val.slice(-2)

      if (!/^[a-zA-Z]+$/.test(gradationText)) {
        return false
      }

      let gradationItem = this.listGroups[2].source.find(
        (v) => v.text.toLowerCase() === gradationText.toLowerCase()
      )

      this.setSelectedItem(2, gradationItem)

      return gradationItem || false
    }
  }
}
</script>

<style lang="less">
.has-feedback {
  max-width: 100%;
}
.spec-comp {
  .editor-panel {
    .row {
      margin-bottom: 10px;
    }
  }
  .barcode-label {
    background: #67ac45;
    border: none;
    color: #fff;
    padding-right: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    & ~ .form-control-feedback {
      top: 0 !important;
      color: #fff;
    }
  }

  .list-title {
    color: #fff;
    .toggle.ios {
      zoom: 0.6;
    }
  }
  .editor-panel .panel-list {
    margin-top: 5px;
  }
}

.type-list {
  max-height: calc(100vh - 365px) !important;
}

.list-group {
  max-height: calc(80vh - 20rem) !important;
}
</style>
