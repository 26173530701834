export default {
  type: 'TI',
  defaultGroup: 8,
  categoryGroups: [
    { GcCode: 1, SortOrder: 1 }, // Organisch, ISO 14688
    { GcCode: 2, SortOrder: 2 }, // Zeer grove grond, ISO 14688
    { GcCode: 3, SortOrder: 3 }, // Grove grond, ISO 14688
    { GcCode: 4, SortOrder: 4 }, // Fijne grond, ISO 14688
    { GcCode: 6, SortOrder: 4 }, // Stollingsgesteenten
    { GcCode: 7, SortOrder: 7 }, // Metamorfe gesteenten, ISO 14689
    { GcCode: 8, SortOrder: 8 } // Overige
  ],
  codes: [
    // Organisch, ISO 14688
    { categoryGroup: 1, sortOrderInGroup: 1, primaryGcCode: 'V', primaryRemark: 'Veen', disableColumnOption: { primary: [], median: null, additive1: ['H', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive1Gradation: [], additive2: ['H', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive2Gradation: [], additive3: ['H', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive3Gradation: [] } },

    // Zeer grove grond, ISO 14688
    { categoryGroup: 2, sortOrderInGroup: 2, primaryGcCode: 'E', primaryRemark: 'Keien', disableColumnOption: { primary: [], median: null, additive1: ['E', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive1Gradation: [], additive2: ['E', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive2Gradation: [], additive3: ['E', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive3Gradation: [] } },

    // Grove grond, ISO 14688
    { categoryGroup: 3, sortOrderInGroup: 1, primaryGcCode: 'G', primaryRemark: 'Grind', disableColumnOption: { primary: [], median: ['4', '5', '6'], additive1: ['G', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive1Gradation: [], additive2: ['G', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive2Gradation: [], additive3: ['G', 'A', 'C', 'D', 'O', 'M', 'L'], additive3Gradation: [] } },
    { categoryGroup: 3, sortOrderInGroup: 2, primaryGcCode: 'Z', primaryRemark: 'Zand', disableColumnOption: { primary: [], median: ['7', '8', '9', '10', '11', '12', '13', '14', '15', '16'], additive1: ['Z', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive1Gradation: [], additive2: ['Z', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive2Gradation: [], additive3: ['Z', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive3Gradation: [] } },

    // Fijne grond, ISO 14688
    { categoryGroup: 4, sortOrderInGroup: 2, primaryGcCode: 'K', primaryRemark: 'Klei', disableColumnOption: { primary: [], median: null, additive1: ['K', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive1Gradation: [], additive2: ['K', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive2Gradation: [], additive3: ['K', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive3Gradation: [] } },

    // Sedimentaire gesteenten, ISO 14689
    { categoryGroup: 5, sortOrderInGroup: 1, primaryGcCode: 'L', primaryRemark: 'Leem', disableColumnOption: { primary: [], median: null, additive1: ['S', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive1Gradation: [], additive2: ['S', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive2Gradation: [], additive3: ['S', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive3Gradation: [] } },

    // Overige
    { categoryGroup: 8, sortOrderInGroup: 1, primaryGcCode: 'W', primaryRemark: 'Water', disableColumnOption: { primary: [], median: null, additive1: null, additive1Gradation: null, additive2: null, additive2Gradation: null, additive3: null, additive3Gradation: null } },
    { categoryGroup: 8, sortOrderInGroup: 3, primaryGcCode: 'S', primaryRemark: 'Slib', disableColumnOption: { primary: [], median: ['4', '5', '6', '7', '8', '9'], additive1: ['S', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive1Gradation: [], additive2: ['S', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive2Gradation: [], additive3: ['S', 'A', 'C', 'D', 'O', 'M', 'L', 'T', '14'], additive3Gradation: [] } }
  ],
  /**
     * A mapping between the dsFieldsProject->tblLayer to a key of the CODES->disableColumnOption->KEY
     */
  mappings: {
    primary: {
      dbField: 'LaSoilTypeCode',
      codeList: { key: 'MainTypes', sort: 'GcShortCode' },
      required: []
    },
    median: {
      dbField: 'LaMedianCode',
      codeList: { key: 'Median', sort: 'GcCode' },
      required: ['LaSoilTypeCode']
    },
    additive1: {
      dbField: 'LaAd1TypeCode',
      codeList: { key: 'Additions', sort: 'GcCode' },
      required: ['LaSoilTypeCode']
    },
    additive1Gradation: {
      dbField: 'LaAd1GradationCode',
      codeList: { key: 'Gradients', sort: 'GcCode' },
      required: ['LaSoilTypeCode', 'LaAd1TypeCode']

    },
    additive2: {
      dbField: 'LaAd2TypeCode',
      codeList: { key: 'Additions', sort: 'GcCode' },
      required: ['LaSoilTypeCode', 'LaAd1TypeCode', 'LaAd1GradationCode']
    },
    additive2Gradation: {
      dbField: 'LaAd2GradationCode',
      codeList: { key: 'Gradients', sort: 'GcCode' },
      required: ['LaSoilTypeCode', 'LaAd1TypeCode', 'LaAd1GradationCode', 'LaAd2TypeCode']
    },
    additive3: {
      dbField: 'LaAd3TypeCode',
      codeList: { key: 'Additions', sort: 'GcCode' },
      required: ['LaSoilTypeCode', 'LaAd1TypeCode', 'LaAd1GradationCode', 'LaAd2TypeCode', 'LaAd2GradationCode']
    },
    additive3Gradation: {
      dbField: 'LaAd3GradationCode',
      codeList: { key: 'Gradients', sort: 'GcCode' },
      required: ['LaSoilTypeCode', 'LaAd1TypeCode', 'LaAd1GradationCode', 'LaAd2TypeCode', 'LaAd2GradationCode', 'LaAd3TypeCode']
    }
  }
}
