<template>
  <div :id="'editor' + id">
    <div class="input-group input-left">
      <input :id="'ivDate' + id" type="text" class="form-control" :class="{errorBorder: !valid}" readonly :value="validatedValueAsString" @click="$parent.expandPanel">
    </div>

    <div :id="'editorpanel' + id" class="editor-panel animated hidden">
      <div class="close" @click="$parent.hidePanel(valid)">
        <span class="glyphicon glyphicon-chevron-right"></span>
      </div>
      <div class="row">
        <div class="col-md-7">
          <label class="field-name">{{title}}</label>
          <!-- <div class="has-feedback form-group">
            <input type="text" class="form-control" size="16" :class="{errorBorder: !valid}" v-model="currentValueAsString" :placeholder="datepickerOptions.format" disabled />
            <span class="form-control-feedback glyphicon glyphicon-remove" @click="emptyValue"></span>
            <label v-if="!valid" class="error">{{errorMessage}}</label>
          </div> -->
        </div>
        <div class="col-md-7">
          <div class="form-group" style="display: flex; max-width: 500px">
          <input v-if="showDateField" id="date-input" v-model="dateValue" type="date" class="form-control" size="8" :style="(showTimeField) ? 'margin-right: 5px' : ''" @change="updateValue">
          <input v-if="showTimeField" id="time-input" v-model="timeValue" type="time" step="1" class="form-control" size="8" :style="(showDateField) ? 'margin-left: 5px' : ''" @change="updateValue">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
'use strict'

import Vue from 'vue'
import _ from 'lodash'
import moment from 'moment'
import editorUtils from '@/utils/editorUtils'

/**
   * Editor with which users can set a date(time) value to a property.
   */
export default {
  name: 'dateEditor',
  props: ['field', 'functions', 'options'],

  data () {
    return {
      id: this._uid,

      datepickerOptions: {
        inline: true,
        sideBySide: true,
        format: 'YYYY-MM-DD',
        useCurrent: false
      },
      isInit: false,

      title: '',
      currentValue: String,
      errorMessage: null,
      valid: true,
      dateValue: null,
      timeValue: null
    }
  },

  computed: {
    mode () {
      if (!this.options || !this.options.mode) return ''
      return this.options.mode
    },
    showTimeField () {
      if (['time', 'datetime'].includes(this.mode)) return true
      return false
    },
    showDateField () {
      if (['date', 'datetime'].includes(this.mode)) return true
      return false
    },
    validatedValueAsString: {
      get () {
        // only try to parse if set otherwise it returns 'Invalid date'
        if (this.validatedValue) {
          return moment(this.validatedValue).format(this.datepickerOptions.format)
        } else {
          return ''
        }
      }
    },

    currentValueAsString: {
      get () {
        // only try to parse if set otherwise it returns 'Invalid date'
        if (this.currentValue) {
          return moment(this.currentValue).format(this.datepickerOptions.format)
        } else {
          return ''
        }
      },
      set (val) {
        // if set, then try to parse, else empty the value
        if (val) {
          this.currentValue = moment(val, this.datepickerOptions.format).format()
        } else {
          this.emptyValue()
        }
      }
    },

    validatedValue: {
      get () {
        let val = editorUtils.getEditorValue(this.field)

        // overwrite current value with 'valid' value from store and set valid to true. get() is called in the following cases:
        // - when the value of this.$store.state.workingObject[this.field.key] changed (e.g. because side effect or switching workingObject)
        // - when initializing the editor
        this.setCurrentValue(val)

        return val
      },
      set (value) {
        let result = editorUtils.setEditorValue(this.field, value)
        if (result.hasChanges) {
          this.$store.commit('updateWorkingObject', result.values)
        }
      }
    },
    activeEditorValidationPending: {
      get () {
        return this.$store.state.activeEditorValidationPending
      },
      set (val) {
        this.$store.commit('setActiveEditorValidationPending', val)
      }
    }
  },

  watch: {
    'currentValue': function (value) {
      // set activeEditorValidationPending to true, so that navigation is blocked until validation is done
      this.activeEditorValidationPending = true

      this.validateCurrentValue(this.currentValue)
    },
    'validatedValue': function (value) {
      this.initFields(value)
    }
  },

  mounted () {
    this.field.isDate = true
    // Format using the input language or the `default` format.
    let format = this.options.formats[Vue.config.lang] || this.options.formats['default']
    if (format) { this.datepickerOptions.format = format }
    this.title = this.field.title
    this.isInit = true
    this.initFields(this.validatedValue)
  },

  methods: {
    setCurrentValue (value) {
      this.currentValue = value
      this.valid = true
    },
    initFields (value) {
      // datepicker cannot handle null values, so just set to current date otherwise we cannot make it appear (e.g. when MpDate is currently null)
      if (value && value.includes('T')) {
        const [date, time] = value.split('T')
        this.timeValue = time.split('+')[0]
        this.dateValue = date
      }
    },
    updateValue () {
      if (this.mode === 'datetime') {
        if (!this.timeValue || !this.dateValue) return
        this.currentValue = `${this.dateValue}T${this.timeValue}`
      }

      if (this.mode === 'date') {
        if (!this.dateValue) return
        this.currentValue = `${this.dateValue}T00:00:00+01:00`
      }

      if (this.mode === 'time') {
        if (!this.timeValue) return
        this.currentValue = `2022-01-25T${this.timeValue}`
      }
    },

    validateCurrentValue: _.debounce(function (val) {
      // validate field and value using TerraIndexValidator
      this.$validateEditor(this.field, val, { 'format': this.datepickerOptions.format }).then(() => {
        // set valid to true and set val to validatedValue as validation has succeeded
        this.valid = true
        this.validatedValue = val
      }).catch(reason => {
        // extract reason and set valid to false
        this.valid = false
        this.errorMessage = reason.message
      }).finally(() => {
        // validation has finished
        this.activeEditorValidationPending = false
      })
    }, 500),

    emptyValue () {
      this.currentValue = null
    }
  }
}
</script>
