<template>
 <div :id="'editor' + id">
    <div class="input-group input-left">
      <input type="text" class="form-control" :value="validatedValueAsString" :title="validatedValueAsString" readonly="readonly" @click="$parent.expandPanel">
    </div>

    <i v-if="field.required" class="required">*</i>

    <div class="editor-panel hidden animated">
      <div class="close" @click="$parent.hidePanel(valid)">
        <span class="glyphicon glyphicon-chevron-right"></span>
      </div>
      <div class="row">
        <div class="col-md-7">
          <label class="field-name">{{title}}</label>
          <div class="has-feedback">
            <input :id="'txtNumber_' + id" v-model="currentValue" type="text" class="form-control" :class="{errorBorder: !valid}">
            <span class="form-control-feedback glyphicon glyphicon-remove" @click="emptyValue"></span>
            <label v-if="!valid" class="error">{{errorMessage}}</label>
          </div>
        </div>
      </div>
      <div class="num-box">
        <div class="row">
          <div class="item" @click="boxClicked('1')">1</div>
          <div class="item" @click="boxClicked('2')">2</div>
          <div class="item" @click="boxClicked('3')">3</div>
          <div class="item" @click="boxClicked('Backspace')"><span class="glyphicons glyphicons-delete"></span></div>
        </div>
        <div class="row">
          <div class="item" @click="boxClicked('4')">4</div>
          <div class="item" @click="boxClicked('5')">5</div>
          <div class="item" @click="boxClicked('6')">6</div>
        </div>
        <div class="row">
          <div class="item" @click="boxClicked('7')">7</div>
          <div class="item" @click="boxClicked('8')">8</div>
          <div class="item" @click="boxClicked('9')">9</div>
        </div>
        <div class="row">
          <div v-if="options.allowDigits" class="item" @click="boxClicked('.')">.</div>
          <div v-else class="item disabled">.</div>
          <div class="item" @click="boxClicked('0')">0</div>
          <div v-if="options.allowMinus" class="item" @click="boxClicked('-')">-</div>
          <div v-else class="item disabled">-</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
'use strict'

import _ from 'lodash'
import $ from 'jquery'
import editorUtils from '@/utils/editorUtils'

/**
 * Editor with which users can set a numeric value to a property.
 */

export default {
  name: 'numberEditor',
  props: ['field', 'functions', 'options'], // options.allowDigits, options.allowMinus

  data () {
    return {
      id: this._uid,
      currentValue: '',
      errorMessage: null,
      title: String,
      valid: true
    }
  },

  computed: {
    validatedValue: {
      get () {
        let val = editorUtils.getEditorValue(this.field)
        // overwrite current value with 'valid' value from store and set valid to true. get() is called in the following cases:
        // - when the value of this.$store.state.workingObject[this.field.key] changed (e.g. because side effect or switching workingObject)
        // - when initializing the editor
        this.setCurrentValue(val)

        return val
      },
      set (value) {
        let result = editorUtils.setEditorValue(this.field, value)
        if (result.hasChanges) {
          this.$store.commit('updateWorkingObject', result.values)
        }
      }
    },
    validatedValueAsString: {
      get () {
        return this.validatedValue === undefined ? '' : this.validatedValue + ''
      }
    },
    activeEditorValidationPending: {
      get () {
        return this.$store.state.activeEditorValidationPending
      },
      set (val) {
        this.$store.commit('setActiveEditorValidationPending', val)
      }
    }
  },

  watch: {
    'currentValue': function (value, oldValue) {
      // set activeEditorValidationPending to true, so that navigation is blocked until validation is done
      this.activeEditorValidationPending = true

      // clean/handle characters from value that are not allowed
      let cleanedValue = this.handleDecimalValue(value)

      cleanedValue = this.handleNegativeValue(cleanedValue)

      if (cleanedValue !== value) {
        this.currentValue = cleanedValue
        // really ugly way to overwrite value in input control, but there is no other way to do this when the
        // current field is focused
        $('#txtNumber_' + this.id).val(cleanedValue)
      } else {
        this.validateCurrentValue(this.currentValue)
      }
    }
  },

  mounted () {
    this.title = this.field.title
  },

  methods: {
    setCurrentValue (value) {
      this.currentValue = value
      this.valid = true
    },
    emptyValue () {
      this.currentValue = ''
    },
    validateCurrentValue: _.debounce(function (val) {
      // validate field and value using TerraIndexValidator
      this.$validateEditor(this.field, val).then(() => {
        // set valid to true and set val to validatedValue as validation has succeeded
        this.valid = true
        this.validatedValue = val
      }).catch(reason => {
        // extract reason and set valid to false
        this.valid = false
        this.errorMessage = reason.message
      }).finally(() => {
        // validation has finished
        this.activeEditorValidationPending = false
      })
    }, 500),
    handleDecimalValue (value) {
      // if value is not defined (value is string, so we can do it like this)
      if (!value) return value

      // if we allow digits, then use .'s else remove them from the string
      let replaceCharacter = this.options.allowDigits ? '.' : ''

      // first replace all ,'s to .'s and then replace all .'s by the replace character
      return value.replace(',', '.').replace('.', replaceCharacter)
    },
    handleNegativeValue (value) {
      // if value is not defined (value is string, so we can do it like this)
      if (!value) return value

      // if we dont allow negative values, then replace the minus from the string
      if (!this.options.allowMinus) {
        value = value.replace('-', '')
      } else {
        let count = [...value].filter(char => char === '-').length

        value = value.replace(new RegExp('-', 'g'), '')

        // if count is 1, then make sure the minus is at the front of the string
        // if it is not 1 (e.g. 2), then convert to positive integer
        if (count === 1) {
          value = '-' + value
        }
      }

      return value
    },
    boxClicked (inputChar) {
      // set activeEditorValidationPending to true, so that navigation is blocked until validation is done
      this.activeEditorValidationPending = true

      // if input char is a character, then add, else remove last character from currentValue
      if (inputChar !== 'Backspace') {
        this.currentValue = this.currentValue ? this.currentValue + inputChar : inputChar
      } else {
        this.currentValue = this.currentValue.slice(0, -1)
      }
    }
  }
}
</script>

<style lang="less">
  .num-box{
    padding-right: 15px;
    padding-left: 13px;
    margin-top: 30px;
    .row{
      height: 74px;
    }
    .item{
      width: 70px;
      height: 70px;
      background: #67ac45;
      margin: 2px;
      color: #fff;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      float: left;
      cursor: pointer;
      transition: 0.5s;
      &:hover{
        cursor: pointer;
        transform: scale(0.90);
      };
      &.disabled{
        opacity: 0.7;
        cursor: not-allowed;
        display: flex !important;
      }
    }

  }
</style>
