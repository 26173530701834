<template>
  <a
    :id="template.FileName + '_' + mediaClassification"
    v-observe-visibility="visibilityChanged"
    :href="externalLink"
    target="new"
    :class="options.type"
    @contextmenu="contextMenu(template, $event)">
    <div class="item" :style="{ backgroundImage: 'url(data:image/png;base64,' + thumbnail + ')' }"></div>
  </a>
</template>
<script>
import popupUtils from '@/utils/popupUtils'
import config from '../../configurations/config.js'

export default {
  name: 'pictureBox',
  props: {
    options: {
      type: Object,
      required: true
    },
    template: {
      type: Object,
      required: false,
      default: () => ({})
    },
    mediaClassification: {
      type: String,
      required: false,
      default() {
        return config.constants.MediaClassification.ProjectPhoto
      }
    }
  },
  data () {
    return {
      styleClasses: '',
      thumbnail: ''
    }
  },
  computed: {
    selectedElement: {
      set (value) {
        this.$store.commit('setSelectedElement', value)
      },
      get () {
        return this.$store.state.selectedElement
      }
    },
    externalLink() {
      return `${this.options.externalLink}&preview=${encodeURIComponent(this.template.FileName)}`
    }
  },
  methods: {
    async visibilityChanged (isVisible) {
      if (isVisible && this.thumbnail === '') {
        await this.loadThumbnail()
      }
    },
    async loadThumbnail () {
      await this.$store.dispatch('getMediaThumbnail', {
        PrGuid: this.options.prGuid,
        FileName: this.template.FileName,
        mediaClassification: this.mediaClassification
      }).then((thumbnailCacheObject) => {
        this.thumbnail = thumbnailCacheObject.fileContent
      })
    },
    contextMenu: function (picture, e) {
      this.selectedElement = {
        type: 'PICTURE',
        id: 'picture-' + this.template.FileName,
        fileName: picture.FileName,
        mediaClassification: this.mediaClassification
      }
      popupUtils.popup(e, '#projectContextMenuPopup', { elementAnchor: 'rb', popupAnchor: 'cc' })
      e.preventDefault()
    }
  }
}
</script>

<style scoped>
.buttonClickEnabled {
  cursor: pointer;
  pointer-events: auto;
}
.loadOpacity {
  opacity: 8%;
}
</style>
