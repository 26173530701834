<template>
  <div :id="'editor' + id" class="texture-code">
    <div class="input-group input-left">
      <textarea
        class="form-control texture-area"
        :class="{ errorBorder: !valid }"
        readonly
        rows="3"
        :value="validatedValueAsLongString"
        :title="workingOBjectValueAsString"
        @click="$parent.expandPanel"
        @focus="setValidatedValue"
      ></textarea>
    </div>
    <i v-if="field.required" class="required">*</i>
    <div class="editor-panel hidden animated">
      <div class="close" @click="$parent.hidePanel(valid)">
        <span class="glyphicon glyphicon-chevron-right"></span>
      </div>
      <div class="row inner-box">
        <div class="col-lg-12">
          <div class="discription-box">
            <div class="row">
              <div class="col-md-8">
                <div id="button-container">
                  <button
                    v-show="normSelectable || mode === 'ti'"
                    :class="mode === 'ti' ? 'modeBtnStyling' : 'modeBtnStyling innactive'"
                    type="button"
                    :title="translate('tooltips.FreeTexture')"
                    @click="setModeAndRuleset('ti', '')"
                  >
                    NEN 5104
                  </button>
                  <button
                    v-show="normSelectable || (mode === 'iso' && ruleSet === '')"
                    :class="mode === 'iso' && ruleSet === '' ? 'modeBtnStyling' : 'modeBtnStyling innactive'"
                    type="button"
                    :title="translate('tooltips.ISO14688-14689')"
                    @click="setModeAndRuleset('iso', '')"
                  >
                    ISO 14688/ 14689
                  </button>
                  <button
                    v-show="normSelectable || (mode === 'iso' && ruleSet === 'rulesNL')"
                    :class="mode === 'isonl' && ruleSet === 'rulesNL' ? 'modeBtnStyling' : 'modeBtnStyling innactive'"
                    type="button"
                    :title="translate('tooltips.NENISO14688')"
                    @click="setModeAndRuleset('isonl', 'rulesNL')"
                  >
                    NEN-ISO-14688
                  </button>
                </div>
              </div>
              <div class="col-md-4">
                <div class="has-feedback">
                  <label v-if="!valid" class="error" style="margin-top: 0px">{{ inValidMessage }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 description-text description-text-texture">
                <span class="item-discription">{{ validatedValueAsLongString }}</span>
                <span class="glyphicons glyphicons-delete" @click="emptyCurrentValues()"></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 texture-lists">
              <texture-list
                v-for="(item, key) in visibleColumns"
                :key="key"
                :listId="item.dbField"
                :title="translateFreeInputMedian(key)"
                :items="getListItems(key, item.dbField)"
                :selected="getValueByListKey(key)"
                :lang="relationLang[lang]"
                class="list-column"
                :class="disabledLists(item) ? 'disabled' : ''"
                :original-source="getoriginalCodeListByFieldName(item.dbField)"
                @OnItemSelect="newItemSelected"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
'use strict'

import codeListUtils from '@/utils/codeListUtils'
import sortUtils from '@/utils/sortUtils'
import editorUtils from '@/utils/editorUtils'
import translation from '@/configurations/app/config-locales'
import Vue from 'vue'
import TextureList from '@/components/input-controls/texture/TextureList'

// This are the json files that are used to determin how what the interface should look like
import ISO14688 from '@/components/input-controls/texture/ISO14688'
import ISO14688NL from '@/components/input-controls/texture/ISO14688NL'
import FreeInput from '@/components/input-controls/texture/FreeInput'

/**
 * List of data fields that are statically ordered
 * The ordering of these items is dictated by the ISO14688 standard.
 */
const ORDERED_DATA_FIELDS = [
  'LaSoilTypeCode',
  'LaMedianCode',
  'LaConsistencyCode',
  'LaAd1GradationCode',
  'LaAd1TypeCode',
  'LaAd2GradationCode',
  'LaAd2TypeCode',
  'LaAd3GradationCode',
  'LaAd3TypeCode',
  'LaAd4TypeCode',
  'LaAd4GradationCode',
  'LaPeatAmorphousCode',
  'LaRockShapeCode',
  'LaRockWeatheringCode',
  'LaTextureFormCode',
  'LaTextureGrainShapeCode',
  'LaTextureParticleStrengthCode',
  'LaTexturePlasticityCode',
  'LaTextureSurfaceCode',
  'LaTextureTertiaryCode'
]

/**
 * Editor with which users can set a textual value (excl. new lines) to a property.
 */
export default {
  name: 'textureCodeEditor',
  props: ['field', 'functions', 'options'],
  components: {
    TextureList
  },
  data() {
    return {
      id: this._uid,
      lang: Vue.config.lang,
      currentFieldName: null,
      currentValue: Object,
      errorMessage: null,
      title: String,
      valid: true,
      inValidMessage: '',
      mode: 'ti',
      ruleSet: '',
      codeLists: {},
      originalCodeLists: {},
      codeRule: {},
      possibleObtions: [],
      relationLang: {
        nl: 'nld',
        en: 'eng',
        fr: 'fra',
        es: 'spa',
        de: 'deu',
        it: 'ita'
      },
      normSelectable: false
    }
  },

  computed: {
    workingObject: {
      get() {
        return this.$store.state.workingObject
      }
    },
    inputModel: {
      get() {
        if (this.mode === 'iso') return ISO14688
        if (this.mode === 'isonl') return ISO14688NL
        return FreeInput
      }
    },
    isISOInput() {
      return this.mode === 'iso' || this.mode === 'isonl'
    },
    validatedValue: {
      get() {
        // texteditor
        editorUtils.getEditorValue(this.field)

        let val = this.field.rawData
        this.setCurrentValue(val)
        return val
      },
      set(value) {
        let result = editorUtils.setEditorValue(this.field, value)
        if (result.hasChanges) {
          this.$store.commit('updateWorkingObject', result.values)
        }
      }
    },
    codeText: {
      get() {
        const result = []
        ORDERED_DATA_FIELDS.forEach((fieldName) => {
          if (this.workingObject[fieldName]) {
            result.push(this.workingObject[fieldName])
          }
        })
        return result.join('')
      }
    },
    workingobjectValueAsLongString: {
      get() {
        return this.workingObject === undefined ? '' : this.fullText('workingObject')
      }
    },
    workingOBjectValueAsString: {
      get() {
        return this.workingObject === undefined ? '' : this.codeText
      }
    },
    validatedValueAsLongString: {
      get() {
        return this.validatedValue === undefined ? '' : this.fullText('validatedValue')
      }
    },
    visibleColumns: {
      get() {
        let columns = { ...this.inputModel.mappings }

        if (this.currentValue['LaSoilTypeCode'] && this.currentValue['LaSoilTypeCode'] !== '' && this.inputModel && this.inputModel.codes) {
          if (this.codeRule && this.codeRule.disableColumnOption) {
            for (let prop in columns) {
              if (this.codeRule.disableColumnOption.hasOwnProperty(prop)) {
                if (this.codeRule.disableColumnOption[prop] === null) {
                  delete columns[prop]
                }
              } else if (prop === 'median') {
                if (this.codeRule.disableColumnOption.hasOwnProperty('sandmedian') && this.codeRule.disableColumnOption.hasOwnProperty('gravelmedian')) {
                  if (this.codeRule.disableColumnOption['sandmedian'] === null && this.codeRule.disableColumnOption['gravelmedian'] === null) {
                    delete columns[prop]
                  }
                }
              } else {
                delete columns[prop]
              }
            }
          }
        } else if (!this.currentValue['LaSoilTypeCode'] || this.currentValue['LaSoilTypeCode']) {
          for (let prop in columns) {
            if (prop !== 'primary') {
              delete columns[prop]
            }
          }
        }
        return columns
      }
    },
    templateID: {
      get() {
        return this.$store.state.currentProject.PrTemplateID
      }
    }
  },

  watch: {
    currentValue: {
      handler: function (value) {
        // set activeEditorValidationPending to true, so that navigation is blocked until validation is done
        this.setCodeRule()
        // Apply the validation rules of the rule set and create a list of all the possible options
        this.valid = true

        this.validateInput()
        if (this.valid) {
          this.validatedValue = value
        }
      },
      deep: true
    },
    mode: {
      handler: function (value) {
        this.initializeCodeList()
        this.setCodeRule()
        this.valid = true
      }
    },
    ruleSet: {
      handler: function (value) {
        if (value !== '') {
          this.buildListOfPossibleOptions()
          this.checkRulesetValid()
        }
      }
    },
    'field.templateSettings': {
      handler: function () {
        this.setTextureMode()
      },
      deep: true
    }
  },
  created() {
    this.setFieldRawsValues()
    this.initializeCodeList()
  },

  mounted() {
    this.title = this.field.title
    this.setTextureMode()
  },

  methods: {
    setTextureMode() {
      if (this.field.templateSettings && this.field.templateSettings.TextureTypeStandard) {
        // Check here if norm is set
        this.normSelectable = false
        switch (this.field.templateSettings.TextureTypeStandard) {
          case 'iso14688':
            this.setModeAndRuleset('iso', '')
            break
          case 'iso14688_nl':
            this.setModeAndRuleset('isonl', 'rulesNL')
            break
          case 'free':
          case 'nen':
            this.setModeAndRuleset('ti', '')
            break
          default:
            this.normSelectable = true
            break
        }
      } else {
        this.normSelectable = true
      }
    },
    setFieldRawsValues() {
      let keyValue = {
        LaSoilType: ['LaSoilTypeCode'],
        LaSandMedian: ['LaMedianCode'],
        LaGravelMedian: ['LaMedianCode'],
        LaSoilAdditive: ['LaAd1TypeCode', 'LaAd2TypeCode', 'LaAd3TypeCode', 'LaAd4TypeCode'],
        LaSoilAdditiveGradiation: ['LaAd1GradationCode', 'LaAd2GradationCode', 'LaAd3GradationCode', 'LaAd4GradationCode'],
        LaSoilConsistency: ['LaConsistencyCode'],
        LaTextureTertiaryCode: ['LaTextureTertiaryCode'],
        LaTexturePlasticityCode: ['LaTexturePlasticityCode'],
        LaTextureGrainShapeCode: ['LaTextureGrainShapeCode'],
        LaTextureFormCode: ['LaTextureFormCode'],
        LaTextureSurfaceCode: ['LaTextureSurfaceCode'],
        LaTextureParticleStrengthCode: ['LaTextureParticleStrengthCode'],
        LaRockShapeCode: ['LaRockShapeCode'],
        LaRockWeatheringCode: ['LaRockWeatheringCode'],
        LaPeatAmorphousCode: ['LaPeatAmorphousCode']
      }

      this.field.raws.forEach((raw) => {
        raw.values = []
        let key = raw.key
        if (keyValue[key]) {
          keyValue[key].forEach((value) => {
            if (this.field.rawData[value] && this.field.rawData[value] !== '') {
              raw.values.push(this.field.rawData[value])
            }
          })
        }
      })
    },
    // the type parameter determins what value from this is used to get the full text
    fullText(type) {
      const result = []
      /**
       * Loops through the orderd list of data fields and extract the
       * an item from the corresponding codeList that is linked to that
       * data field. Then extract the textual value based on the current
       * langiage settings
       */
      ORDERED_DATA_FIELDS.forEach((fieldName) => {
        // In the backend the consistency field is automatically filled when a median has been choosen for Silt.
        // We don't want to show this in the interface when TI is selected as mode
        if (fieldName === 'LaConsistencyCode' && this.mode === 'ti' && this[type]['LaSoilTypeCode'] === 'S') {
          return
        }
        const codeList = this.getoriginalCodeListByFieldName(fieldName)
        const value = codeList.find((item) => {
          return item.value === this[type][fieldName]
        })
        if (value) {
          result.push(this.getTranslatedValue(value))
        }
      })
      return result.join('; ')
    },
    setValidatedValue() {
      let result = {}
      ORDERED_DATA_FIELDS.forEach((fieldName) => {
        const codeList = this.getoriginalCodeListByFieldName(fieldName)
        const value = codeList.find((item) => {
          return item.value === this.workingObject[fieldName]
        })
        if (value) {
          result[fieldName] = value.value
        } else {
          result[fieldName] = ''
        }
      })
      this.validatedValue = result
    },
    setModeAndRuleset(mode, ruleset) {
      this.mode = mode
      this.ruleSet = ruleset
      this.valid = true
      if (this.isISOInput && this.ruleSet !== '') {
        this.buildListOfPossibleOptions()
      }
    },
    setCodeRule() {
      if (this.currentValue['LaSoilTypeCode'] && this.inputModel && this.inputModel.codes) {
        this.codeRule = this.inputModel.codes.find((codeRule) => {
          return codeRule.primaryGcCode === this.currentValue['LaSoilTypeCode']
        })
      }
    },
    newItemSelected(listId, value) {
      this.currentFieldName = listId
      let exceptionArray = []
      if (listId === 'LaSoilTypeCode') {
        exceptionArray.push(listId)
      } else {
        if (['ti', 'iso'].includes(this.mode) && this.inputModel && this.inputModel.mappings) {
          for (let prop in this.inputModel.mappings) {
            if (this.inputModel.mappings[prop].hasOwnProperty('required')) {
              if (!this.inputModel.mappings[prop].required.includes(listId)) {
                exceptionArray.push(this.inputModel.mappings[prop].dbField)
              }
            } else {
              exceptionArray.push(this.inputModel.mappings[prop].dbField)
            }
          }
        }
      }
      this.emptyCurrentValues(exceptionArray)
      if (this.currentValue) {
        this.currentValue[listId] = value
        if (this.isISOInput) {
          if (listId === 'LaAd2GradationCode' && value !== '') {
            this.currentValue['LaAd2TypeCode'] = 'R'
          } else if (listId === 'LaAd2GradationCode' && value === '') {
            this.currentValue['LaAd2TypeCode'] = ''
          }
        }
      }
    },
    /**
     * Gets the translated value of an option item based
     * of user language settings
     */
    getTranslatedValue(value) {
      return (value) ? value.text[0][this.relationLang[this.lang]] : ''
    },
    /**
     * Gets the mappings based on a given key
     */
    getCodelistMapping(key) {
      return this.inputModel.mappings[key]
    },
    /**
     * Given a list key, this method returns the correspong value
     * from the this.currentValue
     */
    getValueByListKey(key) {
      const codeListMapping = this.getCodelistMapping(key)
      const value = this.currentValue[codeListMapping.dbField]
      return value || -1
    },
    /**
     * This method is used to sort the primary list items
     * based on the inputModel.categoryGroups
     */
    sortPrimaryList(list) {
      const result = []
      // For each categoryGroup
      this.inputModel.categoryGroups.forEach((category) => {
        // Find the soilGroupType
        const groupValue = this.codeLists.LaSoilGroups.find((item) => {
          return item.value === category.GcCode.toString()
        })
        // Add the soilGroupType to the main list
        result.push({
          SoilTypeGroup: true,
          GcDescription: this.getTranslatedValue(groupValue)
        })
        // create the sub list of item per category group
        const subList = []
        this.codeLists.LaSoilType.forEach((code) => {
          let item = this.inputModel.codes.find((rule) => {
            // here we assign the sortOrderInGroup to the found item
            // so we can sort it later
            if (code.value === rule.primaryGcCode) {
              code.sortOrderInGroup = rule.sortOrderInGroup
              return true
            } else {
              return false
            }
          })
          if (item && item.categoryGroup === category.GcCode) {
            subList.push(code)
          }
        })
        // sort and append the sub list of items to the main list
        subList.sort((a, b) => (a.sortOrderInGroup > b.sortOrderInGroup ? 1 : -1))
        subList.forEach((itm) => result.push(itm))
      })
      return result
    },
    /**
     * This function creates list items for the TextureList control per key of the
     * inputModel.mappings[key]
     */
    getListItems(key, dbField) {
      const codeListMapping = this.getCodelistMapping(key)
      const codeList = this.getCodeListByFieldName(codeListMapping.dbField) || []
      let itemList = []
      /**
       * The primary list requires special handling since the values need to be
       * filtered from the inputModel.codes items
       */
      if (key === 'primary' && this.inputModel && this.inputModel.codes) {
        this.inputModel.codes.forEach((code) => {
          codeList.forEach((listItem) => {
            if (listItem.value === code.primaryGcCode) {
              itemList.push(listItem)
            }
          })
        })
        /**
         * The ISO primary definitions need additional sorting and category
         * placement due ISO14688 standard
         */
        if (this.isISOInput) {
          itemList = this.sortPrimaryList(itemList)
        }
      } else if (this.codeRule && this.codeRule.disableColumnOption) {
        // Because ISO has a SandMedian and and gravelMedian we need to change the key from median to one of these
        if (key === 'median' && this.mode !== 'ti') {
          if (this.currentValue['LaSoilTypeCode'] === 'G') {
            key = 'gravelmedian'
          } else if (this.currentValue['LaSoilTypeCode'] === 'Z') {
            key = 'sandmedian'
          }
        }

        itemList = codeList.filter((code) => {
          if (this.codeRule.disableColumnOption.hasOwnProperty(key)) {
            /**
             * Need to do extra filtering based on the selected value from the previous addition!
             * If an addition is chosen on one list the same value must not be selecable if the
             * next list.
             */
            let disableColumnOption = [...this.codeRule.disableColumnOption[key]]
            if (this.inputModel[this.ruleSet] && this.inputModel[this.ruleSet].overRuledRows) {
              let rule = this.inputModel[this.ruleSet].overRuledRows.find((rule) => {
                return rule.primaryGcCode === this.currentValue['LaSoilTypeCode']
              })
              if (rule && rule.disableColumnOption) {
                if (rule.disableColumnOption.hasOwnProperty(key)) {
                  disableColumnOption = rule.disableColumnOption[key]
                }
              }
            }
            if (this.mode === 'ti') {
              switch (key) {
                case 'additive3':
                  disableColumnOption.push(this.currentValue['LaAd2TypeCode'])
                  disableColumnOption.push(this.currentValue['LaAd1TypeCode'])
                  break
                case 'additive2':
                  disableColumnOption.push(this.currentValue['LaAd1TypeCode'])
                  break
              }
            }

            if (this.mode === 'iso') {
              switch (key) {
                case 'secondary2':
                  disableColumnOption.push(this.currentValue['LaAd1TypeCode'])
                  break
              }
            }
            return !disableColumnOption.includes(code.value) // check hier ook of de kolom een optie bevat
          }
        })
      }

      // Filter the code list based on the rule set if one is selected
      if (this.ruleSet !== '') {
        if (this.inputModel.hasOwnProperty(this.ruleSet)) {
          let possibleOptions = []
          // Rebuild the primary to show only the primaries that are available in the entire rule set
          if (key === 'primary') {
            this.inputModel[this.ruleSet].rules.forEach((rule) => {
              if (rule.hasOwnProperty('primary') && !possibleOptions.includes(rule.primary)) {
                possibleOptions.push(rule.primary)
              }
            })
            itemList = itemList.filter((item) => {
              return possibleOptions.includes(item.value) || item.SoilTypeGroup
            })
          } else if (this.inputModel[this.ruleSet].rules[0].hasOwnProperty(key)) {
            // Determin if the column should be filterd
            this.possibleObtions.forEach((rule) => {
              if (rule.hasOwnProperty(key) && !possibleOptions.includes(rule[key])) {
                possibleOptions.push(rule[key])
              }
            })
            itemList = itemList.filter((item) => {
              // Filter the list based on all the possible options
              return possibleOptions.includes(item.value) || item.SoilTypeGroup
            })
          }
        }
      }
      // For the free input the lists should be ordered alphabetically
      if (this.mode === 'ti' && !['median', 'additive1Gradation', 'additive2Gradation', 'additive3Gradation'].includes(key)) {
        itemList.sort((a, b) => (this.getTranslatedValue(a) > this.getTranslatedValue(b) ? 0 : -1))
      } else if (this.mode === 'ti' && key === 'median' && this.currentValue['LaSoilTypeCode'] === 'Z') {
        itemList.sort((a, b) => sortUtils.anotherNaturalSort(a.value, b.value))
      }

      if (this.isISOInput && ['consistency', 'sandmedian', 'gravelmedian'].includes(key)) {
        // Sort consistency category based on codeInterface mapping (ISO only)
        itemList = itemList.sort((a, b) => sortUtils.naturalSort(a.codeInterface[0][this.relationLang[this.lang]], b.codeInterface[0][this.relationLang[this.lang]]))
      }
      return itemList || []
    },

    /**
     * This functions builds a list of all the possible options that can be selected in the rule set
     * This list is determined on the values that are already selected
     */
    buildListOfPossibleOptions() {
      if (this.inputModel && this.ruleSet && this.inputModel.hasOwnProperty(this.ruleSet)) {
        this.possibleObtions = this.inputModel[this.ruleSet].rules
        this.possibleObtions = this.possibleObtions.filter((option) => {
          let validCounter = 0
          this.inputModel[this.ruleSet].columns.forEach((column) => {
            if (this.inputModel.mappings.hasOwnProperty(column)) {
              if (this.currentValue[this.inputModel.mappings[column].dbField] === option[column]) {
                validCounter++
              } else if (this.currentValue[this.inputModel.mappings[column].dbField] === '' || this.currentValue[this.inputModel.mappings[column].dbField] === null) {
                validCounter++
              }
            } else {
              validCounter++
            }
          })
          return validCounter === this.inputModel[this.ruleSet].columns.length
        })
      }
    },

    /**
     * Translations helper
     */
    translate(key) {
      return translation.t(key)
    },

    translateFreeInputMedian(key) {
      // have a different translation key for the freeinput median
      key = key === 'median' && !this.isISOInput ? (key = 'median-ti') : key
      return translation.t('texture.' + key)
    },
    /**
     * Setter that is used to set the current value of this
     * control.
     */
    setCurrentValue(value) {
      this.currentValue = value
    },

    /**
     * Empties the current value for certain fields
     * @parm
     * excluededProperties is an array containing string names of currentValueProperties that will not be emptied
     */
    emptyCurrentValues(excludedProperties) {
      for (let prop in this.currentValue) {
        if (excludedProperties && excludedProperties.length > 0 && !excludedProperties.includes(prop)) {
          this.currentValue[prop] = ''
        } else if (!excludedProperties) {
          this.currentValue[prop] = ''
        }
      }
    },

    /**
     * This function maps the fiel names (rawData[key]) to a codeList
     * This codeList is then used to populate the TextureList.vue tems
     */
    getCodeListByFieldName(dbField) {
      switch (dbField) {
        case 'LaSoilType':
        case 'LaSoilTypeCode':
          return this.codeLists.LaSoilType
        /**
         * Median codes require additional logic
         */
        case 'LaMedianCode':
          switch (this.currentValue['LaSoilTypeCode']) {
            case 'G':
              return this.codeLists.LaGravelMedian
            case 'S':
              return this.codeLists.LaSoilConsistency
            default:
              return this.codeLists.LaSandMedian
          }
        case 'LaConsistencyCode':
          return this.codeLists.LaSoilConsistency
        case 'LaAd1TypeCode':
        case 'LaAd2TypeCode':
        case 'LaAd3TypeCode':
        case 'LaAd4TypeCode':
          return this.codeLists.LaSoilAdditive
        case 'LaAd1GradationCode':
        case 'LaAd2GradationCode':
        case 'LaAd3GradationCode':
        case 'LaAd4GradationCode':
          return this.codeLists.LaSoilAdditiveGradiation
        case 'LaPeatAmorphousCode':
          return this.codeLists.LaPeatAmorphousCode
        case 'LaRockShapeCode':
          return this.codeLists.LaRockShapeCode
        case 'LaRockWeatheringCode':
          return this.codeLists.LaRockWeatheringCode
        case 'LaTextureFormCode':
          return this.codeLists.LaTextureFormCode
        case 'LaTextureGrainShapeCode':
          return this.codeLists.LaTextureGrainShapeCode
        case 'LaTextureParticleStrengthCode':
          return this.codeLists.LaTextureParticleStrengthCode
        case 'LaTexturePlasticityCode':
          return this.codeLists.LaTexturePlasticityCode
        case 'LaTextureSurfaceCode':
          return this.codeLists.LaTextureSurfaceCode
        case 'LaTextureTertiaryCode':
          return this.codeLists.LaTextureTertiaryCode
        default:
          return []
      }
    },

    getoriginalCodeListByFieldName(dbField) {
      switch (dbField) {
        case 'LaSoilType':
        case 'LaSoilTypeCode':
          return this.originalCodeLists.LaSoilType.options
        /**
         * Median codes require additional logic
         */
        case 'LaMedianCode':
          switch (this.currentValue['LaSoilTypeCode']) {
            case 'G':
              return this.originalCodeLists.LaGravelMedian.options
            case 'S':
              return this.originalCodeLists.LaSoilConsistency.options
            default:
              return this.originalCodeLists.LaSandMedian.options
          }
        case 'LaConsistencyCode':
          return this.originalCodeLists.LaSoilConsistency.options
        case 'LaAd1TypeCode':
        case 'LaAd2TypeCode':
        case 'LaAd3TypeCode':
        case 'LaAd4TypeCode':
          return this.originalCodeLists.LaSoilAdditive.options
        case 'LaAd1GradationCode':
        case 'LaAd2GradationCode':
        case 'LaAd3GradationCode':
        case 'LaAd4GradationCode':
          return this.originalCodeLists.LaSoilAdditiveGradiation.options
        case 'LaPeatAmorphousCode':
          return this.originalCodeLists.LaPeatAmorphousCode.options
        case 'LaRockShapeCode':
          return this.originalCodeLists.LaRockShapeCode.options
        case 'LaRockWeatheringCode':
          return this.originalCodeLists.LaRockWeatheringCode.options
        case 'LaTextureFormCode':
          return this.originalCodeLists.LaTextureFormCode.options
        case 'LaTextureGrainShapeCode':
          return this.originalCodeLists.LaTextureGrainShapeCode.options
        case 'LaTextureParticleStrengthCode':
          return this.originalCodeLists.LaTextureParticleStrengthCode.options
        case 'LaTexturePlasticityCode':
          return this.originalCodeLists.LaTexturePlasticityCode.options
        case 'LaTextureSurfaceCode':
          return this.originalCodeLists.LaTextureSurfaceCode.options
        case 'LaTextureTertiaryCode':
          return this.originalCodeLists.LaTextureTertiaryCode.options
        default:
          return []
      }
    },

    /**
     * This method constructs codeLists from the field.raw. The keys of the codelists are
     * named after the data field names to avoid additional mappings.
     */
    initializeCodeList() {
      const items = [
        'LaSoilType',
        'LaGravelMedian',
        'LaSandMedian',
        'LaSoilAdditive',
        'LaSoilAdditiveGradiation',
        'LaTextureTertiaryCode',
        'LaTexturePlasticityCode',
        'LaTextureGrainShapeCode',
        'LaTextureFormCode',
        'LaTextureSurfaceCode',
        'LaTextureParticleStrengthCode',
        'LaRockShapeCode',
        'LaRockWeatheringCode',
        'LaSoilGroups',
        'LaSoilConsistency',
        'LaPeatAmorphousCode'
      ]

      const isTerraIndexTemplate = (this.templateID === '-1')
      items.forEach((item) => {
        this.originalCodeLists[item] = this.field.raws.find(function (raw) {
          return raw.key === item
        })
        if (isTerraIndexTemplate) {
          this.codeLists[item] = codeListUtils.getVisibleCodesForField(this.originalCodeLists[item])
        } else {
          this.codeLists[item] = codeListUtils.getVisibleCodesForField(this.originalCodeLists[item], (item !== 'LaSoilGroups'))
        }
      })
    },
    /**
     * Disable lists based on the given key
     */
    disabledLists(itemKey) {
      let disableColumn = false
      if (!itemKey.required || itemKey.required.length < 0) return
      itemKey.required.forEach((dataKey) => {
        if (!this.currentValue.hasOwnProperty(dataKey) || (this.currentValue.hasOwnProperty(dataKey) && this.currentValue[dataKey] === '')) {
          disableColumn = true
        }
      })
      return disableColumn
    },

    validateInput() {
      if (this.mode === 'isonl') {
        this.buildListOfPossibleOptions()
        this.checkRulesetValid()
      }
    },

    checkRulesetValid() {
      if (this.currentValue.LaSoilTypeCode === '') {
        this.valid = true
      } else {
        this.valid = false
        this.inValidMessage = translation.t('message.invalidTextureForRule')
        this.possibleObtions.forEach((option) => {
          if (!this.valid) {
            let validCounter = 0
            this.inputModel[this.ruleSet].columns.forEach((column) => {
              if (this.inputModel.mappings.hasOwnProperty(column)) {
                if (this.currentValue[this.inputModel.mappings[column].dbField] === option[column]) {
                  validCounter++
                } else if ((this.currentValue[this.inputModel.mappings[column].dbField] === '' || this.currentValue[this.inputModel.mappings[column].dbField] === null) && option[column] === '') {
                  validCounter++
                }
              } else {
                validCounter++
              }
            })
            this.valid = validCounter === this.inputModel[this.ruleSet].columns.length
            if (this.valid) {
              this.inValidMessage = ''
            }
          }
        })
      }
    }
  }
}
</script>

<style lang="less">
.description-text {
  display: flex;
}

.texture-lists {
  display: inline-flex;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: auto;
  height: 50vh;
  width: 100%;
}

.texture-lists > div {
  min-width: 200px;
  margin: 1px;
}

.inner-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modeBtnStyling {
  border: 0;
  width: 60px;
  height: 60px;
  padding: 0;
  margin: 0 1em 0 0;
  float: left;
  background: #67ac45;
  color: #ffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &.innactive {
    opacity: 0.6;
  }
}

.description-text {
  display: inline-flex;
  flex-direction: row;
}

.list-column {
  padding-right: 1rem;
}

.clear-texture {
  cursor: pointer;
}

.texture-area {
  resize: none;
}
</style>
