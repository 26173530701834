<template>
  <a href="javascript:" :title="template.LbRemarks || ''" v-bind:class="{ active: selected, contents: true }" @contextmenu="contextMenu(template, $event)" @click="gotoLabassignment()">
    <div class="arrow"></div>
    <div class="item">
      <div>
        <p>
          <strong>{{ template.LbID }}</strong>
        </p>
        <p>{{ LbDateDisplay }}</p>
        <lab-status :template="template" />
      </div>
      <div class="side-box" v-bind:class="{ air: template.LbFieldMatrixCode == 3, water: template.LbFieldMatrixCode == 2, ground: template.LbFieldMatrixCode == 1 }"></div>
    </div>
  </a>
</template>
<script>
import getters from '@/vuex/getters'
import dateFormatter from '@/utils/dateFormatter'
import config from '@/configurations/config.js'
import popupUtils from '@/utils/popupUtils.js'
import labStatus from '@/components/box-controls/labAssignementStatus.vue'

export default {
  name: 'labAssignmentBox',
  components: {
    labStatus
  },
  props: ['template', 'functions', 'options'],

  data() {
    return {
      classes: 'contents',
      selected: false,
      detailUrl: String,
      isActive: false
    }
  },

  vuex: {
    getters: {
      getTemplateField: getters.getTemplateField
    }
  },

  computed: {
    LbDateDisplay() {
      dateFormatter.ready()
      return this.template.LbDate ? dateFormatter.formatDateOnly(this.template.LbDate) : this.$t('message.NoDateAvailable')
    },
    LbStatusDisplay() {
      return this.template.LbIsSent === 'true' ? this.$t('message.Sent') : this.$t('message.NotSent')
    },
    selectedElement: {
      set(value) {
        this.$store.commit('setSelectedElement', value)
      },
      get() {
        return this.$store.state.selectedElement
      }
    }
  },

  methods: {
    gotoLabassignment() {
      // go to step 6 of the labassignment wizard
      window.location = config.apps.labassignment + '6?PrID=' + this.template.PrID + '&LbID=' + this.template.LbID + '&clear=true&saved=true'
    },
    contextMenu: function (mp, e) {
      // user to determine which context menu to show in project.vue
      this.selectedElement = { type: 'LABASSIGNMENT', id: 'labassignement-' + this.template.LbID, labAssignment: this.template }
      this.$parent.$data.canScroll = false
      popupUtils.popup(e, '#projectContextMenuPopup', { elementAnchor: 'rb', popupAnchor: 'cc' }, () => {
        this.$parent.$data.canScroll = true
      })
      e.preventDefault()
    }
  }
}
</script>

<style scoped type="text/css">
.ground {
  background-color: #67ac45;
}
.water {
  background-color: #4586ac;
}
.air {
  background-color: #d25e88;
}

.side-box {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 12px;
  bottom: 15px;
  border-radius: 4px;
}
</style>
