<template>
  <a :ref="'mpBox-'+template.MpGuid" :class="classes" href="javascript:" :style="{cursor: (this.options || {}).enableBoxLink ? 'pointer' : 'default'}" @contextmenu="contextMenu(template, $event)" @click="tileClick">
    <div class="arrow"></div>
    <div class="item">
      <p :title="template.MpName">{{template.MpName}}</p>
      <p :title="MpTypeCodeDisplay">{{MpTypeCodeDisplay}}</p>
      <p :title="template.MpDepth">{{template.MpDepth ||'-'}}</p>
    </div>
  </a>
</template>
<script>
import stringUtils from '@/utils/stringUtils.js'
import popupUtils from '@/utils/popupUtils'

export default {
  name: 'mpBox',
  props: ['template', 'functions', 'options'],

  data () {
    return {
      classes: 'contents',
      detailUrl: String,
      isActive: false
    }
  },

  computed: {
    MpTypeCodeDisplay () {
      let mpTypeCodeList = this.$store.getters.getTemplateField('MpTypeCodeList')
      if (mpTypeCodeList instanceof Object && this.template.MpTypeCode) {
        let item = mpTypeCodeList.find(v => v.value === this.template.MpTypeCode)
        if (item) { return stringUtils.capitalizeFirstLetter(item.desc.toString()) }
      }
      return this.template.MpTypeCode || '-'
    },
    getTemplateField: {
      get () {
        return this.$store.getters.getTemplateField
      }
    },
    selectedElement: {
      set (value) {
        this.$store.commit('setSelectedElement', value)
      },
      get () {
        return this.$store.state.selectedElement
      }
    }
  },

  watch: {
    '$route.params.measurementPointId': {
      handler: function (val) {
        this.isActive = (val === this.template.MpID) || (val === this.template.MpGuid)
        if (this.isActive) {
          this.classes = 'contents active'
        } else {
          this.classes = 'contents'
        }
      },
      immediate: true,
      deep: true
    }
  },

  updated () {
    if (this.isActive) {
      this.$nextTick(() => {
        if (this.$parent.$data.canScroll) {
          this.$refs['mpBox-' + this.template.MpGuid].scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      })
    }
  },

  methods: {
    tileClick () {
      this.detailUrl = `/project/${this.template.PrID}/measurementPoint/${this.template.MpGuid}`
      if (this.$route.params.openMap === 'map') {
        this.focusOnMap()
      } else {
        this.gotoDetails()
      }
    },
    gotoDetails () {
      if ((this.options || {}).enableBoxLink) { this.$router.push(this.detailUrl) }
    },
    focusOnMap () {
      if ((this.options || {}).enableBoxLink) { this.$router.push(this.detailUrl + '/map') }
    },
    contextMenu: function (mp, e) {
      // user to determine which context menu to show in project.vue
      this.selectedElement = { type: 'MEASUREMENTPOINT', id: 'measurementpoint-' + this.template.MpID }
      mp.selected = (!mp.selected)
      if (mp.selected) {
        this.$parent.$parent.getMpGuidOnSelectContextMenu(this.template.MpGuid, this.template.MpID)
      }
      this.$parent.$data.canScroll = false
      popupUtils.popup(e, '#projectContextMenuPopupMp', { elementAnchor: 'rb', popupAnchor: 'cc' }, () => {
        this.$parent.$data.canScroll = true
      })
      e.preventDefault()
    }
  }
}
</script>
