<template>
  <a href="javascript:" v-bind:class="{ 'active': template.selected, 'contents': true}" @contextmenu="contextMenu(template, $event)" >
    <div class="arrow"></div>
    <div class="item">
      <p :title="template.PersonName">{{template.PersonName}}</p>
      <p :title="template.UserName">{{template.UserName}}</p>
    </div>
  </a>
</template>
<script>
import popupUtils from '@/utils/popupUtils'

export default {
  name: 'userBox',
  props: ['template', 'functions', 'options'],

  data () {
    return {
      classes: 'contents',
      selected: false,
      detailUrl: String,
      isActive: false
    }
  },
  computed: {
    selectedElement: {
      set (value) {
        this.$store.commit('setSelectedElement', value)
      },
      get () {
        return this.$store.state.selectedElement
      }
    },
    getTemplateField: {
      get () {
        return this.$store.state.getTemplateField
      }
    }
  },
  methods: {
    contextMenu: function (user, e) {
      // user to determine which context menu to show in project.vue
      this.selectedElement = { type: 'USER', id: 'user-' + this.template.UserName }
      user.selected = (!user.selected)
      popupUtils.popup(e, '#projectContextMenuPopup', { elementAnchor: 'rb', popupAnchor: 'cc' })
      e.preventDefault()
    }
  }
}
</script>
